import React, { FC, useCallback, useEffect, useState } from 'react'
import '../WarehouseSettings.scss'
import Checkbox from '@/components/Checkbox'
import { useFormik } from 'formik'
import { WhService } from '@/parts/WhService'
import { IWarehouseSettingsForm, SearchUnityItem, Warehouse, WarehouseService } from '@shared/types'
import { updateWarehouse, useWarehouse, warehouseToForm } from '@shared/api/warehouse'
import { showInfoModal } from '@/parts/Modals/InfoModal'
import { Collapsable } from '@/components/Collapsable'
import Button from '@/components/Button'
import { SETTINGS_FORM_INITIAL_VALUES, SettingsFormSchema } from '../WarehouseSettingsWarehouse/WarehouseSettingsForm'
import { getServicesNumber } from '@/pages/Warehouse/wr-utils'
import { WarehouseStatusInfo } from '@/parts/WarehouseStatusInfo/WarehouseStatusInfo'
import { InputText } from '@/components/InputText'
import { useServiceTypes } from '@shared/api/useDataHooks'
import { utils } from '@shared/common/utils'
import { Radio } from '@/components/Radio'
import { usePagePrompt } from '@/components/PagePrompt/PagePrompt'
import { showFeedbackModal } from '@/parts/Modals/FeedbackModal'
import { EntityType } from '@shared/api/types'

export function licenseValid(warehouse?: Warehouse): boolean {
    return warehouse?.license?.status?.title === 'CONFIRMED'
}

export const WarehouseSettingsPrices: FC = () => {
    const [currentUtilityName, setCurrentUtilityName] = useState<string>()
    const [utilitiesList, setUtilitiesList] = useState<SearchUnityItem[]>([])
    const { data: response, isLoading: isTypesLoading } = useServiceTypes()
    const { data, formValues, mutate, revalidate, loading } = useWarehouse()

    const formik = useFormik<IWarehouseSettingsForm>({
        initialValues: formValues || SETTINGS_FORM_INITIAL_VALUES,
        validationSchema: SettingsFormSchema,
        enableReinitialize: true,
        onSubmit: async (values) => {
            try {
                const updatedWarehouse = await updateWarehouse(values)
                formik.setSubmitting(false)
                showInfoModal({
                    props: {
                        title: 'Information successfully changed',
                        message: '',
                    },
                })
                mutate(updatedWarehouse)
                formik.resetForm({ values: warehouseToForm(updatedWarehouse, updatedWarehouse.id) })
            } catch (errors: any) {
                formik.setSubmitting(false)
                formik.setErrors(errors)
            }
        },
    })

    usePagePrompt({
        dirty: formik.dirty,
        entity: data
            ? {
                  id: data.id,
                  type: EntityType.warehouse,
              }
            : undefined,
    })

    const reset = useCallback(async () => {
        await revalidate()
        if (formValues) {
            formik.setValues(formValues)
        }
    }, [formik, formValues, revalidate])

    const feedback = () => {
        showFeedbackModal({
            props: {},
        })
    }

    useEffect(() => {
        if (formValues) {
            formik.setValues(formValues)
        }
    }, [loading])

    useEffect(() => {
        const staticData = response && utils.fillServices(response)
        if (staticData && staticData.unitiesList && staticData.unitiesList.length > 0) {
            setUtilitiesList(staticData.unitiesList)
            setCurrentUtilityName(staticData.unitiesList?.[0]?.name)
        }
    }, [isTypesLoading])

    const handleServiceChange = (updatedService: WarehouseService, [first, second, third]: number[]) => {
        const changedServices = {
            ...formik.values.services,
            [first]: {
                ...formik.values.services[first],
                [second]: [...formik.values.services[first][second]],
            },
        } as Record<number, Record<number, WarehouseService[]>>

        changedServices[first][second][third] = updatedService
        formik.setFieldValue('services', changedServices)
    }

    const renderServiceGroup = (serviceGroup: Record<number, any[]>, group: string) => {
        const servicesNumber = getServicesNumber(serviceGroup, [5, 8, 7, 4, 6, 2, 3])
        return (
            <Collapsable
                title={`${serviceGroup?.[1]?.[0]?.title || 'Unknown service name'} ${
                    serviceGroup?.[1]?.[0]?.is_active ? '(active)' : ''
                }`}
                isOpen={false}
            >
                <div className="collapse-box">
                    <div className="collapse-content">
                        {serviceGroup && serviceGroup[1] && (
                            <WhService
                                warehouseId={formik.values.warehouseId}
                                service={serviceGroup[1][0]}
                                highlighted
                                forcedActivated={false}
                                required={!!serviceGroup?.[1]?.[0].required}
                                onChange={(srv) => handleServiceChange(srv, [Number(group), 1, 0])}
                                key={`service-1-0-type-${1}-${serviceGroup[1][0]?.name}`}
                            />
                        )}
                        {serviceGroup &&
                            [5, 8, 7, 4, 6, 2, 3].map((serviceType) => {
                                if (!(servicesNumber > 0 && serviceGroup?.[1]?.[0].is_active)) {
                                    return null
                                }
                                return serviceGroup[serviceType]
                                    ? serviceGroup[serviceType].map((service, index) => (
                                          <div key={`service${service.title}`}>
                                              <WhService
                                                  warehouseId={formik.values.warehouseId}
                                                  service={service}
                                                  highlighted={serviceType === 1 && index === 0}
                                                  oneSelectionService={serviceType === 4}
                                                  forcedActivated={false}
                                                  disabled={
                                                      !serviceGroup[1][0].is_active ||
                                                      (service.type === 2 && !licenseValid(data))
                                                  }
                                                  required={serviceGroup[serviceType][index].required}
                                                  onChange={(srv) =>
                                                      handleServiceChange(srv, [Number(group), serviceType, index])
                                                  }
                                              />
                                          </div>
                                      ))
                                    : null
                            })}
                    </div>
                </div>
            </Collapsable>
        )
    }

    return (
        <div className="warehouse-settings-warehouse wrap">
            <div className="form-warehouse form">
                <form onSubmit={formik.handleSubmit}>
                    {data && <WarehouseStatusInfo warehouse={data} />}

                    <div className="section">
                        <div className="header">
                            <div className="title">Services & Prices</div>
                        </div>

                        <div className="form-wrap row">
                            <div className="field min-order-price">
                                <InputText
                                    isNumericString
                                    placeholder="Price"
                                    value={formik.values.min_order_price}
                                    error={formik.errors.min_order_price}
                                    decimalScale={2}
                                    fixedDecimalScale
                                    onChange={(event) => {
                                        formik.handleChange(event)
                                    }}
                                    label="Min order price"
                                    name="min_order_price"
                                    textError={false}
                                />
                            </div>
                        </div>

                        {utilitiesList && currentUtilityName !== undefined && (
                            <Radio
                                label=""
                                list={utilitiesList.map(({ name }) => ({ name, value: name }))}
                                type="wrap-options"
                                name="unity"
                                value={currentUtilityName}
                                onChange={(e) => {
                                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                                    // @ts-ignore
                                    setCurrentUtilityName(e.target.value)
                                }}
                            />
                        )}

                        {formik.values.services && (
                            <div className="services-section">
                                {Object.keys(formik.values.services)
                                    .filter((item) => Number(item) !== 0)
                                    .map((group) => {
                                        const serviceGroup = formik.values.services?.[Number(group)]
                                        return serviceGroup &&
                                            group &&
                                            serviceGroup[1][0].unity === currentUtilityName ? (
                                            <div className="form-wrap-item" key={`group-${group}`}>
                                                {renderServiceGroup(serviceGroup, group)}
                                            </div>
                                        ) : null
                                    })}
                            </div>
                        )}

                        {formik.values.conditions &&
                            formik.values.conditions.map(
                                (condition: { is_active: boolean; code: number }, index: number) => (
                                    // eslint-disable-next-line react/no-array-index-key
                                    <div className="field" key={`condition${condition.code}`}>
                                        <Checkbox
                                            value={condition.is_active}
                                            onChange={() => {
                                                const updatedConditions = [...formik.values.conditions!]
                                                updatedConditions[index].is_active = !updatedConditions[index].is_active
                                                formik.setFieldValue('conditions', updatedConditions)
                                            }}
                                            label="One day of warehousing for local delivery is free"
                                        />
                                    </div>
                                ),
                            )}
                    </div>

                    <div className="section">
                        <div className="header">
                            <div className="title">Features</div>
                        </div>
                        <div className="form-wrap">
                            <div className="left">
                                {!(formik.values.services?.[0]?.[3] && formik.values.services?.[0]?.[3].length > 0) && (
                                    <div>No features</div>
                                )}
                                <div className="features-section">
                                    {formik.values.services?.[0]?.[3].map((service, index) => (
                                        <div className="feature" key={service.title}>
                                            <WhService
                                                warehouseId={formik.values.warehouseId}
                                                service={service}
                                                onChange={(srv) => handleServiceChange(srv, [0, 3, index])}
                                                forcedActivated={false}
                                            />
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="page-footer">
                        <div className="btns">
                            <Button
                                label="Save"
                                types={['large', 'blue']}
                                type="submit"
                                loading={formik.isSubmitting}
                                disabled={!formik.isValid || !formik.dirty}
                            />
                            <Button label="Cancel" types={['large', 'plain']} onClick={reset} />
                        </div>

                        {/* eslint-disable-next-line jsx-a11y/anchor-is-valid,jsx-a11y/no-static-element-interactions */}
                        <a onClick={feedback} className="secondary-link media-desktop">
                            Contact support
                        </a>
                    </div>
                </form>
            </div>
        </div>
    )
}
