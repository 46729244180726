import React, { FC } from 'react'
import cn from 'classnames'
import './Footer.scss'
import { Link } from 'react-router-dom'
import appleIcon from 'images/apple_icon.svg'
import facebookIcon from 'images/facebook-icon.svg'
import googlePlayIcon from 'images/google_play_icon.svg'
import instagramIcon from 'images/instagram-icon.svg'
import linkedinIcon from 'images/linked_in-icon.svg'
import { getOLIMPLandingURL } from '@/utils/url'

interface IFooter {
    info: {
        phone: string
        email: string
    }
}

const landingLink = getOLIMPLandingURL()

export const Footer: FC<IFooter> = ({ info }) => (
    <footer className={cn('footer', { authorized: '$store.getters[user/auth]' })}>
        <div className="footer__columns">
            <div className="footer__column">
                <nav className="footer__nav">
                    <p className="footer__title">Company</p>
                    <ul>
                        <li className="footer__item">
                            <a href={`${landingLink}/about-us`}>About Us</a>
                        </li>
                        <li className="footer__item">
                            <a href={`${landingLink}/#media`}>In the Media</a>
                        </li>
                        <li className="footer__item">
                            <a href={`${landingLink}/#contact-us`}>Contact Us</a>
                        </li>
                    </ul>
                </nav>
                <div className="footer__support">
                    <p className="footer__title">Support</p>
                    {info && (
                        <ul>
                            <li className="footer__item">
                                <a href={`mailto:${info.email}`} rel="nofollow noopener noreferrer">
                                    {info.email}
                                </a>
                            </li>
                            <li className="footer__item">
                                <a href={`tel:${info.phone}`} rel="nofollow noopener noreferrer">
                                    {info.phone}
                                </a>
                            </li>
                        </ul>
                    )}
                </div>
            </div>
            <div className="footer__column">
                <p className="footer__title">Who we serve</p>
                <ul>
                    <li className="footer__item">
                        <a href={`${landingLink}/freight-brokers`}>Freight Brokers</a>
                    </li>
                    <li className="footer__item">
                        <a href={`${landingLink}/carriers`}>Carriers</a>
                    </li>
                    <li className="footer__item">
                        <a href={`${landingLink}/freight-forwarders`}>Freight Forwarders</a>
                    </li>
                    <li className="footer__item">
                        <a href={`${landingLink}/shippers`}>Shippers</a>
                    </li>
                    <li className="footer__item">
                        <a href={`${landingLink}/warehouses`}>Warehouses</a>
                    </li>
                </ul>
            </div>
            <div className="footer__column">
                <ul className="footer__social-links">
                    <li className="footer__social-item">
                        <a
                            href="https://www.facebook.com/OLIMPWarehousing/?ref=py_c"
                            rel="nofollow noopener noreferrer"
                        >
                            <img src={facebookIcon} alt="Facebook" className="footer__social-link-img" />
                        </a>
                    </li>
                    <li className="footer__social-item">
                        <a href="https://www.linkedin.com/company/olimpwarehousing/" rel="nofollow noopener noreferrer">
                            <img src={linkedinIcon} alt="LinkedIn" className="footer__social-link-img" />
                        </a>
                    </li>
                    <li className="footer__social-item">
                        <a href="https://www.instagram.com/olimpwarehousing/" rel="nofollow noopener noreferrer">
                            <img src={instagramIcon} alt="Instagram" className="footer__social-link-img" />
                        </a>
                    </li>
                </ul>
                <ul className="footer__apps">
                    <li className="footer__apps-item">
                        <a href="https://apps.apple.com/us/app/olimp-freight/id1594026128">
                            <img src={appleIcon} alt="Apple application link" className="footer__links-img" />
                        </a>
                    </li>
                    <li className="footer__apps-item">
                        <a href="https://play.google.com/store/apps/details?id=com.olimpfreight.app&hl=en_US&gl=US&pli=1">
                            <img
                                src={googlePlayIcon}
                                alt="Google play application link"
                                className="footer__links-img"
                            />
                        </a>
                    </li>
                </ul>
            </div>
        </div>
        <div className="footer__item_terms">
            <span className="footer__item_terms-copyright">© 2023 Olimp Corp</span>
            <span className="footer__item_terms-copyright"> • </span>
            <a className="footer__item_terms-link" href={`${landingLink}/terms-and-conditions`}>
                Terms and Conditions
            </a>
            <span className="footer__item_terms-copyright"> • </span>
            <a className="footer__item_terms-link" href={`${landingLink}/privacy-policy`}>
                Privacy Policy
            </a>
        </div>
    </footer>
)
