import React, { FC, useContext, useReducer } from 'react'
import { EntityInfo, EntityType } from '@shared/api/types'
import { EntityAction, EntityActionType, EntityState, EntityStore } from './entity'

const entityDefaultState: EntityState = {
    entityType: EntityType.carrier,
}

const EntityContext = React.createContext<EntityStore>({ state: entityDefaultState })

export const useEntityState = (): EntityStore => useContext<EntityStore>(EntityContext)

export const entityReducer = (state: EntityState, action: EntityAction): EntityState => {
    switch (action.type) {
        case EntityActionType.SET_ENTITY_STATE:
            return { ...state, entityInfo: action.entityState, entityType: action.entityType }
        case EntityActionType.UPDATE_GEO:
            return { ...state, geolocation: action.geolocation }
        default:
            throw new Error('Unknown entity action')
    }
}

export const updateGeoLocation = (dispatch?: React.Dispatch<EntityAction>): void => {
    if (navigator.geolocation && dispatch) {
        navigator.geolocation.getCurrentPosition((position) => {
            dispatch({
                type: EntityActionType.UPDATE_GEO,
                geolocation: {
                    lat: position.coords.latitude,
                    long: position.coords.longitude,
                },
            })
        })
    }
}

export const updateEntityState = (
    entityInfo: EntityInfo,
    entityType: EntityType,
    dispatch?: React.Dispatch<EntityAction>,
): void => {
    if (dispatch) {
        dispatch({
            type: EntityActionType.SET_ENTITY_STATE,
            entityState: entityInfo,
            entityType,
        })
    }
}

export const EntityProvider: FC = ({ children }) => {
    const [state, dispatch] = useReducer(entityReducer, entityDefaultState)
    return <EntityContext.Provider value={{ state, dispatch }}>{children}</EntityContext.Provider>
}
