import React, { FC } from 'react'
import { useNavigate } from 'react-router-dom'
import { SearchQuery } from '@shared/types'
import { utils } from '@shared/common/utils'
import { FormWarehouseSearch } from '@/parts/Forms'

import './PublicSearch.scss'

export const PublicSearch: FC = () => {
    const navigate = useNavigate()

    const send = (query: SearchQuery) => {
        // if (this.$store.state.user.token && this.$store.state.user.type === 'carrier') {
        //   this.$router.push({
        //     path: '/carrier/warehouses/' + utils.encodeRouteFilters(query)
        //   })
        // } else {
        //   this.$router.push({
        //     path: '/search/warehouses/' + utils.encodeRouteFilters(query),
        //   })
        // }
        navigate(`/search/warehouses/${utils.encodeRouteFilters(query)}`)
    }

    return (
        <div className="carrier-search wrapper">
            <div>
                <div className="search-wrap">
                    <div className="header">
                        <div className="icon-search" />
                        <div className="title">Warehouse search</div>
                        <div className="description">Customize your search to find the warehouse you need.</div>
                    </div>

                    {/* <FormWarehouseSearch send={send} publicQuery /> */}
                </div>
            </div>
        </div>
    )
}
