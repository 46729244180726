import React, { FC, HTMLAttributes, useCallback, useEffect, useState } from 'react'
import cn from 'classnames'
import { DropdownMenu } from '@/components/DropdownMenu'
import { useCompanyEntities } from '@shared/api/company'
import { useSelectedEntity } from '@/hooks/useSelectedEntity'
import { CarrierCommonInfo, CompanyEntities, WarehouseCommonInfo } from '@shared/types'
import { EntityType } from '@shared/api/types'
import Button from '@/components/Button'
import Icon from '@/components/Icon/Icon'
import { useSelectEntity } from '@/hooks/useSelectEntity'

import './EntitiesMenu.scss'
import { EntityStatus } from '@/components/EntityStatus'
import { TextCutter } from '@/components/TextCutter'
import { Loader } from '@/components/Loader'
import { getFullEntityStatus } from '@/utils/status'

type Entity = WarehouseCommonInfo | CarrierCommonInfo

const getFirstActiveEntity = (entities: Entity[]) =>
    entities.find((el) => el.status.code.toString().toLocaleLowerCase() === 'active')

const getDefaultSelectedEntity = (entities: CompanyEntities): { entity: Entity; type: EntityType } | null => {
    const firstActiveCarrier = getFirstActiveEntity(entities.carriers)
    const firstActiveWarehouse = getFirstActiveEntity(entities.warehouses)

    if (firstActiveCarrier) {
        return {
            entity: firstActiveCarrier,
            type: EntityType.carrier,
        }
    }

    if (firstActiveWarehouse) {
        return {
            entity: firstActiveWarehouse,
            type: EntityType.warehouse,
        }
    }

    if (entities.carriers.length > 0) {
        return {
            entity: entities.carriers[0],
            type: EntityType.carrier,
        }
    }

    if (entities.warehouses.length > 0) {
        return {
            entity: entities.warehouses[0],
            type: EntityType.warehouse,
        }
    }

    return null
}
const CLASS_NAME = 'entities-menu'

interface IMenu extends HTMLAttributes<HTMLDivElement> {}

export const EntitiesMenu: FC<IMenu> = ({ className }) => {
    const [isOpen, setIsOpen] = useState(false)
    const { data: selectedEntity, loading: selectedEntityLoading } = useSelectedEntity()
    const { data: entities, loading: entitiesLoading } = useCompanyEntities()
    const { select, loading: selectLoading } = useSelectEntity()

    const onEntityClick = useCallback(
        (type: EntityType, id: number) => {
            setIsOpen(false)
            if (id !== selectedEntity?.entity?.id || type !== selectedEntity?.type) select(type, id, true)
        },
        [selectedEntity?.entity?.id, selectedEntity?.type, select],
    )

    useEffect(() => {
        if (entities && !selectedEntity?.entity && !selectedEntityLoading) {
            const defaultSelectedEntity = getDefaultSelectedEntity(entities)

            if (defaultSelectedEntity) {
                select(defaultSelectedEntity.type, defaultSelectedEntity.entity.id, false)
            }
        }
    }, [entities, select, selectedEntity, selectedEntityLoading])

    if (selectLoading || entitiesLoading || selectedEntityLoading) {
        return (
            <div className={CLASS_NAME}>
                <Loader type="small" />
            </div>
        )
    }

    if (!entities || (entities.carriers.length === 0 && entities.warehouses.length === 0) || !selectedEntity?.entity)
        return null

    return (
        <DropdownMenu
            label={<EntityItem entity={selectedEntity.entity} type={selectedEntity.type as EntityType} />}
            isOpen={isOpen}
            className={cn(CLASS_NAME, className)}
            onClick={() => setIsOpen(true)}
        >
            {entities.warehouses.map((el) => (
                <li key={el.id}>
                    <Button
                        className={cn(`${CLASS_NAME}__btn`, {
                            [`${CLASS_NAME}__btn--selected`]:
                                el.id === selectedEntity.entity?.id && selectedEntity.type === EntityType.warehouse,
                        })}
                        onClick={() => onEntityClick(EntityType.warehouse, el.id)}
                    >
                        <EntityItem entity={el} type={EntityType.warehouse} />
                    </Button>
                </li>
            ))}
            {entities.carriers.map((el) => (
                <li key={el.id}>
                    <Button
                        className={cn(`${CLASS_NAME}__btn`, {
                            [`${CLASS_NAME}__btn--selected`]:
                                el.id === selectedEntity.entity?.id && selectedEntity.type === EntityType.carrier,
                        })}
                        onClick={() => onEntityClick(EntityType.carrier, el.id)}
                    >
                        <EntityItem entity={el} type={EntityType.carrier} />
                    </Button>
                </li>
            ))}
        </DropdownMenu>
    )
}

interface IMenuItem {
    entity: WarehouseCommonInfo | CarrierCommonInfo
    type: EntityType
}

const EntityItem: FC<IMenuItem> = ({ entity, type }) => (
    <div className={`${CLASS_NAME}__entity`}>
        <Icon type="common" code={type} />
        <EntityStatus id={entity.id} status={getFullEntityStatus(entity)} />
        <TextCutter title={entity.entity} className={`${CLASS_NAME}__entity-title`}>
            {entity.entity}
        </TextCutter>
    </div>
)
