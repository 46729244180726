import React, { FC, useEffect } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import './CompanyManagers.scss'
import { Helmet } from 'react-helmet'
import { useCompanyEntities, useCompanyManagers } from '@shared/api/company'
import { TableList } from '@/parts/Table/TableList'
import { UserInfo } from '@shared/api/types'
import Button from '@/components/Button'
import EventBus from '@shared/common/eventBus'
import { Events } from '@shared/common/events'
import { Modals } from '@shared/types'
import { showManagerModal } from '@/parts/Modals/ManagerModal'
import { filled } from '@/store/company/helpers'
import { confirmed } from '@/store/user/helpers'
import { Info } from '@/components/Info'
import { useCompanyState } from '@/store/company'
import { useUserState } from '@/store/user'
import ManagerRow from './ManagerRow/ManagerRow'
import { showFeedbackModal } from '@/parts/Modals/FeedbackModal'

const PAGE_TITLE = 'Olimp - Company Managers'
const ENTITY_TABLE_HEADERS = [
    { name: 'Email', alias: 'email' },
    { name: 'First name', alias: 'first-name' },
    { name: 'Last name', alias: 'last-name' },
    { name: 'Type', alias: 'type' },
]

export const CompanyManagers: FC = () => {
    const { data, loading, revalidate } = useCompanyManagers()
    const { data: companyData, loading: companyLoading } = useCompanyEntities()
    const { state: companyState } = useCompanyState()
    const { state: userState } = useUserState()

    useEffect(() => {}, [loading, companyLoading])

    const feedback = () => {
        showFeedbackModal({
            props: {},
        })
    }

    return (
        <div className="company-managers wrapper page">
            <Helmet>
                <title>{PAGE_TITLE}</title>
                <meta name="description" content={PAGE_TITLE} />
            </Helmet>
            <div>
                <div className="page-title">Managers</div>
                <div className="wrap">
                    {(!filled(companyState, false) || !confirmed(userState)) && (
                        <Info onClose={() => {}} className="warning">
                            Fill in <Link to="company/settings">company</Link> and <Link to="user/settings">user</Link>{' '}
                            info and verify your email to activate this functionality
                        </Info>
                    )}
                    {filled(companyState, false) && confirmed(userState) && (
                        <>
                            <div className="control">
                                <Button
                                    label="Add a manager"
                                    types={['blue', 'small']}
                                    onClick={() => {
                                        showManagerModal({
                                            props: {
                                                onClose: (redirect) => {
                                                    EventBus.bus.emit(Events.CLOSE_MODAL, Modals.ManagerModal)

                                                    revalidate()
                                                },
                                            },
                                        })
                                    }}
                                />
                            </div>
                            <TableList pending={loading} headers={ENTITY_TABLE_HEADERS} data={data || []} pageCount={1}>
                                {(data || []).map((item: UserInfo) => (
                                    <ManagerRow
                                        item={item}
                                        key={item.id}
                                        entities={companyData!}
                                        callback={revalidate}
                                    />
                                ))}
                            </TableList>
                        </>
                    )}

                    <div className="company-managers-footer">
                        {/* eslint-disable-next-line jsx-a11y/anchor-is-valid,jsx-a11y/no-static-element-interactions */}
                        <a onClick={feedback} className="secondary-link media-desktop">
                            Contact support
                        </a>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default CompanyManagers
