import React, { FC } from 'react'
import './RegisterForm.scss'
import { useFormik } from 'formik'
import { InputText } from '@/components/InputText'
import Button from '@/components/Button'
import * as Yup from 'yup'
import { apiVersion, Http } from '@shared/api/Http'
import { Register } from '@shared/api/types'
import { Link } from 'react-router-dom'
import Checkbox from '@/components/Checkbox'
import { updateLoggedState, useUserState } from '@/store/user'
import Select from '@/components/Select'
import { useServiceInfo } from '@shared/api/user'

export const stripeAgreementLabel = (
    <>
        {' '}
        and{' '}
        <a href="https://stripe.com/connect-account/legal" target="_blank" rel="noreferrer">
            Stripe Connected Account Agreement
        </a>
    </>
)

export const RegisterSchema = Yup.object().shape({
    type: Yup.string(),
    phone: Yup.string(),
    email: Yup.string().email('Invalid email').required('Required'),
    password: Yup.string()
        .min(6, 'Too short, min. 6 characters!')
        .max(50, 'Too long, max 50 characters!')
        .required('Required'),
    confirmPassword: Yup.string()
        .oneOf([Yup.ref('password'), null], 'Passwords must match')
        .required('Required'),
    agreement: Yup.bool().oneOf([true], 'Field must be checked'),
    refId: Yup.string(),
    came_from: Yup.string(),
})

interface RegisterModalProps {
    title: string
    description?: string
    referralId?: string
    onClose?: (redirectUrl?: string) => void
}

export const RegisterForm: FC<RegisterModalProps> = ({ referralId, onClose, title, description }) => {
    const { dispatch } = useUserState()
    const { data } = useServiceInfo()

    const formik = useFormik({
        initialValues: {
            phone: '',
            email: '',
            password: '',
            confirmPassword: '',
            came_from: undefined,
            agreement: true,
            refId: referralId || '',
        },
        onSubmit: async ({ phone, email, password, refId, came_from }) => {
            try {
                const response = await Http.post<{ email: string; password: string }, Register>(
                    `/app/${apiVersion}/registration`,
                    {
                        phone: phone.startsWith('+1') ? phone : `+1${phone}`,
                        email,
                        password,
                        refId,
                        came_from,
                    },
                )
                formik.setSubmitting(false)

                Http.defaults.headers.common['Auth-Token'] = response.token
                localStorage.setItem('token', response.token)
                localStorage.setItem('type', response.type)
                await updateLoggedState(response.info, dispatch)
                onClose?.('/company/settings')
            } catch (e: any) {
                formik.setSubmitting(false)
                formik.setErrors(e)
            }
        },
        validationSchema: RegisterSchema,
        enableReinitialize: true,
    })

    const leadSources =
        data &&
        data.lead_sources &&
        (data.lead_sources as string)
            .split(',')
            .map((i: string) => i.trim())
            .filter((i: string) => i !== '')
            .map((i: string) => ({ value: i, name: i }))

    return (
        <form onSubmit={formik.handleSubmit} className="register-form">
            <h2>{title}</h2>
            {description && <div className="description" dangerouslySetInnerHTML={{ __html: description }} />}
            <div className="form-wrap row">
                <div className="field phone">
                    <InputText
                        format="+1 (###) ### ####"
                        label="Phone number"
                        name="phone"
                        placeholder="Phone number"
                        onValueChange={(v) => {
                            formik.handleChange({ target: { name: 'phone', value: v.value } })
                        }}
                        value={formik.values.phone}
                        error={formik.errors.phone}
                    />
                </div>
            </div>
            <InputText
                text
                label="E-mail"
                name="email"
                placeholder="E-mail"
                onChange={formik.handleChange}
                value={formik.values.email}
                error={formik.errors.email}
            />
            <InputText
                text
                label="Password"
                name="password"
                type="password"
                placeholder="Password"
                onChange={formik.handleChange}
                value={formik.values.password}
                error={formik.errors.password}
            />
            <InputText
                text
                label="Repeat password"
                name="confirmPassword"
                type="password"
                placeholder="Repeat password"
                onChange={formik.handleChange}
                value={formik.values.confirmPassword}
                error={formik.errors.confirmPassword}
            />
            <Select
                label="How did you hear about us?"
                items={leadSources}
                name="status"
                className="list-select"
                value={formik.values.came_from}
                onAction={({ value }) => {
                    formik.setFieldValue('came_from', value)
                }}
            />
            <br />
            <div className="modal-after">
                <Checkbox
                    name="agreement"
                    onChange={formik.handleChange}
                    error={formik.errors.agreement}
                    value={formik.values.agreement}
                    label={
                        <>
                            By creating your account you agree to{' '}
                            <Link to="/page/term-of-use" target="_blank">
                                Olimp&apos;s Terms of Use
                            </Link>
                            {stripeAgreementLabel}
                        </>
                    }
                />
            </div>
            <Button
                label="Create account"
                type="submit"
                disabled={!formik.isValid}
                loading={formik.isSubmitting}
                types={['blue', 'full']}
            />
        </form>
    )
}
