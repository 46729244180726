import React, { FC } from 'react'
import cn from 'classnames'
import { useMobileWidth } from '@shared/common/hooks'
import { Loader } from '@/components/Loader'
import './style.scss'

type SelectorWithContentOption = {
    value: number
    title: string
    children?: React.ReactNode
}

type SelectorWithContentProps = {
    width?: number
    mobileWidth?: number
    value?: number
    loading?: boolean
    onChange: (value: number) => void
    options: SelectorWithContentOption[]
}

const SelectorWithContent: FC<SelectorWithContentProps> = ({
    loading,
    value,
    options,
    onChange,
    width,
    mobileWidth,
}) => {
    const isMobile = useMobileWidth()

    return (
        <div className="selector-with-content" style={{ minWidth: (isMobile ? mobileWidth : width) || 'auto' }}>
            {loading && <Loader type="small" />}
            {!loading &&
                options.map((option) => {
                    const active = value === option.value
                    return (
                        <div key={option.value} className="select-item">
                            <div
                                className={cn('select-item__control', {
                                    active,
                                })}
                                onClick={() => onChange(option.value)}
                            >
                                <div className="select-item__circle">
                                    {active && <span className="select-item__circle-inner" />}
                                </div>
                                <div className="select-item__title">{option.title}</div>
                            </div>
                            {/*<div className="select-item__control"><IconArrowPrev/></div>*/}
                            {active && option.children && <div className="select-item__body">{option.children}</div>}
                        </div>
                    )
                })}
        </div>
    )
}

export default SelectorWithContent
