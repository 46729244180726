import React, { FC, memo } from 'react'
import { ModalRegistry } from '@shared/common/modals'
import { ModalPartialProps, Modals } from '@shared/types'
import Button from '@/components/Button'
import { useFormik } from 'formik'
import { InputText } from '@/components/InputText'
import { showInfoModal } from '@/parts/Modals/InfoModal'
import './OneFieldModal.scss'

interface OneFieldProps {
    title?: string
    description?: string
    successLabel?: string
    pending?: boolean
    doAsync: (val: any) => Promise<void>
    initialValue?: any
    validationSchema?: any | (() => any)
    inputProps?: any
    onClose?: () => void
    buttonLabel?: string
    footer?: React.ReactChild
}

const OneFieldModal: FC<OneFieldProps> = ({
    title,
    inputProps,
    description,
    onClose,
    validationSchema,
    children,
    initialValue,
    buttonLabel,
    doAsync,
    successLabel,
    footer,
}) => {
    const formik = useFormik({
        onSubmit: async ({ value }) => {
            try {
                await doAsync(value)
                if (successLabel) {
                    showInfoModal({
                        props: {
                            title: successLabel,
                            message: '',
                            onClose,
                        },
                    })
                }
            } catch (e: any) {
                showInfoModal({
                    props: {
                        title: 'Error',
                        message: e?.msg ?? 'Unknown error occurred',
                    },
                })
            }
            onClose?.()
            ModalRegistry.get().close(Modals.OneFieldModal)
        },
        initialValues: {
            value: initialValue,
        },
        validationSchema,
    })
    return (
        <form className="one-field-form" onSubmit={formik.handleSubmit}>
            {title && <div className="modal-header">{title}</div>}
            {description && <div className="message">{description}</div>}

            <InputText
                text
                name="value"
                onChange={formik.handleChange}
                value={formik.values.value}
                error={formik.errors.value as any}
                {...(inputProps || {})}
            />
            <div className="btns">
                <Button
                    label={buttonLabel ?? 'Save'}
                    type="submit"
                    disabled={!formik.isValid}
                    loading={formik.isSubmitting}
                    types={['blue', 'full']}
                />
            </div>
            <div className="modal-one-field_footer">{footer}</div>
        </form>
    )
}

ModalRegistry.get().register<OneFieldProps>(Modals.OneFieldModal, {
    id: 'OneFieldModal',
    className: 'modal-one-field',
    size: 'medium',
    Component: memo(OneFieldModal),
})

export const showOneFieldModal = (props: ModalPartialProps<OneFieldProps>): void =>
    ModalRegistry.get().show<OneFieldProps>(Modals.OneFieldModal, props)

export const showOneFieldModalAsync = (props: ModalPartialProps<OneFieldProps>): Promise<void> =>
    ModalRegistry.get().showAsync<OneFieldProps>(Modals.OneFieldModal, props)

export default memo(OneFieldModal)
