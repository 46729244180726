import React, { FC, useState } from 'react'
import cn from 'classnames'
import './EmptyFrame.scss'
import { PhotoHandlers } from '../types'

interface IEmptyFrame extends PhotoHandlers {
}

const EmptyFrame: FC<IEmptyFrame> = ({
  onUploadClick,
}) => (
  <button className='empty-frame' type='button' onClick={() => onUploadClick()}>
    <div className='empty-frame__add'>+ add</div>
  </button>
);

export default EmptyFrame;
