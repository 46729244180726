import React, { useState } from 'react'
import cn from 'classnames'
import './PhotoFrame.scss'
import { AppFile, ServiceDocument } from '@shared/types'
import { getDocumentStatus } from '@/utils/documents'
import { PhotoHandlers } from '../types'
import { ReactComponent as IconCross } from '@/assets/images/icon-cross.svg'
import { Photo } from '@/parts/PhotosView'

interface IPhotoFrame extends PhotoHandlers {
    photo: AppFile | ServiceDocument
    isLoading?: boolean
    isSecured?: boolean
}

const PhotoFrame: React.FC<IPhotoFrame> = ({ photo, isLoading, isSecured, onMainClick, onDeleteClick }) => {
    const [isHovered, setHover] = useState<boolean>(false)
    const photoId = photo.entity_id || photo.id

    return (
        <div className="photo-frame" onMouseEnter={() => setHover(true)} onMouseLeave={() => setHover(false)}>
            <Photo photo={photo} isSecured={isSecured} isLoading={isLoading} className="photo-frame__photo" />
            {photo.status && <div className="photo-frame__status">{getDocumentStatus(photo.status)}</div>}
            <div className="photo-frame__inner">
                {onMainClick && (
                    <button
                        type="button"
                        className={cn('photo-frame__is-main', {
                            'photo-frame__is-main_active': (photo as ServiceDocument).is_main,
                        })}
                        onClick={() => onMainClick(photoId)}
                    >
                        Cover
                    </button>
                )}
                <div className={cn('photo-frame__controls', { 'photo-frame__controls_showed': isHovered })}>
                    <button
                        className="photo-frame__button"
                        type="button"
                        onClick={() => {
                            onDeleteClick(photoId)
                        }}
                    >
                        <IconCross />
                    </button>
                </div>
            </div>
        </div>
    )
}

export default PhotoFrame
