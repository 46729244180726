import React, { useEffect } from 'react'
import { SignUpTemplate } from '../../components/SignUpTemplate'
import { useFormik } from 'formik'
import { InputText } from '@/components/InputText'
import Button from '@/components/Button'
import { updateLoggedState, useUserState } from '@/store/user'
import { UserActionType } from '@/store/user/user'
import { apiVersion, Http } from '@shared/api/Http'
import { EntityToken, Login } from '@shared/api/types'
import { cache } from 'swr'
import { useOneTimeSessionData } from '@/hooks/useOneTimeSessionData'

interface SignUpCarrierStepProps {}

interface SignUpCarrierValues {
    entity: string
    license?: string
}

export const SignUpCarrierStep: React.FC<SignUpCarrierStepProps> = () => {
    const { state: userState, dispatch } = useUserState()
    const { consume } = useOneTimeSessionData('signupValues')
    const chooseWarehouse = (e: any) => {
        e.preventDefault()
        dispatch?.({ type: UserActionType.SIGN_UP_CHOOSE_ENTITY_TYPE, entityType: 'w' })
    }
    const formik = useFormik<SignUpCarrierValues>({
        onSubmit: async (values) => {
            const carrier = await Http.post<SignUpCarrierValues, { id: number }>(
                `/carrier/${apiVersion}/carrier/new`,
                values,
            )
            const response = await Http.get<null, EntityToken>(`/app/${apiVersion}/select/carrier/${carrier.id}`)
            cache.clear()

            Http.defaults.headers.common['Auth-Token'] = response.token
            localStorage.setItem('token', response.token)
            localStorage.setItem('type', response.type)
            formik.setSubmitting(false)

            await updateLoggedState(
                {
                    ...userState.userInfo!,
                    registration_step: 100,
                },
                dispatch,
            )
        },
        initialValues: {
            entity: '',
        },
    })
    useEffect(() => {
        formik.setFieldValue('license', consume('usdot'))
    }, [])
    return (
        <SignUpTemplate progress={100} title="Add a new Carrier/Broker">
            <div className="sign-up_carrier">
                <form onSubmit={formik.handleSubmit}>
                    <InputText
                        text
                        label="Carrier name"
                        name="entity"
                        placeholder="Carrier name"
                        onChange={formik.handleChange}
                        value={formik.values.entity}
                        error={formik.errors.entity}
                        errorHide={false}
                        textError
                    />

                    <InputText
                        text
                        name="license"
                        onChange={formik.handleChange}
                        value={formik.values.license}
                        error={formik.errors.license}
                        placeholder="USDOT or MC/MX#"
                        label="USDOT or MC/MX#"
                    />
                    <small style={{ display: 'inline-flex', justifyContent: 'flex-start', width: '100%' }}>
                        to ensure that you are legal carrier/freight broker
                    </small>
                    <br />
                    <br />
                    <Button
                        label="Add"
                        type="submit"
                        disabled={!formik.isValid}
                        loading={formik.isSubmitting}
                        types={['blue', 'full']}
                    />
                    <br />
                    <small
                        style={{
                            display: 'flex',
                            justifyContent: 'center',
                            width: '100%',
                            marginTop: 0,
                            marginBottom: 6,
                        }}
                    >
                        or
                    </small>

                    {/* eslint-disable-next-line jsx-a11y/anchor-is-valid,jsx-a11y/no-static-element-interactions */}
                    <a className="link" onClick={chooseWarehouse}>
                        <small style={{ color: 'rgba(9, 21, 38, 0.5)' }}>Add a warehouse</small>
                    </a>
                </form>
            </div>
        </SignUpTemplate>
    )
}
