import React from 'react'
import './UserStatusInfo.scss'
import { Info } from '@/components/Info'
import { useUserState } from '@/store/user'
import { banned, confirmed } from '@/store/user/helpers'
import { apiVersion, Http } from '@shared/api/Http'
import { showInfoModal } from '@/parts/Modals/InfoModal'
import { resend } from '@/utils/manager'

// eslint-disable-next-line complexity
export const UserStatusInfo: React.FC = () => {
    const { state } = useUserState()

    const { userInfo } = state
    if (!userInfo) {
        return null
    }

    return (
        <div className="user-status-info">
            {banned(state) && (
                <Info onClose={() => {}} className="warning">
                    Your account was banned by the administration
                </Info>
            )}

            {!confirmed(state) && (
                <Info onClose={() => {}} className="warning">
                    <span>
                        Please help us get you started on your OLIMP account by verifying your email address. Check your
                        Inbox and Spam folders for your verification link.
                    </span>
                    <br />
                    {/* eslint-disable-next-line react/no-unescaped-entities */}
                    <span>Didn't receive your verification link email? Click on </span>
                    {/* eslint-disable-next-line jsx-a11y/anchor-is-valid,jsx-a11y/no-static-element-interactions */}
                    <a
                        className="link"
                        onClick={(e) => {
                            e.preventDefault()
                            resend()
                        }}
                    >
                        resend
                    </a>
                </Info>
            )}
        </div>
    )
}
