import React, { FC, memo, useCallback, useState } from 'react'
import { ModalRegistry } from '@shared/common/modals'
import { ModalPartialProps, Modals } from '@shared/types'
import Button from '@/components/Button'
import './ConfirmModal.scss'

interface ConfirmModalProps {
    title: string
    message: string
    pending?: boolean
    onConfirm?: (status: boolean) => Promise<void> | void
}

const ConfirmModal: FC<ConfirmModalProps> = ({ title, message, onConfirm, children }) => {
    const [pending, setPending] = useState(false)

    const handleCancel = useCallback(() => {
        ModalRegistry.get().close(Modals.ConfirmModal)
        if (onConfirm) {
            onConfirm(false)
        }
    }, [onConfirm])

    const handleConfirm = useCallback(async () => {
        if (onConfirm) {
            setPending(true)
            await onConfirm(true)
            setPending(false)
        }
        ModalRegistry.get().close(Modals.ConfirmModal)
    }, [onConfirm])

    return (
        <div className="modal-confirm">
            <h2>{title}</h2>
            {message && <div className="message">{message}</div>}

            {children}

            <div className="btns">
                <Button label="Cancel" types={['plain']} onClick={handleCancel} disabled={pending} />
                <Button label="OK" loading={pending} types={['blue']} onClick={handleConfirm} />
            </div>
        </div>
    )
}

ModalRegistry.get().register<ConfirmModalProps>(Modals.ConfirmModal, {
    id: 'ConfirmModalProps',
    className: 'modal-confirm',
    size: 'medium',
    Component: memo(ConfirmModal),
})

export const showConfirmModal = (props: ModalPartialProps<ConfirmModalProps>): void =>
    ModalRegistry.get().show<ConfirmModalProps>(Modals.ConfirmModal, props)

export default memo(ConfirmModal)
