import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { utils } from '@shared/common/utils'

import './styles.scss'
import cn from 'classnames'

type Props = {
    spyElement: HTMLElement
    checkCorners?: boolean
    enabled?: boolean
    className?: string
}

const CLASS_NAME = 'fixed-element'

const FixedElement: React.FC<Props> = ({ spyElement, children, enabled = true, checkCorners = true, className }) => {
    const [isElementInViewport, setIsInViewport] = useState(false)
    const isNeedToShowFixedContainer = useMemo(() => {
        return enabled && !isElementInViewport
    }, [enabled, isElementInViewport])

    const checkIfInViewport = useCallback(() => {
        setIsInViewport(utils.isElementInViewport(spyElement, checkCorners))
    }, [spyElement, checkCorners])

    useEffect(() => {
        window.addEventListener('scroll', checkIfInViewport)

        return () => window.removeEventListener('scroll', checkIfInViewport)
    })

    // useEffect(() => {
    //   spyElement
    // }, [isNeedToShowFixedContainer])

    if (isNeedToShowFixedContainer) {
        return <div className={cn(CLASS_NAME, className)}>{children}</div>
    }

    return <div className={className}>{children}</div>
}

export { FixedElement }
