import React, { ChangeEvent, FC, useEffect, useMemo, useRef, useState } from 'react'
import { OrderDocument, Setting, SettingValueType, OrderStatus, WarehouseOrder } from '@shared/types'
import { PhotosForm, DEFAULT_FILE_EXTENSIONS } from '@/parts/PhotosForm'
import { removeWarehouseOrderRequirementDocument, uploadWarehouseOrderRequirementDocument } from '@shared/api/warehouse'
import { consts } from '@shared/common/consts'
import './WarehouseOrderRequirements.scss'
import { usePrevious } from 'react-use'
import mime from 'mime'

export interface WarehouseOrderRequirementsProps {
    order: WarehouseOrder
    revalidate?: any
    loading?: boolean
    onChange?(completed: boolean): void
}

const FileRequirement: FC<{
    setting: Setting
    docs?: OrderDocument[]
    orderId: number
    props: WarehouseOrderRequirementsProps
}> = ({ orderId, setting, docs, props }) => {
    const [loading, setLoading] = useState(false)
    const [loadingId, setLoadingId] = useState<number | undefined>()
    const inputFile = useRef<HTMLInputElement>(null)

    const handleUpload = async (event: ChangeEvent<HTMLInputElement>) => {
        setLoading(true)
        try {
            await uploadWarehouseOrderRequirementDocument(orderId, setting.name, event.target.files ?? undefined)
            await props.revalidate()
        } catch (e) {
            console.log(e)
        }
        setLoading(false)
    }
    const handleDelete = async (id: number) => {
        setLoading(true)
        setLoadingId(id)
        try {
            await removeWarehouseOrderRequirementDocument(orderId, id)
            await props.revalidate()
        } catch (e) {
            console.log(e)
        }
        setLoadingId(undefined)
        setLoading(false)
    }

    const fileExtensions = useMemo(
        () => setting.settings?.extensions?.split(',') ?? DEFAULT_FILE_EXTENSIONS,
        [setting.settings?.extensions],
    )
    const mimes = useMemo(() => fileExtensions.map((ext) => mime.getType(ext)).join(','), [fileExtensions])

    return (
        <div className="item">
            <div className="label">
                {setting.title} ({setting.required ? 'Required' : 'Optional'})
            </div>
            <div className="value">
                {(!docs || docs.length === 0) && setting.required && (
                    <div className="req-description">To proceed with order, please upload {setting.title}</div>
                )}

                <input
                    id="req-file"
                    type="file"
                    ref={inputFile}
                    onChange={handleUpload}
                    disabled={loading || props.loading}
                    name={setting.name}
                    accept={mimes}
                    hidden
                />
                <PhotosForm
                    isSecured
                    photos={docs ?? []}
                    onUploadClick={() => {
                        if (inputFile.current) {
                            inputFile.current.click()
                        }
                    }}
                    loadingEntityId={loadingId}
                    isLoading={loading || props.loading}
                    onDeleteClick={handleDelete}
                    fileExtensions={fileExtensions}
                    maxPhotos={1}
                    maxSize={(setting.settings?.maxSize ?? 5000) / 1000}
                />
            </div>
        </div>
    )
}

const getKnownOrderRequirement = (order: WarehouseOrder, req: Setting, props: WarehouseOrderRequirementsProps) => {
    if (!shouldBeDisplayed(order.status, req)) {
        return null
    }
    switch (req.type) {
        case SettingValueType.FILE:
            return (
                <FileRequirement
                    key={req.name}
                    orderId={order.id}
                    setting={req}
                    props={props}
                    docs={order.documents.filter(
                        (doc) => doc.type === consts.order.documentType.settingType && doc.req === req.name,
                    )}
                />
            )
        default:
            return null
    }
}

const shouldBeFilled = (documents: OrderDocument[], req: Setting) => {
    switch (req.type) {
        case SettingValueType.FILE:
            return (
                !documents.find((doc) => doc.type === consts.order.documentType.settingType && doc.req === req.name) &&
                req.required
            )
        default:
            return false
    }
}
const shouldBeDisplayed = (status: OrderStatus, req: Setting) => {
    if (req.name === 'gate-ticket-in' && status.full_code === 'BOOKED') {
        return true
    }
    if (req.name === 'gate-ticket-out' && status.full_code === 'DONE') {
        return true
    }
    return false
}

export const WarehouseOrderRequirements: FC<WarehouseOrderRequirementsProps> = (props) => {
    const { order, onChange } = props
    const anyUnfilled = order.order_settings.some(
        (s) => shouldBeDisplayed(order.status, s) && shouldBeFilled(order.documents, s),
    )
    const prevAnyUnfilled = usePrevious(anyUnfilled)

    const requirements = order.order_settings
        .map((setting) => getKnownOrderRequirement(order, setting, props))
        .filter((req) => !!req)

    useEffect(() => {
        if (prevAnyUnfilled !== anyUnfilled) {
            onChange?.(!anyUnfilled)
        }
    }, [anyUnfilled, onChange, prevAnyUnfilled])

    return <div className="group">{requirements}</div>
}
