import React, { FC, useRef, useState } from 'react'
import cn from 'classnames'
import { useNavigate } from 'react-router-dom'
import { Navigation } from '@/parts/Navigation'
import { useUserState } from '@/store/user'
import { Modals } from '@shared/types'
import { Events } from '@shared/common/events'
import MobilePopup from '@/parts/Partials/MobilePopup/MobilePopup'
import Button from '@/components/Button'
import { showLoginModal } from '@/parts/Modals/LoginModal'
import EventBus from '@shared/common/eventBus'
import './Header.scss'
import { Logo } from '@/parts/Partials/Header/Logo/Logo'
import { useMobileWidth } from '@shared/common/hooks'
import { EntitiesMenu } from '@/parts/EntitiesMenu'
import ROUTES from '@/router'
import { useLocation } from 'react-router'

const CLASS_NAME = 'olimp-header'

// eslint-disable-next-line complexity
const Header: FC = () => {
    const navigate = useNavigate()
    const location = useLocation()
    const headerRef = useRef<HTMLDivElement | null>(null)
    const isMobileLayout = useMobileWidth()
    const [menuOpen, setMenuOpen] = useState<boolean>(false)
    const {
        state: { isLoggedIn, userInfo },
    } = useUserState()

    const dev = process.env.NODE_ENV === 'development'

    const loginPage = location.pathname.indexOf('/login') >= 0
    const hasNotFinishedSignUp =
        isLoggedIn &&
        userInfo?.registration_step !== undefined &&
        userInfo?.registration_step !== null &&
        userInfo?.registration_step !== 100

    return (
        <header className={cn(CLASS_NAME, 'wrapper', { dev })}>
            <div ref={headerRef} className={cn(`${CLASS_NAME}__inner`)}>
                <Logo className={`${CLASS_NAME}__logo`} isLoggedIn={isLoggedIn} />

                {isLoggedIn && (
                    <>
                        <EntitiesMenu className={`${CLASS_NAME}__entities`} />
                        <Navigation
                            className={cn(`${CLASS_NAME}__nav`, 'media-desktop')}
                            isOpen={menuOpen}
                            onToggle={(val) => setMenuOpen(Boolean(val))}
                        />

                        {!hasNotFinishedSignUp && (
                            <button
                                type="button"
                                className={cn(`${CLASS_NAME}__burger`, 'btn-menu icon-sandwich media-mobile', {
                                    opened: menuOpen,
                                })}
                                onClick={() => setMenuOpen((o) => !o)}
                            />
                        )}

                        {isMobileLayout && (
                            <MobilePopup visible={menuOpen} onClose={() => setMenuOpen(false)} headerRef={headerRef} />
                        )}
                    </>
                )}

                {(!isLoggedIn || hasNotFinishedSignUp) && (
                    <div className={`${CLASS_NAME}__auth`}>
                        {!hasNotFinishedSignUp && !loginPage && (
                            <Button types={['bordered']} onClick={() => navigate('/login')}>
                                Login
                            </Button>
                        )}
                        <Button
                            types={['bordered']}
                            label={hasNotFinishedSignUp ? 'Continue registration' : 'Create account'}
                            onClick={() => {
                                navigate({ pathname: ROUTES.signUp.index, search: location.search })
                            }}
                        />
                    </div>
                )}
            </div>
        </header>
    )
}

export default Header
