import { useCallback, useEffect, useState } from 'react'
import { showInfoModalAsync } from '@/parts/Modals/InfoModal'
import { Http } from '@shared/api/Http'
import { IWarehouseSearchForm, Service } from '@shared/types'
import { useServiceTypes } from '@shared/api/useDataHooks'

interface UseQuotationRequestOptions {
    queryData?: IWarehouseSearchForm
    isLoading?: boolean
    controlled?: boolean
}

const isAnyActiveQRServiceRequested = (queryData: IWarehouseSearchForm, serviceTypes: Service[]) => {
    if (serviceTypes && queryData) {
        return Boolean(serviceTypes.find((serviceType) => serviceType.code === queryData.service && serviceType.is_qr))
    }
    return false
}

export const useQuotationRequest = (options: UseQuotationRequestOptions) => {
    const { queryData, isLoading, controlled } = options
    const [quotationRequest, setQuotationRequest] = useState<boolean>(false)
    const [quotationRequestLoading, setQuotationRequestLoading] = useState<boolean>(false)
    const handleQuoteRequest = useCallback(async () => {
        setQuotationRequestLoading(true)
        const resp = await Http.post<any, { message: string }>('/carrier/v1/orders/request-quote', {
            address: queryData?.address,
            services: [queryData?.service],
            number_of_units: queryData?.number_of_units,
            number_of_days: queryData?.number_of_days,
            range: queryData?.radius,
        })
        setQuotationRequestLoading(false)

        await showInfoModalAsync({
            props: {
                title: resp.message,
                message: '',
            },
        })
    }, [queryData])

    const { data: serviceTypes, isLoading: serviceTypesLoading } = useServiceTypes()

    useEffect(() => {
        if (!isLoading && !serviceTypesLoading) {
            const qrRequested =
                serviceTypes && queryData ? isAnyActiveQRServiceRequested(queryData, serviceTypes) : false
            setQuotationRequest(qrRequested)
            if (qrRequested && !controlled) {
                handleQuoteRequest()
            }
        }
    }, [isLoading, serviceTypesLoading, controlled])

    return {
        quotationRequest,
        quotationRequestLoading,
        handleQuoteRequest,
    }
}
