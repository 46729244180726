import { useUser } from '@shared/api/user'
import { useCarrier } from '@shared/api/carrier'
import { useWarehouse } from '@shared/api/warehouse'
import { useCallback, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { EntityToken, EntityType } from '@shared/api/types'
import { Http } from '@shared/api/Http'
import { cache } from 'swr'
import { getEntityBasePath } from '@/utils/getEntityBasePath'

export const useSelectEntity = () => {
    const { revalidate: revalidateUser } = useUser()
    const { revalidate: revalidateCarrier } = useCarrier(false)
    const { revalidate: revlidateWarehouse } = useWarehouse(false)

    const [loading, setLoading] = useState(false)
    const [succeded, setSucceded] = useState(false)
    const navigate = useNavigate()

    const select = useCallback(
        async (entityType: EntityType, id: number, withNavigate = true) => {
            setLoading(true)
            const response = await Http.get<{ type: string; id: string }, EntityToken>(
                `/app/v1/select/${entityType}/${id}`,
            )
            cache.clear()

            Http.defaults.headers.common['Auth-Token'] = response.token
            localStorage.setItem('token', response.token)
            localStorage.setItem('type', response.type)

            await revalidateUser()

            if (entityType === EntityType.carrier) {
                await revalidateCarrier()
            } else if (entityType === EntityType.warehouse) {
                await revlidateWarehouse()
            }

            setLoading(false)
            setSucceded(true)

            if (withNavigate) {
                navigate(getEntityBasePath(entityType))
            }
        },
        [navigate, revalidateCarrier, revalidateUser, revlidateWarehouse],
    )

    return {
        loading,
        succeded,
        select,
    }
}
