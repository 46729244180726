import React, { FC, useState } from 'react'
import './Disclaimer.scss'
import useSWR from 'swr'
import { apiVersion, getFetcher, swrConfiguration } from '@shared/api/Http'

export const Disclaimer: FC = () => {
    const [show, setShow] = useState<boolean>(!localStorage.getItem('Disclaimer'))
    const { data: html } = useSWR(
        () => (show ? `app/${apiVersion}/service/landing-chunk?slug=cookie-disclaimer` : null),
        getFetcher,
        swrConfiguration,
    )

    if (show) {
        return null
    }

    return (
        <div className="disclaimer wrapper" v-if="show">
            <div>
                {/* eslint-disable-next-line react/no-danger */}
                <div className="html" dangerouslySetInnerHTML={{ __html: html }} />
                {/* eslint-disable-next-line jsx-a11y/no-static-element-interactions */}
                <span
                    className="icon-close"
                    onClick={() => {
                        localStorage.setItem('Disclaimer', 'hide')
                        setShow(false)
                    }}
                />
            </div>
        </div>
    )
}
