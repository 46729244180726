import { apiVersion, Http } from '@shared/api/Http'
import { showInfoModal } from '@/parts/Modals/InfoModal'

export const resend = async () => {
    await Http.get(`/app/${apiVersion}/manager/confirmation-email-resend`)

    showInfoModal({
        props: {
            title: 'Successfully sent',
            message: '',
        },
    })
}
