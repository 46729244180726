import React, { useState } from 'react'
import { Choose } from '@/pages/SignUp/components/Choose'
import { SignUpTemplate } from '@/pages/SignUp/components/SignUpTemplate'
import { useUserState } from '@/store/user'
import { useNavigate } from 'react-router'
import { useCarrier } from '@shared/api/carrier'

export const SignUpCarrierDoneStep: React.FC = () => {
    const navigate = useNavigate()
    const { state: userState } = useUserState()
    const { data, loading } = useCarrier()
    const [selected, setSelected] = useState<string>()
    const verifiedLicense = data?.license?.status?.codeName === 'CONFIRMED'
    return (
        <SignUpTemplate
            loading={loading}
            title={
                verifiedLicense
                    ? 'Success'
                    : 'Great! Your Carrier / Broker account is currently being verified by OLIMP.'
            }
            extra={verifiedLicense ? 'Now you can' : 'You will receive an email when your account is activated.'}
        >
            <div className="sign-up_carrier-done">
                <Choose
                    optionOne={{
                        key: 'search',
                        title: <>Search for a warehouse</>,
                    }}
                    optionTwo={{
                        key: 'settings',
                        title: <>Go to settings</>,
                    }}
                    onSelect={(key) => {
                        if (key === 'settings') {
                            navigate('/carrier/settings')
                        } else if (key === 'search') {
                            navigate('/carrier/search')
                        }
                    }}
                    selected={selected}
                />
                <br />
            </div>
        </SignUpTemplate>
    )
}
