import React from 'react'
import { Company } from '@shared/types'

export enum CompanyActionType {
    SET_COMPANY_STATE = 'SET_COMPANY_STATE',
}

export type CompanyAction = { type: CompanyActionType.SET_COMPANY_STATE; companyState: Company }

export interface CompanyState {
    companyInfo?: Company
}

export interface CompanyStore {
    state: CompanyState
    dispatch?: React.Dispatch<CompanyAction>
}
