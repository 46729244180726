import classNames from 'classnames';
import React from 'react';
import './CarrierBookingSection.scss';

const CLASS_NAME = 'carrier-booking-section';

type Props = {
  className?: string;
};

const Title: React.FC = ({ children }) => (
  <h2 className={`${CLASS_NAME}__title`}>{children}</h2>
);


export const CarrierBookingSection: React.FC<Props> & {
  Title: React.FC
} = ({
  className,
  children,
}) => (
    <section className={classNames(CLASS_NAME, className)}>
      {children}
    </section>
  );

CarrierBookingSection.Title = Title;
