import React, { FC, memo, useCallback, useMemo, useState } from 'react'
import { ModalRegistry } from '@shared/common/modals'
import { ModalPartialProps, Modals } from '@shared/types'
import Button from '@/components/Button'
import { useFormik } from 'formik'
import { useDragAndDrop } from '@/hooks/useDragAndDrop'
import { ReactComponent as DocIcon } from '@/assets/images/doc.svg'
import { ReactComponent as TrashIcon } from '@/assets/images/icon-trash.svg'
import cn from 'classnames'
import { IInputTextProps, InputText } from '@/components/InputText'
import { FormikHelpers } from 'formik/dist/types'
import './UploadModal.scss'

export interface FormikPropsWithFiles {
    files: File[]
}

interface UploadModalCustomProps {
    fields?: IInputTextProps[]
    fileRequirements?: string
    description?: string
    multiple?: boolean
    onSubmit: (values: FormikPropsWithFiles, formikHelpers: FormikHelpers<FormikPropsWithFiles>) => void | Promise<any>
}
interface UploadModalProps extends React.PropsWithChildren<UploadModalCustomProps> {}

const UploadModal: FC<UploadModalProps> = ({ multiple, description, fields, fileRequirements, onSubmit }) => {
    const [files, setFiles] = useState<File[]>([])

    const submitMiddleware: UploadModalCustomProps['onSubmit'] = async (values, helpers) => {
        try {
            await onSubmit(values, helpers)
            ModalRegistry.get().close(Modals.UploadModal)
        } catch (errors: any) {
            const tooLarge = errors && errors.status === 413
            const fileErr = errors && (errors.file || errors.files)
            if (fileErr || tooLarge) {
                formik.setFieldError('files', fileErr || 'File has too big size')
            }
        } finally {
            formik.setSubmitting(false)
        }
    }

    const initialValues: any = {
        files: [],
    }

    if (fields) {
        for (const field of fields) {
            initialValues[field.name] = field.value || ''
        }
    }

    const formik = useFormik<FormikPropsWithFiles>({
        initialValues,
        onSubmit: submitMiddleware,
    })

    const handleSubmit = useCallback(
        (e) => {
            formik.setFieldValue('files', files)
            formik.handleSubmit(e)
        },
        [files, formik],
    )

    const { handleChange, handleBrowseFiles, handleDrag, handleDrop, inputRef, dragActive } = useDragAndDrop({
        onChange: (f) => {
            const newFilesArr = multiple ? Array.from(f) : f[0] ? [f[0]] : []
            setFiles(newFilesArr)
        },
        dropAreaId: 'modal-upload-area',
    })

    const fieldsRendered = useMemo(() => {
        if (!fields) {
            return null
        }
        return fields.map((f) => {
            return (
                <div className="field" key={f.name}>
                    <InputText
                        text
                        {...f}
                        value={(formik.values as any)[f.name]}
                        onChange={formik.handleChange}
                        error={(formik.errors as any)[f.name]}
                        textError
                        errorHide={false}
                        errorOnFocus
                    />
                </div>
            )
        })
    }, [fields, formik])

    return (
        <form className="modal-info" onSubmit={handleSubmit}>
            <div className="form">
                <div className="form-upload">
                    {description && <span className="description">{description}</span>}
                    <div>
                        <div className="section">
                            <div className="form-wrap">
                                <div className="field upload-field">
                                    <input
                                        ref={inputRef}
                                        type="file"
                                        id="modal-upload_file-input"
                                        onChange={handleChange}
                                    />
                                    <label
                                        id="modal-upload_file-input-label"
                                        htmlFor="modal-upload_file-input"
                                        className={dragActive ? 'drag-active' : ''}
                                    />
                                    <div
                                        id="modal-upload-area"
                                        className={cn('upload-area', { dragActive })}
                                        onDragEnter={handleDrag}
                                        onDragLeave={handleDrag}
                                        onDragOver={handleDrag}
                                        onDrop={handleDrop}
                                    >
                                        {!dragActive && <span>Drag files here to upload or</span>}
                                        {!dragActive && (
                                            <Button types={['blue', 'large']} onClick={handleBrowseFiles}>
                                                Select file
                                            </Button>
                                        )}
                                        {dragActive && <span>Drop file here...</span>}
                                        <div className="gray-txt">
                                            {fileRequirements ||
                                                'High resolution images (png, jpg, gif) or .pdf maximum 10MB'}
                                        </div>
                                    </div>
                                </div>
                                <div className="modal-upload_files">
                                    {files.map((file, idx) => (
                                        <div key={`file-${idx}`} className="modal-upload_file">
                                            <DocIcon /> <span>{file.name}</span>
                                            <Button
                                                types={['plain', 'small']}
                                                onClick={() =>
                                                    setFiles((f) => f.filter((_, fileIndex) => fileIndex !== idx))
                                                }
                                            >
                                                <TrashIcon />
                                            </Button>
                                        </div>
                                    ))}
                                    {formik.errors.files && <div className="file-error">{formik.errors.files}</div>}
                                </div>
                                {fieldsRendered}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="btns">
                <Button
                    label="Cancel"
                    types={['plain']}
                    type="button"
                    onClick={() => ModalRegistry.get().close(Modals.UploadModal)}
                />
                <Button
                    label="Submit"
                    types={['blue']}
                    type="submit"
                    disabled={formik.isSubmitting}
                    loading={formik.isSubmitting}
                />
            </div>
        </form>
    )
}

ModalRegistry.get().register<UploadModalProps>(Modals.UploadModal, {
    id: 'UploadModal',
    className: 'modal-upload',
    size: 'medium',
    Component: memo(UploadModal),
})

export const showUploadModal = (props: ModalPartialProps<UploadModalProps>): void =>
    ModalRegistry.get().show<UploadModalProps>(Modals.UploadModal, props)

export default memo(UploadModal)
