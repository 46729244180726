import React, { FC, useEffect } from 'react'
import { useParams, useNavigate } from 'react-router-dom'

import { EntityToken, EntityType } from '@shared/api/types'
import { apiVersion, Http } from '@shared/api/Http'
import { useNotify } from 'react-admin'
import { toast } from 'react-toastify'

// eslint-disable-next-line complexity
export const SelectCarrierBeforeOrderItem: FC = () => {
    const { orderId, carrierId, bookingWh, booking } = useParams<{
        orderId: string
        carrierId: string
        booking: string
        bookingWh: string
    }>()
    const navigate = useNavigate()

    const selectCarrier = async () => {
        const carrierIntID = parseInt(carrierId ?? '0')

        if (booking && !carrierIntID) {
            toast('Please create carrier entity before booking!', { type: 'warning' })
            navigate('/company')
        } else {
            const response = await Http.get<{ type: string; id: string }, EntityToken>(
                `/app/${apiVersion}/select/carrier/${carrierId}`,
            )

            Http.defaults.headers.common['Auth-Token'] = response.token
            localStorage.setItem('token', response.token)
            localStorage.setItem('type', response.type)
            if (booking) {
                navigate(`/carrier/warehouse/${bookingWh}/${booking}`)
            } else {
                navigate(`/carrier/order/${orderId}`)
            }
        }
    }

    useEffect(() => {
        selectCarrier()
    })

    return <></>
}
