import React, { FC, HTMLAttributes, useCallback, useState } from 'react'
import cn from 'classnames'
import './TableList.scss'
import TablePagination from '@/parts/Table/TablePagination'
import Select from '@/components/Select'
import { Loader } from '@/components/Loader'

interface TableListUpdate {
    sort: string
    page: number
}

interface ITableList extends HTMLAttributes<HTMLDivElement> {
    headers: { name: string; alias: string; value?: string; sort?: string }[]
    data: any[]
    sort?: string
    pending: boolean
    page?: number
    pageCount: number
    onUpdate?: (update: TableListUpdate) => void
    emptyMessage?: string
}

export const TableList: FC<ITableList> = ({
    headers,
    sort = '',
    data,
    pending,
    page = 1,
    pageCount = 1,
    onUpdate,
    children,
    emptyMessage = 'List is empty',
}) => {
    const onSetSortParam = (param: string) => {
        if (param) {
            let sortParam = param

            if (sortParam === sort) {
                const arr = sort.split('')
                // eslint-disable-next-line @typescript-eslint/no-unused-expressions
                arr[0] === '-' ? arr.shift() : arr.unshift('-')
                sortParam = arr.join('')
            }
            onUpdate?.({ sort: sortParam, page })
        }
    }

    const sortList = useCallback(() => {
        const list = headers
            .filter((item) => item.sort)
            .map((item) => ({
                ...item,
                value: item.sort,
            }))

        list.forEach((item, index) => {
            if (item?.value?.replace('-', '') === sort?.replace('-', '')) {
                list[index].value = sort
            }
        })

        return list
    }, [headers])

    const handlePageChange = (newPage: number) => {
        onUpdate?.({ sort, page: newPage })
    }

    return (
        <div className="table-list-wrapper">
            {data && data.length > 0 && (
                <div className="list">
                    {sortList.length > 0 && (
                        <div className="sort-control media-mobile">
                            <Select
                                name="sort"
                                items={sortList()}
                                label="Sort by"
                                sort
                                type="white"
                                value={sort}
                                onAction={({ value }) => onSetSortParam(value as string)}
                            />
                        </div>
                    )}

                    <div className="header">
                        {headers.map((header) => (
                            <div className={cn('cell', `cell-${header.alias}`)} key={header.name}>
                                {/* eslint-disable-next-line jsx-a11y/no-static-element-interactions */}
                                <div
                                    className={cn(
                                        'item',
                                        { sort: !!header.sort },
                                        { desc: `-${header.sort}` === sort },
                                        { asc: header.sort === sort },
                                    )}
                                    onClick={() => onSetSortParam(header.sort || '')}
                                >
                                    <span className="visible">{header.name}</span>
                                    <span className="bold">{header.name}</span>
                                </div>
                            </div>
                        ))}
                    </div>

                    <div className="data">{children}</div>
                </div>
            )}

            {(pending || data.length === 0) && (
                <div className="status">
                    {pending && <Loader type="list" />}
                    {!pending && data.length === 0 && <div className="empty">{emptyMessage}</div>}
                </div>
            )}

            <div className="table-footer">
                <TablePagination
                    className="table-sort-pagination"
                    page={page}
                    pageCount={pageCount}
                    onPageChange={handlePageChange}
                />
            </div>
        </div>
    )
}
