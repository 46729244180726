import React from 'react'
import cn from 'classnames'
import { ReactComponent as TrashIcon } from 'images/icon-trash.svg'
import { ReactComponent as EyeIcon } from '@/assets/images/icon-eye.svg'
import { ReactComponent as SignatureIcon } from '@/assets/images/icon-signature.svg'
import { Loader } from '@/components/Loader'
import Button from '@/components/Button'
import { TextCutter } from '@/components/TextCutter'
import { ILoadedEditableDocument } from '../types'
import { useLoadedDocument } from '../useLoadedDocument'

import './EditableDocument.scss'

const CLASS_NAME = 'editable-document-view'

const EditableDocument: React.FC<ILoadedEditableDocument> = (props) => {
    const { loading: docLoading, onOpen } = useLoadedDocument(props)
    const { className, documentNumber, filename, disabled, canRemove, signed, onRemoveClick } = props

    return (
        <div
            className={cn(CLASS_NAME, className, {
                disabled: disabled || docLoading,
            })}
        >
            {documentNumber && <span className={`${CLASS_NAME}__number`}>{documentNumber}</span>}

            <button className={`${CLASS_NAME}__link`} type="button" onClick={onOpen}>
                {!docLoading ? <EyeIcon /> : <Loader type="small" />} {signed && <SignatureIcon />}
                <TextCutter title={filename}>{filename}</TextCutter>
            </button>
            {canRemove && !docLoading && (
                <Button onClick={onRemoveClick} className={`${CLASS_NAME}__remove`}>
                    <TrashIcon />
                </Button>
            )}
        </div>
    )
}

export { EditableDocument }
