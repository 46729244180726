import React, { FC, useState } from 'react'
import { useNavigate } from 'react-router'
import Button from '@/components/Button'
import { SignUpTemplate } from '@/pages/SignUp/components/SignUpTemplate'
import { useUserState } from '@/store/user'
import { UserActionType } from '@/store/user/user'
import { Choose } from '@/pages/SignUp/components/Choose'
import './RegistrationReadyStep.scss'

export const RegistrationReadyStep: FC = () => {
    const navigate = useNavigate()
    const { state: userState, dispatch } = useUserState()
    const [selected, setSelected] = useState<string>()
    const next = () => {
        if (selected) {
            dispatch?.({ type: UserActionType.SIGN_UP_CHOOSE_ENTITY_TYPE, entityType: selected as 'c' | 'w' })
        }
    }
    return (
        <SignUpTemplate progress={500 / 6}>
            <div className="sign-up_ready">
                <h3>
                    Success!
                    <br />
                    Introduce yourself:
                </h3>
                <Choose
                    optionOne={{
                        key: 'w',
                        title: <>I am a warehouse</>,
                    }}
                    optionTwo={{
                        key: 'c',
                        title: <>I am a carrier/broker</>,
                    }}
                    onSelect={(key) => setSelected(key)}
                    selected={selected}
                />
                <small>You can add more entities later</small>
                <br />
                {selected && <Button label="Next" type="button" types={['blue', 'full']} onClick={next} />}
            </div>
        </SignUpTemplate>
    )
}
