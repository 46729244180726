import React, { useContext, useEffect } from 'react'
import { useNavigate } from 'react-router'
import { History } from 'history'
import { showConfirmModal } from '@/parts/Modals/ConfirmModal'
import { useSelectedEntity } from '@/hooks/useSelectedEntity'
import { EntityType } from '@shared/api/types'
import { UNSAFE_NavigationContext as NavigationContext } from 'react-router-dom'

type ExtendNavigator = Navigator & Pick<History, 'block'>

let unblock: any

interface PagePromptProps {
    dirty: boolean
    entity?: {
        id: number
        type: EntityType
    }
}
// https://gist.github.com/MarksCode/64e438c82b0b2a1161e01c88ca0d0355
// https://github.com/remix-run/react-router/issues/8139#issuecomment-977790637
function useConfirmExit(confirmExit: () => Promise<boolean>, when = true) {
    const { navigator } = useContext(NavigationContext)

    useEffect(() => {
        if (!when) {
            return
        }

        const push = navigator.push

        navigator.push = async (...args: Parameters<typeof push>) => {
            const result = await confirmExit()
            if (result) {
                push(...args)
            }
        }

        return () => {
            navigator.push = push
        }
    }, [navigator, confirmExit, when])
}

export const usePagePrompt: (props: PagePromptProps) => void = ({ dirty, entity }) => {
    const navigate = useNavigate()
    const { navigator } = useContext(NavigationContext)

    const { data } = useSelectedEntity()
    const isEntityActual = data?.entity?.id === entity?.id && data?.type === entity?.type

    useConfirmExit(
        () =>
            new Promise((resolve) => {
                showConfirmModal({
                    props: {
                        title: 'You have unsaved changes! Do you still want to leave the page?',
                        message: '',
                        onConfirm: (result: boolean) => {
                            resolve(result)
                        },
                    },
                })
            }),
        dirty && isEntityActual,
    )
}
