import React, { FC, memo, useMemo } from 'react'
import Select from '@/components/Select'
import Datepicker from '@/components/Datepicker'
import { InputText } from '@/components/InputText'
import Icon from '@/components/Icon/Icon'
import { useParams } from 'react-router-dom'
import { utils } from '@shared/common/utils'
import { IFormOrderSearch } from '@shared/types'
import useOrderSearch, { orderSearchForm } from '@shared/hooks/useOrderSearch'
import { EntityType } from '@shared/api/types'
import './FormOrderSearch.scss'

interface IFormOrderSearchProps {
    filters?: IFormOrderSearch
    onUpdate: (form: IFormOrderSearch) => void
    onRefresh: () => void
}

export const useFilters = (): IFormOrderSearch => {
    const { filters } = useParams<{ filters?: string }>()

    return useMemo(() => {
        // eslint-disable-next-line @typescript-eslint/naming-convention
        const { status, id, period_start, period_end } = (
            filters ? utils.decodeRouteFilters(filters) : {}
        ) as IFormOrderSearch
        return {
            status: Number(status || 0),
            id,
            period_start,
            period_end,
        }
    }, [filters])
}

const FormOrderSearch: FC<IFormOrderSearchProps> = ({ onUpdate, filters, onRefresh }) => {
    const {
        formik,
        statuses: statusList,
        handleStatusAction,
        handleDateRangeChange,
        loading,
    } = useOrderSearch(onUpdate, filters)
    const entityType = localStorage.getItem('type')
    return (
        <div className="form-filters">
            <form onSubmit={formik.handleSubmit}>
                <div className="field">
                    <label htmlFor="status">{orderSearchForm.status.label}</label>
                    <Select
                        max={5}
                        loading={loading}
                        disabled={loading}
                        className="status white"
                        value={formik.values.status}
                        placeholder={orderSearchForm.status.placeholder}
                        items={statusList.filter((i: any) => i.value >= 0 || entityType === EntityType.carrier)}
                        type="select"
                        onAction={handleStatusAction}
                        name="status"
                    />
                </div>

                <div className="field">
                    <label htmlFor="period">{orderSearchForm.dateInterval.label}</label>
                    <Datepicker
                        past
                        type="white"
                        start={formik.values.period_start}
                        end={formik.values.period_end}
                        onChange={handleDateRangeChange as any}
                    />
                </div>

                <div className="field field-id">
                    <label htmlFor="id" className="media-mobile">
                        {orderSearchForm.id.label}
                    </label>

                    <InputText
                        name="id"
                        className="search white"
                        errorHide
                        onChange={formik.handleChange}
                        value={formik.values.id}
                        placeholder={orderSearchForm.id.placeholder}
                        error={formik.errors.id}
                        textError={false}
                    />
                    <button type="submit" className="submit">
                        <span className="icon icon-text-search" />
                    </button>
                </div>

                <div className="refresh">
                    <button onClick={onRefresh}>
                        <Icon code="refresh" type="common" />
                    </button>
                </div>
            </form>
        </div>
    )
}

export default memo(FormOrderSearch)
