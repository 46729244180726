import React, { HTMLProps } from 'react'
import Button from '@/components/Button'
import './ManagerRow.scss'
import { UserInfo } from '@shared/api/types'
import { CompanyEntities, Modals } from '@shared/types'
import EventBus from '@shared/common/eventBus'
import { Events } from '@shared/common/events'
import { showUpdatePermissionsModal } from '@/parts/Modals/UpdatePermissionsModal'
import { useNavigate } from 'react-router-dom'
import { showManagerModal } from '@/parts/Modals/ManagerModal'
import { useUserState } from '@/store/user'

interface ManagerRowProps extends HTMLProps<HTMLDivElement> {
    callback: () => Promise<void>
    item: UserInfo
    entities: CompanyEntities
}

const ManagerRow: React.FC<ManagerRowProps> = ({ callback, item, entities }) => {
    const navigate = useNavigate()
    const {
        state: { userInfo },
    } = useUserState()

    return (
        <div className="row table-line-modal-manager">
            <div className="cell cell-email cell-email-truncated" title={item.email?.email}>
                <div className="label">Email</div>
                <span>{item.email?.email}</span>
            </div>

            <div className="cell cell-first-name cell-first-name-truncated" title={item.first_name}>
                <div className="label">First name</div>
                <span>{item.first_name}</span>
            </div>

            <div className="cell cell-last-name cell-last-name-truncated" title={item.last_name}>
                <div className="label">Last name</div>
                <span>{item.last_name}</span>
            </div>

            <div className="cell cell-phone">
                <div className="label">Type</div>
                <span>{item.type}</span>
            </div>

            <div className="cell cell-button">
                {userInfo?.id !== item.id && (
                    <>
                        <Button
                            label="Permissions"
                            types={item.type !== 'admin' ? ['blue', 'small'] : ['inverse', 'small']}
                            onClick={() => {
                                showUpdatePermissionsModal({
                                    props: {
                                        userInfo: item,
                                        entities,
                                        onClose: () => {
                                            EventBus.bus.emit(Events.CLOSE_MODAL, Modals.UpdatePermissionsModal)

                                            callback()
                                        },
                                    },
                                })
                            }}
                        />

                        <Button
                            label="Update"
                            types={['inverse', 'small']}
                            onClick={() => {
                                showManagerModal({
                                    props: {
                                        userInfo: item,
                                        onClose: () => {
                                            EventBus.bus.emit(Events.CLOSE_MODAL, Modals.ManagerModal)

                                            callback()
                                        },
                                    },
                                })
                            }}
                        />
                    </>
                )}

                {userInfo?.id === item.id && (
                    <Button
                        label="Update"
                        types={['inverse', 'small']}
                        onClick={() => {
                            navigate('/user/settings')
                        }}
                    />
                )}
            </div>
        </div>
    )
}

export default ManagerRow
