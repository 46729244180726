import * as Yup from 'yup'
import { IWarehouseSettingsForm } from '@shared/types'
import { validatePeriod } from '@/components/SelectTime'

export const SettingsFormSchema = Yup.object().shape({
    entity: Yup.string().required(),
    work_time_from: Yup.string().nullable().required('Working time is required'),
    work_time_to: Yup.string()
        .nullable()
        .required('Working time is required')
        .test({
            name: 'Working time end should be different from starting time', // Your custom error id
            test() {
                // eslint-disable-next-line @typescript-eslint/naming-convention
                const { work_time_from, work_time_to } = this.parent
                if (validatePeriod(work_time_from, work_time_to)) return true
                return this.createError({
                    message: 'Working time end should be after starting time', // Error message for the user
                    path: 'work_time_to', // The object path where you want show the error
                })
            },
        }),
    auto_booking: Yup.boolean(),
    working_days: Yup.string(),
    license: Yup.string().nullable(),
    area: Yup.number().min(0).max(50000).nullable(),
    min_order_price: Yup.number().min(0).max(50000).nullable(),
    local_delivery_distance: Yup.number().min(0).max(50000),
    refrigerator_area: Yup.number().min(0).max(50000).nullable(),
    area_sq_feet: Yup.number().nullable(),
    refrigerator_area_sq_feet: Yup.number().nullable(),
    gates_count: Yup.number().min(0).max(255).nullable(),
    comment: Yup.string().max(255),
    physical_address: Yup.string(),
    legal_address: Yup.string(),
    billing_address: Yup.string(),
    services: Yup.array().of(Yup.object()).nullable(),
    conditions: Yup.array().of(Yup.object()),
    legalSamePhysical: Yup.boolean(),
    billingSamePhysical: Yup.boolean(),
    companyId: Yup.number(),
    formChanged: Yup.boolean(),
})

export const SETTINGS_FORM_INITIAL_VALUES: IWarehouseSettingsForm = {
    entity: '',
    auto_booking: false,
    work_time_from: '',
    work_time_to: '',
    working_days: '',
    gates_count: null,
    license: null,
    area: null,
    refrigerator_area: null,
    area_sq_feet: null,
    refrigerator_area_sq_feet: null,
    min_order_price: null,
    comment: '',
    physical_address: '',
    legal_address: '',
    billing_address: '',
    services: [],
    conditions: [],
    payout_destination: {
        last_account_id_numbers: '',
        account_number: '',
        routing_number: '',
    },
}
