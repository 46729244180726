import React, { FC, HTMLAttributes } from 'react'
import cn from 'classnames'
import { Link } from 'react-router-dom'
import logo from 'images/logo.svg'
import ROUTES from '@/router'
import './Logo.scss'

interface ILogo extends HTMLAttributes<HTMLAnchorElement> {
    isLoggedIn?: boolean
}

const CLASS_NAME = 'company-logo'

export const Logo: FC<ILogo> = ({ className, isLoggedIn }) => (
    <Link to={isLoggedIn ? ROUTES.company.base : '/'} className={cn(CLASS_NAME, className)}>
        <img src={logo} alt="logo" />
    </Link>
)
