import React from 'react'
import { Info } from '@/components/Info'
import { banned, filled, moderated } from './helper'
import { Warehouse } from '@shared/types'
import { useUserState } from '@/store/user'
import { resend } from '@/utils/manager'
import { ReactComponent as ClockIcon } from '@/assets/images/clock.svg'
import './WarehouseStatusInfo.scss'

interface WarehouseStatusInfoProps {
    warehouse: Warehouse
}

// eslint-disable-next-line complexity
export const WarehouseStatusInfo: React.FC<WarehouseStatusInfoProps> = ({ warehouse }) => {
    const { state } = useUserState()
    const emailVerified = state?.userInfo?.email?.status?.codeName === 'CONFIRMED'

    return (
        <div className="warehouse-status-info">
            {banned(warehouse) && <Info className="warning">Your warehouse was banned by the administration</Info>}

            {!emailVerified && (
                <Info className="warning">
                    Your warehouse will be displayed in the search once you verify your e-mail address.
                    <br />
                    Check <b>{state?.userInfo?.email?.email}</b> for your verification link. If you can&apos;t find it,
                    check your spam folder{' '}
                    {/* eslint-disable-next-line jsx-a11y/anchor-is-valid,jsx-a11y/no-static-element-interactions */}
                    <a
                        style={{ textDecoration: 'underline' }}
                        onClick={(e) => {
                            e.preventDefault()
                            resend()
                        }}
                    >
                        resend
                    </a>{' '}
                    link.
                </Info>
            )}

            {!moderated(warehouse) && (
                <Info className="pending">
                    Your warehouse will be displayed in the search once it’s verified by OLIMP. Usually it takes 1–2
                    days.
                </Info>
            )}
        </div>
    )
}
