import React, { FC, useCallback, useState, useEffect } from 'react'
import cn from 'classnames'
import { Helmet } from 'react-helmet'
import { startOfMonth, endOfMonth, getDaysInMonth, getDay, format, isToday, isPast, add, sub } from 'date-fns'
import { useNavigate } from 'react-router-dom'
import Currency from '@/components/Currency'
import Button from '@/components/Button'
import { showOrdersModal } from '@/parts/Modals/OrdersModal'
import { Schedule } from '@shared/api/types'
import { getSchedule } from '@shared//api/order'
import { Modals, Order } from '@shared/types'
import { ModalRegistry } from '@shared/common/modals'
import './WarehouseCalendar.scss'

enum Language {
    en = 'en',
    ru = 'ru',
}

const days: Record<Language, string[]> = {
    en: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
    ru: ['Пн', 'Вт', 'Ср', 'Чт', 'Пт', 'Сб', 'Вс'],
}

interface CalendarDate {
    date: string
    source: Date
    num: string
    otherMonth: boolean
    past: boolean
    today: boolean
}

interface Summary {
    number_of_orders: number
    number_of_units: number
    pending: number
    sub_order: number
    total: number
}

const DEFAULT_SUMMARY: Summary = {
    number_of_orders: 0,
    number_of_units: 0,
    pending: 0,
    sub_order: 0,
    total: 0,
}

const PAGE_TITLE = 'Olimp - Calendar'

export const WarehouseCalendar: FC = () => {
    const [month, setMonth] = useState<Date>(startOfMonth(new Date()))
    const [schedule, setSchedule] = useState<Partial<Schedule | null>>(null)
    const navigate = useNavigate()
    // const [lang, setLang] = useState<Language>(Language.en)

    const updateSchedule = async () => {
        try {
            const currentSchedule = await getSchedule(
                format(startOfMonth(month), 'yyyy-MM-dd'),
                format(endOfMonth(month), 'yyyy-MM-dd'),
            )
            setSchedule(currentSchedule)
        } catch (e) {
            // eslint-disable-next-line no-console
            console.log(JSON.stringify(e))
        }
    }

    const prev = (): void => setMonth(sub(startOfMonth(month), { months: 1 }))
    const current = (): void => setMonth(startOfMonth(new Date()))
    const next = (): void => setMonth(add(startOfMonth(month), { months: 1 }))

    const calendar = useCallback(() => {
        // eslint-disable-next-line @typescript-eslint/no-shadow
        const calendar: CalendarDate[][] = []
        let week: CalendarDate[] = []
        const daysInMonth = getDaysInMonth(month)
        const dayIndex = getDay(month)
        let count = daysInMonth + dayIndex

        if (count % 7 > 0) {
            count += 7 - (count % 7)
        }

        let subDay = sub(month, { days: dayIndex })

        // eslint-disable-next-line no-plusplus
        for (let i = 1; i <= count; i++) {
            const otherMonth = i < dayIndex + 1 || i >= daysInMonth + dayIndex + 1

            week.push({
                // 11 May, 2021, Tue
                date: format(subDay, 'dd LLLL, yyyy, E'),
                source: new Date(subDay),
                num: format(subDay, 'd'),
                otherMonth,
                past: isPast(subDay),
                today: isToday(subDay),
            })

            if (i % 7 === 0) {
                calendar.push(week)
                week = []
            }

            subDay = add(subDay, { days: 1 })
        }

        return calendar
    }, [month])

    const updatedSummary = useCallback(() => {
        const result: Summary = { ...DEFAULT_SUMMARY }

        if (schedule) {
            // eslint-disable-next-line no-restricted-syntax,guard-for-in
            for (const key in schedule?.items) {
                result.number_of_orders += schedule?.items[key].number_of_orders
                result.number_of_units += schedule?.items[key].number_of_units
                result.total += schedule?.items[key].total
            }

            // eslint-disable-next-line no-restricted-syntax,guard-for-in
            for (const key in schedule?.pending) {
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                result.pending += schedule?.pending[key as string]?.length
            }

            // eslint-disable-next-line guard-for-in,no-restricted-syntax
            for (const key in schedule?.elements) {
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                schedule?.elements[key]?.forEach((service) => {
                    if (service.parent_id) {
                        result.sub_order += 1
                    }
                })
            }
        }
        return [result]
    }, [schedule])

    useEffect(() => {
        updateSchedule()
    }, [month])

    return (
        <div className="warehouse-calendar wrapper page">
            <Helmet>
                <title>{PAGE_TITLE}</title>
                <meta name="description" content={PAGE_TITLE} />
            </Helmet>
            <div>
                <div className="page-title">Calendar</div>

                <div className="wrap">
                    <div className="section">
                        <div className="header">
                            <div className="left">
                                <div className="title month">{format(month, 'LLLL, yyyy')}</div>

                                {updatedSummary()?.map((summary) => (
                                    // eslint-disable-next-line react/jsx-key
                                    <div className="info summary" key="summary-key">
                                        <div className="params">
                                            <div className="param">
                                                <span className="icon icon-local-destribution" />
                                                <span>{summary.number_of_orders}</span>
                                            </div>

                                            <div className="param">
                                                <span className="icon icon-pallets" />
                                                <span>{summary.number_of_units}</span>
                                            </div>

                                            <div className="param">
                                                <Currency value={summary.total} />
                                            </div>

                                            <div className="param">
                                                <span className="icon-status pending" />
                                                <span>{summary.pending}</span>
                                            </div>
                                            <div className="param">
                                                <span className="icon-status sub-order" />
                                                <span>{summary.sub_order}</span>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div>

                            <div className="nav">
                                <Button types={['bordered', 'small']} className="arrow" onClick={prev}>
                                    <span className="icon prev icon-arrow-thin" />
                                </Button>
                                <Button
                                    label="Today"
                                    types={['bordered', 'small']}
                                    className="total"
                                    onClick={current}
                                />
                                <Button types={['bordered', 'small']} className="arrow" onClick={next}>
                                    <span className="icon next icon-arrow-thin" />
                                </Button>
                            </div>
                        </div>
                    </div>

                    <div className="calendar-wrap">
                        <div className="header-days">
                            {days.en.map((day) => (
                                <div className="cell" key={`day-${day}`}>
                                    {day}
                                </div>
                            ))}
                        </div>

                        <div className="calendar">
                            {calendar().map((week, weekId) => (
                                // eslint-disable-next-line react/no-array-index-key
                                <div className="row row-body" key={`weekId-${weekId}-${week[0].num}`}>
                                    {week.map((day, dayId) => {
                                        const getInfo = (date: Date, param: string | null = null) => {
                                            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                                            // @ts-ignore
                                            const info = schedule?.items?.[format(day.source, 'yyyy-MM-dd')]

                                            if (param) {
                                                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                                                // @ts-ignore
                                                return info && info[param] ? info[param] : null
                                            }

                                            return info || null
                                        }
                                        const pendingOrders =
                                            schedule?.pending?.[format(day.source, 'yyyy-MM-dd')] || null
                                        const subOrders: Partial<Order>[] = []
                                        schedule?.elements?.[format(new Date(day.source), 'yyyy-MM-dd')]?.forEach((o) =>
                                            subOrders.concat(o.subOrders),
                                        )
                                        return (
                                            // eslint-disable-next-line react/no-array-index-key
                                            <div className="cell" key={`${day.date}-${dayId}`}>
                                                {/* eslint-disable-next-line jsx-a11y/no-static-element-interactions */}
                                                <div
                                                    className={cn('item', {
                                                        'other-month': day.otherMonth,
                                                        past: day.past,
                                                        today: day.today,
                                                        'has-info': !!getInfo(day.source) || !!pendingOrders,
                                                    })}
                                                    onClick={() =>
                                                        showOrdersModal({
                                                            props: {
                                                                period_start: format(
                                                                    new Date(day.source),
                                                                    'yyyy-MM-dd',
                                                                ),
                                                                period_end: format(new Date(day.source), 'yyyy-MM-dd'),
                                                                title: format(
                                                                    new Date(day.source),
                                                                    'dd MMMM, yyyy, ddd',
                                                                ),
                                                                onOpenOrder: (id) => {
                                                                    ModalRegistry.get().close(Modals.OrdersModal)
                                                                    navigate(`/warehouse/order/${id}`)
                                                                },
                                                            },
                                                        })
                                                    }
                                                >
                                                    <div className="date">{day.num}</div>
                                                    {pendingOrders && <div className="icon-status pending" />}
                                                    {subOrders.length > 0 && <div className="icon-status sub-order" />}
                                                    {getInfo(day.source) && (
                                                        <div className="info">
                                                            <div className="params">
                                                                <div className="param">
                                                                    <span className="icon icon-local-destribution" />
                                                                    <span>
                                                                        {getInfo(day.source, 'number_of_orders') || '-'}
                                                                    </span>
                                                                </div>

                                                                <div className="param">
                                                                    <span className="icon icon-pallets" />
                                                                    <span>
                                                                        {getInfo(day.source, 'number_of_units') || '-'}
                                                                    </span>
                                                                </div>
                                                            </div>

                                                            <div className="total">
                                                                <Currency value={getInfo(day.source, 'total')} />
                                                            </div>
                                                        </div>
                                                    )}
                                                </div>
                                            </div>
                                        )
                                    })}
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
