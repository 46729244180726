import React, { FC, useEffect } from 'react'
import { SignUpTemplate } from '@/pages/SignUp/components/SignUpTemplate'
import { InputText } from '@/components/InputText'
import Button from '@/components/Button'
import { updateLoggedState, useUserState } from '@/store/user'
import Select from '@/components/Select'
import { useServiceInfo } from '@shared/api/user'
import useCompanySettings from '@shared/hooks/useCompanySettings'
import { useOneTimeSessionData } from '@/hooks/useOneTimeSessionData'

export const CompanyInfoStep: FC = () => {
    const { data } = useServiceInfo()
    const { consume } = useOneTimeSessionData('signupValues')
    const { state: userState, dispatch } = useUserState()
    const { formik, loading } = useCompanySettings({
        onSuccess: async (company: any) => {
            await updateLoggedState(
                {
                    ...userState.userInfo!,
                    registration_step: company.registration_step,
                },
                dispatch,
            )
        },
    })

    const leadSources =
        data &&
        data.lead_sources &&
        (data.lead_sources as string)
            .split(',')
            .map((i: string) => i.trim())
            .filter((i: string) => i !== '')
            .map((i: string) => ({ value: i, name: i }))

    useEffect(() => {
        if (!loading) {
            formik.setFieldValue('entity', consume('company'))
        }
    }, [loading])
    return (
        <SignUpTemplate title="Company Info" progress={userState?.signUp?.chosenEntityType ? 300 / 5 : 300 / 6}>
            <form onSubmit={formik.handleSubmit}>
                <InputText
                    autoFocus
                    text
                    label="Company name"
                    name="entity"
                    onChange={formik.handleChange}
                    value={formik.values.entity}
                    error={formik.errors.entity}
                />
                <Select
                    label="How did you hear about us?"
                    items={leadSources}
                    name="came_from"
                    className="list-select"
                    value={formik.values.came_from}
                    onAction={({ value }) => {
                        formik.setFieldValue('came_from', value)
                    }}
                />
                <br />
                <Button
                    label="Next"
                    type="submit"
                    disabled={!formik.isValid}
                    loading={formik.isSubmitting}
                    types={['blue', 'full']}
                />
            </form>
        </SignUpTemplate>
    )
}
