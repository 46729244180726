import React, { ChangeEvent, memo, useCallback } from 'react'
import { Range } from 'react-range'
import cn from 'classnames'
import './Slider.scss'

interface ISlider {
    className?: string
    measure?: string
    min?: number
    max?: number
    label?: string
    value?: number
    name: string
    onChange: (e: ChangeEvent<HTMLElement>) => void
}

export const Slider: React.FC<ISlider> = memo(
    ({ onChange, className, name, label, measure = '', min = 0, max = 100, value = 0 }) => {
        const handleChange = useCallback(
            (values: number[]) => {
                if (values && values.length && onChange) {
                    const [val] = values
                    const e: any = {
                        target: {
                            name,
                            value: val,
                        },
                    }
                    onChange(e)
                }
            },
            [name, onChange],
        )

        return (
            <div className={cn('slider-input', className)}>
                {label && <div className="label">{label}</div>}

                <Range
                    step={1}
                    min={min}
                    max={max}
                    values={[value]}
                    onChange={handleChange}
                    renderTrack={({ props, children }) => (
                        <div
                            {...props}
                            style={{
                                ...props.style,
                                padding: '12px 0 16px 0',
                            }}
                        >
                            <div
                                style={{
                                    height: 2,
                                    borderRadius: 15,
                                    width: '100%',
                                    backgroundColor: 'rgb(81, 156, 236)',
                                }}
                            />
                            {children}
                        </div>
                    )}
                    renderThumb={({ props }) => (
                        <div
                            {...props}
                            style={{
                                ...props.style,
                                borderRadius: '50%',
                                width: 12,
                                height: 12,
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                backgroundColor: 'rgb(81, 156, 236)',
                                marginTop: -3,
                                boxShadow: '0.5px 0.5px 2px 1px rgb(0 0 0 / 32%)',
                            }}
                        >
                            <div className="slider-input__label">
                                {value} {measure}
                            </div>
                        </div>
                    )}
                />
            </div>
        )
    },
)

Slider.displayName = 'Slider'
