import React from 'react'
import { showOneFieldModalAsync } from '@/parts/Modals/OneFieldPopup'
import { getStatus } from '@/utils/status'
import { VerifStatus } from '@/components/InputText'
import { showFeedbackModal } from '@/parts/Modals/FeedbackModal'
import { Carrier } from '@shared/types'
import { HttpV2 } from '@shared/apiv2/api'

enum LicenseStatus {
    NOT_VERIFIED = 1,
    VERIFYING = 3,
    VERIFIED = 5,
}

interface UpdateCarrierLicenseInDialogOptions {
    carrier: Carrier
    onSuccess: (status: LicenseStatus) => Promise<void>
}

export const updateCarrierLicenseInDialog = (options: UpdateCarrierLicenseInDialogOptions) => {
    const { carrier } = options
    return showOneFieldModalAsync({
        props: {
            description: 'You won’t be able to book a warehouse until you enter a valid USDOT/MC#',
            inputProps: {
                placeholder: 'USDOT or MC/MX#',
                status: getStatus(carrier?.license?.status?.codeName) as VerifStatus,
                label: 'USDOT or MC/MX#',
            },
            successLabel: 'USDOT/MC# was successfully updated.',
            initialValue: carrier?.license?.number,

            title: 'Enter a valid USDOT/MC#',
            doAsync: async (val) => {
                let timeout: NodeJS.Timeout
                await HttpV2.put('/carrier/license', { license: val })

                const status = await new Promise<LicenseStatus>((resolve, reject) => {
                    const getLicenseStatus = () => {
                        if (timeout) {
                            clearTimeout(timeout)
                        }

                        HttpV2.get('/carrier/license').then((resp) => {
                            const s = resp.data as LicenseStatus
                            if (s === LicenseStatus.VERIFYING) {
                                timeout = setTimeout(() => getLicenseStatus(), 800)
                            } else if (s === LicenseStatus.NOT_VERIFIED) {
                                reject({ msg: 'We could not verify this license' })
                            } else {
                                resolve(s)
                            }
                        })
                    }
                    getLicenseStatus()
                    // const resp = await Http.put('/carrier/v1/carrier', { license: val, only_usdot: true })
                })

                if (options.onSuccess) {
                    options.onSuccess(status)
                }
            },
            footer: (
                // eslint-disable-next-line jsx-a11y/anchor-is-valid,jsx-a11y/no-static-element-interactions
                <a onClick={() => showFeedbackModal({ props: {} })} className="secondary-link">
                    Contact support
                </a>
            ),
        },
    })
}
