import { useCallback, useEffect, useState } from 'react'
import { useSecuredImageUrl } from '@shared/hooks/useSecuredImageUrl'
import { ILoadedDocument } from './types'

const CLASS_NAME = 'uploaded-document-view'

interface HookReturnValue {
    href?: string
    onOpen(): void
    target: ILoadedDocument['target']
    loading: boolean
}

const useLoadedDocument = ({
    url,
    target = '_blank',
    loading,
    secured,
    onOpenClick,
}: ILoadedDocument): HookReturnValue => {
    const { imageUrl: securedDocUrl, getImageUrl, loading: urlLoading } = useSecuredImageUrl(url, false)
    const [href, setHref] = useState<string | undefined>()
    const [ready, setReady] = useState<boolean>(false)

    const docLoading = loading || urlLoading

    const onOpen = useCallback(() => {
        onOpenClick?.()

        if (ready && href) {
            window.open(href, target)
            return
        }

        if (secured) {
            getImageUrl()
        } else if (url) {
            setHref(url)
        }
    }, [getImageUrl, href, onOpenClick, ready, secured, target, url])

    useEffect(() => {
        if (secured && !href && securedDocUrl) setHref(securedDocUrl)
    }, [href, secured, securedDocUrl])

    useEffect(() => {
        if (href) setReady(true)
    }, [href])

    useEffect(() => {
        if (ready && href) {
            window.open(href, target)
        }
    }, [href, ready, target])

    return {
        href,
        onOpen,
        target: href ? target : undefined,
        loading: docLoading,
    }
}

export { useLoadedDocument }
