import React, { useCallback, useState } from 'react'
import { SignUpTemplate } from '@/pages/SignUp/components/SignUpTemplate'
import { useFormik } from 'formik'
import Button from '@/components/Button'
import './SignUpWarehouseStep.scss'
import { updateLoggedState, useUserState } from '@/store/user'
import * as Yup from 'yup'
import InputLocation from '@/components/InputLocation'
import { form } from '@/pages/Carrier/CarrierSettings/CarrierSettingsForm'
import Checkbox from '@/components/Checkbox'
import { updateWarehouse } from '@shared/api/warehouse'

interface SignUpWarehouseAddressValues {
    physical_address: string
    legal_address?: string
    billing_address?: string
    legalSamePhysical?: boolean
    billingSamePhysical?: boolean
}
export const SignUpWarehouseAddressSchema = Yup.object().shape({
    physical_address: Yup.string(),
    legal_address: Yup.string(),
    billing_address: Yup.string(),
    legalSamePhysical: Yup.boolean(),
    billingSamePhysical: Yup.boolean(),
})

export const SignUpWarehouseAddressStep: React.FC = () => {
    const { state: userState, dispatch } = useUserState()
    const formik = useFormik<SignUpWarehouseAddressValues>({
        onSubmit: async (values) => {
            const updatedWarehouse = await updateWarehouse({
                physical_address: values.physical_address,
                legal_address: values.legal_address,
                billing_address: values.billing_address,
            })

            await updateLoggedState(
                {
                    ...userState.userInfo!,
                    registration_step: 21,
                },
                dispatch,
            )
        },
        initialValues: {
            physical_address: '',
        },
    })

    const [legalSame, setLegalSame] = useState(true)
    const [billingSame, setBillingSame] = useState(true)

    const handleChangeLegalSame = useCallback(
        (e) => {
            if (e.target.value) {
                setLegalSame(true)
                formik.setFieldValue('legal_address', formik.values.physical_address)
            } else {
                setLegalSame(false)
            }
        },
        [formik],
    )

    const handleChangeBillingSame = useCallback(
        (e) => {
            if (e.target.value) {
                setBillingSame(true)
                formik.setFieldValue('billing_address', formik.values.physical_address)
            } else {
                setBillingSame(false)
            }
        },
        [formik],
    )

    const handlePhysicalAddressChange = useCallback(
        (e) => {
            formik.handleChange(e)
            if (legalSame) {
                formik.handleChange({ target: { name: 'legal_address', value: e.target.value } })
            }
            if (billingSame) {
                formik.handleChange({ target: { name: 'billing_address', value: e.target.value } })
            }
        },
        [formik, legalSame, billingSame],
    )
    return (
        <SignUpTemplate step={[1, 4]} title="Great! What’s your warehouse address?">
            <div className="sign-up_warehouse-address">
                <form onSubmit={formik.handleSubmit}>
                    <div className="addresses">
                        <div className="field">
                            <InputLocation
                                allowLocationSelect
                                textError
                                error={formik.errors.physical_address}
                                value={formik.values.physical_address}
                                onChange={handlePhysicalAddressChange}
                                name="physical_address"
                                placeholder={form.physical_address.label}
                            />
                        </div>

                        <div className="field legal-address">
                            <InputLocation
                                allowLocationSelect
                                textError
                                error={formik.errors.legal_address}
                                value={formik.values.legal_address}
                                onChange={formik.handleChange}
                                name="legal_address"
                                disabled={legalSame}
                                label={form.legal_address.label}
                                placeholder={form.legal_address.label}
                            />

                            <Checkbox
                                value={legalSame}
                                label="Same as location address"
                                onChange={handleChangeLegalSame}
                            />
                        </div>

                        <div className="field billing-address">
                            <InputLocation
                                allowLocationSelect
                                textError
                                error={formik.errors.billing_address}
                                value={formik.values.billing_address}
                                onChange={formik.handleChange}
                                disabled={billingSame}
                                name="billing_address"
                                label={form.billing_address.label}
                                placeholder={form.billing_address.label}
                            />

                            <Checkbox
                                value={billingSame}
                                label="Same as location address"
                                onChange={handleChangeBillingSame}
                            />
                        </div>
                    </div>
                    <br />
                    <Button
                        label="Next"
                        type="submit"
                        disabled={!formik.isValid}
                        loading={formik.isSubmitting}
                        types={['blue', 'full']}
                    />
                </form>
            </div>
        </SignUpTemplate>
    )
}
