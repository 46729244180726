import React, { HTMLProps, useCallback, useEffect, useState } from 'react'
import { CarrierCommonInfo, Modals, WarehouseCommonInfo } from '@shared/types'
import Button from '@/components/Button'
import './EntityRow.scss'
import { EntityType, UserInfo } from '@shared/api/types'
import EventBus from '@shared/common/eventBus'
import { Events } from '@shared/common/events'
import { showEntityManagerModal } from '@/parts/Modals/EntityManagerModal'
import { EntityStatus } from '@/components/EntityStatus'
import { getFullEntityStatus } from '@/utils/status'
import { useSelectEntity } from '@/hooks/useSelectEntity'

interface EntityRowProps extends HTMLProps<HTMLDivElement> {
    managers?: UserInfo[]
    callback: any
    type: EntityType
    disabled?: boolean
    setLoading: React.Dispatch<React.SetStateAction<boolean>>
    item: CarrierCommonInfo | WarehouseCommonInfo
}

const EntityRow: React.FC<EntityRowProps> = ({ type, disabled, setLoading, item, callback, managers }) => {
    const finalStatus = getFullEntityStatus(item)
    const { select: selectEntity, loading: loadingSelect } = useSelectEntity()
    const [entityData, setEntityData] = useState<{ type: EntityType; id: number } | undefined>()

    const onSelectEntity = useCallback(
        (entityType: EntityType, id: number) => {
            setLoading(true)
            setEntityData({ type: entityType, id })
        },
        [setLoading],
    )

    useEffect(() => {
        if (entityData) {
            selectEntity(entityData.type, entityData.id, true)
        }
    }, [entityData, selectEntity])

    return (
        <div className="row table-line-modal-entities">
            <div className="cell cell-id">
                <div className="label">№</div>
                <EntityStatus status={finalStatus} />
                <span>{item.id}</span>
            </div>

            <div className="cell cell-entity-name">
                <div className="label">{type === EntityType.warehouse ? 'Warehouse' : 'Carrier'} name</div>
                <span>{item.entity}</span>
            </div>

            <div className="cell cell-manager">
                <div className="label">Manager</div>
                <span>
                    {item.defaultManager.first_name} {item.defaultManager.last_name}
                </span>
            </div>

            <div className="cell cell-orders">
                <div className="label">Orders</div>
                <div className="order-details">
                    <div className={`order-detail ${item.numberOfPendingOrders > 0 ? 'alert ' : ''}`}>
                        <span className="order-label">In Progress</span>
                        <span>{item.numberOfInProgressOrders}</span>
                    </div>
                    <div className="order-details-pipe" />
                    <div className="order-detail">
                        <span className="order-label">Closed</span>
                        <span>{item.numberOfClosedOrders}</span>
                    </div>
                </div>
            </div>

            <div className="cell cell-button">
                {managers && (
                    <Button
                        label="Set main manager"
                        disabled={disabled}
                        types={['inverse', 'small']}
                        onClick={() => {
                            showEntityManagerModal({
                                props: {
                                    entity: item,
                                    managers,
                                    entityType: type,
                                    onClose: (redirect) => {
                                        EventBus.bus.emit(Events.CLOSE_MODAL, Modals.EntityManagerModal)

                                        callback()
                                    },
                                },
                            })
                        }}
                    />
                )}
                <Button
                    className="entity-row-btn"
                    loading={loadingSelect}
                    disabled={disabled}
                    label="Go"
                    types={['blue', 'small']}
                    onClick={() => onSelectEntity(type, item.id)}
                />
            </div>
        </div>
    )
}

export default EntityRow
