import React, { FC, memo, useState } from 'react'
import { ModalRegistry } from '@shared/common/modals'
import { ModalPartialProps, Modals } from '@shared/types'
import './LoginModal.scss'
import { useFormik } from 'formik'
import { InputText } from '@/components/InputText'
import Button from '@/components/Button'
import EventBus from '@shared/common/eventBus'
import { Events } from '@shared/common/events'
import * as Yup from 'yup'
import { apiVersion, Http } from '@shared/api/Http'
import { Login } from '@shared/api/types'
import { updateLoggedState, userLogout, useUserState } from '@/store/user'
import { showRestoreModal } from '@/parts/Modals/RestoreModal'
import { showInfoModal } from '@/parts/Modals/InfoModal'
import { utils } from '@shared/common/utils'
import { useLocation } from 'react-router-dom'

const LoginSchema = Yup.object().shape({
    type: Yup.string(),
    email: Yup.string().email('Invalid email').required('Required'),
    password: Yup.string().min(2, 'Too Short!').max(50, 'Too Long!').required('Required'),
})

interface LoginModalProps {
    title: string
    onClose?: (redirectUrl?: string) => void
    initialEmail?: string
}

const token = localStorage.getItem('token')

if (token) {
    Http.defaults.headers.common['Auth-Token'] = token
}

const LoginModal: FC<LoginModalProps> = ({ title, onClose, initialEmail }) => {
    const [respErr, setRespErr] = useState<string | undefined>()
    const location = useLocation()

    const { dispatch } = useUserState()

    const queryEmail = utils.getSearchParamByName(location.search, 'email') ?? ''
    const queryMessage = utils.getSearchParamByName(location.search, 'message') ?? ''
    const queryBooking = utils.getSearchParamByName(location.search, 'booking') ?? ''
    const queryBookingWh = utils.getSearchParamByName(location.search, 'bookingWh') ?? ''
    const formik = useFormik({
        initialValues: {
            email: queryEmail,
            password: '',
        },
        onSubmit: async ({ email, password }) => {
            try {
                Http.defaults.headers.common['Auth-Token'] = ''
                await userLogout(dispatch!)
                const response = await Http.post<{ email: string; password: string }, Login>(
                    `/app/${apiVersion}/authorization`,
                    {
                        email,
                        password,
                    },
                )
                formik.setSubmitting(false)
                // eslint-disable-next-line no-console

                Http.defaults.headers.common['Auth-Token'] = response.token
                localStorage.setItem('token', response.token)
                await updateLoggedState(response.info, dispatch)
                if (queryBooking) {
                    onClose?.(
                        `/carrier/${
                            response.info.permissions.carriers[0] ?? 0
                        }/booking/${queryBookingWh}/${queryBooking}`,
                    )
                } else {
                    onClose?.('/company')
                }
            } catch (e: any) {
                formik.setSubmitting(false)
                formik.setErrors(e)
                setRespErr(
                    Object.keys(e)
                        .map((errorField) => e[errorField])
                        .join(', '),
                )
            }
        },
        validationSchema: LoginSchema,
        enableReinitialize: true,
        validateOnChange: false,
        validateOnMount: false,
    })

    const restore = (e: any) => {
        e.preventDefault()
        showRestoreModal({
            props: {
                onRestore: (message = 'Successfully') => {
                    EventBus.bus.emit(Events.CLOSE_MODAL, Modals.RestoreModal)
                    showInfoModal({
                        props: {
                            message,
                        },
                    })
                },
            },
        })
    }

    const err = respErr || formik.errors.email || formik.errors.password

    return (
        <form className="login-form" onSubmit={formik.handleSubmit}>
            <h2>{title}</h2>
            {queryMessage && <p className="login-message">{queryMessage}</p>}
            <InputText
                text
                label="E-mail"
                name="email"
                autoFocus
                onChange={formik.handleChange}
                value={formik.values.email}
                error={formik.errors.email}
                errorHide
            />
            <InputText
                text
                label="Password"
                name="password"
                type="password"
                onChange={formik.handleChange}
                value={formik.values.password}
                error={formik.errors.password}
                errorHide
            />
            {err && <div className="login-err">{err}</div>}
            <div className="modal-after">
                {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                <a href="#" className="secondary-link" onClick={restore}>
                    Forgot password
                </a>
            </div>
            <Button label="Login" type="submit" loading={formik.isSubmitting} types={['blue', 'full']} />
        </form>
    )
}

ModalRegistry.get().register<LoginModalProps>(Modals.LoginModal, {
    id: 'LoginModalProps',
    className: 'modal-login',
    size: 'medium',
    Component: memo(LoginModal),
})

export const showLoginModal = (props: ModalPartialProps<LoginModalProps>): void =>
    ModalRegistry.get().show<LoginModalProps>(Modals.LoginModal, props)

export default memo(LoginModal)
