import React, { FC, memo, useCallback, useEffect, useState } from 'react'
import { sendOrderQuote, useWarehouseForBooking } from '@shared/api/carrier'
import { useNavigate, useParams } from 'react-router-dom'
import { CarrierBookingState } from '@shared/types'
import dayjs from 'dayjs'
import Iframe from '@/components/Iframe'
import { showConfirmModal } from '@/parts/Modals/ConfirmModal'
import { showInfoModalAsync } from '@/parts/Modals/InfoModal'
import { CarrierBookingFormUtils } from '@/pages/Carrier/CarrierBooking/Form/utils'
import { Loader } from '@/components/Loader'
import './CarrierQuote.scss'

const dev = process.env.NODE_ENV === 'development'

// eslint-disable-next-line complexity
export const CarrierQuote: FC = memo(() => {
    const [state, setState] = useState<CarrierBookingState | undefined>()
    const { warehouseId, filters } = useParams<{ warehouseId?: string; filters?: string }>()
    const [html, setHtml] = useState(null)
    const navigate = useNavigate()

    const { data, loading } = useWarehouseForBooking(warehouseId ? Number(warehouseId) : undefined)

    const prepareRequest = useCallback<any>((s: CarrierBookingState, preview = true) => {
        const dateFormat = 'YYYY-MM-DD'

        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        // eslint-disable-next-line @typescript-eslint/naming-convention
        const { searchData, utc_offset } = s
        const { service, delivery } = searchData
        const startDate = dayjs().add(1, 'day').utcOffset(Number(utc_offset))
        return {
            amount: searchData.number_of_units ?? 1,
            period_start: startDate.startOf('day').format(dateFormat),
            period_end: startDate
                .endOf('day')
                .add(Number(searchData.number_of_days || 1), 'day')
                .format(dateFormat),
            arrival_from: s?.work_time_from,
            arrival_to: dayjs(s?.work_time_from, 'HH:mm').add(1, 'hour').format('HH:mm'),
            full_search: true,
            range: searchData.distance,
            comment: '',
            services: delivery ? [service, delivery] : [service],
            warehouse_id: s?.id,
            unit: searchData.unit,
            distribution_address: '-',
            preview,
        }
    }, [])

    const sendQuote = useCallback<any>(() => {
        showConfirmModal({
            props: {
                title: 'Please confirm to send quote email',
                message: '',
                onConfirm: async (result: boolean) => {
                    if (result && state) {
                        const { recipient } = await sendOrderQuote(state.id, prepareRequest(state, false))
                        await showInfoModalAsync({
                            props: {
                                title: 'Success',
                                message: `Quote was sent to email: ${recipient}`,
                            },
                        })
                        navigate(-1)
                    }
                },
            },
        })
    }, [prepareRequest, state])

    useEffect(() => {
        if (!loading && data) {
            const s = CarrierBookingFormUtils.getCarrierBookingState(data, filters)
            setState(s)

            sendOrderQuote(s.id, prepareRequest(s)).then((resp) => {
                setHtml(resp.body)
            })
        }
    }, [loading])

    return (
        <div style={{ height: `calc(100% - 190px${dev ? ' - 42px' : ''})` }} className="order-quote">
            {html !== null ? (
                <Iframe
                    content={html as any}
                    onClick={{
                        id: 'send-quote-btn',
                        callback: sendQuote,
                    }}
                />
            ) : (
                <Loader />
            )}
        </div>
    )
})

CarrierQuote.displayName = 'CarrierQuote'
