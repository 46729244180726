import React, { FC, memo, useEffect, useState } from 'react'
import { useWarehouseForBooking } from '@shared/api/carrier'
import { useParams } from 'react-router-dom'
import { Loader } from '@/components/Loader'
import cn from 'classnames'
import dayjs from 'dayjs'
import { Rating } from '@/parts/Rating'
import { CarrierBookingForm } from '@/pages/Carrier/CarrierBooking/Form'
import { CarrierBookingFormUtils } from '@/pages/Carrier/CarrierBooking/Form/utils'
import { CarrierBookingState } from '@shared/types'

import './CarrierBooking.scss'
import { PhotosView } from '@/parts/PhotosView'
import { getDocumentUrl } from '@/utils/documents'
import { useMobileWidth } from '@shared/common/hooks'
import { CarrierBookingSection } from './Section'
import { CarrierBookingWarehouseInfo } from './WarehouseInfo'

const CLASS_NAME = 'carrier-booking';

// eslint-disable-next-line complexity
export const CarrierBooking: FC = memo(() => {
    const [state, setState] = useState<CarrierBookingState | undefined>()
    const { warehouseId, filters } = useParams<{ warehouseId?: string; filters?: string }>()

    const { data, loading } = useWarehouseForBooking(warehouseId ? Number(warehouseId) : undefined)

    useEffect(() => {
        if (!loading && data) {
            setState(CarrierBookingFormUtils.getCarrierBookingState(data, filters))
        }
    }, [loading])

    if (!data || !state || loading) return <Loader type="order-loader" />;

    return (
        <CarrierBookingForm
            state={state}
            warehouseInfo={data}
        />
    )
})

CarrierBooking.displayName = 'CarrierBooking'
