import React from 'react'
import './CarrierStatusInfo.scss'
import { Info } from '@/components/Info'
import { banned, filled, moderated, licenseValid } from './helper'
import { Carrier } from '@shared/types'
import { useUserState } from '@/store/user'
import { resend } from '@/utils/manager'

interface CarrierStatusInfoProps {
    carrier: Carrier
}

// eslint-disable-next-line complexity
export const CarrierStatusInfo: React.FC<CarrierStatusInfoProps> = ({ carrier }) => {
    const { state } = useUserState()
    const emailVerified = state?.userInfo?.email?.status?.codeName === 'CONFIRMED'
    const licenseVerified = licenseValid(carrier)
    return (
        <div className="carrier-status-info">
            {banned(carrier) && (
                <Info onClose={() => {}} className="warning">
                    Your account was banned by the administration
                </Info>
            )}

            {!licenseVerified && !emailVerified && (
                <Info className="warning">
                    <b>Enter a valid USDOT/MC#</b> and <b>verify your e-mail address.</b>
                    <br />
                    Check <b>{state?.userInfo?.email?.email}</b> for your verification link. If you can&apos;t find it,
                    check your spam folder{' '}
                    {/* eslint-disable-next-line jsx-a11y/anchor-is-valid,jsx-a11y/no-static-element-interactions */}
                    <a
                        style={{ textDecoration: 'underline' }}
                        onClick={(e) => {
                            e.preventDefault()
                            resend()
                        }}
                    >
                        resend
                    </a>{' '}
                    link.
                </Info>
            )}

            {licenseVerified && !emailVerified && (
                <Info className="warning">
                    You won&apos;t be able to book a warehouse until you verify your e-mail address.
                    <br />
                    Check <b>{state?.userInfo?.email?.email}</b> for your verification link. If you can&apos;t find it,
                    check your spam folder{' '}
                    {/* eslint-disable-next-line jsx-a11y/anchor-is-valid,jsx-a11y/no-static-element-interactions */}
                    <a
                        style={{ textDecoration: 'underline' }}
                        onClick={(e) => {
                            e.preventDefault()
                            resend()
                        }}
                    >
                        resend
                    </a>{' '}
                    link.
                </Info>
            )}
            {!licenseVerified && emailVerified && <Info className="warning">Enter a valid USDOT/MC#</Info>}

            {licenseVerified && !moderated(carrier) && (
                <Info className="warning">
                    Your carrier/broker is currently being verified by OLIMP. We will contact you once your account has
                    been activated.
                </Info>
            )}
        </div>
    )
}
