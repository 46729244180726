import React, { FC, useEffect, useState } from 'react'
import './CompanyIndex.scss'
import { Helmet } from 'react-helmet'
import { useCompanyEntities, useCompanyManagers } from '@shared/api/company'
import { TableList } from '@/parts/Table/TableList'
import { CarrierCommonInfo, Modals, WarehouseCommonInfo } from '@shared/types'
import { showNewWarehouseModal } from '@/parts/Modals/NewWarehouseModal'
import Button from '@/components/Button'
import EventBus from '@shared/common/eventBus'
import { Events } from '@shared/common/events'
import { Link, useParams } from 'react-router-dom'
import { showNewCarrierModal } from '@/parts/Modals/NewCarrierModal'
import { EntityType, UserType } from '@shared/api/types'
import { useCompanyState } from '@/store/company'
import { useUserState } from '@/store/user'
import { filled } from '@/store/company/helpers'
import { confirmed } from '@/store/user/helpers'
import { Info } from '@/components/Info'
import EntityRow from './EntityRow/EntityRow'
import { Loader } from '@/components/Loader'

const PAGE_TITLE = 'Olimp - Company'
const ENTITY_TABLE_HEADERS_CARRIER = [
    { name: '№', alias: 'id' },
    { name: 'Carrier name', alias: 'entity-name' },
    { name: 'Main manager', alias: 'manager' },
    { name: 'Orders', alias: 'orders' },
]
const ENTITY_TABLE_HEADERS_WAREHOUSE = [
    { name: '№', alias: 'id' },
    { name: 'Warehouse name', alias: 'entity-name' },
    { name: 'Main manager', alias: 'manager' },
    { name: 'Orders', alias: 'orders' },
]

// eslint-disable-next-line complexity
export const CompanyIndex: FC = () => {
    const { state: userState } = useUserState()
    const { data: managersData } = useCompanyManagers(userState.userInfo?.type === UserType.admin)
    const { data, loading, revalidate } = useCompanyEntities()
    const { registerType } = useParams<{ registerType?: string }>()
    const { state: companyState } = useCompanyState()
    const [selectLoading, setSelectLoading] = useState<boolean>(false)

    const addNewWarehouse = () => {
        showNewWarehouseModal({
            props: {
                onClose: (redirect) => {
                    EventBus.bus.emit(Events.CLOSE_MODAL, Modals.NewWarehouseModal)

                    revalidate()
                },
            },
        })
    }

    const addNewCarrier = () => {
        showNewCarrierModal({
            props: {
                onClose: (redirect) => {
                    EventBus.bus.emit(Events.CLOSE_MODAL, Modals.NewCarrierModal)
                    revalidate()
                },
            },
        })
    }

    useEffect(() => {
        if (registerType === EntityType.warehouse) {
            addNewWarehouse()
        } else if (registerType === EntityType.carrier) {
            addNewCarrier()
        }
    })

    useEffect(() => {
        if (data) {
            localStorage.setItem('company_id', data?.id?.toString() ?? '')
        }
    }, [loading])

    if (!companyState.companyInfo || !userState.userInfo) {
        return <Loader />
    }
    return (
        <div className="company-index wrapper page">
            <Helmet>
                <title>{PAGE_TITLE}</title>
                <meta name="description" content={PAGE_TITLE} />
            </Helmet>
            <div>
                <div className="page-title">Entities</div>
                <div className="wrap">
                    {((companyState.companyInfo && !filled(companyState, false)) || !confirmed(userState)) && (
                        <Info onClose={() => {}} className="warning">
                            Fill in <Link to="settings">company</Link> and <Link to="user/settings">user</Link> info and
                            verify your email to activate this functionality
                        </Info>
                    )}
                    {companyState.companyInfo && filled(companyState, false) && confirmed(userState) && (
                        <>
                            <div className="entity-name">
                                <h2>Warehouses</h2>
                                {userState.userInfo?.type === UserType.admin && (
                                    <Button
                                        disabled={!!selectLoading}
                                        label="Add a warehouse"
                                        types={['inverse', 'small']}
                                        onClick={addNewWarehouse}
                                    />
                                )}
                            </div>

                            <TableList
                                pending={loading}
                                headers={ENTITY_TABLE_HEADERS_WAREHOUSE}
                                data={data?.warehouses || []}
                                pageCount={1}
                            >
                                {(data?.warehouses || []).map((item: WarehouseCommonInfo) => (
                                    <EntityRow
                                        disabled={selectLoading}
                                        setLoading={setSelectLoading}
                                        type={EntityType.warehouse}
                                        item={item}
                                        key={item.id}
                                        callback={revalidate}
                                        managers={managersData!}
                                    />
                                ))}
                            </TableList>

                            <div className="entity-name">
                                <h2>Carriers</h2>
                                {userState.userInfo?.type === UserType.admin && (
                                    <Button
                                        disabled={selectLoading}
                                        label="Add a carrier"
                                        types={['inverse', 'small']}
                                        onClick={addNewCarrier}
                                    />
                                )}
                            </div>

                            <TableList
                                pending={loading}
                                headers={ENTITY_TABLE_HEADERS_CARRIER}
                                data={data?.carriers || []}
                                pageCount={1}
                            >
                                {(data?.carriers || []).map((item: CarrierCommonInfo) => (
                                    <EntityRow
                                        disabled={selectLoading}
                                        setLoading={setSelectLoading}
                                        type={EntityType.carrier}
                                        item={item}
                                        key={item.id}
                                        callback={revalidate}
                                        managers={managersData!}
                                    />
                                ))}
                            </TableList>
                        </>
                    )}
                </div>
            </div>
        </div>
    )
}

export default CompanyIndex
