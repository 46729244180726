import React, { FC, useEffect } from 'react'
import { matchPath, Navigate, Route, Routes, useNavigate } from 'react-router-dom'
import Page from '@/pages/Static/Page'
import { UserActionType } from '@/store/user/user'
import { userLogout, useUserState } from '@/store/user'
import { Http } from '@shared/api/Http'
import { UserRoutes } from '@/pages/User/UserRoutes'
import { CompanyRoutes } from './pages/Company/CompanyRoutes'
import { WarehouseRoutes } from './pages/Warehouse/WarehouseRoutes'
import { PublicRoutes } from './pages/PublicPages/PublicRoutes'
import { PublicBooking } from './pages/PublicPages/PublicBooking/PublicBooking'
import { ServiceRoutes } from './pages/Service/ServiceRoutes'
import { NotFound } from './pages/NotFound/NotFound'
import Landing from './pages/Landing/Landing'
import { CarrierRoutes } from './pages/Carrier/CarrierRoutes'
import SignUp from './pages/SignUp'
import ROUTES, { GUEST_ROUTES } from '@/router'
import { useLocation } from 'react-router'
import { LoginPage } from '@/pages/Login/LoginPage'

const Logout: FC = () => {
    const navigate = useNavigate()
    const { dispatch } = useUserState()
    useEffect(() => {
        const logout = async () => {
            await userLogout(dispatch!)
            localStorage.clear()
            delete Http.defaults.headers.common['Auth-Token']
            navigate(ROUTES.index)
        }
        logout()
    }, [])
    return <></>
}

const AppRoutes: FC = () => {
    const navigate = useNavigate()
    const location = useLocation()
    const { state, dispatch } = useUserState()
    useEffect(() => {
        dispatch?.({ type: UserActionType.UPDATE_LAST_PATH, pathname: location.pathname })
    }, [location])

    useEffect(() => {
        if (
            state.isLoggedIn &&
            state.userInfo &&
            state?.userInfo?.registration_step !== undefined &&
            state?.userInfo?.registration_step !== null &&
            state?.userInfo?.registration_step !== 100 &&
            location.pathname.indexOf('sign-up') < 0 &&
            location.pathname.indexOf('service/email') < 0 &&
            !(location.pathname === '' || location.pathname === '/' || location.pathname.startsWith('/search'))
        ) {
            navigate(ROUTES.signUp.index)
        }
    }, [state])

    useEffect(() => {
        if (state.isLoggedIn && GUEST_ROUTES.some((route) => matchPath(route, location.pathname))) {
            navigate(ROUTES.index, { replace: true })
        }
    }, [location.pathname, navigate, state.isLoggedIn])

    return (
        <div className="content">
            <Routes>
                <Route path={`${ROUTES.signUp.index}/*`} element={<SignUp />} />
                <Route path={ROUTES.index} element={<Landing />} />
                <Route path={ROUTES.public.login} element={<LoginPage />} />
                <Route path={ROUTES.public.logout} element={<Logout />} />
                <Route
                    path={ROUTES.public.register}
                    element={<Navigate to={{ pathname: '/sign-up', search: location.search }} />}
                />
                {/*<Route path={ROUTES.test} element={<Test />} />*/}
                {/* dashboard - entry point */}
                {/*<Route path={ROUTES.dashboard} element={<Test />} />*/}

                {/*<Route path={ROUTES.registerCarrier} element={<CarrierRegister />} />*/}
                {/*<Route path={ROUTES.registerWarehouse} element={<WarehouseRegister />} />*/}
                <Route path={`${ROUTES.public.search}/*`} element={<PublicRoutes />} />
                <Route path={`${ROUTES.public.booking}`} element={<PublicBooking />} />
                <Route path={`${ROUTES.service.base}/*`} element={<ServiceRoutes />} />
                {/* <Route path={ROUTES.page.base} element={Page} /> */}
                <Route path={ROUTES.page.index} element={<Page />} />

                {/* private */}
                <Route path={`${ROUTES.user.base}/*`} element={<UserRoutes />} />
                <Route path={`${ROUTES.company.base}/*`} element={<CompanyRoutes />} />
                <Route path={`${ROUTES.carrier.base}/*`} element={<CarrierRoutes />} />
                <Route path={`${ROUTES.warehouse.base}/*`} element={<WarehouseRoutes />} />

                <Route path="/404" element={<NotFound />} />
                <Route path="*" element={<NotFound />} />
            </Routes>
        </div>
    )
}

export default AppRoutes
