import { Carrier } from '@shared/types'

export function moderated(carrier: Carrier): boolean {
    return carrier.status.codeName === 'ACTIVE'
}

export function banned(carrier: Carrier): boolean {
    return carrier.status.codeName === 'BANNED'
}

export function licenseValid(carrier: Carrier): boolean {
    return carrier.license && carrier.license?.status?.title === 'CONFIRMED'
}

export function filled(carrier: Carrier): boolean {
    return !!(carrier.entity && carrier.license && carrier.license.number)
}

export function active(carrier: Carrier): boolean {
    return !banned(carrier) && filled(carrier)
}
