import React, { FC, memo, useCallback } from 'react'
import * as Yup from 'yup'
import { ModalRegistry } from '@shared/common/modals'
import { ModalPartialProps, Modals } from '@shared/types'
import Button from '@/components/Button'
import { InputText } from '@/components/InputText'
import { useFormik } from 'formik'
import './ManagerModal.scss'
import { apiVersion, Http } from '@shared/api/Http'
import Select from '@/components/Select'
import { UserInfo } from '@shared/api/types'
import { useManagerForm } from '@/parts/Modals/ManagerModal/useManagerForm'

interface ManagerModalProps {
    userInfo?: UserInfo
    onClose?: (redirectUrl?: string) => void
}

const listOfTypes = [
    {
        value: 'admin',
        name: 'Admin',
    },
    {
        value: 'manager',
        name: 'Manager',
    },
]

const ManagerModal: FC<ManagerModalProps> = ({ onClose, userInfo }) => {
    const formik = useManagerForm({ userInfo, onClose: () => onClose?.('/company/managers') })
    const handleTypeChange = useCallback(
        ({ value }) => {
            formik.handleChange({ target: { name: 'type', value } })
        },
        [formik.handleChange],
    )

    return (
        <div className="modal-info">
            <div className="modal-header">{userInfo ? 'Update a manager' : 'Add a manager'}</div>

            <form onSubmit={formik.handleSubmit}>
                <div className="form">
                    <div className="managers">
                        <div className="section">
                            <div className="form-wrap">
                                <div className="field">
                                    <InputText
                                        text
                                        label="First name"
                                        name="first_name"
                                        onChange={formik.handleChange}
                                        value={formik.values.first_name}
                                        error={formik.errors.first_name}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="section">
                            <div className="form-wrap">
                                <div className="field">
                                    <InputText
                                        text
                                        name="last_name"
                                        onChange={formik.handleChange}
                                        value={formik.values.last_name}
                                        error={formik.errors.last_name}
                                        label="Last name"
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="section">
                            <div className="form-wrap">
                                <div className="field">
                                    <InputText
                                        text
                                        name="email"
                                        onChange={formik.handleChange}
                                        value={formik.values.email}
                                        error={formik.errors.email}
                                        label="Email"
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="section">
                            <div className="form-wrap">
                                <div className="field">
                                    <InputText
                                        text
                                        name="phone"
                                        onChange={formik.handleChange}
                                        value={formik.values.phone}
                                        error={formik.errors.phone}
                                        label="Phone"
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="section">
                            <div className="form-wrap">
                                <div className="field">
                                    <InputText
                                        text
                                        name="position"
                                        onChange={formik.handleChange}
                                        value={formik.values.position}
                                        error={formik.errors.position}
                                        label="Position"
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="section">
                            <div className="form-wrap">
                                <div className="field">
                                    <Select
                                        name="type"
                                        label="Type"
                                        value={formik.values.type}
                                        error={formik.errors.type}
                                        items={listOfTypes}
                                        onAction={handleTypeChange}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="btns">
                    <Button
                        label={userInfo?.id ? 'Update' : 'Add'}
                        type="submit"
                        loading={formik.isSubmitting}
                        types={['blue', 'full']}
                        disabled={!formik.isValid || !formik.dirty}
                    />
                </div>
            </form>
        </div>
    )
}

ModalRegistry.get().register<ManagerModalProps>(Modals.ManagerModal, {
    id: 'ManagerModalProps',
    className: 'new-modal-manager',
    size: 'medium',
    Component: memo(ManagerModal),
})

export const showManagerModal = (props: ModalPartialProps<ManagerModalProps>): void =>
    ModalRegistry.get().show<ManagerModalProps>(Modals.ManagerModal, props)

export default memo(ManagerModal)
