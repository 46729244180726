import React, { FC, memo, useCallback, useState } from 'react'
import GoogleMapReact from 'google-map-react'
import { ModalRegistry } from '@shared/common/modals'
import { ModalPartialProps, Modals } from '@shared/types'
import { Loader } from '@/components/Loader'
import './ModalLocation.scss'

interface LocationModalProps {
    lat: number
    lng: number
    maxZoom?: number
}

const Marker: FC<{ lat: number; lng: number }> = () => (
    <div>
        <svg>
            <path
                fill="#3E3E3E"
                d="M10,0C4.5,0,0,4.8,0,10.6C0,16.1,7.6,27,10,27c1,0,3.5-2.6,5.6-5.6c2.7-3.8,4.4-7.6,4.4-10.7C20,4.8,15.5,0,10,0z M10,13.1c-1.8,0-3.2-1.4-3.2-3.2c0-1.8,1.4-3.2,3.2-3.2s3.2,1.4,3.2,3.2C13.2,11.7,11.8,13.1,10,13.1z"
            />
        </svg>
    </div>
)

const LocationModal: FC<LocationModalProps> = ({ lat, lng, maxZoom = 17 }) => {
    const [map, setMap] = useState<any>(null)

    const handleGoogleApiLoaded = useCallback(({ maps, map: m }) => {
        setMap(m)
    }, [])
    return (
        <div className="map-wrapper">
            <div className="map">
                <GoogleMapReact
                    yesIWantToUseGoogleMapApiInternals
                    onGoogleApiLoaded={handleGoogleApiLoaded}
                    bootstrapURLKeys={{ key: process.env.REACT_APP_GMAP_API_KEY! }}
                    center={{ lat, lng }}
                    options={{
                        maxZoom,
                    }}
                    defaultZoom={maxZoom <= 15 ? maxZoom : maxZoom}
                >
                    {map ? <Marker lat={lat} lng={lng} /> : <Loader />}
                </GoogleMapReact>
            </div>
        </div>
    )
}

ModalRegistry.get().register<LocationModalProps>(Modals.LocationModal, {
    id: 'LocationModal',
    className: 'modal-location',
    size: 'large',
    Component: memo(LocationModal),
})

export const showLocationModal = (props: ModalPartialProps<LocationModalProps>): void =>
    ModalRegistry.get().show<LocationModalProps>(Modals.LocationModal, props)

export default memo(LocationModal)
