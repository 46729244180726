import React, { FC, memo, useCallback, useEffect, useState } from 'react'
import { useFormik } from 'formik'
import { showInfoModal } from '@/parts/Modals/InfoModal'
import { ICarrierSettingsForm, Setting } from '@shared/types'
import { InputText, VerifStatus } from '@/components/InputText'
import Checkbox from '@/components/Checkbox'
import Button from '@/components/Button'
import {
    CarrierSettingsSchema,
    CARRIER_SETTINGS_INITIAL_VALUES,
    form,
} from '@/pages/Carrier/CarrierSettings/CarrierSettingsForm'
import InputLocation from '@/components/InputLocation'
import Cards, { useCards } from '@/parts/Cards'
import { Helmet } from 'react-helmet'
import { updateCarrier, useCarrier } from '@shared/api/carrier'
import { showFeedbackModal } from '@/parts/Modals/FeedbackModal'
import { getStatus } from '@/utils/status'
import { CarrierStatusInfo } from '@/parts/CarrierStatusInfo/CarrierStatusInfo'
import { useCompanyEntities } from '@shared/api/company'
import Select from '@/components/Select'
import { PAYMENT_STRATEGIES } from '@shared/api/order'

import './CarrierSettings.scss'

const PAGE_TITLE = 'Olimp - Carrier Settings'

export const CarrierSettings: FC = memo(() => {
    const [submitFailed, setSubmitFailed] = useState(false)
    const { data, formValues, mutate, revalidate, loading } = useCarrier()
    const { revalidate: revalidateEntities } = useCompanyEntities()
    const { handleRemoveCard, handleCardChange, handleCardAdded, cardListPending } = useCards({ mutate, data })
    const formik = useFormik<ICarrierSettingsForm>({
        initialValues: formValues || CARRIER_SETTINGS_INITIAL_VALUES,
        onSubmit: async (values, helpers) => {
            try {
                setSubmitFailed(false)
                const carrier = await updateCarrier(values)
                revalidateEntities()

                showInfoModal({
                    props: {
                        title: 'Information successfully changed',
                        message: '',
                    },
                })
                mutate(carrier, false)
            } catch (errors: any) {
                setSubmitFailed(true)
                if (errors) {
                    // eslint-disable-next-line no-restricted-syntax
                    for (const key of Object.keys(errors)) {
                        const transformedKey = key === 'address' ? 'physical_address' : key
                        formik.setFieldError(transformedKey, errors[key][0])
                    }
                }
            }
        },
        enableReinitialize: true,
        validationSchema: CarrierSettingsSchema,
    })

    const [legalSame, setLegalSame] = useState(formik.values.physical_address === formik.values.legal_address)
    const [billingSame, setBillingSame] = useState(formik.values.physical_address === formik.values.billing_address)

    useEffect(() => {
        if (formValues) {
            formik.setValues(formValues)
        }
    }, [loading])

    const reset = useCallback(async () => {
        await revalidate()
        if (formValues) {
            formik.setValues(formValues)
        }
    }, [formik, formValues, revalidate])

    const handleChangeLegalSame = useCallback(
        (e) => {
            if (e.target.value) {
                setLegalSame(true)
                formik.setFieldValue('legal_address', formik.values.physical_address)
            } else {
                setLegalSame(false)
            }
        },
        [formik],
    )

    const handleChangeBillingSame = useCallback(
        (e) => {
            if (e.target.value) {
                setBillingSame(true)
                formik.setFieldValue('billing_address', formik.values.physical_address)
            } else {
                setBillingSame(false)
            }
        },
        [formik],
    )

    const handlePhysicalAddressChange = useCallback(
        (e) => {
            formik.handleChange(e)
            if (legalSame) {
                formik.handleChange({ target: { name: 'legal_address', value: e.target.value } })
            }
            if (billingSame) {
                formik.handleChange({ target: { name: 'billing_address', value: e.target.value } })
            }
        },
        [formik, legalSame, billingSame],
    )

    const hasDirectInvoicing = data?.settings?.find((setting: Setting) => setting.value && setting.name === 'allow_di')
    const paymentOptions = [PAYMENT_STRATEGIES.card, hasDirectInvoicing && PAYMENT_STRATEGIES.directInvoicing].filter(
        (item) => !!item,
    )
    const feedback = () => {
        showFeedbackModal({
            props: {},
        })
    }

    return (
        <div className="carrier-settings wrapper page">
            <Helmet>
                <title>{PAGE_TITLE}</title>
                <meta name="description" content={PAGE_TITLE} />
            </Helmet>
            <div>
                <div className="page-title">Carrier Information</div>

                <div className="wrap">
                    <div className="form-user form">
                        {data && <CarrierStatusInfo carrier={data} />}
                        <form onSubmit={formik.handleSubmit}>
                            <div className="section">
                                {/* <user-status-info></user-status-info> */}

                                <div className="form-wrap">
                                    <div className="field">
                                        <InputText
                                            text
                                            textError
                                            errorOnFocus={submitFailed}
                                            error={formik.errors.entity}
                                            value={formik.values.entity}
                                            onChange={formik.handleChange}
                                            placeholder={form.entity.label}
                                            label={form.entity.label}
                                            name="entity"
                                        />
                                    </div>

                                    <div className="field">
                                        <InputText
                                            text
                                            value={formik.values.license}
                                            onChange={formik.handleChange}
                                            placeholder={form.license.label}
                                            status={getStatus(data?.license?.status?.codeName) as VerifStatus}
                                            label={form.license.label}
                                            name="license"
                                        />
                                    </div>

                                    <div className="field">
                                        <InputText
                                            value={formik.values.tracks_number}
                                            onChange={formik.handleChange}
                                            placeholder={form.tracks_number.label}
                                            label={form.tracks_number.label}
                                            name="tracks_number"
                                        />
                                    </div>
                                </div>

                                <div className="addresses">
                                    <div className="field">
                                        <InputLocation
                                            allowLocationSelect
                                            textError
                                            errorOnFocus={submitFailed}
                                            error={formik.errors.physical_address}
                                            value={formik.values.physical_address}
                                            onChange={handlePhysicalAddressChange}
                                            name="physical_address"
                                            label={form.physical_address.label}
                                            placeholder={form.physical_address.label}
                                        />
                                    </div>

                                    <div className="field legal-address">
                                        <InputLocation
                                            allowLocationSelect
                                            textError
                                            errorOnFocus={submitFailed}
                                            error={formik.errors.legal_address}
                                            value={formik.values.legal_address}
                                            onChange={formik.handleChange}
                                            name="legal_address"
                                            disabled={legalSame}
                                            label={form.legal_address.label}
                                            placeholder={form.legal_address.label}
                                        />

                                        <Checkbox
                                            value={legalSame}
                                            label="Same as location address"
                                            onChange={handleChangeLegalSame}
                                        />
                                    </div>

                                    <div className="field billing-address">
                                        <InputLocation
                                            allowLocationSelect
                                            textError
                                            errorOnFocus={submitFailed}
                                            error={formik.errors.billing_address}
                                            value={formik.values.billing_address}
                                            onChange={formik.handleChange}
                                            disabled={billingSame}
                                            name="billing_address"
                                            label={form.billing_address.label}
                                            placeholder={form.billing_address.label}
                                        />

                                        <Checkbox
                                            value={billingSame}
                                            label="Same as location address"
                                            onChange={handleChangeBillingSame}
                                        />
                                    </div>
                                </div>
                            </div>

                            <div className="section">
                                <div className="header">
                                    <div className="title">Payment Information</div>
                                </div>
                                {paymentOptions.length > 0 && (
                                    <Select
                                        label="Preferred payment type"
                                        items={paymentOptions}
                                        name="default_payment_strategy"
                                        className="default-payment-strategy"
                                        value={formik.values.default_payment_strategy}
                                        onAction={({ value }) => {
                                            formik.setFieldValue('default_payment_strategy', value)
                                        }}
                                    />
                                )}
                                <Cards
                                    inputLabel="Cards"
                                    loading={cardListPending}
                                    cardsList={data?.cards ?? []}
                                    onChange={handleCardChange}
                                    onDelete={handleRemoveCard}
                                    onCardAdded={handleCardAdded}
                                    value={formValues?.default_card_id}
                                />
                            </div>

                            <div className="links media-mobile">
                                {/* eslint-disable-next-line jsx-a11y/anchor-is-valid,jsx-a11y/no-static-element-interactions */}
                                <a onClick={feedback} className="secondary-link">
                                    Contact support
                                </a>
                            </div>

                            <div className="page-footer">
                                <div className="btns">
                                    <Button
                                        label="Save"
                                        types={['large', 'blue']}
                                        type="submit"
                                        disabled={!formik.isValid || !formik.dirty}
                                        loading={formik.isSubmitting}
                                    />
                                    <Button
                                        label="Reset"
                                        disabled={formik.isSubmitting}
                                        types={['large', 'plain']}
                                        onClick={reset}
                                    />
                                </div>

                                {/* eslint-disable-next-line jsx-a11y/anchor-is-valid,jsx-a11y/no-static-element-interactions */}
                                <a onClick={feedback} className="secondary-link media-desktop">
                                    Contact support
                                </a>
                            </div>
                        </form>
                    </div>
                </div>
            </div>

            {/* <modal-confirm-leave @confirm="leave"></modal-confirm-leave> */}
        </div>
    )
})

export default CarrierSettings
