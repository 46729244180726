import * as React from 'react';
import cn from 'classnames';
import './styles.scss';

const DEFAULT_MAX_NUM_LINES = 1;
const CSS_PROP_MAX_TEXT_LINES = 'WebkitLineClamp';

interface Props {
  maxNumLines?: number;
  title: string;
  className?: string;
  style?: React.CSSProperties;
}
const TextCutter: React.FC<Props> = ({
  className,
  style = {},
  children,
  title,
  maxNumLines = DEFAULT_MAX_NUM_LINES,
}) => (
    <span
      className={cn('text-cutter', className)}
      title={title}
      style={{
        [CSS_PROP_MAX_TEXT_LINES]: maxNumLines,
        ...style,
      }}
    >
      {children}
    </span>
  );

export { TextCutter, DEFAULT_MAX_NUM_LINES, CSS_PROP_MAX_TEXT_LINES };
