import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useMobileWidth } from '@shared/common/hooks'
import { utils } from '@shared/common/utils'
import Button, { IButton } from '@/components/Button'

import './styles.scss'

type Props = {
    spyElement: HTMLElement
    buttonLabel: string
    buttonProps?: Partial<IButton>
    checkCorners?: boolean
}

const CLASS_NAME = 'mobile-fixed-banner'

const MobileFixedBanner: React.FC<Props> = ({
    spyElement,
    buttonProps,
    buttonLabel,
    children,
    checkCorners = true,
}) => {
    const isMobile = useMobileWidth()
    const [isElementInViewport, setIsInViewport] = useState(false)

    const isNeedToShow = useMemo(() => {
        if (!isMobile) return false
        return !isElementInViewport
    }, [isElementInViewport, isMobile])

    const elementCoords = useMemo(() => spyElement.getBoundingClientRect(), [spyElement])

    const onBtnClick = useCallback(() => {
        window.scrollTo({
            top: elementCoords.y + window.scrollY,
            behavior: 'smooth',
        })
    }, [elementCoords])

    const checkIfInViewport = useCallback(() => {
        setIsInViewport(utils.isElementInViewport(spyElement, checkCorners))
    }, [spyElement, checkCorners])

    useEffect(() => {
        window.addEventListener('scroll', checkIfInViewport)

        return () => window.removeEventListener('scroll', checkIfInViewport)
    })

    if (!isNeedToShow) return null

    return (
        <div className={CLASS_NAME}>
            {children}
            <Button
                types={['blue', 'large']}
                label={buttonLabel}
                type="submit"
                className={`${CLASS_NAME}__submit`}
                onClick={onBtnClick}
                {...buttonProps}
            />
        </div>
    )
}

export default MobileFixedBanner
