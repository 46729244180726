import { UserState } from '@/store/user/user'

export function confirmed(state: UserState): boolean {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    return !!state.userInfo && state.userInfo.last_name && state.userInfo.first_name && state.userInfo?.phone?.number
}

export function banned(state: UserState): boolean {
    return !!state.userInfo && state.userInfo.status?.title === 'BANNED'
}

export function filled(state: UserState): boolean {
    const info = state.userInfo

    if (!info) {
        return false
    }

    return (
        !!info.first_name &&
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        !!info?.email.email &&
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        info?.status.code === 5
    )
}

export function active(state: UserState): boolean {
    return !banned(state) && confirmed(state) && filled(state)
}
