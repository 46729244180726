import React, { FC, useState, useCallback, useEffect } from 'react'
import cn from 'classnames'
import Checkbox from '@/components/Checkbox'
import { InputText, VerifStatus } from '@/components/InputText'
import InputLocation from '@/components/InputLocation'
import { useFormik } from 'formik'
import { WhService } from '@/parts/WhService'
import { updateWarehouse, useWarehouse, warehouseToForm } from '@shared/api/warehouse'
import { showInfoModal } from '@/parts/Modals/InfoModal'
import { Loader } from '@/components/Loader'
import Button from '@/components/Button'
import Textarea from '@/components/Textarea'
import SelectTime from '@/components/SelectTime'
import { IWarehouseSettingsForm, WarehouseService } from '@shared/types'
import { SETTINGS_FORM_INITIAL_VALUES, SettingsFormSchema } from './WarehouseSettingsForm'
import { WarehouseStatusInfo } from '@/parts/WarehouseStatusInfo/WarehouseStatusInfo'
import { usePagePrompt } from '@/components/PagePrompt/PagePrompt'
import { getStatus } from '@/utils/status'
import { useInfo } from '@shared/api/useDataHooks'
import { showFeedbackModal } from '@/parts/Modals/FeedbackModal'
import '../WarehouseSettings.scss'
import { useCompanyEntities } from '@shared/api/company'
import { EntityType } from '@shared/api/types'

export const DAYS: string[] = ['SUN', 'MON', 'TUE', 'WED', 'THU', 'FRI', 'SAT']

export const WarehouseSettingsWarehouse: FC = () => {
    const [submitFailed, setSubmitFailed] = useState(false)
    const { data, formValues, mutate, revalidate, loading } = useWarehouse()
    const { revalidate: revalidateEntities } = useCompanyEntities()

    const { data: infoData } = useInfo()

    const formik = useFormik<IWarehouseSettingsForm>({
        initialValues: Object.assign(SETTINGS_FORM_INITIAL_VALUES, formValues || {}),
        validationSchema: SettingsFormSchema,
        enableReinitialize: true,
        onSubmit: async (values) => {
            try {
                const updatedWarehouse = await updateWarehouse(values)
                revalidateEntities()

                formik.setSubmitting(false)
                setSubmitFailed(false)

                showInfoModal({
                    props: {
                        title: 'Information successfully changed',
                        message: '',
                    },
                })
                mutate(updatedWarehouse, false)
                formik.resetForm({ values: warehouseToForm(updatedWarehouse, updatedWarehouse.id) })
            } catch (e: any) {
                formik.setSubmitting(false)
                setSubmitFailed(true)
                formik.setErrors(e)
            }
        },
    })

    usePagePrompt({
        dirty: formik.dirty,
        entity: data
            ? {
                  id: data.id,
                  type: EntityType.warehouse,
              }
            : undefined,
    })

    const [legalSame, setLegalSame] = useState(formik.values.physical_address === formik.values.legal_address)
    const [billingSame, setBillingSame] = useState(formik.values.physical_address === formik.values.billing_address)

    const reset = useCallback(async () => {
        await revalidate()
        if (formValues) {
            formik.setValues(formValues)
        }
    }, [formik, formValues, revalidate])

    useEffect(() => {
        if (formValues) {
            formik.setValues(formValues)
        }
    }, [loading])

    const handleChangeLegalSame = useCallback(
        (e) => {
            if (e.target.value) {
                setLegalSame(true)
                formik.setFieldValue('legal_address', formik.values.physical_address)
            } else {
                setLegalSame(false)
            }
        },
        [formik],
    )

    const handleChangeBillingSame = useCallback(
        (e) => {
            if (e.target.value) {
                setBillingSame(true)
                formik.setFieldValue('billing_address', formik.values.physical_address)
            } else {
                setBillingSame(false)
            }
        },
        [formik],
    )

    const handlePhysicalAddressChange = useCallback(
        (e) => {
            formik.handleChange(e)
            if (legalSame) {
                formik.handleChange({ target: { name: 'legal_address', value: e.target.value } })
            }
            if (billingSame) {
                formik.handleChange({ target: { name: 'billing_address', value: e.target.value } })
            }
        },
        [formik, legalSame, billingSame],
    )

    const handleServiceChange = (updatedService: WarehouseService, [first, second, third]: number[]) => {
        const changedServices = { ...formik.values.services }
        changedServices[first][second][third] = updatedService
        formik.setFieldValue('services', changedServices)
    }

    const feedback = () => {
        showFeedbackModal({
            props: {},
        })
    }

    const documentLink = formik.values.services?.[0]?.[0]?.[1]?.documents?.[0]?.link

    return (
        <div className="warehouse-settings-warehouse wrap">
            <div className="form-warehouse form">
                {loading ? (
                    <Loader type="list" />
                ) : (
                    <form onSubmit={formik.handleSubmit}>
                        {data && <WarehouseStatusInfo warehouse={data} />}
                        <div className="section">
                            <div className="header">
                                <div className="title">Settings</div>
                            </div>

                            <div className="field">
                                <InputText
                                    text
                                    value={formik.values.entity}
                                    error={formik.errors.entity}
                                    textError
                                    onChange={formik.handleChange}
                                    placeholder="Warehouse name"
                                    label="Warehouse name"
                                    required
                                    name="entity"
                                />
                            </div>
                            {formik.values.services?.[0] && (
                                <div className="form-wrap fieldset">
                                    <div className="left">
                                        <div className="working-days-wrapper">
                                            <div className="label">Days of Operation</div>
                                            <div className="control working-days">
                                                {DAYS.map((day) => (
                                                    // eslint-disable-next-line jsx-a11y/no-static-element-interactions
                                                    <div
                                                        key={`day-${day}`}
                                                        onClick={() => {
                                                            const currentValue = formik.values.working_days.split(',')
                                                            let result = []
                                                            if (currentValue.includes(day)) {
                                                                result = DAYS.filter(
                                                                    (d) => currentValue.includes(d) && d !== day,
                                                                )
                                                            } else {
                                                                result = DAYS.filter(
                                                                    (d) => currentValue.includes(d) || d === day,
                                                                )
                                                            }
                                                            formik.setFieldValue('working_days', result.join(','))
                                                        }}
                                                        className={cn({
                                                            active: formik.values.working_days.includes(day),
                                                        })}
                                                    >
                                                        <span>{day}</span>
                                                    </div>
                                                ))}
                                            </div>

                                            <div className="field error-field">
                                                {formik.errors.work_time_to && (
                                                    <div className="error" style={{ whiteSpace: 'nowrap' }}>
                                                        {formik.errors.work_time_to}
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                        <div className="field work-time-from">
                                            <SelectTime
                                                value={formik.values.work_time_from}
                                                error={formik.errors.work_time_from}
                                                label="Hours of Operation  From"
                                                name="work_time_from"
                                                onAction={(time) => {
                                                    formik.setFieldValue('work_time_from', String(time.value as string))
                                                }}
                                            />
                                            <div className="dash">&mdash;</div>
                                        </div>

                                        <div className="field work-time-to">
                                            <SelectTime
                                                value={formik.values.work_time_to}
                                                error={formik.errors.work_time_to}
                                                label="To"
                                                errorHide
                                                min={formik.values.work_time_from}
                                                name="work_time_to"
                                                onAction={(time) => formik.setFieldValue('work_time_to', time.value)}
                                            />
                                        </div>
                                        <div className="field auto-booking">
                                            <Checkbox
                                                value={formik.values.auto_booking}
                                                error={formik.errors.auto_booking}
                                                onChange={formik.handleChange}
                                                label="Automatic booking confirmation"
                                                name="auto_booking"
                                            />
                                        </div>
                                        <div className="field liability-insurance">
                                            <WhService
                                                warehouseId={formik.values.warehouseId}
                                                service={formik.values.services[0][0][0]}
                                                documentType
                                                forcedActivated
                                                onChange={(srv) => handleServiceChange(srv, [0, 0, 0])}
                                                label="Please upload as a PDF document."
                                            />
                                        </div>
                                    </div>

                                    <div className="field wh-photo">
                                        <WhService
                                            warehouseId={formik.values.warehouseId}
                                            service={formik.values.services[0][0][1]}
                                            documentType
                                            forcedActivated
                                            onChange={(srv) => handleServiceChange(srv, [0, 0, 1])}
                                            label="Please upload as a PNG file."
                                            maxDocumentsCount={infoData?.max_warehouse_photos ?? 3}
                                        />
                                    </div>
                                </div>
                            )}

                            <div className="fieldset">
                                <div className="form-wrap row">
                                    <div className="field area">
                                        <InputText
                                            isNumericString
                                            value={formik.values.gates_count}
                                            error={formik.errors.gates_count}
                                            onChange={formik.handleChange}
                                            label="Unloading docks"
                                            name="gates_count"
                                            textError={false}
                                        />
                                    </div>
                                    <div className="field license">
                                        <InputText
                                            isNumericString
                                            value={formik.values.license}
                                            error={formik.errors.license}
                                            onChange={formik.handleChange}
                                            label="USDOT or MC/MX#"
                                            status={getStatus(data?.license?.status?.codeName) as VerifStatus}
                                            // mask={form.license.mask}
                                            // disabled="form.license.disabled"
                                            name="license"
                                            textError={false}
                                        />
                                    </div>
                                </div>
                                <div className="form-wrap row">
                                    <div className="field area">
                                        <InputText
                                            isNumericString
                                            value={formik.values.area}
                                            error={formik.errors.area}
                                            onChange={formik.handleChange}
                                            label="Capacity (Plt)"
                                            name="area"
                                            textError={false}
                                        />
                                    </div>
                                    <div className="field area">
                                        <InputText
                                            isNumericString
                                            value={formik.values.refrigerator_area}
                                            error={formik.errors.refrigerator_area}
                                            onChange={formik.handleChange}
                                            placeholder="Capacity"
                                            label="Fridge capacity (Plt)"
                                            name="refrigerator_area"
                                            textError={false}
                                        />
                                    </div>
                                    <div className="field area">
                                        <InputText
                                            isNumericString
                                            value={formik.values.area_sq_feet}
                                            onChange={formik.handleChange}
                                            label="Capacity (Sq.feet)"
                                            placeholder="Capacity (Sq.feet)"
                                            name="area_sq_feet"
                                            error={formik.errors.area_sq_feet}
                                            textError={false}
                                        />
                                    </div>
                                    <div className="field area">
                                        <InputText
                                            isNumericString
                                            value={formik.values.refrigerator_area_sq_feet}
                                            onChange={formik.handleChange}
                                            placeholder="Fridge capacity (Sq.feet)"
                                            label="Fridge capacity (Sq.feet)"
                                            name="refrigerator_area_sq_feet"
                                            error={formik.errors.refrigerator_area_sq_feet}
                                            textError={false}
                                        />
                                    </div>
                                </div>
                                <div className="form-wrap">
                                    <div className="field area">
                                        <InputText
                                            isNumericString
                                            value={formik.values.local_delivery_distance}
                                            error={formik.errors.local_delivery_distance}
                                            onChange={formik.handleChange}
                                            label="Max. Local Delivery distance (mls)"
                                            name="local_delivery_distance"
                                            textError={false}
                                        />
                                    </div>
                                </div>
                                <div className="form-wrap">
                                    <div className="left">
                                        <div className="wh-service-comment">
                                            <Textarea
                                                value={formik.values.comment}
                                                onChange={formik.handleChange}
                                                error={formik.errors.comment}
                                                placeholder="Comment"
                                                label="Leave comment"
                                                name="comment"
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="addresses fieldset">
                                <div className="field physical-address">
                                    <InputLocation
                                        textError
                                        allowLocationSelect
                                        errorOnFocus={submitFailed}
                                        error={formik.errors.physical_address}
                                        value={formik.values.physical_address}
                                        onChange={handlePhysicalAddressChange}
                                        name="physical_address"
                                        label="Location address"
                                        required
                                    />
                                </div>

                                <div className="field legal-address">
                                    <InputLocation
                                        textError
                                        allowLocationSelect
                                        errorOnFocus={submitFailed}
                                        error={formik.errors.legal_address}
                                        value={formik.values.legal_address}
                                        onChange={formik.handleChange}
                                        name="legal_address"
                                        disabled={legalSame}
                                        label="Mailing address"
                                    />
                                    <Checkbox
                                        value={legalSame}
                                        label="Same as location address"
                                        onChange={handleChangeLegalSame}
                                    />
                                </div>

                                <div className="field billing-address">
                                    <InputLocation
                                        textError
                                        allowLocationSelect
                                        errorOnFocus={submitFailed}
                                        error={formik.errors.billing_address}
                                        value={formik.values.billing_address}
                                        onChange={formik.handleChange}
                                        disabled={billingSame}
                                        name="billing_address"
                                        label="Billing address"
                                    />

                                    <Checkbox
                                        value={billingSame}
                                        label="Same as location address"
                                        onChange={handleChangeBillingSame}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="page-footer">
                            <div className="btns">
                                <Button
                                    label="Save"
                                    types={['large', 'blue']}
                                    type="submit"
                                    disabled={!formik.isValid || !formik.dirty}
                                    loading={formik.isSubmitting}
                                />

                                <Button label="Cancel" types={['large', 'plain']} onClick={reset} />
                            </div>
                            {/* eslint-disable-next-line jsx-a11y/anchor-is-valid,jsx-a11y/no-static-element-interactions */}
                            <a onClick={feedback} className="secondary-link media-desktop">
                                Contact support
                            </a>
                        </div>
                    </form>
                )}
            </div>
        </div>
    )
}
