import React, { FC, memo, useMemo } from 'react'
import Icon from '@/components/Icon/Icon'
import LocationLink from '@/components/LocationLink'
import dayjs from 'dayjs'
import { CarrierOrderItem } from '@shared/types/response'
import { mapService } from '@shared/common/order'
import { utils } from '@OlimpDev/lib/dist/common/utils'
import './CarrierOrderServices.scss'
import { DistanceInfo } from '@/parts/DistanceInfo/DistanceInfo'

type OrderServicesProps = {
    item: CarrierOrderItem
}

const CarrierOrderServices: FC<OrderServicesProps> = ({ item }) => {
    const orderService = item.services && item.services.length ? item.services[0] : null

    const rangeDays = useMemo(
        () => dayjs(orderService?.period_end).diff(dayjs(orderService?.period_start), 'days'),
        [orderService?.period_start, orderService?.period_end],
    )

    if (!item || (item.services?.length ?? 0) === 0) {
        return null
    }

    return (
        <div className="carrier-order-services">
            <div className="service-list">
                {item.services
                    .map((i: any) => mapService(i))
                    .map((service) => (
                        <div className="item" key={service.code}>
                            {service.showIcon && (
                                <div className="info-wrap">
                                    <div className="info">
                                        {service.icon && <Icon code={service.icon} type="services" />}
                                        <span className="title">{service.title}</span>
                                        {service.pickup && (
                                            <span className="pickup-date">
                                                <template>{service.periodStart}</template>
                                            </span>
                                        )}
                                        {item.subOrders.length > 0 && !item.parent_id && service.hasAmount && (
                                            <span className="amount">{service.amount} time(s)</span>
                                        )}
                                        {item.parent_id && <span className="amount">{service.amount} pallet(s)</span>}
                                    </div>
                                    {/* <div className="value">{ service.total}</div> */}
                                </div>
                            )}
                            {service.address && (
                                <>
                                    <div className="location">
                                        <LocationLink
                                            id={service.code}
                                            lat={service.address.lat}
                                            lng={service.address.long}
                                            address={service.address.address!}
                                        />
                                    </div>

                                    <DistanceInfo
                                        direct={service.address.distance}
                                        driving={service.address.route_distance}
                                    />
                                </>
                            )}
                        </div>
                    ))}
            </div>
            <div className="service-details">
                <div className="service-params">
                    <div className="item">
                        <div className="label">Units</div>
                        <div className="value">
                            {orderService?.amount} {item.unit.title}
                        </div>
                    </div>

                    {orderService?.period_start && (
                        <div className="item">
                            <div className="label">Date</div>
                            <div className="value">
                                {dayjs(orderService?.period_start).format('MM-DD-YYYY')}
                                {orderService.terms_related && (
                                    <> - {dayjs(orderService?.period_end).format('MM-DD-YYYY')}</>
                                )}
                            </div>

                            {orderService.terms_related && (
                                <div className="days-count">{utils.pluralize(rangeDays, 'day')}</div>
                            )}
                        </div>
                    )}
                </div>
            </div>
        </div>
    )
}

export default memo(CarrierOrderServices)
