import React, { ChangeEvent, FC, memo, useCallback, useEffect, useMemo } from 'react'
import { WarehouseService } from '@/pages/Carrier/CarrierBooking/WarehouseService/WarehouseService'
import { CarrierBookingFormUtils } from '@/pages/Carrier/CarrierBooking/Form/utils'
import { consts } from '@shared/common/consts'
import { WarehouseServiceMapped } from '@shared/types'

import './WarehouseServiceGroup.scss'

interface IWarehouseServiceGroupProps {
    group: number
    delivery?: number
    subService?: number
    subServicesOptional: number[]
    onChange: (e: ChangeEvent) => void
    services: WarehouseServiceMapped[]
}

export const WarehouseServiceGroup: FC<IWarehouseServiceGroupProps> = memo(
    // eslint-disable-next-line complexity
    ({ group, delivery, subService, subServicesOptional = [], services, onChange }) => {
        const values = useMemo(() => {
            const groups = CarrierBookingFormUtils.groupServices(services, 'type')
            const deliveries = groups[consts.warehouse.serviceTypes.delivery]
                ?.filter((s) => s.rates.some((rate) => rate.is_active))
                ?.sort((a, b) => (a.title > b.title ? 1 : -1))
            const priceModificators = CarrierBookingFormUtils.groupServices(
                groups[consts.warehouse.serviceTypes.priceModificator] ?? [],
                'group',
            )
            const subservicesRequired = groups[consts.warehouse.serviceTypes.subServiceRequired]
            const subservicesOptional = groups[consts.warehouse.serviceTypes.subServiceOptional]
            const mainService = groups[consts.warehouse.serviceTypes.main]?.[0]
            return { groups, deliveries, priceModificators, subservicesRequired, subservicesOptional, mainService }
        }, [services])

        const handleSelect = useCallback(
            (g: number | null) => {
                onChange({ target: { name: 'group', value: { group: g, mods: values.priceModificators[g!] } } } as any)
            },
            [onChange, values.priceModificators],
        )

        const handleDeliverySelect = useCallback(
            (d: number | null) => {
                onChange({ target: { name: 'delivery', value: d } } as any)
            },
            [onChange],
        )

        const handleSubServiceSelect = useCallback(
            (s: number | null) => {
                onChange({ target: { name: 'subService', value: s } } as any)
            },
            [onChange],
        )

        const handleSubServiceOptionalSelect = useCallback(
            (s: number | null, checked?: boolean) => {
                onChange({ target: { name: `subService_${s}`, value: Boolean(checked) } } as any)
            },
            [onChange],
        )

        useEffect(() => {
            if (group === values.mainService.group) {
                handleSelect(group)
            }
        }, [])

        const currentGroupActive = group === values.mainService.code
        return (
            <div className="service-group">
                {values.mainService && (
                    <WarehouseService
                        className="main"
                        active={currentGroupActive}
                        type={null}
                        disabled={false}
                        hint={values.mainService.hint}
                        title={values.mainService.title}
                        onSelect={handleSelect}
                        service={values.mainService.code}
                        priceType={values.mainService.price_type}
                    />
                )}
                {currentGroupActive &&
                    values.mainService?.group &&
                    (values.priceModificators[values.mainService.group] ?? []).map((priceMod) => (
                        <WarehouseService
                            key={priceMod.code}
                            className="main"
                            hint={priceMod.hint}
                            active
                            type={null}
                            title={priceMod.title}
                            service={priceMod.code}
                            priceType={priceMod.price_type}
                        />
                    ))}
                {currentGroupActive && values.deliveries?.length > 0 && (
                    <div className="sub deliveries">
                        <div className="title">
                            Delivery options{' '}
                            {/* {delivery && <span className="icon-close" role="button" tabIndex={0} onClick={() => {}} />} */}
                        </div>

                        {values.deliveries.map((s) => (
                            <WarehouseService
                                key={s.code}
                                className="main"
                                hint={s.hint}
                                active={delivery === s.code}
                                type="radio-check"
                                title={s.title}
                                service={s.code}
                                priceType={s.price_type}
                                onSelect={handleDeliverySelect}
                            />
                        ))}
                    </div>
                )}

                {currentGroupActive && values.subservicesRequired?.length > 0 && (
                    <div className="sub subservices">
                        <div className="title">
                            Subservices{' '}
                            {subService && (
                                <span className="icon-close" role="button" tabIndex={0} onClick={() => {}} />
                            )}
                        </div>

                        {values.subservicesRequired.map((s) => (
                            <WarehouseService
                                key={s.code}
                                active={subService === s.code}
                                type="radio"
                                hint={s.hint}
                                title={s.title}
                                service={s.code}
                                priceType={s.price_type}
                                onSelect={handleSubServiceSelect}
                            />
                        ))}
                    </div>
                )}

                {currentGroupActive && values.subservicesOptional?.length > 0 && (
                    <div className="sub subservices-optional">
                        <div className="title">Subservices (optional)</div>

                        {values.subservicesOptional.map((s) => (
                            <WarehouseService
                                key={s.code}
                                active={subServicesOptional.includes(s.code)}
                                type="checkbox"
                                hint={s.hint}
                                title={s.title}
                                service={s.code}
                                priceType={s.price_type}
                                onSelect={handleSubServiceOptionalSelect}
                            />
                        ))}
                    </div>
                )}
            </div>
        )
    },
)

WarehouseServiceGroup.displayName = 'WarehouseServiceGroup'
