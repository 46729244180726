import React, { FC, memo, useCallback } from 'react'
import dayjs from 'dayjs'
import Icon from '@/components/Icon/Icon'
import { Status } from '@/components/Status'
import { Order } from '@shared/types'
import Button from '@/components/Button'
import { useNavigate } from 'react-router-dom'
import LocationLink from '@/components/LocationLink'
import { utils } from '@shared/common/utils'
import { SERVICE_ICON_MAP } from '@shared/common/consts'
import Currency from '@/components/Currency'
import { getAddressByStatus, getMapZoom } from '@shared/common/order-status'

export interface ICarrierOrderListItemProps extends Partial<Order> {
    id: number
    period_start: string
    total: number
}

const CarrierOrderListItem: FC<ICarrierOrderListItemProps> = ({
    id,
    period_start,
    prev_status,
    status,
    payment_strategy,
    services,
    total,
    warehouse,
}) => {
    const navigate = useNavigate()
    const handleOpenOrderDetails = useCallback(() => {
        navigate(`/carrier/order/${id}`)
    }, [navigate, id])
    return (
        <div className="row table-line-carrier-order">
            <div className="cell cell-id">
                <div className="label">PO №</div>
                <span>{id}</span>
            </div>
            <div className="cell cell-address">
                {warehouse?.physicalAddress && (
                    <LocationLink
                        id={id}
                        lat={warehouse.physicalAddress.lat}
                        lng={warehouse.physicalAddress.long}
                        address={getAddressByStatus(warehouse.physicalAddress.address ?? '', status?.code ?? -1)}
                        zip={warehouse.zip_code ?? ''}
                        maxZoom={getMapZoom(status?.code ?? -1)}
                    />
                )}
            </div>
            <div className="cell cell-date">
                <div className="label">Date</div>
                <span>{dayjs(period_start).format('MM-DD-YYYY')}</span>
            </div>
            <div className="cell cell-status">
                <div className="label">Status</div>

                {prev_status && (
                    <div className="prev-status">
                        <span className="icon icon-arrow-round" />
                        <span>{prev_status}</span>
                    </div>
                )}

                {status && <Status data={status} />}
            </div>
            <div className="cell cell-services">
                <div className="label">Services</div>

                <div className="icons">
                    {services &&
                        services.map((service, index) => (
                            <div className="icon-service" key={service.code}>
                                <Icon code={SERVICE_ICON_MAP[String(service.code)]} type="services" />

                                <span className="tooltip">
                                    <span className="arrow" />
                                    <span>{service.title}, </span>
                                    <span className="details">
                                        {utils.pluralize(service.amount, 'pallet')}
                                        {', '}
                                        {utils.pluralize(service.number_of_days, 'day')}
                                    </span>
                                </span>
                            </div>
                        ))}
                </div>
            </div>
            <div className="cell cell-total">
                <div className="label">Total Cost</div>
                <span>
                    <div className="type">{payment_strategy === 2 ? 'direct invoicing' : 'card payment'}</div>
                    <Currency value={total} />
                </span>
            </div>
            <div className="cell cell-button">
                <Button
                    types={[status?.code === 4 ? 'blue' : 'inverse', 'small']}
                    label="Details"
                    onClick={handleOpenOrderDetails}
                />
            </div>
        </div>
    )
}

export default memo(CarrierOrderListItem)
