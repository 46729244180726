import dayjs from 'dayjs'

interface IComputeMonthWeeksParams {
    lang: 'en' | 'ru'
    past?: boolean
    max?: string
    min?: string
    today: dayjs.Dayjs
    selectedDateEnd?: dayjs.Dayjs
    selectedDateStart?: dayjs.Dayjs
}

export interface IDayObject {
    date: string
    num: string
    disabled: boolean
    otherMonth: boolean
    today: boolean
    limitDate: boolean
}

const format = 'YYYY-MM-DD'

// eslint-disable-next-line complexity
export const computeMonthWeeks = (m: dayjs.Dayjs, params: IComputeMonthWeeksParams): IDayObject[][] => {
    const { lang, past, max, min, today, selectedDateEnd, selectedDateStart } = params
    const month = []
    let week = []
    let date = m.clone()
    const daysInMonth = date.daysInMonth()
    const dayIndex = date.day()
    let count = daysInMonth + dayIndex
    dayjs.locale(lang)
    if (count % 7 > 0) {
        count += 7 - (count % 7)
    }

    date = date.subtract(dayIndex, 'day')

    for (let i = 1; i <= count; i += 1) {
        const otherMonth = i < dayIndex + 1 || i >= daysInMonth + dayIndex + 1

        week.push({
            date: date.format(),
            num: date.format('D'),
            disabled:
                otherMonth ||
                (!past && date < today) ||
                (max && dayjs(max, format) < date) ||
                (min && dayjs(min, format) > date),
            otherMonth,
            today: today.isSame(date),
            limitDate:
                (selectedDateStart && date.isSame(selectedDateStart)) ||
                (selectedDateEnd && date.isSame(selectedDateEnd.startOf('day'))),
        } as IDayObject)

        if (i % 7 === 0) {
            month.push(week)
            week = []
        }

        date = date.add(1, 'day')
    }
    return month
}
