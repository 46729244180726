import React, { CSSProperties, FC, useEffect, useMemo, useState } from 'react'

import { Carousel, CarouselSettings } from '@/components/Carousel'
import { getPhotoUrl, IPhotoLike, isImage } from './helper'
import { getDocumentUrl } from '@/utils/documents'
import { useWindowSize } from 'react-use'

import './PhotosView.scss'
import { useSecuredImageUrl } from '@shared/hooks/useSecuredImageUrl'
import { Loader } from '@/components/Loader'
import DocIcon from '@/assets/images/doc.svg'
import classNames from 'classnames'

type SlideProps = {
    style: CSSProperties
}

interface IPhotosView extends CarouselSettings {
    photos: IPhotoLike[]
    slideProps?: SlideProps
    initPhotoIdx?: number
    carouselOnHover?: boolean
    onChange?(current: number): void
    isSecured?: boolean
}

const CLASS_NAME = 'photos-view'

export const PhotosView: FC<IPhotosView> = ({
    photos: rawPhotos,
    initPhotoIdx,
    carouselOnHover,
    isSecured,
    ...props
}) => {
    const [isHovered, setHover] = useState<boolean>(false)
    const { width } = useWindowSize()

    const photos = useMemo(() => rawPhotos.filter((el) => isImage(el)), [rawPhotos])

    const shouldShowTooltip = useMemo(
        () => photos.length >= 1 && carouselOnHover && width > 1023,
        [photos, carouselOnHover, width],
    )
    const defaultPhoto = photos[0]

    return (
        <div className={CLASS_NAME}>
            {shouldShowTooltip && (
                <div
                    className={`${CLASS_NAME}__preview`}
                    onMouseEnter={() => setHover(true)}
                    onMouseLeave={() => setHover(false)}
                >
                    <Photo photo={defaultPhoto} isThumb isSecured={isSecured} clickable={false} />
                    {isHovered && (
                        <div className={`${CLASS_NAME}__tooltip`}>
                            <PhotosCarousel photos={photos} {...props} />
                        </div>
                    )}
                </div>
            )}
            {!shouldShowTooltip &&
                (photos.length <= 1 ? (
                    <div className={`${CLASS_NAME}__preview`}>
                        {defaultPhoto ? (
                            <Photo photo={defaultPhoto} isThumb isSecured={isSecured} />
                        ) : (
                            <div className={`${CLASS_NAME}__img-wrapper`}>
                                <div className={`${CLASS_NAME}__bg ${CLASS_NAME}__bg--empty`} />
                            </div>
                        )}
                    </div>
                ) : (
                    <PhotosCarousel photos={photos} isSecured={isSecured} {...props} />
                ))}
        </div>
    )
}

type PhotosCarouselProps = Omit<IPhotosView, 'carouselOnHover'>

export const PhotosCarousel: React.FC<PhotosCarouselProps> = ({
    photos,
    onChange,
    isSecured,
    slideProps,
    ...props
}) => {
    return (
        <Carousel arrows={false} afterChange={onChange} {...props}>
            {photos.map((el) => {
                const photoUrl = getPhotoUrl(el)
                const isImg = isImage(el)

                return (
                    <div key={photoUrl} className={`${CLASS_NAME}__slide`}>
                        <div title={el.title} className={`${CLASS_NAME}__slide-inner`} {...(slideProps || {})}>
                            <Photo photo={el} isSecured={isSecured} />
                            {!isImg && el.title && <span className={`${CLASS_NAME}__img-title`}>{el.title}</span>}
                        </div>
                    </div>
                )
            })}
        </Carousel>
    )
}

export const Photo: React.FC<{
    photo: IPhotoLike
    isThumb?: boolean
    isSecured?: boolean
    isLoading?: boolean
    clickable?: boolean
    className?: string
}> = ({ photo, isThumb, isSecured, clickable = true, className, isLoading }) => {
    const { imageUrl, getImageUrl } = useSecuredImageUrl(getPhotoUrl(photo), false)
    const isImg = isImage(photo)

    useEffect(() => {
        if (isSecured) getImageUrl()
    }, [isSecured, getImageUrl])

    if (isLoading || (isSecured && !imageUrl)) return <Loader type="order-loader" />

    const url = isSecured ? (imageUrl as string) : getDocumentUrl(getPhotoUrl(photo))

    const photoEl = (
        <div
            className={`${CLASS_NAME}__bg ${isThumb ? `${CLASS_NAME}__bg--thumb` : ''}`}
            style={{
                backgroundImage: `url(${isImg ? url : DocIcon})`,
                backgroundSize: !isImg ? '50% 50%' : 'contain',
                backgroundPosition: !isImg ? 'center 50%' : 'center center',
            }}
        />
    )

    if (isImg && !clickable) {
        return <div className={`${CLASS_NAME}__img-wrapper`}>{photoEl}</div>
    }

    return (
        <a className={classNames(`${CLASS_NAME}__img-wrapper`, className)} href={url} target="_blank" rel="noreferrer">
            {photoEl}
        </a>
    )
}
