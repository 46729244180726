import React, { FC, memo, useState } from 'react'
import * as Yup from 'yup'
import { ModalRegistry } from '@shared/common/modals'
import { ModalPartialProps, Modals } from '@shared/types'
import Button from '@/components/Button'
import { InputText } from '@/components/InputText'
import { useFormik } from 'formik'
import { useUserState } from '@/store/user'
import { changePassword, ChangePasswordPayload } from '@shared/api/user'
import { Http } from '@shared/api/Http'
import { showInfoModal } from '@/parts/Modals/InfoModal'

interface ChangePasswordForm {
    password: string | undefined
    password_repeat: string | undefined
    old_password: string | undefined
}

const ChangePasswordFormSchema = Yup.object().shape({
    password: Yup.string()
        .min(6)
        .notOneOf([Yup.ref('old_password'), undefined], 'New password must be different from old')
        .required(),
    password_repeat: Yup.string().oneOf([Yup.ref('password'), undefined], 'Passwords must match'),
    old_password: Yup.string().label('old password').min(6).required(),
})

interface ChangePasswordModalProps {}

const ChangePasswordModal: FC<ChangePasswordModalProps> = () => {
    const {
        state: { userType },
    } = useUserState()
    const [errorMessage, setErrorMessage] = useState<string | null>(null)
    const formik = useFormik<ChangePasswordForm>({
        initialValues: { password: undefined, password_repeat: undefined, old_password: undefined },
        validationSchema: ChangePasswordFormSchema,
        enableReinitialize: true,
        onSubmit: async (values) => {
            try {
                formik.setSubmitting(true)
                const payload = { password: values.password, old_password: values.old_password }
                const { token, type } = await changePassword(payload as ChangePasswordPayload)

                formik.setSubmitting(false)
                Http.defaults.headers.common['Auth-Token'] = token
                localStorage.setItem('token', token)

                ModalRegistry.get().close(Modals.ChangePasswordModal)
                showInfoModal({
                    props: {
                        title: 'Password successfully changed',
                        message: '',
                    },
                })
            } catch (errors: any) {
                formik.setSubmitting(false)
                formik.setErrors(errors)
                if (Array.isArray(errors)) {
                    setErrorMessage(JSON.stringify(errors))
                }
            }
        },
    })

    return (
        <div className="modal-change-password">
            {errorMessage && <p>{errorMessage}</p>}

            <form onSubmit={formik.handleSubmit}>
                <InputText
                    text
                    label="Old password"
                    placeholder="Old password"
                    name="old_password"
                    type="password"
                    onChange={formik.handleChange}
                    value={formik.values.old_password}
                    error={formik.errors.old_password}
                    textError
                    errorOnFocus
                />

                <InputText
                    text
                    label="New password"
                    placeholder="New password"
                    name="password"
                    type="password"
                    onChange={formik.handleChange}
                    value={formik.values.password}
                    error={formik.errors.password}
                    textError
                    errorOnFocus
                />

                <InputText
                    text
                    label="Confirm New password"
                    placeholder="Confirm New password"
                    name="password_repeat"
                    type="password"
                    onChange={formik.handleChange}
                    value={formik.values.password_repeat}
                    error={formik.errors.password_repeat}
                    textError
                    errorOnFocus
                />

                <div className="btns">
                    <Button
                        label="Change"
                        disabled={!formik.isValid}
                        loading={formik.isSubmitting}
                        types={['blue', 'large']}
                        type="submit"
                    />
                </div>
            </form>
        </div>
    )
}

ModalRegistry.get().register<ChangePasswordModalProps>(Modals.ChangePasswordModal, {
    id: 'ChangePasswordModalProps',
    className: 'modal-change-password',
    size: 'medium',
    Component: memo(ChangePasswordModal),
    title: 'Change Password',
})

export const showChangePasswordModal = (props: ModalPartialProps<ChangePasswordModalProps>): void =>
    ModalRegistry.get().show<ChangePasswordModalProps>(Modals.ChangePasswordModal, props)

export default memo(ChangePasswordModal)
