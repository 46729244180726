import React, { FC, useEffect } from 'react'
import { NavLink as Link, Navigate, Routes, Route } from 'react-router-dom'
import './WarehouseSettings.scss'
import { PrivateRoute } from '@/components/PrivateRoute/PrivateRoute'
import { useUserState } from '@/store/user'
import { active, filled } from '@/store/company/helpers'
import { Helmet } from 'react-helmet'
import { useCompany } from '@shared/api/company'
import { updateCompanyState, useCompanyState } from '@/store/company'
import { WarehouseSettingsWarehouse } from './WarehouseSettingsWarehouse/WarehouseSettingsWarehouse'
import { WarehouseSettingsPayment } from './WarehouseSettingsPayment/WarehouseSettingsPayment'
import { WarehouseSettingsRating } from './WarehouseSettingsRating/WarehouseSettingsRating'
import { WarehouseSettingsPrices } from './WarehouseSettingsPrices/WarehouseSettingsPrices'
import ROUTES from '@/router'
import { useWarehouse } from '@shared/api/warehouse'
import { Loader } from '@/components/Loader'

const PAGE_TITLE = 'Olimp - Settings'

export const WarehouseSettings: FC = () => {
    const { state } = useUserState()
    const warehouseData = useWarehouse()
    const { state: companyState, dispatch } = useCompanyState()
    const { data, loading } = useCompany()

    useEffect(() => {
        if (data) {
            updateCompanyState(data, dispatch)
        }
    }, [loading])

    if (warehouseData.loading || loading) {
        return <Loader />
    }
    return (
        <div className="warehouse-settings-warehouse wrapper page">
            <Helmet>
                <title>{PAGE_TITLE}</title>
                <meta name="description" content={PAGE_TITLE} />
            </Helmet>
            <div>
                <div className="page-title">Warehouse Information</div>
                <div className="tabs">
                    <Link to="warehouse" className="tab">
                        Warehouse
                    </Link>
                    <Link to="prices" className="tab">
                        Services & Prices
                    </Link>
                    <Link
                        to="payment"
                        className="tab"
                        title={
                            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                            // @ts-ignore
                            companyState.companyInfo?.tax_id && filled(companyState)
                                ? ''
                                : 'Payment info will be available only after filling company info'
                        }
                    >
                        Payment Info
                    </Link>
                    {active(companyState) && (
                        <Link to="rating" className="tab">
                            Rating
                        </Link>
                    )}
                </div>

                <Routes>
                    <Route path="warehouse" element={<WarehouseSettingsWarehouse />} />
                    <Route path="prices" element={<WarehouseSettingsPrices />} />
                    <Route path="payment" element={<WarehouseSettingsPayment />} />
                    <Route path="rating" element={<WarehouseSettingsRating />} />
                    <Route
                        path="/"
                        element={<Navigate to={ROUTES.warehouse.base + ROUTES.warehouse.settings.warehouse} />}
                    />
                </Routes>
            </div>
        </div>
    )
}
