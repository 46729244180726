import React from 'react'
import './CompanyStatusInfo.scss'
import { Info } from '@/components/Info'
import { banned, filled } from '@/store/company/helpers'
import { confirmed } from '@/store/user/helpers'
import { useCompanyState } from '@/store/company'
import { useUserState } from '@/store/user'

// eslint-disable-next-line complexity
export const CompanyStatusInfo: React.FC = () => {
    const { state } = useCompanyState()
    const { state: userState } = useUserState()
    const { companyInfo } = state
    if (!companyInfo) {
        return null
    }

    return (
        <div className="company-status-info">
            {banned(state) && (
                <Info onClose={() => {}} className="warning">
                    Your company was banned by the administration
                </Info>
            )}
        </div>
    )
}
