import React, { FC, useCallback, useRef, useState } from 'react'
import cn from 'classnames'
import './WhService.scss'
import Checkbox from '@/components/Checkbox'
import { WhServiceConstants } from '@shared/common/consts'
import { InputText } from '@/components/InputText'
import Button from '@/components/Button'
import Datepicker from '@/components/Datepicker'
import { ServiceDocument, WarehouseService } from '@shared/types'
import * as WarehouseHelper from '@shared/utils/warehouseDocuments'
import { PhotosForm } from '../PhotosForm'
import { useDocumentRequests } from '@shared/hooks/warehouse/useDocumentRequests'
import { download } from '@shared/api/files'

interface IWhService {
    service: WarehouseService
    highlighted?: boolean
    label?: string
    onChange?: (updatedService: WarehouseService) => void
    disabled?: boolean
    documentType?: boolean
    forcedActivated: boolean
    oneSelectionService?: boolean
    warehouseId?: number
    required?: boolean
    maxDocumentsCount?: number
}

// eslint-disable-next-line complexity
export const WhService: FC<IWhService> = ({
    service,
    label,
    highlighted,
    disabled,
    documentType,
    forcedActivated,
    oneSelectionService,
    onChange,
    warehouseId,
    maxDocumentsCount,
    // required,
}) => {
    const activeDocuments = service.documents
    const currentDocEntityId = activeDocuments[0]?.entity_id
    const serviceDocument = activeDocuments.find((el) => el.entity_id === currentDocEntityId) ?? null
    const [activeDocument, setActiveDocument] = useState<ServiceDocument | null>(serviceDocument)
    const [viewPending, setViewPending] = useState(false)
    const inputFile = useRef<HTMLInputElement>(null)
    const isPhotoService = WarehouseHelper.isServiceWarehousePhoto(service)

    const { uploadDocument, removeDocument, setMainDocument, isLoading, errorMessage, loadingEntityId } =
        useDocumentRequests({ warehouseId: warehouseId as number, service, onChange })

    const statusStyle = () => {
        let result = 'failed'
        const status = service.status?.code

        Object.keys(WhServiceConstants.statusStyles).forEach((style) => {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            if (status && WhServiceConstants.statusStyles?.[style]?.includes(status)) {
                result = style
            }
        })

        return result
    }

    const style = () => {
        let result = 'failed'
        const status = activeDocument?.status

        // eslint-disable-next-line @typescript-eslint/no-shadow
        Object.keys(WhServiceConstants.styles).forEach((style) => {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            if (status && WhServiceConstants.styles[style]?.includes(status)) {
                result = style
            }
        })

        return result
    }

    const onFileInputChange = useCallback(async () => {
        if (inputFile.current?.files) {
            const document = await uploadDocument(inputFile.current?.files)
            if (document) {
                setActiveDocument(document as any)
            }
        }
    }, [uploadDocument])

    const onUploadDocument = () => {
        inputFile?.current?.click()
    }

    const deleteActiveDocument = async () => {
        if (activeDocument) {
            await removeDocument(activeDocument.entity_id as number)
            setActiveDocument(null)
        }
    }

    const deleteDoc = async (id: number) => {
        await removeDocument(id)
    }

    const viewActiveDocument = async () => {
        if (activeDocument) {
            setViewPending(true)
            const link = activeDocument?.link || activeDocument?.url
            await download(activeDocument?.name, link as any)
            setViewPending(false)
        }
    }

    const handleServiceCheck = () => {
        const updatedService: WarehouseService = { ...service, is_active: !service.is_active }
        onChange?.(updatedService)
    }

    const mimes = serviceDocument?.file_mask
        ?.split(',')
        .map((item) => `.${item.trim()}`)
        .join(',')
    const fileExtensions = serviceDocument?.file_mask?.toUpperCase().split(',') || ['Unknown']
    const maxFileSizeMB = Math.floor(
        (Number(serviceDocument?.max_file_size) || WarehouseHelper.DUMMY_DOCUMENT.max_file_size) / 1000,
    )
    const isDocumentRequired = Boolean(serviceDocument?.required)
    // By default documents[] contains one object without link (just for settings like required etc)
    const hasUploadedDocuments = Boolean(serviceDocument?.link)

    const hasMaximumDocs = maxDocumentsCount && activeDocuments.length >= maxDocumentsCount

    if (service.required && !service.is_active) {
        handleServiceCheck()
    }

    return (
        <div className={cn('wh-service', { highlighted })}>
            {!documentType && (
                <div className="wh-service-title-wrapper">
                    {forcedActivated && !oneSelectionService && <div className="wh-service-title">{service.title}</div>}
                    <div className="wh-service-checkbox-title">
                        {!forcedActivated && !oneSelectionService && (
                            <Checkbox
                                value={service.is_active || !!service.required}
                                disabled={disabled || service.required}
                                label={service.title}
                                highlighted={highlighted}
                                onChange={handleServiceCheck}
                            />
                        )}
                        {!forcedActivated && oneSelectionService && (
                            <div className="radio">
                                <div className="list">
                                    {/* eslint-disable-next-line jsx-a11y/no-static-element-interactions */}
                                    <div
                                        className={cn('item', { active: service.is_active || !!service.required })}
                                        onClick={handleServiceCheck}
                                    >
                                        <div className="control" />
                                        <div className="service-label">{service.title}</div>
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>
                    {service.status && service.is_active && (
                        <div className="service-status">
                            <span className={cn('status', statusStyle(), { 'has-style': statusStyle() })}>
                                {service.status.title}
                            </span>
                        </div>
                    )}
                    {Number(service.price_type) !== 0 && (
                        <div className="service-price-type">
                            <span> {`${WhServiceConstants.prices[Number(service.price_type)]}, $`}</span>
                        </div>
                    )}
                </div>
            )}
            <div className="wh-service-notes">{service?.notes || ''}</div>
            {Number(service.price_type) !== 0 && (
                <div className="wh-service-rates-section">
                    <div className="service-rates">
                        {service.rates.map((sRate, index) => (
                            // eslint-disable-next-line react/no-array-index-key
                            <div key={`rates-${sRate.id} -${index}`} className="service-rate-item">
                                <InputText
                                    isNumericString
                                    type={undefined}
                                    decimalScale={2}
                                    fixedDecimalScale
                                    placeholder="Price"
                                    label={
                                        service.terms_related
                                            ? `${WhServiceConstants.rateLabels[index]}`
                                            : `Price ${WhServiceConstants.prices[Number(service.price_type)]}, $`
                                    }
                                    value={sRate.rate}
                                    disabled={!service.is_active}
                                    name={`rate ${sRate.code} ${sRate.days_from}`}
                                    onChange={(e) => {
                                        const updatedService = { ...service }
                                        updatedService.rates[index].rate = parseFloat(e.target.value)
                                        onChange?.(updatedService)
                                    }}
                                />
                            </div>
                        ))}
                    </div>
                    {service.suggest_prices && service.suggest_prices.length > 0 && (
                        <div className="suggestion-prices">
                            <div className="suggestion-prices-title">
                                <span>Recommended rates</span>
                            </div>
                            <div className="rates">
                                {service.rates.map((sRate, index) => (
                                    // eslint-disable-next-line react/no-array-index-key
                                    <div key={`suggest_prices-${index}-${sRate.id}`}>
                                        {service.suggest_prices[index] && (
                                            <div className="rate active">
                                                <div className="value">
                                                    ${service.suggest_prices[index].min_price}-$
                                                    {service.suggest_prices[index].max_price}
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                ))}
                            </div>
                        </div>
                    )}
                    {service.rates_sq_feet.length > 0 && <hr />}
                    <div className="service-rates">
                        {service.rates_sq_feet.map((sRate, index) => (
                            <div key={sRate.id} className="service-rate-item">
                                <InputText
                                    decimalScale={2}
                                    fixedDecimalScale
                                    isNumericString
                                    type={undefined}
                                    placeholder="Price"
                                    label={`(${sRate.unit_info.title})`}
                                    disabled={!service.is_active}
                                    name={`${sRate.unit_info.title}`}
                                    onChange={(e) => {
                                        const updatedService = { ...service }
                                        updatedService.rates[index].rate = parseFloat(e.target.value)
                                        onChange?.(updatedService)
                                    }}
                                    // error="errors.first('rateSq' + sRate.code + sRate.days_from)"
                                />
                            </div>
                        ))}
                    </div>
                    {service.suggest_sq_feet_prices && service.suggest_sq_feet_prices.length > 0 && (
                        <div className="suggestion-prices">
                            <div className="suggestion-prices-title">
                                <span>Recommended rates</span>
                            </div>
                            <div className="rates">
                                {service.rates_sq_feet.map((sRate, index) => (
                                    <div key={sRate.id}>
                                        {service.suggest_sq_feet_prices[index] && (
                                            <div className="rate active">
                                                <div className="value">
                                                    ${service.suggest_sq_feet_prices[index].min_price}-$
                                                    {service.suggest_sq_feet_prices[index].max_price}
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                ))}
                            </div>
                        </div>
                    )}
                </div>
            )}
            {isDocumentRequired && (
                <div className="service-document">
                    {isPhotoService ? (
                        <>
                            <PhotosForm
                                photos={activeDocuments}
                                maxPhotos={maxDocumentsCount as number}
                                fileExtensions={fileExtensions}
                                maxSize={maxFileSizeMB}
                                onUploadClick={onUploadDocument}
                                onMainClick={setMainDocument}
                                onDeleteClick={deleteDoc}
                                isLoading={isLoading}
                                loadingEntityId={loadingEntityId}
                            />
                            <input
                                id={service.title}
                                type="file"
                                ref={inputFile}
                                onChange={onFileInputChange}
                                name={service.title}
                                accept={mimes}
                                hidden
                            />
                        </>
                    ) : (
                        <>
                            <div className="service-document-info">
                                {serviceDocument?.name && <div className="service-label">{serviceDocument.name}</div>}
                                {activeDocument?.status ? (
                                    <div className="service-document-status">
                                        <div className={cn('document-status', style(), { 'has-style': style() })}>
                                            {WarehouseHelper.getDocumentStatus(activeDocument.status)}
                                        </div>
                                    </div>
                                ) : (
                                    <div className="service-document-status">
                                        <div className="document-status has-style failed">Absent</div>
                                    </div>
                                )}
                            </div>
                            <div className="service-document-upload">
                                {(!hasUploadedDocuments || !hasMaximumDocs) && (
                                    <div className="upload-wrap">
                                        <div className="btn-upload">
                                            <input
                                                id={service.title}
                                                type="file"
                                                ref={inputFile}
                                                onChange={onFileInputChange}
                                                name={service.title}
                                                accept={mimes}
                                            />
                                        </div>
                                        <div className="service-label">
                                            {label || `Please, upload document with type: ${fileExtensions}`}
                                        </div>
                                    </div>
                                )}
                            </div>
                            <div className="service-document-control">
                                <Button
                                    types={['blue', 'small']}
                                    loading={isLoading}
                                    disabled={hasMaximumDocs || !(forcedActivated || service.is_active) || isLoading}
                                    onClick={onUploadDocument}
                                >
                                    {!isLoading && 'Upload'}
                                </Button>
                                {activeDocument?.entity_id && (
                                    <Button
                                        types={['plain', 'small']}
                                        disabled={isLoading || !activeDocument}
                                        onClick={deleteActiveDocument}
                                    >
                                        Delete
                                    </Button>
                                )}
                                {activeDocument?.entity_id && (
                                    <Button
                                        types={['plain', 'small']}
                                        disabled={!activeDocument}
                                        loading={viewPending}
                                        onClick={viewActiveDocument}
                                    >
                                        {!viewPending && (
                                            <>
                                                <span className="icon-eye" style={{ marginRight: 6 }} /> View
                                            </>
                                        )}
                                    </Button>
                                )}
                            </div>
                        </>
                    )}
                    {errorMessage && (
                        <div className="w-error">
                            <div className="error">{`${errorMessage.field}: ${errorMessage.msg}`}</div>
                        </div>
                    )}
                    {serviceDocument?.expiration_date && hasUploadedDocuments && (
                        <div className="service-document-expiration-info">
                            <Datepicker
                                start={serviceDocument?.expiration_date}
                                end={serviceDocument?.expiration_date}
                                label="Expiration Date"
                                hasReset={false}
                                range={false}
                                // name="expiration_date"
                                // error={errors.expiration_date}
                                onChange={() => {}}
                            />
                        </div>
                    )}
                </div>
            )}
        </div>
    )
}
