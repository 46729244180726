import React from 'react'
import { SignUpTemplate } from '@/pages/SignUp/components/SignUpTemplate'
import { useFormik } from 'formik'
import Button from '@/components/Button'
import './SignUpWarehouseStep.scss'
import { updateLoggedState, useUserState } from '@/store/user'
import * as Yup from 'yup'
import { form } from '@/pages/Carrier/CarrierSettings/CarrierSettingsForm'
import cn from 'classnames'
import SelectTime, { validatePeriod } from '@/components/SelectTime'
import { DAYS } from '@/pages/Warehouse/WarehouseSettings/WarehouseSettingsWarehouse/WarehouseSettingsWarehouse'
import { updateWarehouse } from '@shared/api/warehouse'

interface SignUpWarehouseWorkingHoursValues {
    working_days?: string
    work_time_from?: string
    work_time_to?: string
}
export const SignUpWarehouseWorkingHoursSchema = Yup.object().shape({
    work_time_from: Yup.string().nullable().required('Working time is required'),
    work_time_to: Yup.string()
        .nullable()
        .required('Working time is required')
        .test({
            name: 'Working time end should be different from starting time', // Your custom error id
            test() {
                // eslint-disable-next-line @typescript-eslint/naming-convention
                const { work_time_from, work_time_to } = this.parent
                if (validatePeriod(work_time_from, work_time_to)) return true
                return this.createError({
                    message: 'Working time end should be after starting time', // Error message for the user
                    path: 'work_time_to', // The object path where you want show the error
                })
            },
        }),
    working_days: Yup.string(),
})

export const SignUpWarehouseWorkingHoursStep: React.FC = () => {
    const { state: userState, dispatch } = useUserState()
    const formik = useFormik<SignUpWarehouseWorkingHoursValues>({
        onSubmit: async (values) => {
            await updateWarehouse({
                working_days: values.working_days,
                work_time_from: values.work_time_from,
                work_time_to: values.work_time_to,
            })
            await updateLoggedState(
                {
                    ...userState.userInfo!,
                    registration_step: 22,
                },
                dispatch,
            )
        },
        validationSchema: SignUpWarehouseWorkingHoursSchema,
        initialValues: {
            working_days: '',
        },
    })

    return (
        <SignUpTemplate step={[2, 4]}>
            <div className="sign-up_warehouse-hours">
                <h3>What are your shipping and receiving hours?</h3>
                <form onSubmit={formik.handleSubmit}>
                    <div className="working-days-wrapper">
                        <div className="label">Days of Operation</div>
                        <div className="control working-days">
                            {DAYS.map((day) => (
                                // eslint-disable-next-line jsx-a11y/no-static-element-interactions
                                <div
                                    key={`day-${day}`}
                                    onClick={() => {
                                        const currentValue = formik.values.working_days?.split(',') ?? []
                                        let result = []
                                        if (currentValue.includes(day)) {
                                            result = DAYS.filter((d) => currentValue.includes(d) && d !== day)
                                        } else {
                                            result = DAYS.filter((d) => currentValue.includes(d) || d === day)
                                        }
                                        formik.setFieldValue('working_days', result.join(','))
                                    }}
                                    className={cn({ active: formik.values.working_days?.includes(day) })}
                                >
                                    <span>{day}</span>
                                </div>
                            ))}
                        </div>

                        <div className="field error-field">
                            {formik.errors.work_time_to && (
                                <div className="error" style={{ whiteSpace: 'nowrap' }}>
                                    {formik.errors.work_time_to}
                                </div>
                            )}
                        </div>
                    </div>
                    <div className="work-time-from-to">
                        <div className="field work-time-from">
                            <SelectTime
                                value={formik.values.work_time_from}
                                error={formik.errors.work_time_from}
                                label="Hours of Operation  From"
                                name="work_time_from"
                                onAction={(time) => {
                                    formik.setFieldValue('work_time_from', String(time.value as string))
                                }}
                            />
                            <div className="dash">&mdash;</div>
                        </div>

                        <div className="field work-time-to">
                            <SelectTime
                                value={formik.values.work_time_to}
                                error={formik.errors.work_time_to}
                                label="To"
                                errorHide
                                min={formik.values.work_time_from}
                                name="work_time_to"
                                onAction={(time) => formik.setFieldValue('work_time_to', time.value)}
                            />
                        </div>
                    </div>
                    <br />
                    <br />
                    <Button
                        label="Next"
                        type="submit"
                        disabled={!formik.isValid}
                        loading={formik.isSubmitting}
                        types={['blue', 'full']}
                    />
                </form>
            </div>
        </SignUpTemplate>
    )
}
