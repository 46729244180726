import React, { FC, memo, useCallback } from 'react'
import * as Yup from 'yup'
import { ModalRegistry } from '@shared/common/modals'
import { CompanyEntities, ModalPartialProps, Modals } from '@shared/types'
import Button from '@/components/Button'
import { useFormik } from 'formik'
import './UpdatePermissionsModal.scss'
import cn from 'classnames'
import { apiVersion, Http } from '@shared/api/Http'
import { UserInfo } from '@shared/api/types'

interface UpdatePermissionsForm {
    carriers: number[]
    warehouses: number[]
}

const UpdatePermissionsFormSchema = Yup.object().shape({
    carriers: Yup.array().of(Yup.number()),
    warehouses: Yup.array().of(Yup.number()),
})

interface UpdatePermissionsModalProps {
    userInfo: UserInfo
    entities: CompanyEntities
    onClose?: (redirectUrl?: string) => void
}

const UpdatePermissionsModal: FC<UpdatePermissionsModalProps> = ({ entities, onClose, userInfo }) => {
    const formik = useFormik<UpdatePermissionsForm>({
        initialValues: {
            carriers: userInfo.permissions.carriers,
            warehouses: userInfo.permissions.warehouses,
        },
        validationSchema: UpdatePermissionsFormSchema,
        onSubmit: async (values) => {
            try {
                const response = await Http.post(`/app/${apiVersion}/manager/permissions`, {
                    email: userInfo.email?.email || '',
                    id: userInfo.id,
                    permissions: {
                        carriers: values.carriers,
                        warehouses: values.warehouses,
                    },
                })

                formik.setSubmitting(true)
                onClose?.('/company/managers')
            } catch (e: any) {
                formik.setSubmitting(false)
                formik.setErrors(e)
            }
        },
    })

    const handleTypeChange = useCallback(
        ({ value }) => {
            formik.handleChange({ target: { name: 'type', value } })
        },
        [formik.handleChange],
    )

    return (
        <div className="modal-info">
            <form onSubmit={formik.handleSubmit}>
                <div className="form">
                    <div className="permissions">
                        {entities.carriers.length > 0 && (
                            <div>
                                <h3>Carriers</h3>
                                <div className="control permissions-entity">
                                    {entities.carriers.map((carrier) => (
                                        // eslint-disable-next-line jsx-a11y/no-static-element-interactions
                                        <div
                                            key={`carrier-${carrier.id}`}
                                            onClick={() => {
                                                const currentValue = formik.values.carriers
                                                let result = []
                                                if (currentValue.includes(carrier.id)) {
                                                    result = formik.values.carriers.filter(
                                                        (d) => currentValue.includes(d) && d !== carrier.id,
                                                    )
                                                } else {
                                                    result = [carrier.id].concat(formik.values.carriers)
                                                }
                                                formik.setFieldValue('carriers', result)
                                            }}
                                            className={cn({ active: formik.values.carriers.includes(carrier.id) })}
                                        >
                                            <span>{carrier.entity}</span>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        )}
                        {entities.warehouses.length > 0 && (
                            <div>
                                <h3>Warehouses</h3>
                                <div className="control permissions-entity">
                                    {entities.warehouses.map((warehouse) => (
                                        // eslint-disable-next-line jsx-a11y/no-static-element-interactions
                                        <div
                                            key={`warehouse-${warehouse.id}`}
                                            onClick={() => {
                                                const currentValue = formik.values.warehouses
                                                let result = []
                                                if (currentValue.includes(warehouse.id)) {
                                                    result = formik.values.warehouses.filter(
                                                        (d) => currentValue.includes(d) && d !== warehouse.id,
                                                    )
                                                } else {
                                                    result = [warehouse.id].concat(formik.values.warehouses)
                                                }
                                                formik.setFieldValue('warehouses', result)
                                            }}
                                            className={cn({ active: formik.values.warehouses.includes(warehouse.id) })}
                                        >
                                            <span>{warehouse.entity}</span>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        )}
                    </div>
                </div>

                <div className="btns">
                    {userInfo.type !== 'admin' && (
                        <Button
                            label="Update"
                            type="submit"
                            loading={formik.isSubmitting}
                            types={['blue', 'full']}
                            disabled={!formik.isValid || !formik.dirty}
                        />
                    )}
                    {userInfo.type === 'admin' && (
                        <Button
                            label="Close"
                            loading={formik.isSubmitting}
                            types={['plain', 'bordered']}
                            onClick={() => {
                                ModalRegistry.get().close(Modals.UpdatePermissionsModal)
                            }}
                        />
                    )}
                </div>
            </form>
        </div>
    )
}

ModalRegistry.get().register<UpdatePermissionsModalProps>(Modals.UpdatePermissionsModal, {
    id: 'PermissionsModalProps',
    className: 'new-modal-permissions',
    size: 'medium',
    Component: memo(UpdatePermissionsModal),
    title: 'Permissions',
})

export const showUpdatePermissionsModal = (props: ModalPartialProps<UpdatePermissionsModalProps>): void =>
    ModalRegistry.get().show<UpdatePermissionsModalProps>(Modals.UpdatePermissionsModal, props)

export default memo(UpdatePermissionsModal)
