import {
    CarrierBookingState,
    CheckArrivalTimeOptions,
    WarehouseForBooking,
    WarehouseService,
    WarehouseServiceMapped,
} from '@shared/types'
import { getWarehouseFormFromFilters } from '@/parts/Forms'
import dayjs from 'dayjs'

const HOUR_FORMAT = 'HH:mm'

export const SERVICE_HINTS: Record<number, string> = {
    4: 'Local Distribution is only available with a minimum of 1 day of Warehousing',
    5: 'Frozen Local Distribution is only available with a minimum of 1 day of Warehousing',
}

const getUTCTime = (time: string, offset: number): dayjs.Dayjs => {
    const [hour, minute] = time.split(':')
    return dayjs().utcOffset(offset).set('hour', Number(hour)).set('minute', Number(minute))
}
const getUTCDate = (localDate: string, offset: number): dayjs.Dayjs => {
    const [year, month, day] = localDate.split('-')
    return dayjs()
        .utcOffset(offset)
        .set('date', Number(day))
        .set('year', Number(year))
        .set('month', Number(month) - 1)
}

export const ARRIVAL_TIME_STEP = 2
export const DEFAULT_ARRIVAL_HOURS_FROM = '08:00'
export const DEFAULT_MIN_ARRIVAL_HOURS_FROM = '00:00'
export const DEFAULT_MAX_ARRIVAL_HOURS_TO = '23:00'

export class CarrierBookingFormUtils {
    static getCarrierBookingState(data: WarehouseForBooking, filters?: string): CarrierBookingState {
        const warehouseServices = data.bookingServices
        const services = CarrierBookingFormUtils.mapServices(warehouseServices)
        const searchData = getWarehouseFormFromFilters(filters)
        const service = warehouseServices.find((s) => s.code === searchData.service)
        return {
            ...data,
            bookingServices: services,
            searchData,
            serviceDict: CarrierBookingFormUtils.getServicesDict(services),
            groupedServices: CarrierBookingFormUtils.groupServices(services),
            groups: CarrierBookingFormUtils.extractGroups(services, service?.group),
        }
    }

    static mapService(service: WarehouseService): WarehouseServiceMapped {
        return {
            ...service,
            name: service.title,
            value: service.code,
            hint: SERVICE_HINTS[service.code],
            rates: service.rates.sort((a, b) => a.days_from - b.days_from),
        }
    }

    static mapServices(services: WarehouseService[]): WarehouseServiceMapped[] {
        const mapped = []
        for (let i = 0; i < services.length; i += 1) {
            const service = services[i]
            if (service.rates.length && service.rates.some(({ is_active }) => is_active)) {
                mapped.push(CarrierBookingFormUtils.mapService(service))
            }
        }
        return mapped.sort((a, b) => a.group - b.group || a.code - b.code)
    }

    static extractGroups(services: WarehouseServiceMapped[], selectedGroup?: number): Set<number> {
        const selectedService = selectedGroup ? services.find((item) => item.group === selectedGroup) : undefined
        const setOfGroups = new Set<number>()
        services

            .filter(
                (item) =>
                    (!selectedService?.unity || item.unity?.code === selectedService?.unity?.code) && item.type === 1,
            )
            .forEach((item) => {
                setOfGroups.add(item.group)
            })

        return setOfGroups
    }

    static groupServices(
        services: WarehouseServiceMapped[],
        field: keyof WarehouseServiceMapped = 'group',
    ): Record<number, WarehouseServiceMapped[]> {
        return services.reduce<Record<number, WarehouseServiceMapped[]>>((grouped, service) => {
            if (grouped[service[field] as number]) {
                grouped[service[field] as number].push(service)
            } else {
                // eslint-disable-next-line no-param-reassign
                grouped[service[field] as number] = [service]
            }
            return grouped
        }, {})
    }

    static getServicesDict(services: WarehouseServiceMapped[]): Record<number, WarehouseServiceMapped> {
        return services.reduce<Record<number, WarehouseServiceMapped>>((grouped, service) => {
            if (!grouped[service.code]) {
                // eslint-disable-next-line no-param-reassign
                grouped[service.code] = service
            }
            return grouped
        }, {})
    }

    static getToHours = (fromHours: string, utcOffset = 0): string =>
        getUTCTime(fromHours, utcOffset).add(ARRIVAL_TIME_STEP, 'hour').format(HOUR_FORMAT)

    static getFromHours = (toHours: string, utcOffset = 0): string =>
        getUTCTime(toHours, utcOffset).subtract(ARRIVAL_TIME_STEP, 'hour').format(HOUR_FORMAT)

    static getDefaultArrivalHoursTo(): string {
        return this.getToHours(DEFAULT_ARRIVAL_HOURS_FROM)
    }

    static getDefaultMaxArrivalHoursFrom(): string {
        return this.getFromHours(DEFAULT_MAX_ARRIVAL_HOURS_TO)
    }

    static getDefaultMinArrivalHoursTo(): string {
        return this.getToHours(DEFAULT_MIN_ARRIVAL_HOURS_FROM)
    }

    static getRoundedTimeNow = (utcOffset = 0): dayjs.Dayjs => {
        const now = dayjs().utcOffset(utcOffset)
        const time = now.clone()
        const roundedTime = time.clone().startOf('hour').isSame(time) ? time : time.add(1, 'hour').startOf('hour')

        return roundedTime
    }

    static getArrivalTimeData(
        periodStart: string,
        utcOffset = 0,
        workTimeFrom?: string,
        workTimeTo?: string,
    ): {
        minFrom: string
        maxFrom: string
        minTo: string
        maxTo: string
        fromHours: string
        toHours: string
    } {
        let fromHours
        let toHours
        let minFrom
        let maxFrom
        let minTo
        let maxTo
        const now = dayjs().utcOffset(utcOffset)

        const today = now.clone().startOf('day')
        const to = getUTCTime(workTimeTo || this.getDefaultArrivalHoursTo(), utcOffset)
        const startDate = getUTCDate(periodStart, utcOffset)

        if (!workTimeFrom) {
            if (today.clone().isSame(startDate)) {
                const roundedFromTime = this.getRoundedTimeNow(utcOffset)
                fromHours = roundedFromTime.format(HOUR_FORMAT)
                // eslint-disable-next-line no-undef
                toHours = roundedFromTime.add(to.diff(roundedFromTime), 'hour').format(HOUR_FORMAT)
            } else {
                fromHours = DEFAULT_ARRIVAL_HOURS_FROM
                toHours = this.getDefaultArrivalHoursTo()
            }
            minFrom = DEFAULT_MIN_ARRIVAL_HOURS_FROM
            maxFrom = this.getDefaultMaxArrivalHoursFrom()
            minTo = this.getDefaultMinArrivalHoursTo()
            maxTo = DEFAULT_MAX_ARRIVAL_HOURS_TO
        } else {
            const timeFrom =
                today.format('YYYY-MM-DD') === startDate.format('YYYY-MM-DD')
                    ? this.getRoundedTimeNow(utcOffset)
                    : getUTCTime(workTimeFrom, utcOffset)

            const timeTo = getUTCTime(workTimeTo || this.getDefaultArrivalHoursTo(), utcOffset)
            minFrom = timeFrom.format(HOUR_FORMAT)
            maxFrom = timeTo.subtract(ARRIVAL_TIME_STEP, 'hour').format(HOUR_FORMAT)
            minTo = timeFrom.add(ARRIVAL_TIME_STEP, 'hour').format(HOUR_FORMAT)
            maxTo = timeTo.format(HOUR_FORMAT)
            if (
                timeFrom.clone().startOf('hour').isSame(timeFrom) &&
                getUTCTime(workTimeFrom, utcOffset).diff(timeFrom) > 0
            ) {
                fromHours = timeFrom.format(HOUR_FORMAT)
            } else {
                fromHours = timeFrom.clone().add(1, 'hour').startOf('hour').format(HOUR_FORMAT)
            }

            const diff = to.startOf('hour').diff(getUTCTime(fromHours, utcOffset), 'hour')
            const diffHours = diff < ARRIVAL_TIME_STEP ? diff : ARRIVAL_TIME_STEP

            toHours = (diff < ARRIVAL_TIME_STEP ? to : timeFrom.add(diffHours + 1, 'hour'))
                .startOf('hour')
                .format(HOUR_FORMAT)
        }

        return {
            minFrom,
            maxFrom,
            minTo,
            maxTo,
            toHours,
            fromHours,
        }
    }

    // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types,complexity
    static checkArrivalTimeValidity({
        start,
        end,
        periodStart,
        workTimeFrom,
        workTimeTo,
        utcOffset = 0,
    }: CheckArrivalTimeOptions): boolean {
        const now = dayjs().utcOffset(utcOffset)
        const today = now.clone().startOf('day')
        const startDate = getUTCDate(periodStart, utcOffset)
        const roundedNow = this.getRoundedTimeNow(utcOffset)

        const workTime = {
            from: getUTCTime(workTimeFrom || DEFAULT_ARRIVAL_HOURS_FROM, utcOffset),
            to: getUTCTime(workTimeTo || this.getDefaultArrivalHoursTo(), utcOffset),
        }

        if (workTime.to.isSameOrBefore(workTime.from)) {
            workTime.to.add(1, 'day')
        }

        const arrivalTime = {
            from: getUTCTime(start || DEFAULT_ARRIVAL_HOURS_FROM, utcOffset),
            to: getUTCTime(end || this.getDefaultArrivalHoursTo(), utcOffset),
        }

        if (arrivalTime.from.isBefore(workTime.from)) {
            arrivalTime.from.add(1, 'day')
        }

        if (arrivalTime.to.isBefore(arrivalTime.from)) {
            arrivalTime.to.add(1, 'day')
        }

        const inIntervalCondition =
            arrivalTime.from.isSameOrAfter(workTime.from) &&
            arrivalTime.from.isBefore(workTime.to) &&
            arrivalTime.to.isAfter(workTime.from) &&
            arrivalTime.to.isSameOrBefore(workTime.to) &&
            arrivalTime.from.isBefore(arrivalTime.to)

        const startDateDiff = today.diff(startDate, 'day')

        const isIntervalAfterNow =
            startDateDiff === 0 ? arrivalTime.from.isAfter(roundedNow) && arrivalTime.to.isAfter(roundedNow) : true

        return inIntervalCondition && isIntervalAfterNow
    }

    static getStartEndDate(
        utcOffset = 0,
        workTimeFrom?: string,
        workTimeTo?: string,
        numberOfDays?: number,
    ): [string, string] {
        const now = dayjs().utcOffset(utcOffset)
        const from = dayjs(now.format(HOUR_FORMAT), HOUR_FORMAT)
        const roundedFromTime = from.clone().startOf('hour').isSame(from) ? from : from.add(1, 'hour').startOf('hour')
        const to = getUTCTime(workTimeTo || '12:00', utcOffset)

        if (roundedFromTime.clone().diff(to, 'hour') <= -1) {
            return [
                now.startOf('day').format('YYYY-MM-DD'),
                now
                    .endOf('day')
                    .add(Number(numberOfDays || 1), 'day')
                    .format('YYYY-MM-DD'),
            ]
        }

        return [
            now.startOf('day').add(1, 'day').format('YYYY-MM-DD'),
            now
                .endOf('day')
                .add(Number(numberOfDays || 1), 'day')
                .format('YYYY-MM-DD'),
        ]
    }

    static isTimeBefore = (time: string, minTime: string, utcOffset = 0) => {
        const utcTime = getUTCTime(time, utcOffset)
        const minUtcTime = getUTCTime(minTime, utcOffset)

        return utcTime.isBefore(minUtcTime)
    }

    static isTimeAfter = (time: string, maxTime: string, utcOffset = 0) => {
        const utcTime = getUTCTime(time, utcOffset)
        const maxUtcTime = getUTCTime(maxTime, utcOffset)

        return utcTime.isAfter(maxUtcTime)
    }
}
