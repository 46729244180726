import React, { FC, memo } from 'react'
import * as Yup from 'yup'
import { ModalRegistry } from '@shared/common/modals'
import { ModalPartialProps, Modals } from '@shared/types'
import Button from '@/components/Button'
import { InputText } from '@/components/InputText'
import { useFormik } from 'formik'
import './NewWarehouseModal.scss'
import { apiVersion, Http } from '@shared/api/Http'
import { Login } from '@shared/api/types'

interface NewWarehouseForm {
    entity?: string
}

const NewWarehouseFormSchema = Yup.object().shape({
    entity: Yup.string().required().min(3),
})

interface NewWarehouseModalProps {
    onClose?: (redirectUrl?: string) => void
}

const NewWarehouseModal: FC<NewWarehouseModalProps> = ({ onClose }) => {
    const formik = useFormik<NewWarehouseForm>({
        initialValues: {},
        validationSchema: NewWarehouseFormSchema,
        onSubmit: async (values) => {
            try {
                const response = await Http.post<{ email: string; password: string }, Login>(
                    `/warehouse/${apiVersion}/warehouse/new`,
                    values,
                )
                formik.setSubmitting(false)

                onClose?.('/company')
            } catch (e: any) {
                formik.setSubmitting(false)
                formik.setErrors(e)
            }
        },
    })

    return (
        <div className="modal-info">
            <form onSubmit={formik.handleSubmit}>
                <div className="form">
                    <div className="form-company">
                        <div className="section">
                            <div className="form-wrap">
                                <div className="field">
                                    <InputText
                                        text
                                        label="Warehouse Name"
                                        name="entity"
                                        onChange={formik.handleChange}
                                        value={formik.values.entity}
                                        error={formik.errors.entity}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="btns">
                    <Button
                        label="Add"
                        type="submit"
                        loading={formik.isSubmitting}
                        types={['blue', 'full']}
                        disabled={!formik.isValid || !formik.dirty}
                    />
                </div>
            </form>
        </div>
    )
}

ModalRegistry.get().register<NewWarehouseModalProps>(Modals.NewWarehouseModal, {
    id: 'WarehouseModalProps',
    className: 'new-modal-warehouse',
    size: 'large',
    Component: memo(NewWarehouseModal),
    title: 'Add a new Warehouse',
})

export const showNewWarehouseModal = (props: ModalPartialProps<NewWarehouseModalProps>): void =>
    ModalRegistry.get().show<NewWarehouseModalProps>(Modals.NewWarehouseModal, props)

export default memo(NewWarehouseModal)
