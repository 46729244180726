import React, { CSSProperties, memo } from 'react'
import cn from 'classnames'
import './Icon.scss'

interface IIcon {
    style?: CSSProperties
    type: string
    code?: string
    className?: string
}

const Icon: React.FC<IIcon> = memo(({ style, type, code, className }) => (
    <span className={cn('icon', `type-${type}`, code && `icon-${code}`, className)} style={style} />
))

Icon.displayName = 'Icon'

export default Icon
