import React, { FC, memo, useState } from 'react'
import * as Yup from 'yup'
import { ModalRegistry } from '@shared/common/modals'
import { ModalPartialProps, Modals } from '@shared/types'
import Button from '@/components/Button'
import { restorePassword, RestorePayload } from '@shared/api/user'
import { useFormik } from 'formik'
import { InputText } from '@/components/InputText'
import { showFeedbackModal } from '@/parts/Modals/FeedbackModal'

interface RestoreForm {
    email: string | null
}

const RestoreFormSchema = Yup.object().shape({
    email: Yup.string().email('Invalid email').required('Required'),
})

interface RestoreModalProps {
    onRestore?: (msg: string) => Promise<void> | void
}

const RestoreModal: FC<RestoreModalProps> = ({ onRestore }) => {
    const [errorMessage, setErrorMessage] = useState<string | null>(null)
    const formik = useFormik<RestoreForm>({
        initialValues: { email: null },
        validateOnMount: true,
        validationSchema: RestoreFormSchema,
        enableReinitialize: true,
        onSubmit: async (values) => {
            try {
                formik.setSubmitting(true)
                await restorePassword(values as RestorePayload)
                formik.setSubmitting(false)
                ModalRegistry.get().close(Modals.RestoreModal)
                onRestore?.('We have send you link on your e-mail')
            } catch (errors) {
                formik.setSubmitting(false)
                if (Array.isArray(errors)) {
                    setErrorMessage(JSON.stringify(errors))
                }
            }
        },
    })

    const feedback = () => {
        showFeedbackModal({
            props: {},
        })
    }

    return (
        <div className="modal-restore-password">
            {errorMessage && <div className="message">{errorMessage}</div>}

            <form onSubmit={formik.handleSubmit}>
                <InputText
                    text
                    label="E-mail"
                    name="email"
                    onChange={formik.handleChange}
                    value={formik.values.email}
                    error={formik.errors.email}
                />

                <div slot="after" className="modal-after">
                    {/* eslint-disable-next-line jsx-a11y/anchor-is-valid,jsx-a11y/no-static-element-interactions */}
                    <a onClick={feedback} className="secondary-link media-desktop">
                        Contact support
                    </a>
                </div>

                <div className="btns">
                    <Button
                        label="Send reset link"
                        disabled={!formik.isValid}
                        loading={formik.isSubmitting}
                        types={['blue', 'large']}
                        type="submit"
                    />
                </div>
            </form>
        </div>
    )
}

ModalRegistry.get().register<RestoreModalProps>(Modals.RestoreModal, {
    id: 'RestoreModalProps',
    className: 'modal-restore',
    size: 'medium',
    Component: memo(RestoreModal),
    title: 'Reset password',
})

export const showRestoreModal = (props: ModalPartialProps<RestoreModalProps>): void =>
    ModalRegistry.get().show<RestoreModalProps>(Modals.RestoreModal, props)

export default memo(RestoreModal)
