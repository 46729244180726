import React, { FC, memo } from 'react'
import { ModalRegistry } from '@shared/common/modals'
import { ModalPartialProps, Modals } from '@shared/types'
import Button from '@/components/Button'
import { InputText } from '@/components/InputText'
import Textarea from '@/components/Textarea'
import { useDispatchDriver } from '@shared/forms/DispatchDriver'
import './DispatchDriverModal.scss'

interface DispatchDriverModalProps {
    onClose?: () => void
    orderId: number
}

const DispatchDriverModal: FC<DispatchDriverModalProps> = ({ orderId, onClose }) => {
    const formik = useDispatchDriver(orderId, onClose)

    return (
        <div className="modal-info">
            <form onSubmit={formik.handleSubmit}>
                <div className="form">
                    <div className="dispatch-driver">
                        <div className="section">
                            <div className="form-wrap">
                                <div className="field">
                                    <InputText
                                        text
                                        name="phone"
                                        onChange={formik.handleChange}
                                        value={formik.values.phone}
                                        error={formik.errors.phone}
                                        label="Phone#"
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="section">
                            <div className="form-wrap">
                                <div className="field">
                                    <InputText
                                        text
                                        name="email"
                                        onChange={formik.handleChange}
                                        value={formik.values.email}
                                        error={formik.errors.email}
                                        label="Email#"
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="section">
                            <div className="form-wrap">
                                <div className="field">
                                    <Textarea
                                        value={formik.values.message}
                                        error={formik.errors.message}
                                        onChange={formik.handleChange}
                                        name="message"
                                        label="Message to a driver"
                                        placeholder="For example, the instruction"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="btns">
                    <Button
                        label="Send"
                        type="submit"
                        loading={formik.isSubmitting}
                        types={['blue', 'full']}
                        disabled={!formik.isValid || !formik.dirty}
                    />
                </div>
            </form>
        </div>
    )
}

ModalRegistry.get().register<DispatchDriverModalProps>(Modals.DispatchDriverModal, {
    id: 'DispatchDriverModalProps',
    className: 'dispatch-driver-modal',
    size: 'medium',
    Component: memo(DispatchDriverModal),
    title: 'Dispatch a driver',
})

export const showDispatchDriverModal = (props: ModalPartialProps<DispatchDriverModalProps>): void =>
    ModalRegistry.get().show<DispatchDriverModalProps>(Modals.DispatchDriverModal, props)

export default memo(DispatchDriverModal)
