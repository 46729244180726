import React, { memo, useCallback } from 'react'
import cn from 'classnames'
import Icon from '@/components/Icon/Icon'

export type SelectItemAction = 'select' | 'delete'

export interface ISelectItem {
    editable?: boolean
    sort?: boolean
    selected?: number | string | boolean
    name: string
    icon?: string
    value: number | string | boolean
    index: number
    onAction?: (item: { value: number | string | boolean; name: string; action: SelectItemAction }) => void
}

const SelectItem: React.FC<ISelectItem> = ({ selected, onAction, icon, editable, index, value, name, sort }) => {
    const handleSelectItem = useCallback(() => {
        if (onAction) onAction({ value, name, action: 'select' })
    }, [onAction, value, name])

    const handleDeleteItem = useCallback(
        (e) => {
            e.stopPropagation()
            if (onAction) onAction({ value, name, action: 'delete' })
        },
        [onAction, value, name],
    )

    return (
        <div
            role="button"
            tabIndex={index}
            className={cn('option', { active: value === selected, first: index === 0 })}
            title={name}
            onClick={handleSelectItem}
        >
            {icon && <Icon code={icon} type="cards" />}
            <span className="name">{name}</span>

            {editable && (
                <span
                    aria-label="delete"
                    className="delete icon-basket"
                    role="button"
                    tabIndex={-1}
                    onClick={handleDeleteItem}
                />
            )}

            {sort && selected === value && (
                <span
                    aria-label="sort"
                    className={cn('sort icon-triangle', {
                        reverse: typeof value === 'string' && value.split('')[0] !== '-',
                    })}
                />
            )}
        </div>
    )
}

export default memo(SelectItem)
