import React, { FC } from 'react'
import { Routes, Route, Navigate } from 'react-router-dom'
import { PublicList } from './PublicList/PublicList'
import { PublicSearch } from './PublicSearch/PublicSearch'

export const PublicRoutes: FC = () => (
    <div className="content">
        <Routes>
            <Route path="/" element={<PublicSearch />} />
            <Route path="/warehouses/:filters" element={<PublicList />} />
            <Route path="/warehouses" element={<PublicList />} />
        </Routes>
    </div>
)
