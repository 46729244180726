import React, { FC, useEffect } from 'react'
import { useParams, useNavigate } from 'react-router-dom'

import { EntityToken, EntityType } from '@shared/api/types'
import { apiVersion, Http } from '@shared/api/Http'

// eslint-disable-next-line complexity
export const SelectWarehouseBeforeOrderItem: FC = () => {
    const { orderId, warehouseId } = useParams<{ orderId: string; warehouseId: string }>()
    const navigate = useNavigate()

    const selectWarehouse = async () => {
        const response = await Http.get<{ type: string; id: string }, EntityToken>(
            `/app/${apiVersion}/select/warehouse/${warehouseId}`,
        )

        Http.defaults.headers.common['Auth-Token'] = response.token
        localStorage.setItem('token', response.token)
        localStorage.setItem('type', response.type)
        navigate(`/warehouse/order/${orderId}`)
    }

    useEffect(() => {
        selectWarehouse()
    })

    return <></>
}
