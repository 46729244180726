import React, { FC, useCallback, useMemo, useState } from 'react'
import { DropdownDocuments } from '@/components/DropdownDocuments'
import { Loader } from '@/components/Loader'
import { Link, Navigate, useParams } from 'react-router-dom'
import cn from 'classnames'
import { Status } from '@/components/Status'
import Button, { ButtonType } from '@/components/Button'
import Dropdown from '@/components/Dropdown'
import { Hint } from '@/components/Hint'
import { RatingItem } from '@/parts/Rating/Item'
import { CarrierService, Modals, OrderAdjustment, OrderDocument } from '@shared/types'
import { changeOrderStatus, payForOrder, useCarrierOrder, useCarrier } from '@shared/api/carrier'
import dayjs from 'dayjs'
import LocationLink from '@/components/LocationLink'
import { consts } from '@shared/common/consts'
import Currency from '@/components/Currency'
import { utils } from '@shared/common/utils'
import { showError, showInfoModal } from '@/parts/Modals/InfoModal'
import { showConfirmModal } from '@/parts/Modals/ConfirmModal'
import { showRatingModal } from '@/parts/Modals/RatingModal'
import { showRejectCostAdjustmentModal } from '@/parts/Modals/RejectCostAdjustmentModal'

import { Http } from '@shared/api/Http'
import { Helmet } from 'react-helmet'
import { getArrivalString } from '@shared/common/order'
import { showDispatchDriverModal } from '@/parts/Modals/DispatchDriverModal'
import CarrierOrderServices from '@/pages/Carrier/CarrierOrderItem/CarrierOrderServices'
import EventBus from '@shared/common/eventBus'
import { Events } from '@shared/common/events'
import { PhotosCarousel } from '@/parts/PhotosView'
import { useMobileWidth } from '@shared/common/hooks'
import { CONFIRMATION_STATUSES, prepareOrderAdjustmentConfirmation } from '@shared/api/order-confirm'
import { OrderCostAdjustment } from '@/parts/OrderCostAdjustment'
import './CarrierOrderItem.scss'
import { StatusHistory } from '@/parts/StatusHistory/StatusHistory'

const messages: {
    actions: Record<number, { title: string; btn: ButtonType[] }>
    confirm: Record<number, { title: string; message: string }>
} = {
    actions: {
        5: { title: 'Complete Order', btn: ['blue', 'small'] },
        7: { title: 'Cancel Order', btn: ['bordered', 'small'] },
        8: { title: 'Cancel Order', btn: ['bordered', 'small'] },
    },
    confirm: {
        5: {
            title: 'Are you sure you want complete this order?',
            message: '',
        },
        7: {
            title: 'Are you sure you want cancel this order?',
            message: 'Order will be canceled without cancellation fee',
        },
        8: {
            title: 'Are you sure you want cancel this order?',
            message: 'Order will be canceled with cancellation fee',
        },
    },
}

const PAGE_TITLE = 'Olimp - Order'

// eslint-disable-next-line complexity
export const CarrierOrderItem: FC<{ id?: string }> = ({ id }) => {
    const { orderId: orderParamId } = useParams<{ orderId?: string }>()
    const orderId = (orderParamId ?? id)!
    const { item, data: orderData, mutate, revalidate } = useCarrierOrder(orderId)
    const { formValues: carrierData } = useCarrier()
    const [showModalRating, setShowModalRating] = useState<boolean>()
    const [adjustmentSignPending, setAdjustmentSignPending] = useState<boolean>()
    const [adjustmentPending, setAdjustmentPending] = useState<boolean>()
    const [confirmed, setConfirmed] = useState<boolean>()

    const itemAvailableStatus = item?.available_statuses?.length && item.available_statuses[0]

    const payment = async () => {
        if (carrierData?.default_card_id && orderId) {
            await payForOrder(Number(orderId))
            showInfoModal({
                props: {
                    message: 'Your order was successfully paid',
                },
            })
        } else {
            showInfoModal({
                props: {
                    message: 'Add a payment card in your account',
                },
            })
        }
    }

    const updateRating = (r: any) => {
        if (orderData) {
            mutate(
                {
                    ...orderData,
                    rating: r,
                },
                true,
            )
        }
    }

    const openModalRating = () => {
        if (item) {
            showRatingModal({
                props: {
                    orderId: item.id,
                    warehouseId: item.warehouse.id,
                    onAction: updateRating,
                },
            })
        }
    }

    const changeStatus = async (yesNo: boolean) => {
        if (item && itemAvailableStatus && yesNo) {
            try {
                const data: any = await changeOrderStatus(Number(orderId), itemAvailableStatus.code)
                mutate(data, false)
                if (data.status.code === 5 && !item.parent_id) {
                    openModalRating()
                }
            } catch (err: any) {
                showError(err)
            }
        }
    }

    const confirm = () => {
        if (itemAvailableStatus) {
            showConfirmModal({
                props: {
                    title: messages.confirm[itemAvailableStatus.code].title,
                    message: messages.confirm[itemAvailableStatus.code].message,
                    onConfirm: changeStatus,
                },
            })
        }
    }

    const updateAdjustmentStatus = useCallback(
        async (adjustmentId: number, yesNo: boolean, rejectReason?: string) => {
            if (item?.id) {
                const actionUrl = `carrier/v1/orders/${item.id}/adjust/${adjustmentId}`
                setAdjustmentPending(true)
                setConfirmed(yesNo)
                try {
                    await Http.post(actionUrl, { confirmed: yesNo, rejectReason })
                    await revalidate()
                } catch (err: any) {
                    let errText = 'Unknown error occurred'
                    if (err && err.card_id) {
                        errText = err.card_id
                    }
                    showInfoModal({
                        title: 'Error occurred',
                        props: {
                            message: errText,
                        },
                    })
                } finally {
                    setAdjustmentPending(false)
                }
            }
        },
        [item, revalidate],
    )

    const confirmUpdateAdjustmentStatus = (adjustment: any) => {
        const isDirectInvoice = item?.confirmation?.order
        if (adjustment.id) {
            const payMessage = isDirectInvoice
                ? ''
                : `and pay $${utils.convertNumberToDecimal(adjustment.price.total, 2)}`
            showConfirmModal({
                props: {
                    title: 'Confirm adjustment',
                    message: `Are you sure you want to confirm "${
                        consts.order.adjustments[adjustment.type]
                    }" ${payMessage}?`,
                    onConfirm: async (result) => {
                        if (result) {
                            await updateAdjustmentStatus(adjustment.id, true)
                        }
                    },
                },
            })
        }
    }

    const rejectUpdateAdjustmentStatus = useCallback(
        (adjustment: OrderAdjustment) => {
            if (item?.id) {
                showRejectCostAdjustmentModal({
                    props: {
                        orderId: item.id,
                        adjustment,
                        onComplete: (values) => updateAdjustmentStatus(adjustment.id, false, values.rejectReason),
                    },
                })
            }
        },
        [item, updateAdjustmentStatus],
    )

    const handleSignAdjustments = useCallback(async () => {
        setAdjustmentSignPending(true)
        const data = await prepareOrderAdjustmentConfirmation(item!.id)
        mutate({ ...data, confirmation: { ...data.confirmation, order: data } }, false)
        setAdjustmentSignPending(false)
    }, [item, mutate])

    const services = item?.services || []

    const orderService = services.length ? services[0] : null
    const rangeDays = useMemo(
        () => dayjs(orderService?.period_end).diff(dayjs(orderService?.period_start), 'days'),
        [orderService?.period_start, orderService?.period_end],
    )
    const dispatchDriver = () => {
        if (item?.id) {
            showDispatchDriverModal({
                props: {
                    orderId: item.id,
                    onClose: () => {
                        EventBus.bus.emit(Events.CLOSE_MODAL, Modals.DispatchDriverModal)
                    },
                },
            })
        }
    }

    const isMobile = useMobileWidth()
    const orderConfirmation = item?.confirmation?.order

    if (orderConfirmation && orderConfirmation.status !== 3) {
        return <Navigate to={`/carrier/order/${orderId}/confirmation/${orderConfirmation.id}`} />
    }

    const CLASS_NAME = 'carrier-order-item'

    const toSignAdjustments =
        orderConfirmation &&
        item?.adjustments.filter((adj) => adj.status.codeName === 'CONFIRMED' && !adj.confirmation_id)
    const onVerificationAdjustments =
        orderConfirmation && item?.adjustments.filter((adj) => adj.status.codeName === 'ON_VERIFICATION')
    const hasSomethingToSign = (onVerificationAdjustments?.length ?? 0) > 0 || (toSignAdjustments?.length ?? 0) > 0
    const readyToSign = hasSomethingToSign && (onVerificationAdjustments?.length ?? 0) === 0

    return (
        <div className={cn(CLASS_NAME, 'wrapper', 'page')}>
            {!item && <Loader type="order-loader" />}

            {item && (
                <div>
                    <Helmet>
                        <title>{`${PAGE_TITLE} #${orderId}`}</title>
                        <meta name="description" content={`${PAGE_TITLE} #${orderId}`} />
                    </Helmet>

                    <div>
                        <div className={cn(`${CLASS_NAME}__wrapper`, 'wrap')}>
                            <div className="section">
                                <div className={cn(`${CLASS_NAME}__header-wrapper`)}>
                                    <div className={cn(`${CLASS_NAME}__header`, 'header')}>
                                        <div className={cn(`${CLASS_NAME}__title`, 'title')}>Order Details</div>
                                        {/* TODO - prepare array of actions based on status and render both for mobile and desktop via .map(), also check that nothing displayed, when mobile and no actions */}

                                        <div className="btns">
                                            {item?.documents && item.documents.length > 0 && (
                                                <DropdownDocuments
                                                    list={item.documents}
                                                    type="right"
                                                    title={utils.pluralize(item.documents.length, 'File')}
                                                />
                                            )}
                                            <div className="actions">
                                                {/* <Link to={`/carrier/warehouse/${item.warehouse.id}/duplicate`} > */}
                                                {/* <Button types={['bordered', 'small']} label="Duplicate order" /> */}
                                                {/* </Link> */}
                                                {item.status.code === 6 && !item.rating && (
                                                    <Button
                                                        types={['bordered', 'small']}
                                                        onClick={openModalRating}
                                                        label="Evaluate warehouse"
                                                    />
                                                )}
                                                {itemAvailableStatus ? (
                                                    <Button
                                                        label={messages.actions[itemAvailableStatus.code].title}
                                                        types={messages.actions[itemAvailableStatus.code].btn}
                                                        onClick={confirm}
                                                    />
                                                ) : null}
                                                {item.status.code === 2 && (
                                                    <Button
                                                        label="Confirm payment"
                                                        types={['blue', 'small']}
                                                        onClick={payment}
                                                    />
                                                )}
                                                {item.status.code === 3 && (
                                                    <Button
                                                        label="Dispatch driver"
                                                        types={['blue', 'small']}
                                                        onClick={dispatchDriver}
                                                    />
                                                )}
                                            </div>

                                            <Dropdown
                                                type="right"
                                                className="header-dropdown more media-mobile"
                                                toggleElement={
                                                    <div className="toggle">
                                                        {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                                                        <a className="download">
                                                            <span className="icon-more">
                                                                <i />
                                                                <i />
                                                                <i />
                                                            </span>
                                                        </a>
                                                    </div>
                                                }
                                            >
                                                <>
                                                    <div className="arrow" />

                                                    {/* <div className="item"> */}
                                                    {/*    <Link to={`/carrier/warehouse/${item.warehouse.id}?dublicate`}> */}
                                                    {/*        Dublicate order */}
                                                    {/*    </Link> */}
                                                    {/* </div> */}

                                                    {item.status.code === 2 && (
                                                        // eslint-disable-next-line jsx-a11y/no-static-element-interactions
                                                        <div className="item" onClick={payment}>
                                                            Confirm payment
                                                        </div>
                                                    )}
                                                    {item.status.code === 3 && (
                                                        // eslint-disable-next-line jsx-a11y/no-static-element-interactions
                                                        <div className="item" onClick={dispatchDriver}>
                                                            Dispatch driver
                                                        </div>
                                                    )}

                                                    {itemAvailableStatus && (
                                                        // eslint-disable-next-line jsx-a11y/no-static-element-interactions
                                                        <div className="item" onClick={confirm}>
                                                            {messages.actions[itemAvailableStatus.code].title}
                                                        </div>
                                                    )}

                                                    {item.status.code === 6 && !item.rating && (
                                                        // eslint-disable-next-line jsx-a11y/no-static-element-interactions
                                                        <div
                                                            className="item"
                                                            onClick={() => setShowModalRating(!showModalRating)}
                                                        >
                                                            Evaluate warehouse
                                                        </div>
                                                    )}
                                                </>
                                            </Dropdown>
                                        </div>
                                    </div>

                                    <Status className={cn(`${CLASS_NAME}__status`)} data={item.status} type="large" />
                                    {item.status.hint.text && (
                                        <div className={cn(`${CLASS_NAME}__status-tip`)}>
                                            <span className="tip-text">{item.status.hint.text}</span>
                                            <Hint type="right" toggle={<span className="icon">?</span>}>
                                                {item.status.hint.description}
                                            </Hint>
                                        </div>
                                    )}
                                </div>

                                <div className="common-info">
                                    <div className="group">
                                        {item.parent_id && (
                                            <Link to={`/carrier/order/${item.parent_id}`}>
                                                <Button
                                                    label="Back to main order"
                                                    types={['inverse', 'small', 'bold']}
                                                />
                                            </Link>
                                        )}
                                        <div className="item">
                                            <div className="label">Order number</div>
                                            <div className="value">{item.id}</div>
                                        </div>
                                        {orderConfirmation && (
                                            <div className="item">
                                                <div className="label">Order confirmation</div>

                                                <Link
                                                    to={`/carrier/order/${item.id}/confirmation/${orderConfirmation.id}`}
                                                    style={{
                                                        display: 'flex',
                                                        alignItems: 'center',
                                                        justifyContent: 'flex-start',
                                                    }}
                                                >
                                                    <div
                                                        className={cn(
                                                            'order-confirmation_status',
                                                            CONFIRMATION_STATUSES[orderConfirmation.status].id,
                                                        )}
                                                    >
                                                        {CONFIRMATION_STATUSES[orderConfirmation.status].title}
                                                    </div>
                                                    <span
                                                        className="value"
                                                        style={{ marginLeft: '12px', display: 'inline' }}
                                                    >
                                                        {orderConfirmation.rc}
                                                    </span>
                                                </Link>
                                            </div>
                                        )}

                                        {!item.parent_id && (
                                            <div className="item">
                                                <div className="label">Warehouse Name</div>
                                                <div className="value">{item.company.entity}</div>
                                            </div>
                                        )}

                                        {!item.parent_id && (
                                            <div className="item">
                                                <div className="label">Company Group Name</div>
                                                <div className="value">{item.company.group_name}</div>
                                            </div>
                                        )}

                                        {item.warehouse.comment && (
                                            <div className="group">
                                                <div className="item">
                                                    <div className="label">Comment</div>
                                                    <div className="value">{item.warehouse.comment}</div>
                                                </div>
                                            </div>
                                        )}

                                        {item.arrival_from && (
                                            <div className="item">
                                                <div className="label">Arrival Date</div>
                                                <div className="value">
                                                    {getArrivalString(
                                                        item.services[0],
                                                        item.arrival_from,
                                                        item.arrival_to,
                                                    )}
                                                    <span className="time-offset">
                                                        {item.warehouse.utc_offset_title}
                                                    </span>
                                                </div>
                                            </div>
                                        )}

                                        {item.container_number && (
                                            <div className="item">
                                                <div className="label">Container #</div>
                                                <div className="value">{item.container_number}</div>
                                            </div>
                                        )}
                                        {item.load_number && (
                                            <div className="item">
                                                <div className="label">Load #</div>
                                                <div className="value">{item.load_number}</div>
                                            </div>
                                        )}

                                        {!item.parent_id && item.warehouse.physicalAddress && (
                                            <div className="item">
                                                <div className="label">Address</div>

                                                <div className="value address">
                                                    <LocationLink
                                                        id={item.id}
                                                        lat={item.warehouse.physicalAddress.lat}
                                                        lng={item.warehouse.physicalAddress.long}
                                                        address={item.warehouse.physicalAddress.address || ''}
                                                    />
                                                </div>
                                            </div>
                                        )}

                                        {item.documents?.length > 0 && (
                                            <div className="item">
                                                <div className="label">Photos / Documents</div>

                                                <div className="value">
                                                    <div className="photos-wrapper">
                                                        <PhotosCarousel
                                                            photos={item.documents}
                                                            slidesToShow={
                                                                isMobile ? 1 : Math.min(item.documents.length, 3)
                                                            }
                                                            slideProps={{
                                                                style: {
                                                                    height: isMobile ? 241 : 107,
                                                                },
                                                            }}
                                                            isSecured
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                </div>

                                <div className="group">
                                    {item.comment && (
                                        <div className="item">
                                            <div className="label">Order Comment</div>
                                            <div className="value comment">{item.comment}</div>
                                        </div>
                                    )}

                                    {item.rating && (
                                        <div className="item">
                                            <div className="label">My rating warehouse</div>

                                            <div className="value rating">
                                                <div className="groups">
                                                    {item.rating.details.map((r: any) => (
                                                        <RatingItem
                                                            type="small"
                                                            key={r.value}
                                                            value={r.value}
                                                            title={r.title}
                                                        />
                                                    ))}
                                                </div>

                                                <div className="rating-comment">
                                                    <div className="label">My rating comment</div>
                                                    <div className="value">{item.rating.comment || '-'}</div>
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>

                        <div className={cn(`${CLASS_NAME}__wrapper`, 'wrap')}>
                            <div className="section">
                                <div className="group services">
                                    <div className={cn(`${CLASS_NAME}__header`, 'header')}>
                                        <div className="title">Services</div>
                                    </div>
                                    <div className="item">
                                        <CarrierOrderServices item={item} />
                                    </div>
                                </div>
                            </div>
                        </div>
                        {item.adjustments && item.adjustments.length > 0 && (
                            <div className={cn(`${CLASS_NAME}__wrapper`, 'wrap')}>
                                <div className="section">
                                    <div className="group cost-adjustment">
                                        <div className={cn(`${CLASS_NAME}__header`, 'header')}>
                                            <div className="title">Cost Adjustment</div>
                                        </div>
                                        <div className="item">
                                            <div className="value services-info">
                                                <div className="service-list">
                                                    {item.adjustments.map((adjustment) => (
                                                        <OrderCostAdjustment
                                                            key={`adj_${adjustment.id}`}
                                                            adjustment={adjustment}
                                                            loading={adjustmentPending}
                                                            confirmed={confirmed}
                                                            editable
                                                            onConfirmClick={() =>
                                                                confirmUpdateAdjustmentStatus(adjustment)
                                                            }
                                                            onRejectClick={() =>
                                                                rejectUpdateAdjustmentStatus(adjustment)
                                                            }
                                                            className={'item'}
                                                        />
                                                    ))}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {item.adjustments &&
                                        item.adjustments.length > 0 &&
                                        orderConfirmation &&
                                        hasSomethingToSign && (
                                            <div className="group ca-confirmation">
                                                <div className="item">
                                                    <div className="value">
                                                        <div className="ca-sign">
                                                            <div
                                                                className={cn('ca-sign-info', {
                                                                    ready: readyToSign,
                                                                })}
                                                            >
                                                                {readyToSign
                                                                    ? 'Cost adjustments confirmed and ready to sign'
                                                                    : 'Confirm or reject cost adjustments listed above, then sign'}
                                                            </div>
                                                            <Button
                                                                types={['blue', 'full']}
                                                                disabled={!readyToSign || adjustmentSignPending}
                                                                loading={adjustmentSignPending}
                                                                onClick={handleSignAdjustments}
                                                            >
                                                                Sign cost adjustments
                                                            </Button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        )}
                                </div>
                            </div>
                        )}

                        <div className={cn(`${CLASS_NAME}__wrapper`, `${CLASS_NAME}__wrapper--prices`, 'wrap')}>
                            <div className="section">
                                <div className="prices">
                                    <div className="item payment-type">
                                        <div className="label">Payment type</div>
                                        <div className="value">
                                            {item.payment_strategy === 1 ? 'Card Payment' : 'Direct Invoicing'}
                                        </div>
                                    </div>

                                    {/* <div className="item">Payment fee: { item.payment_fee }</div> */}
                                    <div className="item total">
                                        <span>Total Cost&nbsp;</span>
                                        <Currency value={item.total} />
                                    </div>
                                </div>
                            </div>
                        </div>

                        {item?.manager && (
                            <div className={cn(`${CLASS_NAME}__wrapper`, 'wrap')}>
                                <div className="section">
                                    <div className="group">
                                        <div className={cn(`${CLASS_NAME}__header`, 'header')}>
                                            <div className="title">Warehouse manager contacts</div>
                                        </div>

                                        <div className="item manager-info">
                                            <div className="group">
                                                {item.manager.first_name && (
                                                    <div className="item">
                                                        <div className="label">Name</div>
                                                        <div className="value">
                                                            {item.manager.first_name} {item.manager.last_name}
                                                        </div>
                                                    </div>
                                                )}

                                                {item.manager.position && (
                                                    <div className="item">
                                                        <div className="label">Position</div>
                                                        <div className="value">{item.manager.position}</div>
                                                    </div>
                                                )}

                                                {item.manager.phone && (
                                                    <div className="item">
                                                        <div className="label">Phone number</div>
                                                        <div className="value">{item.manager.phone.number}</div>
                                                    </div>
                                                )}

                                                {item.manager.email && (
                                                    <div className="item">
                                                        <div className="label">E-mail</div>
                                                        <div className="value">{item.manager.email.email}</div>
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            )}
            {/*{item && (*/}
            {/*    <div*/}
            {/*        className={cn(CLASS_NAME, 'wrapper', 'wrap')}*/}
            {/*        style={{ minWidth: 350, maxWidth: 500, flexDirection: 'column' }}*/}
            {/*    >*/}
            {/*        <div className={cn(`${CLASS_NAME}__order-history-title`)}>Order History</div>*/}
            {/*        <StatusHistory />*/}
            {/*    </div>*/}
            {/*)}*/}
        </div>
    )
}
