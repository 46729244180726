import React, { HTMLProps } from 'react'
import cn from 'classnames'
import { ReactComponent as WarningIcon } from '@/assets/images/warning.svg'
import { ReactComponent as ClockIcon } from '@/assets/images/clock.svg'
import './Info.scss'

interface IProps extends HTMLProps<HTMLDivElement> {
    onClose?: () => void
    icon?: React.ReactChild
}

export const Info: React.FC<IProps> = ({ onClose, children, className, icon }) => (
    <div className={cn('info-wrapper', className)}>
        {className === 'warning' && (icon ?? <WarningIcon />)}
        {className === 'pending' && (icon ?? <ClockIcon />)}
        <div className="info-wrapper-container">{children}</div>
        {/* eslint-disable-next-line jsx-a11y/no-static-element-interactions */}
        <div className="close" onClick={onClose}>
            <span />
        </div>
    </div>
)
