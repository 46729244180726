import React, { FC, useEffect } from 'react'
import { postFetcher, Http, apiVersion } from '@shared/api/Http'
import { useParams, useNavigate } from 'react-router-dom'
import useSWR, { cache } from 'swr'
import InfoModal, { showInfoModal } from '@/parts/Modals/InfoModal'
import { useUser } from '@shared/api/user'
import { updateLoggedState, useUserState } from '@/store/user'
import { Loader } from '@/components/Loader'

export const ServiceEmailConfirm: FC = () => {
    const navigate = useNavigate()
    const { dispatch, state } = useUserState()
    const { mutate, loading, data: uInfo } = useUser()
    const { token } = useParams<{ type: string; token: string }>()
    const { data, error } = useSWR<any>(
        () => (token ? `/app/${apiVersion}/service/email-confirmation?token=${token}` : null),
        postFetcher,
    )

    const onClose = async () => {
        if (
            state.userInfo?.registration_step === undefined ||
            state.userInfo?.registration_step == null ||
            state.userInfo?.registration_step === 100
        ) {
            navigate(state.lastPathname || '/company')
        } else {
            navigate('/sign-up')
        }
    }

    useEffect(() => {
        if (data?.token) {
            const setup = async () => {
                if (!state.isLoggedIn) {
                    Http.defaults.headers.common['Auth-Token'] = data.token
                    localStorage.setItem('token', data.token)
                }
                cache.clear()

                await mutate(state?.userInfo, true)
                await updateLoggedState(uInfo!, dispatch)

                showInfoModal({
                    props: {
                        title: 'Your email has been confirmed',
                        message: '',
                        onClose,
                    },
                })
            }
            setup()
        }

        if (error) {
            showInfoModal({
                props: {
                    title: 'Link has already been used',
                    message: '',
                    onClose,
                },
            })
        }
    }, [data, error, uInfo])

    if (!data || loading) {
        return <Loader />
    }
    return <></>
}
