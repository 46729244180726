import React, { memo, useCallback } from 'react'
import { showLocationModal } from '@/parts/Modals/LocationModal'
import cn from 'classnames'
import './LocationLink.scss'

interface ILocationLink {
    id: number
    lat?: number
    lng?: number
    hint?: string
    address: string
    zip?: string
    maxZoom?: number
}

const LocationLink: React.FC<ILocationLink> = ({ lat, lng, hint, address, zip, maxZoom = 17 }) => {
    const open = useCallback(() => {
        if (lat && lng) {
            showLocationModal({
                title: address,
                props: {
                    lat,
                    lng,
                    maxZoom,
                },
            })
        }
    }, [lat, lng, address, maxZoom])

    return (
        <div className="location-link">
            {/* eslint-disable-next-line jsx-a11y/no-static-element-interactions */}
            <div className={cn('link-wrap', { empty: !lat || !lng, multyline: !hint })} onClick={open}>
                <span className="icon-pin" />
                <span className="address">{address}</span>
                {!!zip && <span className="zipcode">/ {zip}</span>}

                {hint && (
                    <span className="tooltip">
                        <span className="arrow" />
                        <span className="address">{address}</span>
                    </span>
                )}
            </div>
        </div>
    )
}
export default memo(LocationLink)
