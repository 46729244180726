import React from 'react'
import cn from 'classnames'
import './Rating.scss'
import { Hint } from '@/components/Hint'
import { RatingItem } from './Item'

interface IRatingProps {
    type: string
    details?: { value: number; title: string }[]
    average?: number
}

export const Rating: React.FC<IRatingProps> = ({ type, details, average }) => (
    <div className={cn('rating', type)}>
        <RatingItem value={average} type="type" />

        {details && (
            <Hint className="details" direction="right">
                <div className="groups">
                    {details?.map((r) => (
                        <RatingItem value={r.value} title={r.title} key={r.title} type={type}>
                            {r.title === 'Response' && (
                                <Hint>
                                    Response rating is based on ratio of rejected and confirmed orders:
                                    <br />
                                    <br />
                                    5 stars - amount of rejected orders is less than 5%
                                    <br />
                                    4 stars - amount of rejected orders is from 5 to 10%
                                    <br />
                                    3 stars - amount of rejected orders is from 10 to 20%
                                    <br />
                                    2 stars - amount of rejected orders is from 20 to 30%
                                    <br />
                                    1 stars - amount of rejected orders is more than 30%
                                    <br />
                                </Hint>
                            )}
                        </RatingItem>
                    ))}
                </div>
            </Hint>
        )}
    </div>
)
