import { CompanyState } from '@/store/company/company'

export function filled(state: CompanyState, checkTaxId = true): boolean {
    const info = state.companyInfo

    if (!info) {
        return false
    }

    return (
        !!info.entity &&
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        (!!info?.tax_id || !checkTaxId)
    )
}

export function moderated(state: CompanyState): boolean {
    return !!state.companyInfo && state.companyInfo.company_status?.title === 'ACTIVE'
}

export function banned(state: CompanyState): boolean {
    return !!state.companyInfo && state.companyInfo.company_status?.title === 'BANNED'
}

export function active(state: CompanyState): boolean {
    return !banned(state) && moderated(state)
}
