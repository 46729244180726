import React, { useEffect, useState } from 'react'
import ReactDOM from 'react-dom'
import './index.scss'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import { UserProvider } from '@/store/user'
import dayjs from 'dayjs'
import customParseFormat from 'dayjs/plugin/customParseFormat'
import utc from 'dayjs/plugin/utc'
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter'
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore'
import { EntityProvider } from '@/store/entity'
import { CompanyProvider } from '@/store/company'
import { App } from './App'
import reportWebVitals from './reportWebVitals'
import { Toast } from '@/components/Toast'
import { Loader } from '@/components/Loader'

import relativeTime from 'dayjs/plugin/relativeTime'
import timezone from 'dayjs/plugin/timezone'

dayjs.extend(relativeTime)
dayjs.extend(isSameOrAfter)
dayjs.extend(isSameOrBefore)

dayjs.extend(customParseFormat)
dayjs.extend(utc)
dayjs.extend(timezone)

const AdminAppRenderer = () => {
    const [loading, setLoading] = useState(true)
    const [Component, setComponent] = useState<React.FC>()

    useEffect(() => {
        async function load() {
            setComponent((await import('@/admin/AdminApp')).default)
            setLoading(false)
        }
        load()
    }, [])

    if (loading || !Component) {
        return <Loader />
    }

    return <Component />
}

ReactDOM.render(
    <React.StrictMode>
        <BrowserRouter>
            <Routes>
                <Route path={'/admin/*'} element={<AdminAppRenderer />} />
                <Route
                    path={'/*'}
                    element={
                        <UserProvider>
                            <CompanyProvider>
                                <EntityProvider>
                                    <App />
                                </EntityProvider>
                            </CompanyProvider>
                        </UserProvider>
                    }
                />
            </Routes>
        </BrowserRouter>
        <Toast />
    </React.StrictMode>,
    document.getElementById('app'),
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals(console.log)
