import * as Yup from 'yup'
import { IPaymentSettingsForm } from '@shared/types'

export const PaymentFormSchema = Yup.object().shape({
    billing_address: Yup.string(),
    routing_number: Yup.string().required('Required'),
    account_number: Yup.string().required('Required'),
})

export const PAYMENT_FORM_INITIAL_VALUES: IPaymentSettingsForm = {
    account_number: '',
    billing_address: '',
    routing_number: '',
    required_fields: [],
}
