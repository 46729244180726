import React, { FC } from 'react'
import { Navigate, Route, Routes } from 'react-router-dom'
import { LoggedInRoute } from '@/components/PrivateRoute/PrivateRoute'
import { useUserState } from '@/store/user'
import { UserInfo, UserType } from '@shared/api/types'
import { AdminRoute } from '@/components/AdminRoute/AdminRoute'
import { CompanyIndex, CompanyManagers } from './index'
import ROUTES, { DEPRECATED_ROUTES } from '@/router'

const checkAdmin = (info?: UserInfo): boolean => (info && info.type === UserType.admin) || false

export const CompanyRoutes: FC = () => {
    const {
        state: { isLoggedIn, userInfo },
    } = useUserState()

    return (
        <>
            <LoggedInRoute />
            <Routes>
                <Route
                    path="/managers"
                    element={
                        <AdminRoute
                            component={CompanyManagers}
                            isLoggedIn={isLoggedIn}
                            isAdmin={checkAdmin(userInfo)}
                        />
                    }
                />

                <Route path="/" element={<CompanyIndex />} />
                <Route path={DEPRECATED_ROUTES.company.settings} element={<Navigate to={ROUTES.user.settings} />} />
            </Routes>
        </>
    )
}
