import { useEffect, useMemo, useState } from 'react'
import { useCompanyEntities } from '@shared/api/company'
import { EntityType } from '@shared/api/types'
import { CarrierCommonInfo, WarehouseCommonInfo } from '@shared/types'
import { useUser } from '@shared/api/user'

type SelectedEntity = {
    entity: WarehouseCommonInfo | CarrierCommonInfo | null
    type: EntityType | null
}
interface IUseSelectedEntity {
    data: SelectedEntity | null
    loading: boolean
}

export const useSelectedEntity = (): IUseSelectedEntity => {
    const { data: userInfo, loading: userInfoLoading } = useUser()
    const { data: entities, loading: entitiesLoading } = useCompanyEntities()

    const [chosenEntity, setChosenEntity] = useState<SelectedEntity | null>(null)
    useEffect(() => {
        if (!entities || !userInfo) return
        const { carrier_id: carrierId, warehouse_id: warehouseId } = userInfo.session

        if (carrierId) {
            setChosenEntity({
                type: EntityType.carrier,
                entity: entities.carriers.find((el) => el.id === carrierId) as CarrierCommonInfo,
            })
            return
        }

        if (warehouseId) {
            setChosenEntity({
                type: EntityType.warehouse,
                entity: entities.warehouses.find((el) => el.id === warehouseId) as WarehouseCommonInfo,
            })
            return
        }

        setChosenEntity({ entity: null, type: null })
    }, [entities, userInfo])

    return {
        data: chosenEntity,
        loading: userInfoLoading || entitiesLoading || !chosenEntity,
    }
}
