import React, { FC, HTMLAttributes } from 'react'
import { Link } from 'react-router-dom'
import Button from '@/components/Button'
import { useCarrier } from '@shared/api/carrier'
import { active } from '@/parts/CarrierStatusInfo/helper'
import ROUTES from '@/router'
import { buildURI } from '@shared/utils/buildURI'

interface IMenu extends HTMLAttributes<HTMLDivElement> {
    closeMenu: () => void
}

export const CarrierMenu: FC<IMenu> = ({ closeMenu }) => {
    const { data: carrier } = useCarrier()

    if (!carrier) return null

    return (
        <>
            {active(carrier) && (
                <>
                    <Link to={'/carrier' + ROUTES.carrier.search}>
                        <Button types={['plain', 'bold']} onClick={() => closeMenu()}>
                            <span className="icon-plus" />
                            <span>New order</span>
                        </Button>
                    </Link>

                    <Link to={buildURI('/carrier' + ROUTES.carrier.ordersList, { pathParams: { filters: undefined } })}>
                        <Button label="Order List" types={['plain', 'bold']} onClick={() => closeMenu()} />
                    </Link>
                </>
            )}

            <Link to={'/carrier' + ROUTES.carrier.settings} className="item">
                <Button types={['plain', 'bold']} onClick={() => closeMenu()} label="Carrier information" />
            </Link>
        </>
    )
}
