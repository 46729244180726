import React, { FC, memo } from 'react'
import cn from 'classnames'
import dayjs from 'dayjs'
import { Rating } from '@/parts/Rating'
import { WarehouseForBooking } from '@shared/types'

import { PhotosView } from '@/parts/PhotosView'
import { getDocumentUrl } from '@/utils/documents'
import { useMobileWidth } from '@shared/common/hooks'
import { CarrierBookingSection } from '../Section'
import './CarrierWarehouseInfo.scss'

const CLASS_NAME = 'carrier-warehouse-info'

type Props = {
    warehouseInfo: WarehouseForBooking
    className?: string
}

// eslint-disable-next-line complexity
export const CarrierBookingWarehouseInfo: FC<Props> = memo(({ warehouseInfo, className }) => {
    const isMobileWidth = useMobileWidth()
    const photos = warehouseInfo.specials.filter((item) => item.type === 7)

    return (
        <CarrierBookingSection className={cn(CLASS_NAME, className)}>
            <CarrierBookingSection.Title>Warehouse Info</CarrierBookingSection.Title>
            <ul className={`${CLASS_NAME}__details-list`}>
                <li
                    style={{ maxWidth: '440px', overflow: 'hidden' }}
                    className={`${CLASS_NAME}__item ${CLASS_NAME}__item--photos`}
                >
                    {isMobileWidth ? (
                        <PhotosView photos={photos} autoplay autoplaySpeed={2000} />
                    ) : (
                        <PhotosView
                            photos={photos}
                            arrows
                            customPaging={(i) => (
                                // eslint-disable-next-line jsx-a11y/anchor-is-valid
                                <a href="" className="photo-thumb">
                                    {photos[i] && <img src={getDocumentUrl(photos[i].link)} alt={photos[i].name} />}
                                </a>
                            )}
                        />
                    )}
                </li>
                <li className={`${CLASS_NAME}__item`}>
                    <div className={`${CLASS_NAME}__label`}>Warehouse ID</div>
                    <div className={`${CLASS_NAME}__value`}>{warehouseInfo.id}</div>
                </li>

                <li className={`${CLASS_NAME}__item`}>
                    <div className={`${CLASS_NAME}__label`}>Warehouse Address</div>
                    <div className={`${CLASS_NAME}__value`}>{warehouseInfo.physical_address.address}</div>
                </li>

                {warehouseInfo.work_time_from && warehouseInfo.work_time_to && (
                    <li className={`${CLASS_NAME}__item`}>
                        <div className={`${CLASS_NAME}__label`}>Warehouse Operating Hours</div>

                        <div className={`${CLASS_NAME}__value`}>
                            {dayjs(warehouseInfo.work_time_from, 'HH:mm').format('hh:mm a')}
                            {' - '}
                            {dayjs(warehouseInfo.work_time_to, 'HH:mm').format('hh:mm a')}{' '}
                            <span className="time-offset">{warehouseInfo.utc_offset_title}</span>
                        </div>
                    </li>
                )}

                {warehouseInfo.rating && (
                    <li className={`${CLASS_NAME}__item`}>
                        <div className={`${CLASS_NAME}__label`}>Average rating</div>

                        <div className="rating-container">
                            <Rating
                                type="small"
                                average={warehouseInfo.rating.average}
                                details={warehouseInfo.rating.details}
                            />
                        </div>
                    </li>
                )}

                {warehouseInfo.min_order_price && (
                    <li className={`${CLASS_NAME}__item`}>
                        <div className={`${CLASS_NAME}__label`}>Minimum order price</div>

                        <div className="rating-container">
                            <div className={`${CLASS_NAME}__value`}>${warehouseInfo.min_order_price}</div>
                        </div>
                    </li>
                )}

                {warehouseInfo.area ? (
                    <li className={`${CLASS_NAME}__item`}>
                        <div className={`${CLASS_NAME}__label`}>Total Capacity</div>
                        <div className={`${CLASS_NAME}__value`}>
                            {warehouseInfo.area}Plt{' '}
                            {warehouseInfo.area_sq_feet ? ` / ${warehouseInfo.area_sq_feet}Sq.feet` : ''}
                        </div>
                    </li>
                ) : null}

                {warehouseInfo.refrigerator_area ? (
                    <li className={`${CLASS_NAME}__item`}>
                        <div className={`${CLASS_NAME}__label`}>Refrigerator Capacity</div>
                        <div className={`${CLASS_NAME}__value`}>
                            {warehouseInfo.refrigerator_area}Plt{' '}
                            {warehouseInfo.area_sq_feet ? ` / ${warehouseInfo.refrigerator_area_sq_feet}Sq.feet` : ''}
                        </div>
                    </li>
                ) : null}

                {warehouseInfo.gates_count ? (
                    <li className={`${CLASS_NAME}__item`}>
                        <div className={`${CLASS_NAME}__label`}>Number of Unloading Docks</div>
                        <div className={`${CLASS_NAME}__value`}>{warehouseInfo.gates_count}</div>
                    </li>
                ) : null}

                {warehouseInfo.conditions.length > 0 ? (
                    <li className={`${CLASS_NAME}__item`}>
                        <ul className={`${CLASS_NAME}__conditions`}>
                            {warehouseInfo.conditions.map((condition) => (
                                <li className={`${CLASS_NAME}__condition-item`} key={condition.code}>
                                    {condition.title}
                                </li>
                            ))}
                        </ul>
                    </li>
                ) : null}
            </ul>

            {Number(warehouseInfo.featureServices?.length) > 0 && (
                <div className="details features">
                    <div className="subtitle">Warehouse features</div>
                    {warehouseInfo.featureServices?.map((feature) => (
                        <div className={`${CLASS_NAME}__item ${CLASS_NAME}__item--feature`} key={feature.code}>
                            <div className={`${CLASS_NAME}__label`}>{feature.title}</div>
                        </div>
                    ))}
                </div>
            )}
        </CarrierBookingSection>
    )
})

CarrierBookingWarehouseInfo.displayName = 'CarrierBookingWarehouseInfo'
