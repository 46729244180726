import React, { FC, useCallback, useState } from 'react'
import cn from 'classnames'
import './PublicList.scss'
import { useNavigate, useParams } from 'react-router-dom'
import { IWarehouseSearchForm, IWarehouseRequestMode } from '@shared/types'
import { useWarehouseSearch } from '@shared/api/search'
import OrderList from '@/components/OrdersList'
import { utils } from '@shared/common/utils'
import { PageRequest } from '@shared/types/page'
import { OnBookInfo } from '@/pages/Carrier/CarrierWarehouseSearch/CarrierWarehouseTableLine/CarrierWarehouseTableLine'
import { showBookNoRegisterModal } from '@/parts/Modals/BookNoRegisterModal'

export const PublicList: FC = () => {
    const navigate = useNavigate()
    const [queryData, updateQueryData] = useState<IWarehouseSearchForm>()
    const [pageData, updatePageData] = useState<PageRequest>({
        'per-page': 10,
        page: 1,
        sort: 'distance',
    })

    const { filters } = useParams<{ filters?: string }>()
    const filtersDecoded = utils.decodeRouteFilters(filters || '')
    const [mode, updateMode] = useState<IWarehouseRequestMode>(filtersDecoded.mode || 'list')
    const [quotationRequestLoading, setQuotationRequestLoading] = useState<boolean>(false)
    const [quotationRequest, setQuotationRequest] = useState<boolean>(false)
    const [showForm, setShowForm] = useState<boolean>(true)
    const [circle, setCircle] = useState({
        radius: 0,
        long: 0,
        lat: 0,
    })

    const {
        markers,
        warehousesData,
        data: searchData,
        isLoading,
    } = useWarehouseSearch({
        ...pageData,
        form: queryData,
        mode,
        publicQuery: true,
    })

    const totalFound = searchData.provider?.total

    const handleModeUpdate = (m: IWarehouseRequestMode) => {
        updateMode(m)
        navigate(`/search/warehouses/${utils.encodeRouteFilters({ ...filtersDecoded, mode: m }) || ''}`)
    }

    const handleTableUpdate = useCallback(
        ({ sort, page }) => {
            updatePageData({
                ...pageData,
                sort,
                page,
            })
        },
        [pageData],
    )

    const handleBook = useCallback(
        (info: OnBookInfo) => {
            const { id, distance } = info
            const decoded = utils.decodeRouteFilters(filters || '')
            const decodedFilters = {
                ...decoded,
                distance,
            }
            showBookNoRegisterModal({
                props: {
                    distance,
                    filters: utils.encodeRouteFilters(decodedFilters)!,
                    warehouseId: id,
                },
            })
        },
        [filters],
    )

    const orderSettings = {
        searchPage: '/search/warehouses',
        mode,
        isLoading,
        circle,
        quotationRequestLoading,
        quotationRequest,
        publicQuery: true,
    }
    const orderData = {
        pageData,
        filters,
        warehousesData,
        markers,
        searchData,
        totalFound,
    }
    const orderMethods = {
        updatePageData,
        updateQueryData,
        handleModeUpdate,
        handleBook,
        handleTableUpdate,
    }
    return (
        <div className="warehouses-list wrapper">
            <div>
                <div className="page-header">
                    <div className="page-title">Warehouse search</div>
                    {/* eslint-disable-next-line jsx-a11y/no-static-element-interactions */}
                    <div
                        className={cn('icon-arrow', 'media-mobile', { opened: showForm })}
                        onClick={() => setShowForm(!showForm)}
                    />
                </div>
                <OrderList settings={orderSettings} data={orderData} methods={orderMethods} />
            </div>
        </div>
    )
}
