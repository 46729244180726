export type IPhotoLike = { link?: string | null; url?: string; name?: string; is_main?: boolean; title?: string }

export const getMainPhotoIdx = (photos: IPhotoLike[]): number => {
    const idx = photos.findIndex((el) => el.is_main)
    if (idx === -1) return DEFAULT_MAIN_PHOTO_IDX
    return idx
}

export const getPhotoUrl = (photo: IPhotoLike): string => (photo.link || photo.url) as string

export const DEFAULT_MAIN_PHOTO_IDX = 0

export const isImage = (photo: IPhotoLike): boolean => /\.(jpg|jpeg|png|gif)$/i.test(getPhotoUrl(photo))
