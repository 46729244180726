import React, { ChangeEvent, memo, useCallback } from 'react'
import cn from 'classnames'
import { Loader } from '@/components/Loader'
import './Radio.scss'

type RadioItem = { name: string; value: string | number }

interface IRadio {
    label?: string
    type: string
    name: string
    loading?: boolean
    list: RadioItem[]
    value: number | string
    onChange: (e: ChangeEvent<HTMLElement>) => void
}

export const Radio: React.FC<IRadio> = memo(({ type, label, name, list, value, onChange, loading }) => {
    const handleRadioChange = useCallback(
        (val: string | number) => {
            const event: any = { target: { name, value: val } }
            onChange(event)
        },
        [onChange, name],
    )

    const renderRadioItem = useCallback<(i: RadioItem) => React.ReactNode>(
        ({ name: n, value: val }) => (
            <div
                role="button"
                tabIndex={-1}
                className={cn('item', { active: val === value })}
                key={val}
                onClick={(e) => handleRadioChange(val)}
            >
                <div className="control" />
                <div className="label">{n}</div>
            </div>
        ),
        [value, handleRadioChange],
    )

    return (
        <div className={cn('radio', type)}>
            {label && <div className="title">{label}</div>}
            {loading && <Loader type="small radio-loader" />}
            {!loading && <div className="list">{list.map(renderRadioItem)}</div>}
        </div>
    )
})

Radio.displayName = 'Radio'
