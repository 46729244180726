import { useFormik } from 'formik'
import { apiVersion, Http } from '@shared/api/Http'
import * as Yup from 'yup'
import { UserInfo } from '@shared/api/types'
import { useState } from 'react'
import { FormikSharedConfig } from 'formik/dist/types'

interface ManagerForm {
    id?: number
    first_name: string
    last_name: string
    email: string
    phone: string
    position: string
    type: string
}

const ManagerFormSchema = Yup.object().shape({
    first_name: Yup.string().required().min(2, 'Too short! Min. 2 characters'),
    last_name: Yup.string().required().min(2, 'Too short! Min. 2 characters'),
    email: Yup.string().email('Invalid email').required('Required'),
    phone: Yup.string(),
    position: Yup.string().required().min(3),
    type: Yup.string().oneOf(['admin', 'manager']).required(),
})

type UseManagerFormProps = {
    userInfo?: UserInfo
    onClose?: (response: UserInfo) => void
    validationSchema?: any | (() => any)
    validationConfig?: Partial<FormikSharedConfig>
}

export const useManagerForm = ({ userInfo, onClose, validationSchema, validationConfig }: UseManagerFormProps) => {
    const phone = userInfo && userInfo.phone?.number ? userInfo.phone.number.replace('+1', '') : ''
    const formik = useFormik<ManagerForm>({
        initialValues: {
            id: userInfo ? userInfo.id : undefined,
            first_name: userInfo ? userInfo.first_name : '',
            last_name: userInfo ? userInfo.last_name : '',
            email: userInfo ? userInfo.email?.email || '' : '',
            phone,
            position: userInfo?.position ?? '',
            type: userInfo ? userInfo.type : '',
        },
        ...validationConfig,
        validationSchema: validationSchema || ManagerFormSchema,
        onSubmit: async (values) => {
            try {
                const copy = { ...values }
                copy.phone = copy.phone.startsWith('+1') ? copy.phone : `+1${copy.phone}`
                let response
                if (values.id) {
                    response = await Http.post<UserInfo, UserInfo>(`/app/${apiVersion}/manager/update`, copy)
                } else {
                    response = await Http.post<UserInfo, UserInfo>(`/app/${apiVersion}/manager/new`, copy)
                }

                formik.setSubmitting(false)
                onClose?.(response)
            } catch (e: any) {
                formik.setSubmitting(false)
                formik.setErrors(e)
            }
        },
    })
    return formik
}
