import React from 'react'
import { SignUpTemplate } from '@/pages/SignUp/components/SignUpTemplate'
import Button from '@/components/Button'
import { useUserState } from '@/store/user'
import { ReactComponent as CrossIcon } from '@/assets/images/cross-new.svg'
import { ReactComponent as TickIcon } from '@/assets/images/tick-new.svg'
import { UserActionType } from '@/store/user/user'

export const SignUpWarehouseLocalDeliveryStep: React.FC = () => {
    const { state: userState, dispatch } = useUserState()
    const answer = (localDeliveryNeeded: boolean) => {
        dispatch?.({ type: UserActionType.SIGN_UP_LOCAL_DELIVERY_ANSWER, answer: localDeliveryNeeded })
    }

    return (
        <SignUpTemplate
            title="Do you provide local delivery service?"
            description="Up to 75 mile"
            className="delivery"
            step={[3, 4]}
        >
            <div className="sign-up_warehouse-local-delivery">
                <div className="button-block">
                    <Button
                        onClick={() => answer(true)}
                        label={
                            <span>
                                <TickIcon /> Yes
                            </span>
                        }
                        type="button"
                        types={['blue', 'full']}
                    />
                    <Button
                        onClick={() => answer(false)}
                        label={
                            <span>
                                <CrossIcon /> No
                            </span>
                        }
                        type="button"
                        types={['blue', 'full']}
                    />
                </div>
            </div>
        </SignUpTemplate>
    )
}
