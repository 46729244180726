import React from 'react'
import cn from 'classnames'
import { EntityStatus as Status } from '@/store/entity/entity'

import './EntityStatus.scss'

const CLASS_NAME = 'entity-status'

interface IProps {
    status: Status
    id?: number
    dot?: boolean
}
export const EntityStatus: React.FC<IProps> = ({ status, id, dot }) => (
    <span
        className={cn(CLASS_NAME, {
            [`${CLASS_NAME}--${status}`]: status,
            [`${CLASS_NAME}--dotted`]: dot || typeof id !== 'number',
        })}
    >
        {typeof id === 'number' ? `id ${id}` : null}
    </span>
)
