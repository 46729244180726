import React from 'react'
import { GeoLocation } from '@shared/types'
import { UserInfo, UserType } from '@shared/api/types'

export enum UserActionType {
    SET_LOGGED_STATE = 'SET_LOGGED_STATE',
    LOGOUT = 'LOGOUT',
    UPDATE_GEO = 'UPDATE_GEO',
    UPDATE_LAST_PATH = 'UPDATE_LAST_PATH',
    SIGN_UP_VERIFY_LATER = 'SIGN_UP_VERIFY_LATER',
    SIGN_UP_LOCAL_DELIVERY_ANSWER = 'SIGN_UP_LOCAL_DELIVERY_ANSWER',
    SIGN_UP_CHOOSE_ENTITY_TYPE = 'SIGN_UP_CHOOSE_ENTITY_TYPE',
}

export type UserAction =
    | { type: UserActionType.SET_LOGGED_STATE; userInfo?: UserInfo }
    | { type: UserActionType.UPDATE_GEO; geolocation?: GeoLocation }
    | { type: UserActionType.SIGN_UP_VERIFY_LATER }
    | { type: UserActionType.SIGN_UP_LOCAL_DELIVERY_ANSWER; answer: boolean }
    | { type: UserActionType.SIGN_UP_CHOOSE_ENTITY_TYPE; entityType: 'c' | 'w' }
    | { type: UserActionType.UPDATE_LAST_PATH; pathname: string }
    | { type: UserActionType.LOGOUT }

export type SignUpInfo = {
    verificationSkipped?: boolean
    localDeliverySkipped?: boolean
    chosenEntityType?: 'w' | 'c'
}

export interface UserState {
    userType?: UserType
    isLoggedIn: boolean
    userInfo?: UserInfo
    geolocation?: GeoLocation
    signUp: SignUpInfo
    lastPathname?: string
    pathname?: string
}

export interface UserStore {
    state: UserState
    dispatch?: React.Dispatch<UserAction>
}
