import React, { ReactNode, useState } from 'react'
import './Collapsable.scss'

interface ICollapsable {
    title?: ReactNode
    isOpen?: boolean
    children?: ReactNode
}

export const Collapsable: React.FC<ICollapsable> = ({ title = '', isOpen = true, children }) => {
    const [open, setOpen] = useState<boolean>(isOpen)
    return (
        <div className="collapse-box">
            <button
                onClick={(e) => {
                    e.preventDefault()
                    setOpen(!open)
                }}
            >
                {title}
                <span className="collapse-state">{open ? 'Hide' : 'Show'}</span>
            </button>
            {open && <div className="collapse-content">{children}</div>}
        </div>
    )
}
