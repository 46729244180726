import React, { memo } from 'react'
import cn from 'classnames'
import { Link } from 'react-router-dom'
import { Status } from '@/components/Status'
import Icon from '@/components/Icon/Icon'
import Button from '@/components/Button'
import { Order } from '@shared/types'
import { utils } from '@shared/common/utils'
import Currency from '@/components/Currency'
import dayjs from 'dayjs'

interface TableLineWarehouseOrdersProps {
    order: Order
}

const TableLineWarehouseOrders: React.FC<TableLineWarehouseOrdersProps> = ({ order }) => {
    const hasPrev = order.prev_status && order.prev_status.code !== -1
    return (
        <div className="row table-line-warehouse-order">
            <div className="cell cell-id">
                <div className="label">PO №</div>
                <span>{order.id}</span>
            </div>

            <div className="cell cell-date">
                <div className="label">Date</div>
                <span>{dayjs(order.period_start).format('MM-DD-YYYY')}</span>
            </div>

            <div className={cn('cell', 'cell-status', { 'has-prev': hasPrev })}>
                <div className="label">Status</div>

                <div className="status-wrap">
                    {hasPrev && (
                        <div className="prev-status">
                            <span className="icon-arrow-round" />
                            <span className="icon-arrow-long" />
                            <span>{order.prev_status.title}</span>
                        </div>
                    )}

                    <Status data={order.status} />
                </div>
            </div>

            <div className="cell cell-services">
                <div className="label">Services</div>

                <div className="icons">
                    {order.services.map((service) => (
                        <div className="icon-service" key={service.title}>
                            <Icon code={service.icon || utils.getDefaultServiceIcon(service.title)} type="services" />

                            <span className="tooltip">
                                <span className="arrow" />
                                {service.title}
                            </span>
                        </div>
                    ))}
                </div>
            </div>

            <div className="cell cell-amount">
                <div className="label">Units</div>
                <span>
                    {order.amount} {order.unit.title}
                </span>
            </div>

            <div className="cell cell-days">
                <div className="label">Storage</div>
                <span>{order.number_of_days}</span>
            </div>

            <div className="cell cell-contacts">
                <div className="label">Contacts</div>

                <div className="contacts">
                    <span className="contacts-icon">i</span>

                    <div className="tooltip">
                        <div className="arrow" />

                        {order && (
                            <div className="contacts-list">
                                {order.carrier.company?.entity && (
                                    <div className="item">
                                        <span className="icon icon-user" />
                                        <span className="value">
                                            {order.carrier.company.group_name} {order.carrier.company.entity}
                                        </span>
                                    </div>
                                )}

                                {order.carrier.license && (
                                    <div className="item">
                                        <span className="icon icon-license" />
                                        <span className="value">{order.carrier.license.number}</span>
                                    </div>
                                )}
                            </div>
                        )}
                    </div>
                </div>
            </div>

            <div className="cell cell-total">
                <div className="label">Total Cost</div>
                <span>
                    <Currency value={order.total} />
                </span>
            </div>

            <div className="cell cell-button">
                <Link to={`/warehouse/order/${order.id}`}>
                    <Button label="Details" types={['inverse', 'small']} />
                </Link>
            </div>
        </div>
    )
}

export default memo(TableLineWarehouseOrders)
