import React, { ChangeEventHandler, useCallback, useRef } from 'react'
import cn from 'classnames'
import { ReactComponent as UploadIcon } from '@/assets/images/icon-upload.svg'
import { Loader } from '@/components/Loader'
import Button from '@/components/Button'
import './UploadButton.scss'

interface IUploadButtonProps {
    name: string
    label?: string
    loading?: boolean
    disabled?: boolean
    acceptMimes?: string
    multiple?: boolean
    onFileChange: (fileList: FileList | null) => void
    className?: string
    title?: string
}

const CLASS_NAME = 'upload-button'

const UploadButton: React.FC<IUploadButtonProps> = ({
    name,
    acceptMimes,
    multiple,
    loading,
    label,
    disabled,
    onFileChange,
    className,
    title,
}) => {
    const inputFile = useRef<HTMLInputElement>(null)
    const triggerUpload = () => inputFile?.current?.click()
    const handleFileChange: ChangeEventHandler<HTMLInputElement> = useCallback(
        (e) => {
            onFileChange?.(e.target.files)
        },
        [onFileChange],
    )
    return (
        <div className={cn(className, CLASS_NAME)}>
            <input
                name={name}
                type="file"
                ref={inputFile}
                multiple={multiple}
                onChange={handleFileChange}
                accept={acceptMimes}
                hidden
                title={title}
            />
            <Button className={`${CLASS_NAME}__btn`} types={['full']} onClick={triggerUpload} disabled={disabled}>
                {loading ? <Loader type="small" /> : <UploadIcon />}
                {label || 'Upload'}
            </Button>
        </div>
    )
}

export { UploadButton }
