import React, { FC, useEffect, useState } from 'react'
import { SignUpTemplate } from '@/pages/SignUp/components/SignUpTemplate'
import { InputText } from '@/components/InputText'
import Button from '@/components/Button'
import { useManagerForm } from '@/parts/Modals/ManagerModal/useManagerForm'
import { updateLoggedState, useUserState } from '@/store/user'
import { useNavigate } from 'react-router'
import * as Yup from 'yup'
import { useOneTimeSessionData } from '@/hooks/useOneTimeSessionData'

export const phoneRegExp =
    /^((\+[1-9]{1,4}[\s-]*)|(\([0-9]{2,3}\)[\s-]*)|([0-9]{2,4})[\s-]*)*?[0-9]{3,4}?[\s-]*[0-9]{3,4}?$/

const ManagerDetailsStepFormSchema = Yup.object().shape({
    first_name: Yup.string().required('Required').min(2, 'Too short! Min. 2 characters'),
    last_name: Yup.string().required('Required').min(2, 'Too short! Min. 2 characters'),
    email: Yup.string().email('Invalid email').required('Required'),
    phone: Yup.string().required('Required').matches(phoneRegExp, 'Phone number is not valid'),
    position: Yup.string().min(3).optional().nullable(),
    type: Yup.string().oneOf(['admin', 'manager']).required(),
})

export const ManagerDetailsStep: FC = () => {
    const [validateOnChange, setValidateOnChange] = useState(false)
    const navigate = useNavigate()
    const { consume } = useOneTimeSessionData('signupValues')
    const { state: userState, dispatch } = useUserState()
    const formik = useManagerForm({
        validationSchema: ManagerDetailsStepFormSchema,
        validationConfig: {
            validateOnChange,
            validateOnMount: false,
            isInitialValid: true,
        },
        userInfo: userState.userInfo,
        onClose: async (userInfo) => {
            await updateLoggedState(userInfo, dispatch)
            navigate('/sign-up/company')
        },
    })

    useEffect(() => {
        formik.setFieldValue('phone', consume('phone'))
        formik.setFieldValue('first_name', consume('firstName'))
        formik.setFieldValue('last_name', consume('lastName'))
    }, [])

    const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        setValidateOnChange(true)
        e.preventDefault()
        formik.handleSubmit(e)
    }

    return (
        <SignUpTemplate title="Introduce yourself" progress={userState?.signUp?.chosenEntityType ? 200 / 5 : 200 / 6}>
            <form onSubmit={handleSubmit}>
                <InputText
                    autoFocus
                    text
                    label="First name"
                    name="first_name"
                    onChange={formik.handleChange}
                    value={formik.values.first_name}
                    error={formik.errors.first_name}
                    errorOnFocus
                    errorHide={false}
                    textError
                />
                <InputText
                    text
                    name="last_name"
                    onChange={formik.handleChange}
                    value={formik.values.last_name}
                    error={formik.errors.last_name}
                    errorOnFocus
                    errorHide={false}
                    textError
                    label="Last name"
                />
                <InputText
                    name="phone"
                    onChange={formik.handleChange}
                    value={formik.values.phone}
                    error={formik.errors.phone}
                    errorOnFocus
                    errorHide={false}
                    textError
                    label="Phone"
                    format="+1 (###) ### ####"
                />
                <InputText
                    text
                    name="position"
                    onChange={formik.handleChange}
                    value={formik.values.position}
                    error={formik.errors.position}
                    label="Position"
                />
                <br />
                <Button
                    label="Next"
                    type="submit"
                    disabled={!formik.isValid}
                    loading={formik.isSubmitting}
                    types={['blue', 'full']}
                />
            </form>
        </SignUpTemplate>
    )
}
