import { ICarrierSettingsForm } from '@shared/types'
import * as Yup from 'yup'

export const form = {
    entity: {
        value: '',
        label: 'Carrier name',
    },
    license: {
        value: '',
        label: 'USDOT or MC/MX#',
        validator: 'validators.licenseOptional',
    },
    physical_address: {
        value: '',
        label: 'Location address',
        validator: { required: true },
    },
    legal_address: {
        value: '',
        label: 'Mailing address',
    },
    billing_address: {
        value: '',
        label: 'Billing address',
    },
    default_card_id: {
        value: '',
    },
    tracks_number: {
        value: '',
        label: 'Truck Quantity',
        validator: { numeric: true, min_value: 0, max_value: 10000 },
    },

    legalSamePhysical: false,
    billingSamePhysical: false,
    pending: false,
}

export const CarrierSettingsSchema = Yup.object().shape({
    entity: Yup.string().required('Entity is required'),
    physical_address: Yup.string(),
    legal_address: Yup.string(),
    billing_address: Yup.string(),
    default_card_id: Yup.string(),
    tracks_number: Yup.number().min(0).max(10000),
    legalSamePhysical: Yup.boolean(),
    billingSamePhysical: Yup.boolean(),
})

export const CARRIER_SETTINGS_INITIAL_VALUES: ICarrierSettingsForm = {
    entity: '',
    license: '',
    physical_address: '',
    legal_address: '',
    billing_address: '',
    default_card_id: 0,
    legalSamePhysical: false,
    billingSamePhysical: false,
    tracks_number: 0,
}
