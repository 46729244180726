import React, { FC, useCallback, useState } from 'react'
import cn from 'classnames'
import { Helmet } from 'react-helmet'
import { useNavigate } from 'react-router-dom'
import { utils } from '@shared/common/utils'
import FormOrderSearch, { useFilters } from '@/parts/Forms/FormOrderSearch/FormOrderSearch'
import TableLineWarehouseOrders from '@/parts/Table/TableLineWarehouseOrders'
import { TableList } from '@/parts/Table/TableList'
import { useWarehouseOrders } from '@shared/api/warehouse'
import { Order } from '@shared/types'
import { mobile } from '@shared/common/mobile'

import './WarehouseOrderList.scss'

const WAREHOUSE_TABLE_HEADERS = [
    { name: 'PO №', sort: 'id', alias: 'id' },
    { name: 'Date', sort: 'period_start', alias: 'date' },
    { name: 'Status', alias: 'status' },
    { name: 'Services', alias: 'services' },
    { name: 'Units', sort: 'amount', alias: 'amount' },
    { name: 'Storage', sort: 'number_of_days', alias: 'days' },
    { name: 'Contacts', alias: 'contacts' },
    { name: 'Total Cost', sort: 'total', alias: 'total' },
    { name: '', alias: 'button' },
]

const PER_PAGE = 20
const PAGE_TITLE = 'Olimp - Orders'

export const WarehouseOrderList: FC = () => {
    const navigate = useNavigate()
    const filters = useFilters()
    const [showForm, setShowForm] = useState<boolean>(!mobile.phone)

    const [page, setPage] = useState(1)
    const [sort, setSort] = useState('-period_start')

    const { data, loading, revalidate } = useWarehouseOrders({ ...filters, 'per-page': PER_PAGE, page, sort })

    const handleFiltersUpdate = useCallback(
        (f) => {
            navigate(`/warehouse/orders/${utils.encodeRouteFilters(f)}`)
        },
        [navigate],
    )

    const toggleFormVisibility = useCallback(() => {
        setShowForm(!showForm)
    }, [showForm])

    return (
        <div className="orders-list wrapper">
            <Helmet>
                <title>{PAGE_TITLE}</title>
                <meta name="description" content={PAGE_TITLE} />
            </Helmet>
            <div>
                <div className="page-header-wrapper">
                    <div className="page-header">
                        <div className="page-title">Order List</div>
                        {/* eslint-disable-next-line jsx-a11y/no-static-element-interactions */}
                        <div
                            className={cn('icon-filter', 'media-mobile', { opened: showForm })}
                            onClick={toggleFormVisibility}
                        />
                    </div>
                    {showForm && (
                        <FormOrderSearch onUpdate={handleFiltersUpdate} filters={filters} onRefresh={revalidate} />
                    )}
                </div>

                <div className="list">
                    <TableList
                        headers={WAREHOUSE_TABLE_HEADERS}
                        data={data?.items ?? []}
                        sort={sort}
                        pending={loading}
                        page={data?.page}
                        pageCount={data?.pageCount}
                        onUpdate={(p) => {
                            setSort(p.sort)
                            setPage(p.page)
                        }}
                    >
                        {data?.items &&
                            data?.items.length > 0 &&
                            (data?.items ?? []).map((item: Order) => (
                                <TableLineWarehouseOrders key={item.id} order={item} />
                            ))}
                    </TableList>
                </div>
            </div>
        </div>
    )
}
