import React from 'react'
import { SignUpTemplate } from '@/pages/SignUp/components/SignUpTemplate'
import { useFormik } from 'formik'
import { InputText } from '@/components/InputText'
import Button from '@/components/Button'
import { UserActionType } from '@/store/user/user'
import { updateLoggedState, useUserState } from '@/store/user'
import * as Yup from 'yup'
import { apiVersion, Http } from '@shared/api/Http'
import { EntityToken, Login } from '@shared/api/types'
import { cache } from 'swr'
import './SignUpWarehouseStep.scss'

interface SignUpWarehouseStepProps {}

interface SignUpWarehouseValues {
    entity: string
}

const NewWarehouseFormSchema = Yup.object().shape({
    entity: Yup.string().required('required').min(3),
})

export const SignUpWarehouseStep: React.FC<SignUpWarehouseStepProps> = () => {
    const { state: userState, dispatch } = useUserState()
    const chooseCarrier = (e: any) => {
        e.preventDefault()
        dispatch?.({ type: UserActionType.SIGN_UP_CHOOSE_ENTITY_TYPE, entityType: 'c' })
    }
    const formik = useFormik<SignUpWarehouseValues>({
        onSubmit: async (values) => {
            try {
                const warehouse = await Http.post<SignUpWarehouseValues, { id: number }>(
                    `/warehouse/${apiVersion}/warehouse/new`,
                    values,
                )
                const response = await Http.get<{ type: string; id: string }, EntityToken>(
                    `/app/${apiVersion}/select/warehouse/${warehouse.id}`,
                )
                cache.clear()

                Http.defaults.headers.common['Auth-Token'] = response.token
                localStorage.setItem('token', response.token)
                localStorage.setItem('type', response.type)
                formik.setSubmitting(false)

                await updateLoggedState(
                    {
                        ...userState.userInfo!,
                        registration_step: 20,
                    },
                    dispatch,
                )
            } catch (e: any) {
                formik.setSubmitting(false)
                formik.setErrors(e)
            }
        },
        initialValues: {
            entity: '',
        },
        validationSchema: NewWarehouseFormSchema,
    })
    return (
        <SignUpTemplate progress={100} title="Add a Warehouse">
            <div className="sign-up_warehouse">
                <form onSubmit={formik.handleSubmit}>
                    <InputText
                        text
                        label="Warehouse name"
                        name="entity"
                        placeholder="Warehouse name"
                        onChange={formik.handleChange}
                        value={formik.values.entity}
                        error={formik.errors.entity}
                        errorHide={false}
                        textError
                    />

                    <br />
                    <Button
                        label="Add"
                        type="submit"
                        disabled={!formik.isValid}
                        loading={formik.isSubmitting}
                        types={['blue', 'full']}
                    />
                    <br />
                    <small
                        style={{
                            display: 'flex',
                            justifyContent: 'center',
                            width: '100%',
                            marginTop: 0,
                            marginBottom: 6,
                        }}
                    >
                        or
                    </small>

                    {/* eslint-disable-next-line jsx-a11y/anchor-is-valid,jsx-a11y/no-static-element-interactions */}
                    <a className="link" onClick={chooseCarrier}>
                        <small style={{ color: 'rgba(9, 21, 38, 0.5)' }}>Add a carrier </small>
                    </a>
                </form>
            </div>
        </SignUpTemplate>
    )
}
