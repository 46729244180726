import React, { FC } from 'react'
import { Routes, Navigate, Route } from 'react-router-dom'
import { WarehouseCalendar, WarehouseOrderItem, WarehouseOrderList } from './index'
import { WarehouseSettings } from './WarehouseSettings/WarehouseSettings'
import { SelectWarehouseBeforeOrderItem } from '@/pages/Warehouse/SelectWarehouseBeforeOrderItem/SelectWarehouseBeforeOrderItem'
import ROUTES from '@/router'
import { getFullEntityStatus } from '@/utils/status'
import { useWarehouse } from '@shared/api/warehouse'
import { Loader } from '@/components/Loader'
import { EntityType } from '@shared/api/types'
import { useCheckEntityPath } from '@/hooks/useCheckEntityPath'
import { LoggedInRoute } from '@/components/PrivateRoute/PrivateRoute'

export const WarehouseRoutes: FC = () => {
    const checkResult = useCheckEntityPath(EntityType.warehouse)
    const warehouse = useWarehouse(checkResult.isCorrectPath)

    if (warehouse.loading || !warehouse.data || checkResult.loading) {
        return <Loader />
    }

    const status = getFullEntityStatus(warehouse.data!)
    const defaultRoute = status === 'active' ? ROUTES.warehouse.calendar : ROUTES.warehouse.settings.base

    return (
        <>
            <LoggedInRoute />
            <Routes>
                <Route path={ROUTES.warehouse.calendar} element={<WarehouseCalendar />} />
                <Route path={ROUTES.warehouse.ordersList} element={<WarehouseOrderList />} />
                <Route path={ROUTES.warehouse.ordersListNoFilters} element={<WarehouseOrderList />} />
                <Route path={ROUTES.warehouse.order} element={<WarehouseOrderItem />} />
                <Route path={ROUTES.warehouse.selectBeforeOrder} element={<SelectWarehouseBeforeOrderItem />} />
                <Route path={'/settings/*'} element={<WarehouseSettings />} />
                <Route path="/" element={<Navigate to={`${ROUTES.warehouse.base}${defaultRoute}`} />} />
            </Routes>
        </>
    )
}
