import React, { FC } from 'react'
import { Routes, Navigate, Route } from 'react-router-dom'
import { ServiceEmailConfirm, ServicePasswordChange } from './index'
import ROUTES from '@/router'

export const ServiceRoutes: FC = () => (
    <Routes>
        <Route path={ROUTES.service.emailConfirm} element={<ServiceEmailConfirm />} />
        <Route path={ROUTES.service.passwordChange} element={<ServicePasswordChange />} />
        <Route element={<Navigate to="/service/logout" />} />
    </Routes>
)
