import React from 'react'
import { SignUpTemplate } from '@/pages/SignUp/components/SignUpTemplate'
import Button from '@/components/Button'
import { useUserState } from '@/store/user'
import './SignUpWarehouseStep.scss'
import { useNavigate } from 'react-router'

export const SignUpWarehouseDoneStep: React.FC = () => {
    const navigate = useNavigate()
    const { state: userState, dispatch } = useUserState()
    return (
        <SignUpTemplate
            progress={100}
            title="Great! Your Warehouse account is currently being verified by OLIMP."
            extra="You will receive an email when your warehouse is activated. Add more information about your warehouse"
        >
            <div className="sign-up_warehouse_done">
                <br />
                <Button
                    label="Go to warehouse settings"
                    type="button"
                    types={['blue', 'full']}
                    onClick={() => navigate('/warehouse/settings')}
                />
            </div>
        </SignUpTemplate>
    )
}
