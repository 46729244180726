import React, { FC, memo } from 'react'
import * as Yup from 'yup'
import { ModalRegistry } from '@shared/common/modals'
import { ModalPartialProps, Modals } from '@shared/types'
import Button from '@/components/Button'
import { InputText } from '@/components/InputText'
import { useFormik } from 'formik'
import './NewCarrierModal.scss'
import { apiVersion, Http } from '@shared/api/Http'
import { Login } from '@shared/api/types'

interface NewCarrierForm {
    entity: string
    license: string
}

const NewCarrierFormSchema = Yup.object().shape({
    entity: Yup.string().required().min(3),
    license: Yup.string().required().min(5),
})

interface NewCarrierModalProps {
    onClose?: (redirectUrl?: string) => void
}

const NewCarrierModal: FC<NewCarrierModalProps> = ({ onClose }) => {
    const formik = useFormik<NewCarrierForm>({
        initialValues: {
            entity: '',
            license: '',
        },
        validationSchema: NewCarrierFormSchema,
        onSubmit: async (values) => {
            try {
                const response = await Http.post<{ email: string; password: string }, Login>(
                    `/carrier/${apiVersion}/carrier/new`,
                    values,
                )
                formik.setSubmitting(false)

                onClose?.('/company')
            } catch (e: any) {
                formik.setSubmitting(false)
                formik.setErrors(e)
            }
        },
    })

    return (
        <div className="modal-info">
            <form onSubmit={formik.handleSubmit}>
                <div className="form">
                    <div className="form-company">
                        <div className="section">
                            <div className="form-wrap">
                                <div className="field">
                                    <InputText
                                        text
                                        label="Carrier Name"
                                        name="entity"
                                        onChange={formik.handleChange}
                                        value={formik.values.entity}
                                        error={formik.errors.entity}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="section">
                            <div className="form-wrap">
                                <div className="field">
                                    <InputText
                                        text
                                        name="license"
                                        onChange={formik.handleChange}
                                        value={formik.values.license}
                                        error={formik.errors.license}
                                        label="USDOT or MC/MX#"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="btns">
                    <Button
                        label="Add"
                        type="submit"
                        loading={formik.isSubmitting}
                        types={['blue', 'full']}
                        disabled={!formik.isValid || !formik.dirty}
                    />
                </div>
            </form>
        </div>
    )
}

ModalRegistry.get().register<NewCarrierModalProps>(Modals.NewCarrierModal, {
    id: 'CarrierModalProps',
    className: 'new-modal-carrier',
    size: 'large',
    Component: memo(NewCarrierModal),
    title: 'Add a new Carrier',
})

export const showNewCarrierModal = (props: ModalPartialProps<NewCarrierModalProps>): void =>
    ModalRegistry.get().show<NewCarrierModalProps>(Modals.NewCarrierModal, props)

export default memo(NewCarrierModal)
