import React from 'react'
import { ReactComponent as IconArrowPrev } from 'images/ic-arrow-prev.svg'
import cn from 'classnames'
import { Loader } from '@/components/Loader'
import { userLogout, useUserState } from '@/store/user'
import { useNavigate } from 'react-router-dom'
import { ReactComponent as CrossIcon } from '@/assets/images/cross-new.svg'
import './SignUpTemplate.scss'

interface SignUpTemplateProps {
    title?: string
    description?: string | React.ReactChild
    extra?: string
    className?: string
    progress?: number
    step?: number[]
    loading?: boolean
    refLogo?: string
    refDescription?: string
    onBack?: () => void
}

// eslint-disable-next-line complexity
const CompSignUpTemplate: React.FC<SignUpTemplateProps> = ({
    children,
    progress,
    extra,
    step,
    className,
    description,
    title,
    loading,
    refLogo,
    refDescription,
    onBack,
}) => {
    const navigate = useNavigate()
    const { dispatch, state } = useUserState()
    const rLogo = refLogo || localStorage.getItem('refLogo')
    console.log(refDescription)
    let landingLink
    if (window.location.origin === 'https://olimpwarehousing.com:4343') {
        landingLink = 'https://olimpfreight.com/'
    } else if (window.location.origin === 'https://stage.olimpfreight.com:4343') {
        landingLink = 'https://stage.olimpfreight.com/'
    } else if (window.location.origin === 'https://demo.olimpwarehousing.com') {
        landingLink = '/'
    } else {
        landingLink = 'https://dev.olimpfreight.com/'
    }
    return (
        <div className={cn('olimp-sign-up', className)}>
            <div className="sign-up_header">
                {onBack && (
                    <div className="sign-up_back" onClick={onBack} role="button" tabIndex={-1}>
                        <IconArrowPrev />
                    </div>
                )}
                <div className="logos" style={{ justifyContent: rLogo ? 'space-between' : 'center' }}>
                    <img src="/static/email-logo.jpg" width={60} alt="logo" />
                    {rLogo && <img src={rLogo} width={60} alt="logo" />}
                </div>
                {progress && (
                    <div className="progress">
                        <div className="progress-fill" style={{ width: `${progress || 0}%` }} />
                    </div>
                )}
                {step && (
                    <div className="step">
                        Step {step[0]}/{step[1]}
                    </div>
                )}
                {refDescription && (
                    <div className="referral-description" dangerouslySetInnerHTML={{ __html: refDescription }} />
                )}
            </div>
            {!loading && (
                <div className="sign-up_description">
                    {title && <h2 className="sign-up_heading">{title}</h2>}
                    {description && <p>{description}</p>}
                    {extra && <p className="extra">{extra}</p>}
                </div>
            )}
            <div className="sign-up_form"> {loading ? <Loader type="small" /> : children}</div>
            <div className="bottom-footer" style={{ marginTop: 24 }}>
                {/* eslint-disable-next-line jsx-a11y/anchor-is-valid,jsx-a11y/no-static-element-interactions */}
                <a href={landingLink} className="secondary-link">
                    Go to home page
                </a>

                {state.userInfo && (
                    <small>
                        {/* eslint-disable-next-line jsx-a11y/anchor-is-valid,jsx-a11y/no-static-element-interactions */}
                        <a
                            onClick={() => {
                                userLogout(dispatch!)
                            }}
                            className="secondary-link"
                        >
                            <CrossIcon />
                            Logout
                        </a>
                    </small>
                )}
            </div>
        </div>
    )
}

export const SignUpTemplate = CompSignUpTemplate
