import React, { FC, memo, useCallback, useState } from 'react'
import { ModalRegistry } from '@shared/common/modals'
import { ModalPartialProps, Modals } from '@shared/types'
import Currency from '@/components/Currency'
import Button from '@/components/Button'
import dayjs from 'dayjs'
import { utils } from '@shared/common/utils'
import './ConfirmOrderModal.scss'

interface ConfirmOrderModalProps {
    data: any
    minDaysForLTW: number
    onConfirm?: (status: boolean) => Promise<void> | void
}

const ConfirmOrderModal: FC<ConfirmOrderModalProps> = ({ data, minDaysForLTW, onConfirm }) => {
    const [pending, setPending] = useState(false)

    const handleCancel = useCallback(() => {
        ModalRegistry.get().close(Modals.ConfirmOrderModal)
        if (onConfirm) {
            onConfirm(false)
        }
    }, [onConfirm])

    const handleConfirm = useCallback(async () => {
        if (onConfirm) {
            setPending(true)
            await onConfirm(true)
            setPending(false)
        }
        ModalRegistry.get().close(Modals.ConfirmOrderModal)
    }, [onConfirm])

    const endValue = data.period_start !== data.period_end ? ` - ${dayjs(data.period_end).format('MM-DD-YYYY')}` : ''
    const value = `${dayjs(data.period_start).format('MM-DD-YYYY')}${endValue}`
    const daysCount = data.period_end ? dayjs(data.period_end).diff(dayjs(data.period_start), 'days') : 1
    const title = daysCount < minDaysForLTW ? 'Send request and make a payment?' : 'Send Long Term Warehousing request?'
    return (
        <>
            {daysCount < minDaysForLTW && (
                <div className="description">If the warehouse cancels the request, the money will be returned.</div>
            )}

            <div className="info-list">
                <div className="item address full">
                    <div className="label">Address</div>
                    <div className="value">{data.warehouse.physical_address.address}</div>
                </div>

                <div className="item amount">
                    <div className="label">Units ({data.unit === 2 ? 'Sq.Feet' : 'Plt'})</div>
                    <div className="value">{data.amount}</div>
                </div>

                <div className="item date">
                    <div className="label">Date</div>

                    <div className="value">
                        {value}
                        {daysCount > 1 && <div className="days-count">{utils.pluralize(daysCount, 'day')}</div>}
                    </div>
                </div>

                {data.distribution_address && (
                    <div className="item address full">
                        <div className="label">Distribution place</div>
                        <div className="value">{data.distribution_address}</div>
                    </div>
                )}

                <div className="item total full">
                    <div className="label">Total Cost</div>
                    <div className="value">
                        <Currency value={data.total} />
                    </div>
                </div>

                <div className="btns">
                    <Button label="No" types={['plain']} onClick={handleCancel} disabled={pending} />
                    <Button label="Yes" loading={pending} types={['blue']} onClick={handleConfirm} />
                </div>
            </div>
        </>
    )
}

ModalRegistry.get().register<ConfirmOrderModalProps>(Modals.ConfirmOrderModal, {
    id: 'ConfirmOrderModalProps',
    className: 'modal-confirm-order',
    size: 'medium',
    Component: memo(ConfirmOrderModal),
    title: 'Confirm order',
})

export const showConfirmOrderModal = (props: ModalPartialProps<ConfirmOrderModalProps>): void =>
    ModalRegistry.get().show<ConfirmOrderModalProps>(Modals.ConfirmOrderModal, props)

export default memo(ConfirmOrderModal)
