import React from 'react'
import './DropdownDocuments.scss'
import Dropdown from '@/components/Dropdown'
import { download, getUrl } from '@shared/api/files'
import { OrderDocument } from '@shared/types'
import { useToggle } from 'react-use'
import cn from 'classnames'
import { EditableDocument } from '../LoadedDocument'
import { ReactComponent as DocIcon } from '@/assets/images/ic-doc.svg'

interface IDropdownDocumentsProps {
    title?: string
    list: OrderDocument[]
    type?: 'select' | 'right' | 'left'
    withIcon?: boolean
}

const CLASS_NAME = 'dropdown-documents'

export const DropdownDocuments: React.FC<IDropdownDocumentsProps> = ({ title = 'Files', type, list, withIcon }) => {
    const [open, toggle] = useToggle(false)
    return (
        <Dropdown
            type={type}
            className={CLASS_NAME}
            open={open}
            onToggle={toggle}
            toggleElement={
                <div className={`${CLASS_NAME}__toggle`}>
                    {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                    <a className={`${CLASS_NAME}__download`}>
                        {withIcon && <DocIcon />}
                        <span className={`${CLASS_NAME}__label`}>{title}</span>
                        <span className={cn('icon-arrow', { reversed: open })} />
                    </a>
                </div>
            }
        >
            {list.map((doc) => (
                <EditableDocument
                    key={doc.title}
                    className={`${CLASS_NAME}__link`}
                    url={getUrl(doc)}
                    filename={doc.title}
                />
            ))}
        </Dropdown>
    )
}
