import React, { FC, memo, useMemo } from 'react'
import Select from '@/components/Select'
import dayjs from 'dayjs'
import { ISelectItem } from '@/components/Select/SelectItem'
import './SelectTime.scss'

interface ISelectTime {
    label?: string
    name: string
    value?: number | string
    min?: string
    max?: string
    nameFormat?: string
    error?: string
    textError?: boolean
    disabled?: boolean
    errorHide?: boolean
    onAction: ISelectItem['onAction']
}

const valueFormat = 'HH:mm'

export const validatePeriod = (from: string, to: string) =>
    Math.abs(dayjs(to, valueFormat).diff(dayjs(from, valueFormat))) > 0

const SelectTime: FC<ISelectTime> = ({
    min = '00:00',
    max = '23:00',
    name,
    nameFormat = 'hh:mm a',
    errorHide = false,
    textError = true,
    disabled,
    label,
    value,
    onAction,
    error,
}) => {
    const items = useMemo(() => {
        const result: any[] = []
        let current = dayjs(min, valueFormat)
        let m = dayjs(max, valueFormat)

        if (m.isSame(current) || m.isBefore(current)) {
            m = m.add(1, 'day')
        }

        while (current.isSame(m) || current.isBefore(m)) {
            result.push({
                value: current.format(valueFormat),
                name: current.format(nameFormat),
            })

            const diff = m.diff(current, 'minute')

            if (!current.clone().startOf('hour').isSame(current)) {
                current = current.add(1, 'hour').startOf('hour')
            } else if (diff < 60 && diff > 0) {
                current = current.add(diff, 'minute')
            } else {
                current = current.add(1, 'hour')
            }
        }

        return result
    }, [nameFormat, min, max])
    return (
        <Select
            items={items}
            name={name}
            value={value}
            disabled={disabled}
            label={label}
            onAction={onAction}
            max={6}
            className="select-time"
            error={error}
            errorHide={errorHide}
        />
    )
}

export default memo(SelectTime)
