import React from 'react'
import { ToastContainer, cssTransition } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import './Toast.scss'

// https://github.com/fkhadra/react-toastify/issues/873 elements not removed from DOM
// const Transition = cssTransition({
//     enter: 'animated slideInUp',
//     exit: 'animated fadeOutDown',
// })

const Toast = () => <ToastContainer position="bottom-center" theme="dark" hideProgressBar autoClose={2000} limit={3} />

export { Toast }
