import React, { FC, memo, useState } from 'react'
import { ModalRegistry } from '@shared/common/modals'
import { ModalPartialProps, Modals, OrderAdjustment } from '@shared/types'
import Button from '@/components/Button'
import Textarea from '@/components/Textarea'
import './RejectCostAdjustmentModal.scss'
import * as Yup from 'yup'
import { useFormik } from 'formik'
import classNames from 'classnames'
import { OrderCostAdjustment } from '@/parts/OrderCostAdjustment'

const RejectCostAdjustmentFormSchema = Yup.object().shape({
    rejectReason: Yup.string()
        .label('Reason for rejection')
        .test('len', 'Must be no more than 255 characters', (val) => val === undefined || (!!val && val.length <= 255))
        .nullable(),
})

type FormValues = {
    rejectReason?: string
}

interface RejectCostAdjustmentModalProps {
    adjustment: OrderAdjustment
    orderId: number
    onComplete?: (values: FormValues) => void
    onError?: (errors?: any) => void
}

const RejectCostAdjustmentModal: FC<RejectCostAdjustmentModalProps> = ({
    orderId,
    adjustment,
    onComplete,
    onError,
}) => {
    const formik = useFormik<FormValues>({
        initialValues: {
            rejectReason: '',
        },
        validationSchema: RejectCostAdjustmentFormSchema,
        enableReinitialize: true,
        onSubmit: async (values) => {
            try {
                onComplete?.(values)
                ModalRegistry.get().close(Modals.RejectCostAdjustmentModal)
            } catch (errors) {
                onError?.(errors)
            }
        },
    })

    const CLASS_NAME = 'reject-cost-adjustment'
    return (
        <form onSubmit={formik.handleSubmit} className={classNames(CLASS_NAME, 'form')}>
            <div className={`${CLASS_NAME}__field`}>
                <OrderCostAdjustment adjustment={adjustment} className={`${CLASS_NAME}__adjustment`} />
            </div>
            <div className={`${CLASS_NAME}__field`}>
                <Textarea
                    label="Reason for rejection"
                    value={formik.values.rejectReason}
                    error={formik.errors.rejectReason}
                    onChange={formik.handleChange}
                    name="rejectReason"
                />
            </div>
            <div className={`${CLASS_NAME}__btns`}>
                <Button
                    label="Cancel"
                    types={['plain', 'large']}
                    onClick={() => {
                        ModalRegistry.get().close(Modals.RejectCostAdjustmentModal)
                    }}
                />
                <Button
                    label="Reject"
                    loading={formik.isSubmitting}
                    disabled={!formik.isValid}
                    types={['red', 'large']}
                    type="submit"
                />
            </div>
        </form>
    )
}

ModalRegistry.get().register<RejectCostAdjustmentModalProps>(Modals.RejectCostAdjustmentModal, {
    id: 'RejectCostAdjustmentModalProps',
    className: 'modal-reject-cost-adjustment',
    size: 'small',
    Component: memo(RejectCostAdjustmentModal),
    title: 'Reject adjustment',
})

export const showRejectCostAdjustmentModal = (props: ModalPartialProps<RejectCostAdjustmentModalProps>): void =>
    ModalRegistry.get().show<RejectCostAdjustmentModalProps>(Modals.RejectCostAdjustmentModal, props)

export default memo(RejectCostAdjustmentModal)
