import React, { FC, useMemo, useState } from 'react'
import { ReactComponent as PlusIcon } from '@/assets/images/icon-plus.svg'
import cn from 'classnames'
import { Helmet } from 'react-helmet'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { toast } from 'react-toastify'
import { Link, useParams } from 'react-router-dom'
import { Status } from '@/components/Status'
import LocationLink from '@/components/LocationLink'
import Icon from '@/components/Icon/Icon'
import { Hint } from '@/components/Hint'
import { DropdownDocuments } from '@/components/DropdownDocuments'
import { Loader } from '@/components/Loader'
import { consts, SERVICE_ICON_MAP } from '@shared/common/consts'
import { uploadWarehouseOrderDocument, useWarehouse, useWarehouseOrder } from '@shared/api/warehouse'
import { utils } from '@shared/common/utils'
import { useUserState } from '@/store/user'
import { UserType } from '@shared/api/types'
import { handleWarehouseOrderSubmit } from '@shared/api/order'
import { showCostAdjustmentModal } from '@/parts/Modals/CostAdjustmentModal'
import { OrderAdjustment } from '@shared/types'
import Button from '@/components/Button'
import Select from '@/components/Select'
import Currency from '@/components/Currency'
import { useEnv } from '@shared/api/user'
import dayjs from 'dayjs'
import { PhotosCarousel } from '@/parts/PhotosView'
import { useMobileWidth } from '@shared/common/hooks'
import { getArrivalString } from '@shared/common/order'
import { WarehouseOrderRequirements } from '@/pages/Warehouse/WarehouseOrderItem/WarehouseOrderRequirements'
import { OrderCostAdjustment } from '@/parts/OrderCostAdjustment'
import { UploadButton } from '@/components/UploadButton'
import { FixedElement } from '@/components/FixedElement'
import { transformBackendErrorToString } from '@/parts/Modals/InfoModal'
import { DistanceInfo } from '@/parts/DistanceInfo/DistanceInfo'

import './WarehouseOrderItem.scss'

interface IWarehouseOrderForm {
    type: UserType
    status: number
    id: number
}

const WarehouseOrderFormSchema = Yup.object().shape({
    type: Yup.string().required(),
    status: Yup.number().required(),
    id: Yup.number().required(),
})

const PAGE_TITLE = 'Olimp - Order'

// eslint-disable-next-line complexity
export const WarehouseOrderItem: FC = () => {
    const [saving, setSaving] = useState(false)
    const { orderId } = useParams<{ orderId: string }>()
    const {
        state: { userInfo, userType },
    } = useUserState()
    const { data: environment } = useEnv()
    const { data: warehouse } = useWarehouse()
    const { data: item, mutate, revalidate, loading } = useWarehouseOrder(orderId!)
    const formik = useFormik<IWarehouseOrderForm>({
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        initialValues: { status: item?.status.code, id: Number(orderId), type: userType! },
        validationSchema: WarehouseOrderFormSchema,
        enableReinitialize: true,
        onSubmit: async (values) => {
            if (item && environment && userInfo) {
                try {
                    const updatedOrder = await handleWarehouseOrderSubmit({
                        item,
                        warehouse,
                        env: environment,
                        daysCount: dayjs(item.services[0].period_end).diff(dayjs(item.services[0].period_start)),
                        statusCode: values.status,
                    })
                    formik.setSubmitting(false)
                    toast(`Status set to “${statuses.find((el) => el.value === values.status)?.name}”`, {
                        type: 'success',
                    })
                    mutate(updatedOrder)
                } catch (e: any) {
                    formik.setSubmitting(false)
                    const errorMessage = transformBackendErrorToString(e)

                    if (errorMessage) {
                        toast(errorMessage, { type: 'error' })
                    } else {
                        formik.setErrors(e)
                    }
                }
            }
        },
    })

    const hasRequirements = Number(item?.order_settings?.length) > 0
    const [requirementsComplete, setRequirementsComplete] = useState(false)

    const isMobile = useMobileWidth()
    const formControlsRef = React.useRef(null)

    const daysCount = useMemo(
        () => (item ? dayjs(item?.services[0]?.period_end).diff(dayjs(item?.services[0]?.period_start), 'days') : 0),
        [item?.services[0]?.period_start, item?.services[0]?.period_end],
    )

    if (loading || !item) {
        return <Loader />
    }

    const statuses = [...item.available_statuses, item.status].map((it) => ({
        name: it.title,
        value: it.code,
    }))

    const { services = [], adjustments = [] } = item
    const openAdjustment = (ad: OrderAdjustment) => {
        showCostAdjustmentModal({
            props: {
                element: ad,
                orderId: item.id,
                editable: ad.status.codeName === 'ON_VERIFICATION',
                onUpdate: () => revalidate(),
            },
        })
    }

    const uploadBill = async (files: FileList | null) => {
        if (!files?.length) return
        setSaving(true)
        const response = await uploadWarehouseOrderDocument(item.id, files)
        mutate(response)
        setSaving(false)
        toast('Bill of landing was successfully uploaded!', { type: 'success' })
    }

    const timeleft = () => {
        if (item.period_start && item.status.code === 1) {
            // moment().to(moment().add(this.item.expires_in, 'seconds'), true) + ' left'
            return 0
        }

        return null
    }

    const hasAvailableStatus = item.available_statuses.length > 0

    const orderBooked = item.status?.full_code === 'BOOKED'
    const orderDropped = item.status?.full_code === 'DROPPED'
    const canBeDone = hasAvailableStatus && item.available_statuses.find((status) => status.full_code === 'DONE')

    const CLASS_NAME = 'warehouse-order-item'

    const hasBillOfLading = item.documents.some((d) => d.type === 4)
    const billOfLadingBlock = item.allow_upload && (orderBooked || orderDropped) && (
        <>
            <div className="field upload-order">
                {hasBillOfLading && (
                    <p>
                        Bill of lading is uploaded and available from <b>Files</b> dropdown.
                    </p>
                )}
                <UploadButton
                    name="document"
                    acceptMimes="application/pdf"
                    loading={saving}
                    title="Order PDF"
                    onFileChange={uploadBill}
                    label={
                        item.subOrders.length > 0 && environment?.BOLdescription
                            ? environment.BOLdescription
                            : `${hasBillOfLading ? 'Re-upload' : 'Upload'} your bill of lading`
                    }
                />
            </div>
        </>
    )

    const periodEndDay = item.services[0].period_end && dayjs(item.services[0].period_end)

    const statusHasBeenChanged = formik.values.status !== item.status.code
    const loadRepairPhotos = item.documents.filter(
        (i) => [consts.order.documentType.loadRepairPhoto].indexOf(i.type) >= 0,
    )
    return (
        <div className={cn(CLASS_NAME, 'wrapper', 'page')}>
            <Helmet>
                <title>{`${PAGE_TITLE} #${orderId}`}</title>
                <meta name="description" content={`${PAGE_TITLE} #${orderId}`} />
            </Helmet>
            <div>
                <div className={cn(`${CLASS_NAME}__wrapper`, 'wrap')} v-if="item">
                    <div className="section">
                        <div className={cn(`${CLASS_NAME}__header-wrapper`)}>
                            <div className={cn(`${CLASS_NAME}__header`, 'header')}>
                                <div className={cn(`${CLASS_NAME}__title`, 'title')}>Order Details</div>
                                <div className="btns">
                                    {!!timeleft() && (
                                        <div className="timeleft">
                                            <div className="timeleft-title">{timeleft()}</div>
                                            <div className="description">before cancellation by timing</div>
                                        </div>
                                    )}

                                    {item.documents && item.documents.length > 0 && (
                                        <DropdownDocuments
                                            list={item.documents}
                                            type="right"
                                            title={utils.pluralize(item.documents.length, 'File')}
                                        />
                                    )}
                                </div>
                            </div>
                            <Status className={cn(`${CLASS_NAME}__status`)} data={item.status} type="large" />
                        </div>

                        <div className="common-info">
                            <div className="group">
                                {item.parent_id && (
                                    <Link to={`/warehouse/order/${item.parent_id}`}>
                                        <Button label="Back to main order" types={['inverse', 'small', 'bold']} />
                                    </Link>
                                )}
                                <div className="item">
                                    <div className="label">Order number</div>
                                    <div className="value">{item.id}</div>
                                </div>

                                <div className="item">
                                    <div className="label">Arrival Date & time</div>

                                    <div className="value">
                                        {item.arrival_from && (
                                            <span>
                                                {getArrivalString(item.services[0], item.arrival_from, item.arrival_to)}
                                            </span>
                                        )}
                                    </div>
                                </div>

                                {item.container_number && (
                                    <div className="item">
                                        <div className="label">Container #</div>
                                        <div className="value">{item.container_number}</div>
                                    </div>
                                )}
                                {item.load_number && (
                                    <div className="item">
                                        <div className="label">Load #</div>
                                        <div className="value">{item.load_number}</div>
                                    </div>
                                )}

                                {item.documents && item.documents.length > 0 && (
                                    <div className="item">
                                        <div className="label">Photos / Documents</div>

                                        <div className="value">
                                            <div className="photos-wrapper">
                                                <PhotosCarousel
                                                    photos={loadRepairPhotos}
                                                    slidesToShow={isMobile ? 1 : Math.min(loadRepairPhotos.length, 3)}
                                                    slideProps={{
                                                        style: {
                                                            height: isMobile ? 241 : 107,
                                                        },
                                                    }}
                                                    isSecured
                                                />
                                            </div>
                                        </div>
                                    </div>
                                )}

                                {item.comment && (
                                    <div className="group">
                                        <div className="item">
                                            <div className="label">Order Comment</div>
                                            <div className="value comment">{item.comment}</div>
                                        </div>
                                    </div>
                                )}
                            </div>
                            <hr />
                            {(adjustments.length > 0 || orderBooked || orderDropped) && (
                                <div className="group cost-adjustment">
                                    <div className="item">
                                        <div className="label">
                                            <span>Cost adjustments</span>
                                        </div>

                                        <div className="value services-info adjustment">
                                            {(orderBooked || orderDropped) && (
                                                <div className="cost-adjustment-controls">
                                                    <Button
                                                        className="cost-adjustment-button"
                                                        types={['plain', 'full']}
                                                        onClick={() =>
                                                            showCostAdjustmentModal({
                                                                props: {
                                                                    orderId: item.id,
                                                                    editable: true,
                                                                    onUpdate: () => {
                                                                        revalidate()
                                                                    },
                                                                },
                                                            })
                                                        }
                                                    >
                                                        <PlusIcon /> Add cost adjustment
                                                    </Button>
                                                </div>
                                            )}
                                            <div className="service-list">
                                                {adjustments.map((adjustment) => (
                                                    <OrderCostAdjustment
                                                        key={`adj_${adjustment.id}`}
                                                        adjustment={adjustment}
                                                        className="item"
                                                        onClick={() => openAdjustment(adjustment)}
                                                    />
                                                ))}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )}

                            <div className="group services">
                                <div className="item">
                                    <div className="label">Services</div>

                                    <div className="value services-info">
                                        <div className="service-list">
                                            {/* eslint-disable-next-line complexity */}
                                            {services.map((service) => (
                                                <div className="item" key={service.code}>
                                                    {(service.total > 0 || service.address) && (
                                                        <div className="info-wrap">
                                                            <div className="info">
                                                                <Icon
                                                                    code={
                                                                        service.icon || SERVICE_ICON_MAP[service.code]
                                                                    }
                                                                    type="services"
                                                                />
                                                                <span className="title">{service.title}</span>
                                                                {service.type_code === 'PICKUP' && (
                                                                    <span className="pickup-date">
                                                                        {service.period_start}
                                                                    </span>
                                                                )}
                                                                {item.subOrders.length > 0 &&
                                                                    !item.parent_id &&
                                                                    service.amount > 0 &&
                                                                    (service.type_code === 'DELIVERY' ||
                                                                        service.type_code === 'DELIVERY_STOP' ||
                                                                        service.type_code === 'PICKUP') && (
                                                                        <span className="amount">
                                                                            {service.amount}time(s)
                                                                        </span>
                                                                    )}
                                                                {item.parent_id && (
                                                                    <span className="amount">
                                                                        {service.amount}pallet(s)
                                                                    </span>
                                                                )}
                                                            </div>

                                                            <div className="value">
                                                                <Currency value={service.total} />
                                                            </div>
                                                        </div>
                                                    )}

                                                    {service.address && (
                                                        <>
                                                            <div className="location">
                                                                <LocationLink
                                                                    id={item.id}
                                                                    lat={service.address.lat}
                                                                    lng={service.address.long}
                                                                    address={service.address.address || ''}
                                                                />
                                                            </div>
                                                            <DistanceInfo
                                                                direct={service.address.distance}
                                                                driving={service.address.route_distance}
                                                            />
                                                        </>
                                                    )}
                                                </div>
                                            ))}
                                        </div>

                                        <div className="service-details">
                                            <div className="service-params">
                                                <div className="item">
                                                    <div className="label">Units</div>
                                                    <div className="value">
                                                        {services[0].amount} {item?.unit?.title || 'Plt'}
                                                    </div>
                                                </div>

                                                <div className="item">
                                                    <div className="label">Date</div>
                                                    <div className="value">
                                                        {dayjs(services[0]?.period_start).format('MM-DD-YYYY')}
                                                        {services[0].terms_related && (
                                                            <>
                                                                {' '}
                                                                - {dayjs(services[0]?.period_end).format('MM-DD-YYYY')}
                                                            </>
                                                        )}
                                                    </div>
                                                    {services?.[0].terms_related && (
                                                        <div className="days-count">{daysCount} day(s)</div>
                                                    )}
                                                </div>
                                            </div>
                                        </div>

                                        <div className="prices">
                                            {item.used_min_price && (
                                                <div className="item">
                                                    <div className="label">Min price</div>
                                                    <Currency value={item.min_price} />
                                                </div>
                                            )}
                                            <div className="item">
                                                Olimp fee:&nbsp;
                                                <Currency value={-item.service_fee} />
                                            </div>
                                            <div className="item total">
                                                <span>Total Cost&nbsp;</span>
                                                <Currency value={item.total} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <hr />

                            <form className="form-change-status form group" onSubmit={formik.handleSubmit}>
                                <div className="group">
                                    <div className="item status">
                                        <div className="label">Order status</div>
                                        <div className="value">
                                            <div className="main-line">
                                                {hasAvailableStatus ? (
                                                    <Select
                                                        items={statuses}
                                                        name="status"
                                                        value={formik.values.status}
                                                        disabled={formik.isSubmitting}
                                                        onAction={({ value }) => formik.setFieldValue('status', value)}
                                                    />
                                                ) : (
                                                    <Status data={item.status} type="large" />
                                                )}

                                                {item.status.hint && (
                                                    <div className="tip">
                                                        <span className="tip-text">{item.status.hint.text}</span>
                                                        {item.status.hint.description && (
                                                            <Hint className="details" direction="right">
                                                                {item.status.hint.description}
                                                            </Hint>
                                                        )}
                                                    </div>
                                                )}
                                            </div>

                                            {billOfLadingBlock}

                                            {orderDropped &&
                                                !canBeDone &&
                                                periodEndDay &&
                                                periodEndDay.isAfter(dayjs()) && (
                                                    <p>
                                                        You can switch status to <b>Done</b> {periodEndDay.fromNow()}
                                                    </p>
                                                )}
                                        </div>
                                    </div>
                                </div>

                                {hasRequirements && (
                                    <WarehouseOrderRequirements
                                        order={item}
                                        loading={loading}
                                        revalidate={revalidate}
                                        onChange={setRequirementsComplete}
                                    />
                                )}

                                {hasAvailableStatus && statusHasBeenChanged && (
                                    <div className={`${CLASS_NAME}__controls`} ref={formControlsRef}>
                                        {formControlsRef.current && (
                                            <FixedElement
                                                className="item"
                                                spyElement={formControlsRef.current}
                                                enabled={isMobile}
                                            >
                                                <div className="label" />
                                                <div className="value btns">
                                                    <Button
                                                        label="Cancel"
                                                        types={['plain', 'small']}
                                                        disabled={formik.isSubmitting}
                                                        onClick={() =>
                                                            formik.setFieldValue('status', item?.status.code)
                                                        }
                                                    />
                                                    <Button
                                                        loading={formik.isSubmitting}
                                                        label="Apply"
                                                        type="submit"
                                                        types={['blue', 'small']}
                                                        disabled={
                                                            !formik.isValid ||
                                                            !formik.dirty ||
                                                            formik.values.status === item.status.code ||
                                                            (hasRequirements && !requirementsComplete)
                                                        }
                                                    />
                                                </div>
                                            </FixedElement>
                                        )}
                                    </div>
                                )}
                            </form>

                            {/* @deprecated */}
                            {/*{item.subOrders.map((s_order) => (*/}
                            {/*    <div className="group services" key={`s_order${s_order.id}`}>*/}
                            {/*        <div className="item">*/}
                            {/*            <div className="label">*/}
                            {/*                <div>Delivery</div>*/}
                            {/*                <div>Order &numero;{s_order.id}</div>*/}
                            {/*                <Status data={s_order.status} />*/}
                            {/*            </div>*/}
                            {/*            <div className="value services-info">*/}
                            {/*                <div className="service-list">*/}
                            {/*                    <Link to={s_order.id} className="details-button" replace>*/}
                            {/*                        <Button types={['inverse', 'small', 'bold']}>Details</Button>*/}
                            {/*                    </Link>*/}
                            {/*                    <div className="item">*/}
                            {/*                        Delivery date:{' '}*/}
                            {/*                        {dayjs(s_order.services?.[0].period_start).format('MM-DD-YYYY')}*/}
                            {/*                    </div>*/}
                            {/*                    {s_order.services.map((service: any) => (*/}
                            {/*                        <div className="item" key={`service${service.code}`}>*/}
                            {/*                            <div className="info-wrap">*/}
                            {/*                                <div className="info">*/}
                            {/*                                    <Icon code={service.type_code} type="services" />*/}
                            {/*                                    <span className="title">*/}
                            {/*                                        {service.title} ({service.amount}Plt)*/}
                            {/*                                    </span>*/}
                            {/*                                </div>*/}
                            {/*                                <div className="value">*/}
                            {/*                                    <Currency value={service.total} />*/}
                            {/*                                </div>*/}
                            {/*                            </div>*/}

                            {/*                            <div className="location" v-if="service.address">*/}
                            {/*                                <LocationLink*/}
                            {/*                                    id={service.code}*/}
                            {/*                                    lat={service.address.lat}*/}
                            {/*                                    lng={service.address.long}*/}
                            {/*                                    address={service.address.address}*/}
                            {/*                                    // hint="false"*/}
                            {/*                                />*/}
                            {/*                                {service.address.distance && (*/}
                            {/*                                    <div className="distance">*/}
                            {/*                                        {Number(service.address.distance).toFixed(1)} mls*/}
                            {/*                                    </div>*/}
                            {/*                                )}*/}
                            {/*                            </div>*/}
                            {/*                        </div>*/}
                            {/*                    ))}*/}
                            {/*                </div>*/}
                            {/*            </div>*/}
                            {/*        </div>*/}
                            {/*    </div>*/}
                            {/*))}*/}
                        </div>
                    </div>
                </div>

                <div className={cn(`${CLASS_NAME}__wrapper`, 'wrap')}>
                    <div className="section">
                        <div className="header">
                            <div className="title">Customer contacts</div>
                        </div>

                        <div className="carrier-info">
                            <div className="group">
                                {item.carrier.company?.entity && (
                                    <div className="item">
                                        <div className="label">Company name</div>
                                        <div className="value">{item.carrier.company.entity}</div>
                                    </div>
                                )}

                                {item.carrier.company?.site && (
                                    <div className="item">
                                        <div className="label">Website</div>
                                        <div className="value">{item.carrier.company.site}</div>
                                    </div>
                                )}

                                {item.carrier.license && (
                                    <div className="item">
                                        <div className="label">USDOT or MC/MX#</div>
                                        <div className="value">{item.carrier.license.number}</div>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
