import { useCallback } from 'react'

export const useOneTimeSessionData = (key: string) => {
    const update = useCallback(
        (data: any) => {
            if (data) {
                sessionStorage.setItem(key, JSON.stringify(data))
            } else {
                sessionStorage.removeItem(key)
            }
        },
        [key],
    )

    const consume = useCallback(
        (prop: string) => {
            const item = sessionStorage.getItem(key)
            const value = JSON.parse(item ?? '{}')
            const valueElement = value[prop]
            if (valueElement) {
                delete value[prop]
            }
            if (item && Object.keys(value).length === 0) {
                sessionStorage.removeItem(key)
            } else if (item) {
                sessionStorage.setItem(key, JSON.stringify(value))
            }
            return (valueElement ?? '') as string
        },
        [key],
    )

    return {
        update,
        consume,
    }
}
