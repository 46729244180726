import React, { createRef, FC, memo, useCallback, useEffect, useState } from 'react'
import GoogleMapReact from 'google-map-react'
import Currency from '@/components/Currency'
import { RatingItem } from '@/parts/Rating/Item'
import Button from '@/components/Button'
import { googleMap } from '@shared/common/map'
import './Map.scss'
import { PhotosView } from '@/parts/PhotosView'
import { MarkerItem } from '@shared/api/search'
import SnazzyInfoWindow from 'snazzy-info-window'

const self = window as any

const Map: FC<{
    markers: MarkerItem[]
    filters?: string
    center: any
    maxZoom?: number
    handleBook: any
    data: any
}> = ({ markers, filters, center = { lat: 40.78, long: -73.96 }, maxZoom = 17, handleBook, data }) => {
    const [map, setMap] = useState<any>(null)
    const [popupRefs, setPopupRefs] = React.useState([])
    const [openedWindow, setOpenedWindow] = useState<SnazzyInfoWindow | null>(null)

    React.useEffect(() => {
        // add or remove refs
        setPopupRefs((r) =>
            Array(markers.length)
                .fill(0)
                .map((_, i) => r[i] || createRef()),
        )
    }, [markers.length])

    const setDefaultCenter = useCallback(() => {
        if (map && center) {
            map.setCenter({
                lat: center.lat,
                lng: center.long,
            })

            map.setZoom(15)
        }
    }, [center, map])

    const drawCircle = useCallback(() => {
        const circle = new self.google.maps.Circle({
            strokeColor: '#519cec',
            strokeOpacity: 1,
            strokeWeight: 1,
            fillColor: '#519cec',
            fillOpacity: 0.1,
            map,
            center: { lat: center.lat, lng: center.long },
            radius: center.radius * 1609,
            zIndex: 1,
        })

        const dot = new self.google.maps.Marker({
            position: { lat: center.lat, lng: center.long },
            icon: {
                path: self.google.maps.SymbolPath.CIRCLE,
                fillOpacity: 1,
                fillColor: '#519cec',
                scale: 8,
                strokeOpacity: 0.2,
                strokeWeight: 1,
                strokeColor: '#000000',
            },
            map,
            zIndex: 100,
        })

        if (!markers.length) {
            map.fitBounds(circle.getBounds())
        }
    }, [center.lat, center.long, center.radius, map, markers.length])

    const drawMarkers = useCallback(async () => {
        const bounds = new self.google.maps.LatLngBounds()
        const InfoWindow: any = (await import('snazzy-info-window')).default

        if (markers.length && map) {
            const iws: typeof InfoWindow[] = []
            markers.forEach((item: any, i: number) => {
                const latlng = new self.google.maps.LatLng(item.lat, item.lng)

                const marker = new self.google.maps.Marker({
                    position: latlng,
                    icon: {
                        path: 'M10,0C4.5,0,0,4.8,0,10.6C0,16.1,7.6,27,10,27c1,0,3.5-2.6,5.6-5.6c2.7-3.8,4.4-7.6,4.4-10.7C20,4.8,15.5,0,10,0z M10,13.1c-1.8,0-3.2-1.4-3.2-3.2c0-1.8,1.4-3.2,3.2-3.2s3.2,1.4,3.2,3.2C13.2,11.7,11.8,13.1,10,13.1z',
                        fillColor: '#3E3E3E',
                        fillOpacity: 1,
                        anchor: new self.google.maps.Point(13.5, 20),
                        strokeWeight: 0,
                        scale: 1,
                    },
                    map,
                })
                const popup = document.getElementById(`warehouse-${item.id}`)
                const iw = new InfoWindow({
                    marker,
                    map,
                    content: popup?.innerHTML || '',
                    callbacks: {
                        afterOpen: () => {
                            document?.getElementById('book_order')?.addEventListener('click', (e) => {
                                e.preventDefault()
                                handleBook(data[item.id])
                            })
                            setOpenedWindow(iw)
                        },
                        beforeClose: () => setOpenedWindow(null),
                    },
                    // closeOnMapClick: true, - this doesn't work
                    closeWhenOthersOpen: true,
                    showCloseButton: true,
                })
                iws.push(iw)
                bounds.extend({ lat: item.lat, lng: item.lng })
            })

            map.fitBounds(bounds)

            if (map.getZoom() > maxZoom) {
                setDefaultCenter()
            }
        }
    }, [map, markers, maxZoom, popupRefs, setDefaultCenter])

    const handleGoogleApiLoaded = useCallback(({ maps, map: m }) => {
        setMap(m)
    }, [])

    const onOutsideClick = useCallback(() => {
        if (openedWindow) openedWindow.close()
    }, [openedWindow])

    useEffect(() => {
        if (map) {
            drawMarkers()
            drawCircle()
        }
    }, [drawMarkers, map])

    return (
        <div className="map-wrapper">
            <GoogleMapReact
                yesIWantToUseGoogleMapApiInternals
                onGoogleApiLoaded={handleGoogleApiLoaded}
                bootstrapURLKeys={{ key: process.env.REACT_APP_GMAP_API_KEY! }}
                defaultZoom={11}
                options={{
                    ...googleMap.options,
                    maxZoom,
                }}
                center={{ lat: center.lat, lng: center.long }}
                onClick={onOutsideClick}
            />
            <div className="popups">
                {markers.map((item, i: number) => (
                    <div className="popup" key={item.id} id={`warehouse-${item.id}`} ref={popupRefs[i]}>
                        {item.searchData?.photos && (
                            <div className="photos-wrapper">
                                <PhotosView
                                    photos={item.searchData.photos}
                                    carouselOnHover
                                    autoplay
                                    autoplaySpeed={2000}
                                />
                            </div>
                        )}

                        <div className="item">
                            <div className="label">Company name</div>
                            <div className="value">{item.entity}</div>
                        </div>

                        <div className="item">
                            <div className="value">
                                <RatingItem value={item.rating} />
                            </div>
                        </div>

                        <div className="item-footer">
                            <div className="currency">
                                <Currency value={item.total} />
                            </div>
                            <a href="#" id="book_order">
                                <Button types={['blue', 'small', 'full']} label="Book" />
                            </a>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    )
}

export default memo(Map)
