import React from 'react'
import Button from '@/components/Button'
import Currency from '@/components/Currency'
import { DropdownDocuments } from '@/components/DropdownDocuments'
import { OrderAdjustment } from '@shared/types'
import { ReactComponent as DocIcon } from '@/assets/images/ic-doc.svg'
import cn from 'classnames'
import { consts } from '@shared/common/consts'
import { utils } from '@shared/common/utils'
import './OrderCostAdjustment.scss'
import { isCarrierOrderAdjustment, isWarehouseOrderAdjustment } from '@shared/utils/orderAdjustment'

interface ICostAdjustmentProps {
    adjustment: OrderAdjustment<'any' | 'carrier' | 'warehouse'>
    loading?: boolean
    confirmed?: boolean
    editable?: boolean
    onClick?(): void
    onConfirmClick?(): void
    onRejectClick?(): void
    className?: string
}

const CLASS_NAME = 'order-cost-adjustment'

const OrderCostAdjustment: React.FC<ICostAdjustmentProps> = ({ adjustment, ...props }) => {
    if (isCarrierOrderAdjustment(adjustment)) return <CarrierAdjustmentView adjustment={adjustment} {...props} />
    if (isWarehouseOrderAdjustment(adjustment)) return <WarehouseAdjustmentView adjustment={adjustment} {...props} />

    return null
}

interface ICarrierAdjustmentView extends ICostAdjustmentProps {
    adjustment: OrderAdjustment<'carrier'>
}

// eslint-disable-next-line complexity
const CarrierAdjustmentView: React.FC<ICarrierAdjustmentView> = ({
    adjustment,
    loading,
    confirmed,
    onConfirmClick,
    onRejectClick,
    editable,
    className,
}) => (
    <div className={cn(CLASS_NAME, `${CLASS_NAME}--carrier`, className)}>
        <div className={`${CLASS_NAME}__wrapper`}>
            <div className={`${CLASS_NAME}__inner`}>
                <div className={`${CLASS_NAME}__label`}>
                    <span className={`${CLASS_NAME}__icon`}>
                        {adjustment.status.codeName === 'ON_VERIFICATION' && <DocIcon className="icon-doc" />}
                        {adjustment.status.codeName === 'CONFIRMED' && <span className="icon-checkbox" />}
                        {adjustment.status.codeName === 'UNCONFIRMED' && <span className="icon-close" />}
                    </span>
                    <span className={`${CLASS_NAME}__type`}>{consts.order.adjustments[adjustment.type]}</span>
                </div>
                <div
                    className={cn(`${CLASS_NAME}__value`, {
                        unconfirmed: adjustment.status.codeName === 'UNCONFIRMED',
                    })}
                >
                    <Currency value={adjustment.price.initial} />
                </div>
            </div>
            {adjustment.comment && <div className={`${CLASS_NAME}__comment`}>{adjustment.comment}</div>}
            <div className={`${CLASS_NAME}__inner`}>
                <div className={`${CLASS_NAME}__label`}>Payment fee</div>

                <div
                    className={cn(`${CLASS_NAME}__value`, {
                        unconfirmed: adjustment.status.codeName === 'UNCONFIRMED',
                    })}
                >
                    <Currency value={adjustment.price.fee} />
                </div>
            </div>
            <hr />

            <div className={`${CLASS_NAME}__inner`}>
                <div className={`${CLASS_NAME}__label`}>Total</div>
                <div
                    className={cn(`${CLASS_NAME}__value`, {
                        unconfirmed: adjustment.status.codeName === 'UNCONFIRMED',
                    })}
                >
                    <Currency value={adjustment.price.total} />
                </div>
            </div>
        </div>

        {adjustment.status.codeName !== 'ON_VERIFICATION' && adjustment.rejectReason && (
            <div className={`${CLASS_NAME}__reject-reason`}>
                <div className={`${CLASS_NAME}__label`}>Reason of rejection</div>
                <div>{adjustment.rejectReason}</div>
            </div>
        )}

        <div className={`${CLASS_NAME}__wrapper`}>
            {adjustment.documents.length > 0 && (
                <div className={`${CLASS_NAME}__inner`}>
                    <div className={`${CLASS_NAME}__files`}>
                        <DropdownDocuments
                            withIcon
                            type="right"
                            list={adjustment.documents}
                            title={utils.pluralize(adjustment.documents.length, 'File')}
                        />
                    </div>
                </div>
            )}

            {adjustment.status.codeName === 'ON_VERIFICATION' && editable && (
                <div className={`${CLASS_NAME}__inner`}>
                    <div className={`${CLASS_NAME}__btns-wrapper`}>
                        <Button
                            className={`${CLASS_NAME}__btn`}
                            types={['bordered', 'small']}
                            disabled={loading}
                            loading={loading && !confirmed}
                            label="Reject"
                            onClick={onRejectClick}
                        />
                        <Button
                            disabled={loading}
                            loading={loading && confirmed}
                            className={`${CLASS_NAME}__btn`}
                            types={['blue', 'small']}
                            label="Confirm"
                            onClick={onConfirmClick}
                        />
                    </div>
                </div>
            )}
        </div>

        {adjustment.status.codeName === 'ON_VERIFICATION' && editable && (
            <div className={`${CLASS_NAME}__confirm-text`}>
                By clicking on CONFIRM button you agree with the rates described in invoice file
            </div>
        )}
    </div>
)

interface IWarehouseAdjustmentView extends ICostAdjustmentProps {
    adjustment: OrderAdjustment<'warehouse'>
}

const WarehouseAdjustmentView: React.FC<IWarehouseAdjustmentView> = ({ adjustment, onClick, className }) => (
    <button className={cn(CLASS_NAME, `${CLASS_NAME}--warehouse`, className)} onClick={onClick}>
        <div className={`${CLASS_NAME}__wrapper`}>
            <div className={`${CLASS_NAME}__inner`}>
                <div className={`${CLASS_NAME}__label`}>
                    <span className={`${CLASS_NAME}__icon`}>
                        <DocIcon className="icon-doc" />
                    </span>
                    <span className={`${CLASS_NAME}__type`}>{consts.order.adjustments[adjustment.type]}</span>
                </div>
                <div
                    className={cn(`${CLASS_NAME}__value`, {
                        unconfirmed: adjustment.status.codeName === 'UNCONFIRMED',
                    })}
                >
                    <Currency value={adjustment.price} />
                </div>
                {adjustment.status.codeName === 'CONFIRMED' && (
                    <span className={cn(`${CLASS_NAME}__status-icon`, 'icon-checkbox')} />
                )}
                {adjustment.status.codeName === 'UNCONFIRMED' && (
                    <span className={cn(`${CLASS_NAME}__status-icon`, 'icon-close')} />
                )}
            </div>
            {adjustment.comment && <div className={`${CLASS_NAME}__comment`}>{adjustment.comment}</div>}
        </div>

        {adjustment.status.codeName !== 'ON_VERIFICATION' && adjustment.rejectReason && (
            <div className={`${CLASS_NAME}__reject-reason`}>
                <div className={`${CLASS_NAME}__label`}>Reason of rejection</div>
                <div>{adjustment.rejectReason}</div>
            </div>
        )}
    </button>
)

export { OrderCostAdjustment }
