import React, { FC } from 'react'
import { ReactComponent as IconRoute } from 'images/icon-route.svg'
import { ReactComponent as IconPlane } from 'images/icon-plane.svg'
import './DistanceInfo.scss'

export interface DistanceInfoProps {
    direct?: number | null
    driving?: number | null
}

export const DistanceInfo: FC<DistanceInfoProps> = ({ direct, driving }) => {
    return (
        <>
            {driving && (
                <div className="distance-info">
                    <span>
                        <IconRoute style={{ color: '#ffffff' }} />
                        Driving Distance
                    </span>

                    <div>{Number(driving).toFixed(1)} mls</div>
                </div>
            )}
            {direct && (
                <div className="distance-info">
                    <span>
                        <IconPlane />
                        Direct Distance
                    </span>

                    <div>{Number(direct).toFixed(1)} mls</div>
                </div>
            )}
        </>
    )
}
