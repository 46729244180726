import React, { FC, HTMLAttributes } from 'react'
import { useUserState } from '@/store/user'
import { EntityType, UserType } from '@shared/api/types'
import cn from 'classnames'
import './Navigation.scss'
import { AccountMenu } from '@/parts/Navigation/AccountMenu'
import { WarehouseMenu } from '@/parts/Navigation/WarehouseMenu'
import { CarrierMenu } from '@/parts/Navigation/CarrierMenu'
import { useSelectedEntity } from '@/hooks/useSelectedEntity'
import { EntityStatus } from '@/components/EntityStatus'
import { TextCutter } from '@/components/TextCutter'
import { getFullEntityStatus } from '@/utils/status'

interface IMenu extends HTMLAttributes<HTMLDivElement> {
    className?: string
    isOpen: boolean
    onToggle?: (isOpen?: boolean) => void
}

const CLASS_NAME = 'navigation'

export const Navigation: FC<IMenu> = ({ isOpen, onToggle, className }) => {
    const {
        state: { isLoggedIn, userInfo },
    } = useUserState()
    const { data: selectedEntity } = useSelectedEntity()

    const isAdmin = userInfo?.type === UserType.admin

    if (!isLoggedIn) return null

    const closeMenu = () => onToggle?.(false)

    return (
        <div className={cn(CLASS_NAME, className)}>
            <div className={`${CLASS_NAME}__wrapper`}>
                {selectedEntity?.entity && (
                    <div className={`${CLASS_NAME}__special-menu`}>
                        <div className={`${CLASS_NAME}__entity-label`}>
                            <EntityStatus
                                id={selectedEntity.entity.id}
                                status={getFullEntityStatus(selectedEntity.entity)}
                            />
                            <TextCutter title={selectedEntity.entity.entity}>{selectedEntity.entity.entity}</TextCutter>
                        </div>
                        {selectedEntity.type === EntityType.warehouse && <WarehouseMenu closeMenu={closeMenu} />}
                        {selectedEntity.type === EntityType.carrier && <CarrierMenu closeMenu={closeMenu} />}
                    </div>
                )}

                <AccountMenu
                    isOpen={isOpen}
                    isAdmin={isAdmin}
                    closeMenu={closeMenu}
                    className={`${CLASS_NAME}__account-menu`}
                />
            </div>
        </div>
    )
}
