import React, { useEffect, useState } from 'react'
import { install } from 'ga-gtag'

interface IUseGoogleAnalytics {
    gaId?: string
    enabled?: boolean
}

export const useGoogleAnalytics = ({ gaId, enabled }: IUseGoogleAnalytics) => {
    const [initialized, setInit] = useState<boolean>(false)

    useEffect(() => {
        if (gaId && enabled && !initialized) {
            install(gaId)
            setInit(true)
        }
    }, [enabled, gaId, initialized])
}
