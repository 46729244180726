import { useCallback, useEffect, useRef, useState } from 'react'

interface CountdownOptions {
    seconds: number
    onComplete: () => void
}

export const useCountdown = (options: CountdownOptions) => {
    const intervalRef = useRef<any>()
    const { onComplete, seconds } = options
    const [counter, setCounter] = useState(seconds)

    const countDown = () =>
        setCounter((count) => {
            if (count > 0) {
                return count - 1
            }

            clearInterval(intervalRef.current)
            intervalRef.current = undefined
            onComplete()
            return 0
        })

    const start = () => {
        intervalRef.current = setInterval(() => countDown(), 1000)
    }

    useEffect(() => {
        return () => {
            if (intervalRef.current) {
                clearInterval(intervalRef.current)
                intervalRef.current = undefined
            }
        }
    }, [])

    return {
        start,
        counter,
    }
}
