import React, { FC, HTMLAttributes } from 'react'
import { Link } from 'react-router-dom'
import Button from '@/components/Button'
import ROUTES from '@/router'
import { buildURI } from '@shared/utils/buildURI'

interface IMenu extends HTMLAttributes<HTMLDivElement> {
    closeMenu: () => void
}

export const WarehouseMenu: FC<IMenu> = ({ closeMenu }) => (
    <>
        <Link to={buildURI(`/warehouse${ROUTES.warehouse.ordersList}`, { pathParams: { filters: undefined } })}>
            <Button label="Order List" types={['plain', 'bold']} onClick={() => closeMenu()} />
        </Link>

        <Link to={`/warehouse${ROUTES.warehouse.calendar}`}>
            <Button label="Calendar" types={['plain', 'bold']} onClick={() => closeMenu()} />
        </Link>

        <Link to={`/warehouse${ROUTES.warehouse.settings.base}`}>
            <Button types={['plain', 'bold']} onClick={() => closeMenu()} label="Warehouse Information" />
        </Link>
    </>
)
