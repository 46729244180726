import React, { FC, memo } from 'react'
import { showInfoModal } from '@/parts/Modals/InfoModal'
import { InputText } from '@/components/InputText'
import Button from '@/components/Button'
import { CompanyStatusInfo } from '@/parts/CompanyStatusInfo/CompanyStatusInfo'
import { showFeedbackModal } from '@/parts/Modals/FeedbackModal'
import { updateCompanyState, useCompanyState } from '@/store/company'
import useCompanySettings, { CompanySettingsFormData } from '@shared/hooks/useCompanySettings'
import './CompanySettings.scss'

const CompanySettings: FC = memo(() => {
    const { dispatch } = useCompanyState()
    const { formik, reset, submitFailed, mutate, validating } = useCompanySettings({
        onSuccess: async (company) => {
            if (company) {
                mutate(await updateCompanyState(company, dispatch))
            }

            showInfoModal({
                props: {
                    title: 'Company information successfully changed',
                    message: '',
                },
            })
        },
    })

    const feedback = () => {
        showFeedbackModal({
            props: {},
        })
    }

    return (
        <div className="company-settings wrap">
            <div className="form-user form">
                <form onSubmit={formik.handleSubmit}>
                    <CompanyStatusInfo />
                    <div className="section">
                        {/* <user-status-info></user-status-info> */}

                        <div className="header">
                            <div className="title">
                                <span>Company</span>
                            </div>
                        </div>

                        <div className="form-wrap">
                            <div className="field entity">
                                <InputText
                                    text
                                    required
                                    textError
                                    errorOnFocus={submitFailed}
                                    error={formik.errors.entity}
                                    value={formik.values.entity}
                                    onChange={formik.handleChange}
                                    placeholder={CompanySettingsFormData.entity.label}
                                    label={CompanySettingsFormData.entity.label}
                                    name="entity"
                                />
                            </div>

                            <div className="field group-name">
                                <InputText
                                    text
                                    value={formik.values.group_name}
                                    onChange={formik.handleChange}
                                    error={formik.errors.group_name}
                                    placeholder={CompanySettingsFormData.group_name.label}
                                    label={CompanySettingsFormData.group_name.label}
                                    name="group_name"
                                />
                            </div>

                            <div className="field site">
                                <InputText
                                    text
                                    textError
                                    error={formik.errors.site}
                                    value={formik.values.site}
                                    onChange={formik.handleChange}
                                    placeholder={CompanySettingsFormData.site.label}
                                    label={CompanySettingsFormData.site.label}
                                    name="site"
                                />
                            </div>

                            <div className="field tax">
                                <InputText
                                    text
                                    value={formik.values.tax_id}
                                    onChange={formik.handleChange}
                                    placeholder={CompanySettingsFormData.tax_id.label}
                                    label={CompanySettingsFormData.tax_id.label}
                                    name="tax_id"
                                />
                            </div>
                        </div>
                    </div>

                    <div className="page-footer">
                        <div className="btns">
                            <Button
                                label="Save"
                                types={['large', 'blue']}
                                type="submit"
                                disabled={!formik.isValid || !formik.dirty || validating}
                                loading={formik.isSubmitting}
                            />
                            <Button
                                label="Reset"
                                disabled={formik.isSubmitting}
                                loading={validating}
                                types={['large', 'plain']}
                                onClick={reset}
                            />
                        </div>

                        {/* eslint-disable-next-line jsx-a11y/anchor-is-valid,jsx-a11y/no-static-element-interactions,no-console */}
                        <a onClick={feedback} className="secondary-link media-desktop">
                            Contact support
                        </a>
                    </div>
                </form>
            </div>
        </div>
    )
})

export default CompanySettings
