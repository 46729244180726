import { CarrierLicense, Status, WarehouseLicense } from '@shared/types'
import { EntityStatus as EntityStatusType } from '@/store/entity/entity'

export const getStatus = (value?: string): string => {
    switch (value) {
        case 'CONFIRMED':
            return 'confirmed'
        case 'UNCONFIRMED':
            return 'unconfirmed'
        case 'ON_VERIFICATION':
            return 'on-verification'
        default:
            return ''
    }
}
export const getFullEntityStatus = <T extends { license?: CarrierLicense | WarehouseLicense; status: Status }>(
    item: T,
): EntityStatusType => {
    const entityStatus = getEntityStatus(item.status.codeName) as EntityStatusType
    const licenseStatus = getStatus('license' in item ? item.license?.status?.codeName : 'CONFIRMED')

    let finalStatus: EntityStatusType
    switch (licenseStatus) {
        case 'unconfirmed':
            finalStatus = 'rejected'
            break
        case 'on-verification':
            finalStatus = 'inactive'
            break
        default:
            finalStatus = entityStatus
            break
    }
    return finalStatus
}

export const getEntityStatus = (value?: string): string => {
    switch (value) {
        case 'ACTIVE':
            return 'active'
        case 'INACTIVE':
            return 'inactive'
        case 'ARCHIVED':
            return 'archived'
        case 'REJECTED':
            return 'rejected'
        case 'BANNED':
            return 'banned'
        default:
            return ''
    }
}
