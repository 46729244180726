import React, { FC } from 'react'
import { Navigate, Route, Routes } from 'react-router-dom'
import { CarrierOrderItem, CarrierOrderList, CarrierSearch, CarrierSettings } from './index'
import { CarrierWarehouseSearch } from './CarrierWarehouseSearch/CarrierWarehouseSearch'
import { CarrierBooking } from './CarrierBooking'
import { CarrierQuote } from './CarrierQuote/CarrierQuote'
import { SelectCarrierBeforeOrderItem } from '@/pages/Carrier/SelectCarrierBeforeOrderItem/SelectCarrierBeforeOrderItem'
import { useCarrier } from '@shared/api/carrier'
import { getFullEntityStatus } from '@/utils/status'
import { Loader } from '@/components/Loader'
import ROUTES from '@/router'
import { useCheckEntityPath } from '@/hooks/useCheckEntityPath'
import { EntityType } from '@shared/api/types'
import CarrierOrderConfirmation from './CarrierOrderConfirmation/CarrierOrderConfirmation'
import { LoggedInRoute } from '@/components/PrivateRoute/PrivateRoute'

export const CarrierRoutes: FC = () => {
    const checkResult = useCheckEntityPath(EntityType.carrier)
    const carrierFetch = checkResult.isCorrectPath && localStorage.getItem('type') === 'carrier'
    const carrier = useCarrier(carrierFetch)

    if ((carrierFetch && carrier.loading) || checkResult.loading) {
        return <Loader />
    }

    const status = carrier.data && getFullEntityStatus(carrier.data!)
    const defaultRoute = status === 'active' ? ROUTES.carrier.search : ROUTES.carrier.settings

    return (
        <>
            <LoggedInRoute />
            <Routes>
                <Route path={ROUTES.carrier.selectBeforeOrder} element={<SelectCarrierBeforeOrderItem />} />
                <Route path={ROUTES.carrier.selectBeforeBooking} element={<SelectCarrierBeforeOrderItem />} />
                <Route path={ROUTES.carrier.booking} element={<CarrierBooking />} />
                <Route path={ROUTES.carrier.bookingNoFilters} element={<CarrierBooking />} />
                <Route path={ROUTES.carrier.quote} element={<CarrierQuote />} />
                <Route path={ROUTES.carrier.quoteNoFilters} element={<CarrierQuote />} />
                {/* <Route exact path={`${CARRIER_BASE}/warehouse/:id/distribution/:filters?`} component={CarrierBooking} /> */}
                <Route path={ROUTES.carrier.warehouseSearch} element={<CarrierWarehouseSearch />} />
                <Route path={ROUTES.carrier.warehouseSearchNoFilters} element={<CarrierWarehouseSearch />} />
                <Route path={ROUTES.carrier.ordersList} element={<CarrierOrderList />} />
                <Route path={ROUTES.carrier.ordersListNoFilters} element={<CarrierOrderList />} />
                <Route path={ROUTES.carrier.order} element={<CarrierOrderItem />} />
                <Route path={ROUTES.carrier.orderConfirmation} element={<CarrierOrderConfirmation />} />
                <Route path={ROUTES.carrier.settings} element={<CarrierSettings />} />
                <Route path={ROUTES.carrier.search} element={<CarrierSearch />} />
                <Route path="/" element={<Navigate to={`${ROUTES.carrier.base}${defaultRoute}`} />} />
            </Routes>
        </>
    )
}
