import React, { FC } from 'react'
import { RatingItem } from '@/parts/Rating/Item'
import './WarehouseSettingsRating.scss'
import { Loader } from '@/components/Loader'
import { useWarehouse } from '@shared/api/warehouse';

export const WarehouseSettingsRating: FC = () => {
    const { data, loading } = useWarehouse()

    const { rating, reviews } = data || {}

    if (loading) {
        return <Loader />
    }

    return (
        <div className="wrap warehouse-rating">
            <div className="section">
                <div className="header">
                    <div className="title">Rating</div>
                </div>

                {rating && (
                    <div className="ratings">
                        <div className="item average">
                            <RatingItem value={rating.average} title="Total rating" type="large" />
                        </div>
                        <div className="separator" />

                        {rating.details.map((item) => (
                            <div className="item" key={item.title}>
                                <RatingItem value={item.value} title={item.title} type="large" />
                            </div>
                        ))}
                    </div>
                )}

                {reviews && reviews.length > 0 && (
                    <div className="section">
                        <div className="header">
                            <div className="title">Reviews</div>
                        </div>

                        <div className="reviews">
                            {reviews.map((item) => (
                                <div className="review" key={item.created_at}>
                                    <div className="review-info">
                                        <div className="header-info">
                                            <div className="name">
                                                {item.carrier.first_name} {item.carrier.last_name}
                                            </div>
                                            <div className="date">{item.created_at}</div>
                                        </div>

                                        <div className="comment">{item.comment}</div>
                                    </div>

                                    <div className="review-rating">
                                        {item.rating.map((r: { title: string; value: number | undefined }) => (
                                            <div className="item" key="i">
                                                {r.title !== 'Response' && (
                                                    <RatingItem value={r.value} title={r.title} type="small" />
                                                )}
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                )}
            </div>
        </div>
    )
}
