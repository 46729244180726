import React, { useEffect, useState } from 'react'
import { Navigate, Route, Routes } from 'react-router-dom'
import { SignUpRegisterStep } from '@/pages/SignUp/steps/SignUpRegisterStep'
import { useCompanyState } from '@/store/company'
import { useUserState } from '@/store/user'
import { ManagerDetailsStep } from '@/pages/SignUp/steps/ManagerDetailsStep'
import { useLocation, useNavigate } from 'react-router'
import { CompanyInfoStep } from '@/pages/SignUp/steps/CompanyInfoStep'
import { VerificationStep } from '@/pages/SignUp/steps/VerificationStep'
import { RegistrationReadyStep } from './steps/RegistrationReadyStep'
import { SignUpCarrierStep } from '@/pages/SignUp/steps/carrier/SignUpCarrierStep'
import { SignUpWarehouseStep } from './steps/warehouse/SignUpWarehouseStep'
import { SignUpWarehouseAddressStep } from '@/pages/SignUp/steps/warehouse/SignUpWarehouseAddressStep'
import { SignUpWarehouseWorkingHoursStep } from '@/pages/SignUp/steps/warehouse/SignUpWarehouseWorkingHoursStep'
import { SignUpWarehouseLocalDeliveryStep } from '@/pages/SignUp/steps/warehouse/SignUpWarehouseLocalDeliveryStep'
import { SignUpWarehouseUSDOTStep } from '@/pages/SignUp/steps/warehouse/SignUpWarehouseUSDOTStep'
import { SignUpWarehouseTaxNumberStep } from './steps/warehouse/SignUpWarehouseTaxNumberStep'
import { SignUpWarehouseDoneStep } from '@/pages/SignUp/steps/warehouse/SignUpWarehouseDoneStep'
import { SignUpCarrierDoneStep } from './steps/carrier/SignUpCarrierDoneStep'
import { entityTypes } from '@shared/types'
import { utils } from '@shared/common/utils'
import { UserActionType } from '@/store/user/user'

// eslint-disable-next-line complexity
const SignUp: React.FC = () => {
    const { state: companyState } = useCompanyState()
    const { state: userState, dispatch } = useUserState()
    const { signUp } = userState
    const location = useLocation()
    const [entity, setEntity] = useState<string | null>(
        entityTypes.find((u) => u.code === utils.getSearchParamByName(location.search, 'type'))?.value || null,
    )
    const registrationStep = userState.userInfo?.registration_step ?? 0
    const emailStatus = userState.userInfo?.email?.status
    const onVerification = (!emailStatus || emailStatus.codeName === 'ON_VERIFICATION') && !signUp.verificationSkipped
    const readyPage = signUp.chosenEntityType === 'c' ? '/sign-up/carrier/ready' : '/sign-up/warehouse/ready'

    useEffect(() => {
        if (entity === 'carrier') {
            dispatch?.({ type: UserActionType.SIGN_UP_CHOOSE_ENTITY_TYPE, entityType: 'c' })
        } else if (entity === 'warehouse') {
            dispatch?.({ type: UserActionType.SIGN_UP_CHOOSE_ENTITY_TYPE, entityType: 'w' })
        }
    }, [entity])
    if (!userState.isLoggedIn && location.pathname !== '/sign-up') {
        return <Navigate to="/sign-up" />
    }
    if (userState.isLoggedIn && registrationStep === 1 && location.pathname !== '/sign-up/intro') {
        return <Navigate to="/sign-up/intro" />
    }
    if (userState.isLoggedIn && registrationStep === 2 && location.pathname !== '/sign-up/company') {
        return <Navigate to="/sign-up/company" />
    }
    // also check if user skipped it
    if (
        userState.isLoggedIn &&
        registrationStep > 2 &&
        onVerification &&
        location.pathname !== '/sign-up/verification'
    ) {
        return <Navigate to="/sign-up/verification" />
    }

    // also check for preferred user type
    if (userState.isLoggedIn && registrationStep === 3 && !onVerification) {
        if (signUp.chosenEntityType === 'w' && location.pathname !== '/sign-up/warehouse') {
            return <Navigate to="/sign-up/warehouse" />
        }
        if (signUp.chosenEntityType === 'c' && location.pathname !== '/sign-up/carrier') {
            return <Navigate to="/sign-up/carrier" />
        }
        if (!signUp.chosenEntityType && location.pathname !== '/sign-up/ready') {
            return <Navigate to="/sign-up/ready" />
        }
    }
    if (
        userState.isLoggedIn &&
        registrationStep === 20 &&
        !onVerification &&
        location.pathname !== '/sign-up/warehouse/address'
    ) {
        return <Navigate to="/sign-up/warehouse/address" />
    }
    if (
        userState.isLoggedIn &&
        registrationStep === 21 &&
        !onVerification &&
        location.pathname !== '/sign-up/warehouse/hours'
    ) {
        return <Navigate to="/sign-up/warehouse/hours" />
    }
    if (
        userState.isLoggedIn &&
        registrationStep === 22 &&
        !onVerification &&
        location.pathname.indexOf('warehouse/usdot') < 0 &&
        location.pathname !== '/sign-up/warehouse/delivery' &&
        signUp.localDeliverySkipped === undefined
    ) {
        return <Navigate to="/sign-up/warehouse/delivery" />
    }
    if (
        userState.isLoggedIn &&
        registrationStep === 22 &&
        !onVerification &&
        location.pathname.indexOf('warehouse/usdot') < 0 &&
        signUp.localDeliverySkipped === false
    ) {
        return <Navigate to="/sign-up/warehouse/usdot" />
    }
    if (
        userState.isLoggedIn &&
        (registrationStep === 23 || (registrationStep === 22 && signUp.localDeliverySkipped === true)) &&
        !onVerification &&
        location.pathname.indexOf('warehouse/tax') < 0
    ) {
        return <Navigate to="/sign-up/warehouse/tax" />
    }
    if (userState.isLoggedIn && registrationStep === 100 && !onVerification && location.pathname !== readyPage) {
        return <Navigate to={readyPage} />
    }
    return (
        <Routes location={location}>
            <Route path="/" element={<SignUpRegisterStep />} />
            <Route path="/intro" element={<ManagerDetailsStep />} />
            <Route path="/company" element={<CompanyInfoStep />} />
            <Route path="/verification" element={<VerificationStep />} />
            <Route path="/ready" element={<RegistrationReadyStep />} />
            <Route path="/carrier" element={<SignUpCarrierStep />} />
            <Route path="/carrier/ready" element={<SignUpCarrierDoneStep />} />
            <Route path="/warehouse" element={<SignUpWarehouseStep />} />
            <Route path="/warehouse/address" element={<SignUpWarehouseAddressStep />} />
            <Route path="/warehouse/hours" element={<SignUpWarehouseWorkingHoursStep />} />
            <Route path="/warehouse/delivery" element={<SignUpWarehouseLocalDeliveryStep />} />
            <Route path="/warehouse/usdot" element={<SignUpWarehouseUSDOTStep />} />
            <Route path="/warehouse/tax" element={<SignUpWarehouseTaxNumberStep />} />
            <Route path="/warehouse/ready" element={<SignUpWarehouseDoneStep />} />
        </Routes>
    )
}

export default SignUp
