import React, { FC, memo } from 'react'
import { ModalRegistry } from '@shared/common/modals'
import { ModalPartialProps, Modals } from '@shared/types'
import './OrdersModal.scss'
import TableLineModalOrders from '@/parts/Table/TableLineModalOrders'
import { TableList } from '@/parts/Table/TableList'
import { useWarehouseOrders } from '@shared/api/useDataHooks'

const HEADERS = [
    { name: 'PO №', alias: 'id' },
    { name: 'Estimated Arrival Time', alias: 'time' },
    { name: 'Services', alias: 'services' },
    { name: 'Units', alias: 'amount' },
    { name: 'Contacts', alias: 'contacts' },
    { name: 'Total Cost', alias: 'total' },
    { name: '', alias: 'button' },
]

interface OrdersModalProps {
    title?: string
    period_start: string
    period_end: string
    onOpenOrder?: (id: number) => void
}

const OrdersModal: FC<OrdersModalProps> = ({ period_start, period_end, onOpenOrder }) => {
    const { data, isLoading } = useWarehouseOrders({
        per_page: 100,
        sort: '-period_start',
        period_start,
        period_end,
    })

    // eslint-disable-next-line @typescript-eslint/naming-convention
    const { items = [], page, page_count } = data || {}

    return (
        <div className="list">
            <TableList data={items} pending={isLoading} headers={HEADERS} pageCount={page_count} page={page}>
                {items.map((order) => (
                    <TableLineModalOrders className="collapse" order={order} key={order.id} onOpenOrder={onOpenOrder} />
                ))}
            </TableList>
        </div>
    )
}

ModalRegistry.get().register<OrdersModalProps>(Modals.OrdersModal, {
    id: 'OrdersModalProps',
    className: 'modal-orders',
    size: 'huge',
    Component: memo(OrdersModal),
    title: 'Orders',
})

export const showOrdersModal = (props: ModalPartialProps<OrdersModalProps>): void =>
    ModalRegistry.get().show<OrdersModalProps>(Modals.OrdersModal, props)

export default memo(OrdersModal)
