import React, { FC, memo, useCallback, useMemo, useState } from 'react'
import { ModalRegistry } from '@shared/common/modals'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { Card, ModalPartialProps, Modals } from '@shared/types'
import Button from '@/components/Button'
import { CardNumberElement, CardCvcElement, CardExpiryElement, useStripe, useElements } from '@stripe/react-stripe-js'
import { InputText } from '@/components/InputText'
import Icon from '@/components/Icon/Icon'
import { addCard } from '@shared/api/carrier'
import { showInfoModal } from '@/parts/Modals/InfoModal'
import './CardModal.scss'

const options = {
    style: {
        base: {
            color: '#000',
            lineHeight: '24px',
            fontFamily: 'AvenirNext, "Helvetica Neue", Helvetica, sans-serif',
            fontSmoothing: 'antialiased',
            fontSize: '15px',
            '::placeholder': {
                color: '#aab7c4',
            },
        },
        invalid: {
            color: '#000',
        },
    },
}

interface CardModalProps {
    onSuccess?: (card: Card) => void
}

interface ICardForm {
    name: string
}

const CardFormScheme = Yup.object().shape({
    name: Yup.string()
        .matches(/^((?:[A-Za-z]+ ?){1,3})$/, 'Enter correct name!')
        .required('Please enter cardholder name'),
})

const CardModal: FC<CardModalProps> = ({ onSuccess }) => {
    const [brand, setBrand] = useState('unknown')

    const [number, setComplete] = useState(false)
    const [cvc, setCvc] = useState(false)
    const [expire, setExpire] = useState(false)

    const stripe = useStripe()
    const elements = useElements()

    const handleNumberChange = useCallback((e) => {
        setBrand(e.brand)
        setComplete(e.complete)
    }, [])

    const handleExpireChange = useCallback((e) => {
        setExpire(e.complete)
    }, [])

    const handleCvcChange = useCallback(({ complete }) => {
        setCvc(complete)
    }, [])

    const onSubmit = useCallback(
        async (values) => {
            if (stripe && elements) {
                const el = elements.getElement(CardNumberElement)
                if (el) {
                    try {
                        const { token } = await stripe.createToken(el, { name: values.name })
                        if (token && token.id) {
                            const card = await addCard(token.id)
                            if (onSuccess) {
                                onSuccess(card)
                                ModalRegistry.get().close(Modals.CardModal)
                            }
                        }
                    } catch (e: any) {
                        showInfoModal({
                            props: {
                                message: e,
                            },
                        })
                    }
                }
            }
        },
        [elements, stripe, onSuccess],
    )

    const formik = useFormik<ICardForm>({
        onSubmit,
        validationSchema: CardFormScheme,
        initialValues: {
            name: '',
        },
    })

    const disabled = useMemo(
        () => !(stripe && formik.isValid && number && cvc && expire),
        [formik.isValid, number, cvc, expire],
    )

    return (
        <form className="form form-card" onSubmit={formik.handleSubmit}>
            <div className="card">
                <div className="back" />
                <div className="main" />

                <Icon type="cards" code={brand} />

                <div className="fields">
                    <div className="field number">
                        <CardNumberElement
                            className="stripe-element card-number"
                            options={options}
                            onChange={handleNumberChange}
                        />
                    </div>

                    <div className="field cvv">
                        <CardCvcElement
                            className="stripe-element card-cvc"
                            options={options}
                            onChange={handleCvcChange}
                        />
                    </div>

                    <div className="field name">
                        <InputText
                            text
                            error={formik.errors.name}
                            textError
                            name="name"
                            value={formik.values.name}
                            placeholder="CARDHOLDER NAME"
                            onChange={formik.handleChange}
                        />
                    </div>

                    <div className="field date">
                        <CardExpiryElement
                            className="stripe-element card-expiry"
                            options={options}
                            onChange={handleExpireChange}
                        />
                    </div>
                </div>
            </div>

            <div className="btns">
                <Button
                    types={['blue']}
                    type="submit"
                    label="Submit"
                    loading={formik.isSubmitting}
                    disabled={disabled}
                />
                <Button
                    types={['plain']}
                    label="Cancel"
                    onClick={() => ModalRegistry.get().close(Modals.CardModal)}
                    disabled={formik.isSubmitting}
                />
            </div>
        </form>
    )
}

ModalRegistry.get().register<CardModalProps>(Modals.CardModal, {
    id: 'CardModalProps',
    className: 'modal-card',
    size: 'medium',
    title: 'Add new card',
    Component: memo(CardModal),
})

export const showCardModal = (props: ModalPartialProps<CardModalProps>): void =>
    ModalRegistry.get().show<CardModalProps>(Modals.CardModal, props)

export default memo(CardModal)
