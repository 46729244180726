import React, {
    ChangeEvent,
    FC,
    FormEvent,
    memo,
    PropsWithChildren,
    useCallback,
    useEffect,
    useMemo,
    useRef,
    useState,
} from 'react'
import { useFormik } from 'formik'
import Button from '@/components/Button'
import cn from 'classnames'
import { WarehouseServiceGroup } from '@/pages/Carrier/CarrierBooking/WarehouseServiceGroup/WarehouseServiceGroup'
import './CarrierBookingForm.scss'
import {
    CarrierBookingState,
    ICarrierBookingForm,
    Modals,
    OrderDocument,
    WarehouseForBooking,
    WarehouseServiceMapped,
} from '@shared/types'
import Datepicker from '@/components/Datepicker'
import dayjs from 'dayjs'
import { utils } from '@shared/common/utils'
import { InputText } from '@/components/InputText'
import InputLocation from '@/components/InputLocation'
import { createOrder, getBookingPrice, getBookingSettings, useCarrier } from '@shared/api/carrier'
import Currency from '@/components/Currency'
import Cards, { useCards } from '@/parts/Cards'
import SelectTime from '@/components/SelectTime'
import {
    CarrierBookingFormUtils,
    DEFAULT_MAX_ARRIVAL_HOURS_TO,
    DEFAULT_MIN_ARRIVAL_HOURS_FROM,
} from '@/pages/Carrier/CarrierBooking/Form/utils'
import Textarea from '@/components/Textarea'
import { Loader } from '@/components/Loader'
import { showConfirmOrderModal } from '@/parts/Modals/ConfirmOrderModal'
import { showError, showInfoModalAsync } from '@/parts/Modals/InfoModal'
import { useNavigate } from 'react-router-dom'
import { ModalRegistry } from '@shared/common/modals'
import { useEnv } from '@shared/api/user'
import { PhotosForm } from '@/parts/PhotosForm'
import { useInfo } from '@shared/api/useDataHooks'
import { usePalletsReworkPhotosRequests } from '@shared/hooks/carrier/usePalletsReworkPhotosRequests'
import { getOrderDocuments, PAYMENT_STRATEGIES } from '@shared/api/order'
import { BookingSettings, extractBookingSettings } from '@shared/common/settings'
import { TextCutter } from '@/components/TextCutter'
import SelectorWithContent from '@/components/SelectorWithContent'
import MobileFixedBanner from './MobileFixedBanner'
import { consts } from '@shared/common/consts'
import { CarrierBookingSection } from '../Section'
import { CarrierBookingWarehouseInfo } from '../WarehouseInfo'
import { licenseValid } from '@/parts/CarrierStatusInfo/helper'
import { updateCarrierLicenseInDialog } from '@/utils/carrierLicenseModal'
import { useQuotationRequest } from '@/pages/Carrier/CarrierWarehouseSearch/useQuotationRequest'
import { getDistance } from '@shared/apiv2/orders'
import { DistanceInfo } from '@/parts/DistanceInfo/DistanceInfo'

const CLASS_NAME = 'carrier-booking-form'

const getPriceChangeMessage = (warehouse: WarehouseForBooking, price: any, oldPrice: any) => {
    if (price.discount_percent !== oldPrice.discount_percent) {
        return 'The amount of discount has been changed. Check the changed total price at Order Details and if it suits well for you - please Send booking Request.'
    }

    const services: string[] = []

    Object.keys(price.services).forEach((key: string) => {
        const code = parseInt(key, 2)

        if (price.services[key].price !== oldPrice.services[code].price) {
            const service = warehouse.bookingServices.find((item) => item.code === code)
            if (service) {
                services.push(service.title)
            }
        }
    })

    const separator = services.length === 2 ? ' and ' : ', '
    return `${warehouse.entity} has recently changed the price for ${services.join(
        separator,
    )}. Check the changed price at Order Details and if it suits well for you - please Send booking Request.`
}

type ICarrierBookingFormProps = PropsWithChildren<{
    state: CarrierBookingState
    warehouseInfo: WarehouseForBooking
    publicMode?: boolean
    onOrderCreated?: (id: number) => void
}>

const labels = [
    { text: 'Warehousing Start and End Date', services: [0] },
    { text: 'Crossdocking date', services: [1] },
    { text: 'Shrink wrapping date', services: [2] },
    { text: 'Distribution', services: [4] },
    { text: 'Transloading', services: [5] },
]

const dateFormat = 'YYYY-MM-DD'

export const CarrierBookingForm: FC<ICarrierBookingFormProps> = memo(
    // eslint-disable-next-line complexity
    ({ state, warehouseInfo, publicMode, onOrderCreated }) => {
        const [submitted, setSubmitted] = useState<boolean>(false)
        const [bookingSettings, setBookingSettings] = useState<BookingSettings>({})
        const [requestInProgress, setRequestInProgress] = useState(false)
        const [priceLoading, setPriceLoading] = useState(false)
        const [distanceLoading, setDistanceLoading] = useState(false)
        const [[minArrivalFrom, maxArrivalFrom], setMinMaxArrivalFrom] = useState<[string, string]>([
            DEFAULT_MIN_ARRIVAL_HOURS_FROM,
            CarrierBookingFormUtils.getDefaultMaxArrivalHoursFrom(),
        ])
        const [[minArrivalTo, maxArrivalTo], setMinMaxArrivalTo] = useState<[string, string]>([
            CarrierBookingFormUtils.getDefaultMinArrivalHoursTo(),
            DEFAULT_MAX_ARRIVAL_HOURS_TO,
        ])

        // eslint-disable-next-line @typescript-eslint/naming-convention
        const { groups, groupedServices, searchData, serviceDict, physical_address, utc_offset_title } = state
        const debouncedTimeoutRef = useRef<number>()
        const debouncedTimeoutDistanceRef = useRef<number>()
        const debouncedTimeoutSettingsRef = useRef<number>()
        const debouncedTimeoutData = useRef<{ state: CarrierBookingState; values: ICarrierBookingForm }>()

        const [price, setPrice] = useState<any>()
        const [distance, setDistance] = useState<any>()
        const [datepickerLabel, setDatepickerLabel] = useState<string | undefined>('')
        const [modificators, setModificators] = useState<WarehouseServiceMapped[]>([])
        const { data: env, isLoading: envLoading } = useEnv()
        const {
            data: carrierData,
            loading: carrierDataPending,
            mutate: carrierMutate,
            revalidate: revalidateCarrier,
        } = useCarrier()
        const { handleCardAdded, handleRemoveCard, cardListPending } = useCards({
            mutate: carrierMutate,
            data: carrierData,
        })
        const [uploadedDocuments, setUploadedDocuments] = useState<OrderDocument[]>([])

        const inputFile = useRef<HTMLInputElement>(null)

        const { data: infoData, isLoading: infoLoading } = useInfo()
        // this is example
        const { uploadDocument, removeDocument, isLoading, loadingEntityId } = usePalletsReworkPhotosRequests({
            warehouseId: warehouseInfo.id,
        })

        const onFileInputChange = useCallback(async () => {
            if (inputFile.current?.files) {
                const document = await uploadDocument(inputFile.current?.files)

                if (document) {
                    setUploadedDocuments((prev) => [...prev, document])
                }
            }
        }, [uploadDocument])

        const onUploadDocument = () => {
            inputFile?.current?.click()
        }

        const onRemoveDocument = useCallback(
            async (docId: number) => {
                await removeDocument(docId)
                setUploadedDocuments((prev) => prev.filter((el) => el.id !== docId))
            },
            [removeDocument],
        )

        const navigate = useNavigate()

        const createNewOrder = useCallback(
            async (status, values, setError) => {
                if (status) {
                    try {
                        setRequestInProgress(true)
                        const data = await createOrder(state.id, values, {
                            range: searchData.distance,
                        })

                        ModalRegistry.get().close(Modals.ConfirmOrderModal)
                        if (data.status.short_title === 'LTW pending') {
                            await showInfoModalAsync({
                                props: {
                                    title: 'info',
                                    message:
                                        'Thank you for order! Your request start to proceed by our staff and we will connect you ASAP for details',
                                },
                            })
                        }
                        if (!publicMode) {
                            navigate(`/carrier/order/${data.id}`)
                        }

                        onOrderCreated?.(data.id)
                    } catch (errors: any) {
                        if (errors) {
                            const errorKeys = Object.keys(errors)
                            for (let i = 0; i < errorKeys.length; i += 1) {
                                const errorKey = errorKeys[i]
                                setError(errorKey, errors[errorKey])
                            }
                            showError(errors)
                        }
                    } finally {
                        setRequestInProgress(false)
                    }
                }
            },
            [navigate, searchData.distance, state.id],
        )

        const {
            values,
            validateForm,
            handleSubmit,
            handleChange,
            setFieldValue,
            setValues,
            isSubmitting,
            setFieldError,
            errors,
            isValid,
        } = useFormik<ICarrierBookingForm>({
            validateOnChange: submitted,
            validateOnMount: false,
            isInitialValid: true,
            initialValues: {
                group: searchData.service,
                subServicesOptional: [],
                amount: searchData.number_of_units ?? 1,
                period_start: dayjs().utcOffset(Number(state.utc_offset)).startOf('day').format(dateFormat),
                period_end: dayjs()
                    .utcOffset(Number(state.utc_offset))
                    .endOf('day')
                    .add(Number(searchData.number_of_days || 1), 'day')
                    .format(dateFormat),
                delivery: searchData.localDelivery ? searchData.delivery : undefined,
                number_of_days: searchData.number_of_days,
                range: true,
                comment: '',
                documents: [],
                modificators: [],
                payment_strategy: carrierData?.default_payment_strategy ?? PAYMENT_STRATEGIES.card.value,
            },
            onSubmit: async (v) => {
                if (quotationRequest) {
                    return handleQuoteRequest()
                }
                const data: any = await getBookingPrice(state, v)
                if (data.total !== price.total) {
                    await showInfoModalAsync({
                        props: {
                            message: getPriceChangeMessage(state, data, price),
                        },
                    })
                }

                if (v.payment_strategy !== PAYMENT_STRATEGIES.directInvoicing.value) {
                    showConfirmOrderModal({
                        props: {
                            data: {
                                ...v,
                                warehouse: state,
                                total: price.total,
                            },
                            minDaysForLTW: env?.minDaysForLTW || 0,
                            onConfirm: (status) => createNewOrder(status, v, setFieldError),
                        },
                    })
                } else {
                    createNewOrder(true, v, setFieldError)
                }
            },
            validate: (v) => {
                let wrongInterval = false
                let noDistributionAddress = false
                if (v.arrival_from && v.arrival_to) {
                    const options = {
                        start: v.arrival_from,
                        end: v.arrival_to,
                        periodStart: v.period_start,
                        utcOffset: Number(state.utc_offset),
                        workTimeFrom: state.work_time_from,
                        workTimeTo: state.work_time_to,
                    }
                    wrongInterval = !CarrierBookingFormUtils.checkArrivalTimeValidity(options)
                }
                const hasDelivery = (v.delivery ?? 0) > 0

                if (hasDelivery && !v.distribution_address) {
                    noDistributionAddress = true
                }

                const result: any = {
                    arrival_interval: wrongInterval && 'Invalid interval',
                    delivery: noDistributionAddress && 'Address required',
                }

                if (bookingSettings?.loadNumberRequired && !v.load_number) {
                    result.load_number = 'Load number required'
                }

                if (bookingSettings?.containerNumberRequired && !v.container_number) {
                    result.container_number = 'Container number required'
                }

                if (Object.keys(result).reduce((prev, curr) => prev && !result[curr], true)) {
                    return {}
                }
                return result
            },
        })

        const activeUnity = serviceDict[values.group]?.unity?.code ?? 0

        debouncedTimeoutData.current = { state, values }

        useEffect(() => {
            if (debouncedTimeoutDistanceRef.current) {
                clearTimeout(debouncedTimeoutDistanceRef.current)
            }
            const distributionAddress = values.distribution_address
            if ((distributionAddress?.length ?? 0) > 3) {
                setDistanceLoading(true)
                debouncedTimeoutDistanceRef.current = window.setTimeout(async () => {
                    if (debouncedTimeoutData.current) {
                        const distanceData = (
                            await getDistance(debouncedTimeoutData.current.state.id, distributionAddress!)
                        ).data
                        setDistance(distanceData)
                        setFieldError('distribution_address', distanceData.error)
                        setDistanceLoading(false)
                    }
                }, 500)
            }
        }, [values.distribution_address])

        const updatePrice = useCallback(() => {
            if (debouncedTimeoutRef.current) {
                clearTimeout(debouncedTimeoutRef.current)
            }
            setPriceLoading(true)
            debouncedTimeoutRef.current = window.setTimeout(async () => {
                if (debouncedTimeoutData.current) {
                    setPrice(
                        await getBookingPrice(debouncedTimeoutData.current.state, debouncedTimeoutData.current.values),
                    )
                    setPriceLoading(false)
                }
            }, 500)
        }, [])

        const updateSettings = useCallback(() => {
            if (debouncedTimeoutSettingsRef.current) {
                clearTimeout(debouncedTimeoutSettingsRef.current)
            }
            debouncedTimeoutSettingsRef.current = window.setTimeout(async () => {
                if (debouncedTimeoutData.current) {
                    const resp = await getBookingSettings(
                        debouncedTimeoutData.current.state,
                        debouncedTimeoutData.current.values,
                    )
                    setBookingSettings(extractBookingSettings((resp as any) || []))
                }
            }, 500)
        }, [])

        const { handleQuoteRequest, quotationRequest } = useQuotationRequest({
            queryData: searchData,
            isLoading,
            controlled: true,
        })

        const updateArrival = useCallback(() => {
            const { minFrom, maxFrom, minTo, maxTo, toHours, fromHours } = CarrierBookingFormUtils.getArrivalTimeData(
                values.period_start,
                Number(state.utc_offset),
                state.work_time_from,
                state.work_time_to,
            )
            setMinMaxArrivalFrom([minFrom, maxFrom])
            setMinMaxArrivalTo([minTo, maxTo])

            if (
                !values.arrival_from ||
                !values.arrival_to ||
                CarrierBookingFormUtils.isTimeBefore(values.arrival_from, minFrom) ||
                CarrierBookingFormUtils.isTimeAfter(values.arrival_to, maxTo)
            ) {
                setValues(
                    (v) => ({
                        ...v,
                        arrival_from: fromHours,
                        arrival_to: toHours,
                    }),
                    false,
                )
            }
        }, [setValues, state.utc_offset, state.work_time_from, state.work_time_to, values])

        useEffect(() => {
            if (
                publicMode &&
                infoData &&
                infoData.public_booking_usdot_required &&
                carrierData &&
                !licenseValid(carrierData!)
            ) {
                updateCarrierLicenseInDialog({ carrier: carrierData!, onSuccess: (status) => revalidateCarrier(true) })
            }
        }, [infoLoading, carrierDataPending])

        const handleBookingCardAdded = useCallback(
            (card) => {
                handleCardAdded(card)
                if (card.is_default) {
                    setFieldValue('card_id', card.id)
                }
            },
            [handleCardAdded, setFieldValue],
        )

        const updatePeriod = useCallback(() => {
            const [start, end] = CarrierBookingFormUtils.getStartEndDate(
                Number(state.utc_offset),
                state.work_time_from,
                state.work_time_to,
                searchData.number_of_days,
            )

            setValues(
                (v) => ({
                    ...v,
                    period_start: start,
                    period_end: end,
                    range: true,
                }),
                false,
            )
            updateArrival()
            updatePrice()
        }, [
            state.utc_offset,
            state.work_time_from,
            state.work_time_to,
            searchData.number_of_days,
            setValues,
            updateArrival,
            updatePrice,
        ])

        const handleServiceGroupChange = useCallback(() => {
            const active = serviceDict[values.group]
            const unityCode = active?.unity?.code ?? 0
            const termsRelated = active?.terms_related
            setDatepickerLabel(labels.find((item) => item.services.includes(unityCode))?.text)
            if (termsRelated && values.period_start === values.period_end) {
                updatePeriod()
            } else {
                setFieldValue('range', termsRelated, false)
                if (!termsRelated) {
                    setFieldValue('period_end', values.period_start, false)
                }
            }
            updateArrival()
            updatePrice()
            updateSettings()
        }, [
            serviceDict,
            setFieldValue,
            updateArrival,
            updatePeriod,
            updatePrice,
            values.group,
            values.period_end,
            values.period_start,
        ])

        // eslint-disable-next-line react-hooks/exhaustive-deps
        useEffect(handleServiceGroupChange, [handleServiceGroupChange])

        const handlePeriodChange = useCallback(
            ({ start, end }) => {
                setValues((v) => ({
                    ...v,
                    period_start: start?.format(dateFormat),
                    period_end: end?.format(dateFormat),
                }))

                updatePrice()
                if (submitted) {
                    validateForm()
                }
            },
            [setValues, updatePrice, validateForm, submitted],
        )

        const handleCardChange = useCallback(
            (value) => {
                setFieldValue('card_id', value)
            },
            [setFieldValue],
        )

        const handleServiceChange = useCallback(
            (e: ChangeEvent<any>) => {
                const { name, value } = e.target

                if (name === 'group') {
                    let additionalValues: Partial<ICarrierBookingForm> = {}
                    if (values.group && values.group !== value.group) {
                        additionalValues = {
                            delivery: undefined,
                            subService: undefined,
                            subServicesOptional: [],
                        }
                    }
                    setValues((v) => ({
                        ...v,
                        ...additionalValues,
                        group: value.group,
                        modificators: (value.mods ?? []).map((i: WarehouseServiceMapped) => i.code),
                    }))
                    setModificators(value.mods || [])
                } else if (name.startsWith('subService_')) {
                    const optionalService = Number(name.replace('subService_', ''))

                    const updated = [...values.subServicesOptional]
                    if (!value) {
                        updated.splice(updated.indexOf(optionalService), 1)
                    } else {
                        updated.push(optionalService)
                    }
                    setFieldValue('subServicesOptional', updated, false)
                } else {
                    handleChange(e)
                }

                updatePrice()
                updateSettings()
            },
            [updatePrice, values, setValues, setFieldValue, handleChange],
        )

        const handleSelectArrivalFrom = useCallback(
            (e) => {
                setValues((v) => ({
                    ...v,
                    arrival_from: e.value,
                    arrival_to: CarrierBookingFormUtils.getToHours(e.value),
                }))
            },
            [setValues],
        )

        const handleSelectArrivalTo = useCallback(
            (e) => {
                setValues((v) => ({
                    ...v,
                    arrival_from: CarrierBookingFormUtils.getFromHours(e.value),
                    arrival_to: e.value,
                }))
            },
            [setValues],
        )

        useEffect(() => {
            if (!carrierDataPending) {
                setFieldValue('card_id', carrierData?.cards.find((card) => card.is_default)?.id)
            }
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [carrierDataPending])

        const rangeDays = useMemo(
            () => dayjs(values.period_end).diff(dayjs(values.period_start), 'days'),
            [values.period_start, values.period_end],
        )

        const handleAmountChange = useCallback(
            (e) => {
                handleChange(e)
                updatePrice()
            },
            [handleChange, updatePrice],
        )

        useEffect(() => {
            updatePrice()
            updateSettings()
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [])

        useEffect(() => {
            const uploadDocuments = async () => {
                const documents = await getOrderDocuments(warehouseInfo.id)
                setUploadedDocuments(documents)
                return documents
            }

            uploadDocuments()
        }, [warehouseInfo.id])

        useEffect(() => {
            handleChange({
                target: {
                    name: 'documents',
                    value: uploadedDocuments.map((el) => el.id),
                },
            })
        }, [handleChange, uploadedDocuments])

        const totalSection = useRef<HTMLDivElement | null>(null)
        const bannerSummary = useMemo(
            () => `${utils.pluralize(Number(values.amount), 'pallet')} x ${serviceDict[values.group]?.title}`,
            [serviceDict, values.amount, values.group],
        )

        const cards = (
            <>
                <Cards
                    loading={cardListPending || carrierDataPending}
                    cardsList={carrierData?.cards ?? []}
                    onChange={handleCardChange}
                    onDelete={handleRemoveCard}
                    onCardAdded={handleBookingCardAdded}
                    label="+ Add"
                    value={values.card_id}
                />
            </>
        )
        const isDirectInvoicing = values.payment_strategy === PAYMENT_STRATEGIES.directInvoicing.value

        const paymentOptions: any[] = [
            {
                value: 1,
                title: 'Card Payment',
                children: cards,
            },
        ]

        if (bookingSettings.allowDI) {
            paymentOptions.push({ value: 2, title: 'Direct Invoicing' })
        }

        const shortTermRequest = !rangeDays || envLoading || (rangeDays ?? 0) < (env?.minDaysForLTW ?? 0)

        const handleFormSubmit = (event: FormEvent<HTMLFormElement>) => {
            setSubmitted(true)
            handleSubmit(event)
        }

        const submitBtnLabel = quotationRequest
            ? 'Send Quote Request'
            : isDirectInvoicing
            ? 'Confirm Rates and Book'
            : 'Send Booking Request'
        const submitDisabled =
            !isValid || envLoading || isLoading || requestInProgress || priceLoading || distanceLoading
        const submitLoading = isSubmitting || requestInProgress
        return (
            <form id="booking-form" className={cn('form', CLASS_NAME)} onSubmit={handleFormSubmit}>
                <CarrierBookingSection className={cn(`${CLASS_NAME}__section`, `${CLASS_NAME}__section--details`)}>
                    <CarrierBookingSection.Title>Order Details</CarrierBookingSection.Title>
                    <div className="prices">
                        {groups &&
                            Array.from(groups.values())
                                .filter((group) => {
                                    const services = groupedServices[group]
                                    return services?.[0].code === values.group
                                })
                                .map((group) => (
                                    <WarehouseServiceGroup
                                        key={group}
                                        group={values.group}
                                        subService={values.subService}
                                        subServicesOptional={values.subServicesOptional}
                                        delivery={values.delivery}
                                        services={groupedServices[group]}
                                        onChange={handleServiceChange}
                                    />
                                ))}
                    </div>

                    <div className="form-group">
                        <div className="field-row">
                            <div className="field">
                                <div className="label">{datepickerLabel}</div>

                                <Datepicker
                                    start={values.period_start}
                                    end={values.period_end}
                                    range={values.range}
                                    past={false}
                                    minInterval={1}
                                    onChange={handlePeriodChange}
                                    hasReset={false}
                                    errorHide
                                />
                                {values.range && <div className="days-count">{utils.pluralize(rangeDays, 'day')}</div>}
                            </div>

                            <div className="field">
                                <InputText
                                    name="amount"
                                    value={values.amount}
                                    label="Number of pallets"
                                    onChange={handleAmountChange}
                                    disabled={requestInProgress}
                                />
                            </div>
                        </div>

                        {(values.delivery ?? 0) > 0 && (
                            <div className="field distribution-address">
                                <InputLocation
                                    allowLocationSelect
                                    label="Distribution place"
                                    value={values.distribution_address}
                                    placeholder="Distribution place"
                                    hiddenAddress={physical_address.address}
                                    name="distribution_address"
                                    onChange={handleChange}
                                    errorOnFocus
                                    disabled={requestInProgress}
                                    error={errors.distribution_address ?? errors.delivery}
                                    errorHide={!errors.distribution_address}
                                    textError
                                />
                                {!distanceLoading && distance && distance.distances && (
                                    <DistanceInfo driving={distance.distances.distance} />
                                )}
                                {distanceLoading && <Loader type="small" />}
                            </div>
                        )}
                    </div>

                    <div className="form-group time-interval">
                        <div className="title">Estimated Arrival Time</div>

                        <div className="time-offset">{utc_offset_title}</div>

                        <div className="field-row">
                            <div className="field">
                                <div className="label">From</div>
                                <SelectTime
                                    disabled={requestInProgress}
                                    value={values.arrival_from}
                                    onAction={handleSelectArrivalFrom}
                                    name="arrival_from"
                                    min={minArrivalFrom}
                                    max={maxArrivalFrom}
                                    error={errors.arrival_from}
                                />
                            </div>

                            <div className="field">
                                <div className="label">To</div>
                                <SelectTime
                                    disabled={requestInProgress}
                                    value={values.arrival_to}
                                    onAction={handleSelectArrivalTo}
                                    name="arrival_to"
                                    min={minArrivalTo}
                                    max={maxArrivalTo}
                                    error={errors.arrival_to}
                                />
                            </div>
                        </div>

                        <div className="w-error">
                            {errors.arrival_interval && <div className="error">{errors.arrival_interval}</div>}
                        </div>
                    </div>

                    {/* For Pallets rework only */}
                    {activeUnity === consts.unities.loadsRepair && (
                        <div className="form-group">
                            <PhotosForm
                                maxPhotos={!infoLoading ? infoData.max_carrier_order_photos : 3}
                                onUploadClick={onUploadDocument}
                                onDeleteClick={onRemoveDocument}
                                isLoading={isLoading}
                                loadingEntityId={loadingEntityId}
                                photos={uploadedDocuments}
                                isSecured
                            />
                            <input
                                id="order-photos"
                                type="file"
                                ref={inputFile}
                                onChange={onFileInputChange}
                                disabled={requestInProgress}
                                name="order-photos"
                                // accept={mimes}
                                hidden
                            />
                        </div>
                    )}
                </CarrierBookingSection>
                <div className={cn(`${CLASS_NAME}__section`, `${CLASS_NAME}__section--total`)} ref={totalSection}>
                    <CarrierBookingSection>
                        <CarrierBookingSection.Title>
                            <span>Total Cost</span>
                            <div className={`${CLASS_NAME}__cost`}>
                                {priceLoading || !price ? <Loader type="list" /> : <Currency value={price.total} />}
                            </div>
                        </CarrierBookingSection.Title>

                        <div className={`${CLASS_NAME}__order-params`}>
                            <div className="list main">
                                {serviceDict[values.group] && (
                                    <div className="item">{serviceDict[values.group].title}</div>
                                )}
                                {modificators.map((mod) => (
                                    <div className="item modificator" key={mod.code}>
                                        {mod.title}
                                    </div>
                                ))}
                                {`${utils.pluralize(Number(values.amount), 'pallet')}`}
                            </div>

                            {values.delivery && (
                                <div className="delivery">
                                    <div className="list">
                                        {serviceDict[values.delivery] && (
                                            <div className="item">{serviceDict[values.delivery].title}</div>
                                        )}
                                    </div>
                                </div>
                            )}

                            {values.subService && (
                                <div className="subservice">
                                    <div className="list">
                                        {serviceDict[values.subService] && (
                                            <div className="item">{serviceDict[values.subService].title}</div>
                                        )}
                                    </div>
                                </div>
                            )}

                            {values.subServicesOptional.length > 0 && (
                                <div>
                                    {values.subServicesOptional.map((subService) => (
                                        <div className="subservice" key={subService}>
                                            <div className="list">
                                                {serviceDict[subService] && (
                                                    <div className="item">{serviceDict[subService].title}</div>
                                                )}
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            )}
                        </div>

                        <dl className={`${CLASS_NAME}__summary`}>
                            {values.distribution_address && (
                                <>
                                    <dt>Distribution place</dt>
                                    <dd>{values.distribution_address}</dd>
                                </>
                            )}
                            <dt>{datepickerLabel}</dt>
                            <dd>
                                {values.range ? (
                                    <>
                                        {dayjs(values.period_start).format('MMM DD, YYYY')} —{' '}
                                        {dayjs(values.period_end).format('MMM DD, YYYY')}
                                        &nbsp;
                                        {`(${utils.pluralize(rangeDays, 'day')})`}
                                    </>
                                ) : (
                                    dayjs(values.period_end).format('MMM DD, YYYY')
                                )}
                            </dd>

                            <dt>Estimated Arrival Time</dt>
                            <dd>
                                {dayjs(values.arrival_from, 'HH:mm').format('hh:mm a')} —{' '}
                                {dayjs(values.arrival_to, 'HH:mm').format('hh:mm a')}
                            </dd>
                        </dl>

                        <div className="prices">
                            {!priceLoading && price && price.discount ? (
                                <div className="item discount">
                                    <div className="name">Discount {price.discount_percent} %</div>
                                    <div className="value">
                                        - <Currency value={price.discount} />
                                    </div>
                                </div>
                            ) : null}
                        </div>
                        <div className="field">
                            <div className="label">Payment method</div>

                            {shortTermRequest && (
                                <SelectorWithContent
                                    loading={envLoading || cardListPending}
                                    width={400}
                                    value={values.payment_strategy}
                                    options={paymentOptions}
                                    onChange={(val) => {
                                        if (!requestInProgress) {
                                            setFieldValue('payment_strategy', val)
                                        }
                                    }}
                                />
                            )}

                            {!shortTermRequest && (
                                <>
                                    <div className="label">Long Term Warehousing Request</div>
                                    <div className="description green">No payment needed at this moment</div>
                                </>
                            )}
                            {isDirectInvoicing && (
                                <>
                                    <div className="label">Direct Invoicing (B2B)</div>
                                    <div className="description green">No payment needed at this moment</div>
                                </>
                            )}
                        </div>

                        <div className="field">
                            <InputText
                                disabled={requestInProgress}
                                name="load_number"
                                error={errors.load_number}
                                textError
                                value={values.load_number}
                                required={bookingSettings?.loadNumberRequired}
                                label={
                                    bookingSettings?.loadNumberRequired
                                        ? 'Internal load number'
                                        : 'Internal load number (IF EXISTS)'
                                }
                                onChange={handleChange}
                                text
                            />
                        </div>

                        {activeUnity === consts.unities.transloading && (
                            <div className="field">
                                <InputText
                                    disabled={requestInProgress}
                                    name="container_number"
                                    error={errors.container_number}
                                    textError
                                    value={values.container_number}
                                    required={bookingSettings?.containerNumberRequired}
                                    label={
                                        bookingSettings?.containerNumberRequired
                                            ? 'Container number'
                                            : 'Container number (IF EXISTS)'
                                    }
                                    onChange={handleChange}
                                    text
                                />
                            </div>
                        )}

                        <div className="field comment">
                            <Textarea
                                disabled={requestInProgress}
                                value={values.comment}
                                error={errors.comment}
                                onChange={handleChange}
                                name="comment"
                                label="Order Comment"
                                placeholder="For example, the approximate time of arrival at the warehouse..."
                            />
                        </div>
                        <Button
                            types={['blue', 'large', 'full']}
                            label={submitBtnLabel}
                            type="submit"
                            disabled={submitDisabled}
                            loading={submitLoading}
                            className={`${CLASS_NAME}__submit`}
                        />
                    </CarrierBookingSection>
                </div>

                <CarrierBookingWarehouseInfo
                    className={cn(`${CLASS_NAME}__section`, `${CLASS_NAME}__section--warehouse`)}
                    warehouseInfo={warehouseInfo}
                />

                {totalSection.current && (
                    <MobileFixedBanner
                        spyElement={totalSection.current}
                        buttonLabel={submitBtnLabel}
                        buttonProps={{
                            disabled: submitDisabled,
                            loading: submitLoading,
                            form: 'booking-form',
                            onClick: () => {},
                            className: 'booking-mobile-btn',
                        }}
                        checkCorners={false}
                    >
                        <div className={`${CLASS_NAME}__banner-wrapper`}>
                            <div className={`${CLASS_NAME}__banner-inner`}>
                                <span>Total</span>
                                <span className={`${CLASS_NAME}__banner-cost`}>
                                    {priceLoading || !price ? <Loader type="list" /> : <Currency value={price.total} />}
                                </span>
                            </div>
                            <TextCutter
                                className={`${CLASS_NAME}__banner-summary`}
                                title={bannerSummary}
                                maxNumLines={1}
                            >
                                {bannerSummary}
                            </TextCutter>
                        </div>
                    </MobileFixedBanner>
                )}
            </form>
        )
    },
)
