import React, { FC, FormEvent, memo, useEffect, useRef, useState } from 'react'
import { ModalRegistry } from '@shared/common/modals'
import { ModalPartialProps, Modals, OrderAdjustment } from '@shared/types'
import { toast } from 'react-toastify'

import Button from '@/components/Button'
import Textarea from '@/components/Textarea'
import './CostAdjustmentModal.scss'
import Select from '@/components/Select'
import { InputText } from '@/components/InputText'
import { removeCostAdjustment } from '@shared/api/order'
import { showError } from '@/parts/Modals/InfoModal'
import { getUrl, removeAdjustmentDocument, uploadAdjustmentDocument } from '@shared/api/files'
import { useCostAdjustmentForm } from '@shared/forms/CostAdjustment'
import { EditableDocument } from '@/components/LoadedDocument'
import { UploadButton } from '@/components/UploadButton'

interface CostAdjustmentModalProps {
    editable: boolean
    orderId: number
    element?: OrderAdjustment
    onUpdate?: () => void
}

const CostAdjustmentModal: FC<CostAdjustmentModalProps> = ({ editable, orderId, element, onUpdate }) => {
    const [submitted, setSubmitted] = useState<boolean>()
    const [loading, setLoading] = useState<boolean>()
    const [errorMessage, setErrorMessage] = useState<{ field: string; msg: string } | null>(null)
    const formik = useCostAdjustmentForm({
        id: orderId,
        adjustment: element,
        validateOnChange: submitted,
        onComplete: () => {
            onUpdate?.()
            ModalRegistry.get().close(Modals.CostAdjustmentModal)
        },
        onError: (errors) => {
            const errStrings: string[] = []
            Object.keys(errors).forEach((k) => {
                errStrings.push(...errors[k])
            })
            showError(errStrings.join(', '))
        },
    })

    const upload = async (files: FileList | null) => {
        try {
            setErrorMessage(null)
            setLoading(true)

            if (files) {
                // eslint-disable-next-line no-plusplus
                for (let i = 0; i < files.length; i++) {
                    const file = files[i]
                    const formData = new FormData()
                    formData.append('document', file)

                    try {
                        // eslint-disable-next-line no-await-in-loop,@typescript-eslint/naming-convention
                        const response = await uploadAdjustmentDocument(orderId, formik.values.type!, formData)
                        const updatedList = [...formik.values.downloadedDocuments, response]
                        formik.setFieldValue('downloadedDocuments', updatedList)
                    } catch (error) {
                        setErrorMessage({ field: 'downloadedDocuments', msg: 'Error loading files' })
                    }
                }
                setLoading(false)
                toast('Cost adjustment was successfully sent', {
                    type: 'success',
                })
            }
        } catch (e) {
            toast('Error loading file', {
                type: 'error',
            })
            setLoading(false)
        }
    }

    const deleteAdjustment = async () => {
        if (element && element.id) {
            await removeCostAdjustment(orderId, element.id)
            onUpdate?.()
            ModalRegistry.get().close(Modals.CostAdjustmentModal)
            toast('Cost adjustment deleted', {
                type: 'success',
            })
        }
    }
    // eslint-disable-next-line @typescript-eslint/no-shadow
    const removeDocument = async (id: number, type?: number) => {
        try {
            // TODO: remove with get looks odd
            await removeAdjustmentDocument(orderId, id, type || null)
            const updatedDocList = formik.values.downloadedDocuments.filter((d) => d.id !== id)
            formik.setFieldValue('downloadedDocuments', updatedDocList)
        } catch (e) {
            setErrorMessage({ field: 'downloadedDocuments', msg: 'Removing of document failed' })
        }
    }
    const removeAllDocuments = () => {
        formik.values.downloadedDocuments.forEach((item) => {
            removeDocument(item.id)
        })
        formik.setFieldValue('downloadedDocuments', [])
    }

    const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
        setSubmitted(true)
        formik.handleSubmit(event)
    }

    return (
        <form onSubmit={handleSubmit} className="form form-cost-adjustment">
            <div className="fields">
                {errorMessage && <p>{`${errorMessage?.field}: ${errorMessage?.msg}`}</p>}
                <div className="field required">
                    <Select
                        label="Type of adjustment"
                        value={formik.values.type}
                        items={[
                            { name: 'Lumper', value: 5 },
                            { name: 'Detention', value: 6 },
                            { name: 'Additional service', value: 7 },
                        ]}
                        onAction={({ value }) => {
                            removeAllDocuments()
                            formik.setFieldValue('type', value)
                        }}
                        placeholder="Select..."
                        disabled={!editable}
                        name="type"
                    />
                </div>
                <div className="field required">
                    <InputText
                        label="Amount"
                        value={formik.values.amount}
                        error={formik.errors.amount}
                        onChange={formik.handleChange}
                        placeholder="$"
                        disabled={!editable}
                        name="amount"
                        textError
                        errorOnFocus
                        isNumericString
                        type={undefined}
                        decimalScale={2}
                        fixedDecimalScale
                    />
                </div>

                <div className="field">
                    <Textarea
                        label="Description"
                        value={formik.values.description}
                        error={formik.errors.description}
                        onChange={formik.handleChange}
                        disabled={!editable}
                        name="description"
                    />
                </div>
            </div>

            {editable && (
                <div className="cost-adjustment-document-upload">
                    <UploadButton
                        name="confirmation-doc"
                        label="Upload confirmation docs"
                        acceptMimes="['application/pdf', 'image/png', 'image/jpeg']"
                        loading={loading}
                        multiple
                        onFileChange={upload}
                        disabled={!formik.values.type || loading}
                        className="upload-wrap"
                    />
                </div>
            )}

            {formik.values.downloadedDocuments.length > 0 && (
                <div className="cost-adjustment-document-control">
                    {formik.values.downloadedDocuments.map((dFile) => (
                        <EditableDocument
                            className="cost-adjustment-document__loaded-doc"
                            key={dFile.id}
                            url={getUrl(dFile)}
                            filename={dFile.title}
                            target="_blank"
                            canRemove={editable}
                            onRemoveClick={() => removeDocument(dFile.id, formik.values.type!)}
                        />
                    ))}
                </div>
            )}
            {editable && (
                <div className="btns">
                    <Button
                        label="Cancel"
                        types={['plain', 'large']}
                        onClick={() => {
                            ModalRegistry.get().close(Modals.CostAdjustmentModal)
                        }}
                    />
                    <Button
                        label="Send"
                        loading={formik.isSubmitting}
                        disabled={!formik.isValid || !formik.values.type || !formik.values.amount}
                        types={['blue', 'large']}
                        type="submit"
                    />
                    {element && (
                        <Button
                            label="Delete"
                            types={['red', 'large']}
                            className="media-mobile"
                            onClick={deleteAdjustment}
                        />
                    )}
                </div>
            )}
        </form>
    )
}

ModalRegistry.get().register<CostAdjustmentModalProps>(Modals.CostAdjustmentModal, {
    id: 'CostAdjustmentModalProps',
    className: 'modal-cost-adjustment',
    size: 'small',
    Component: memo(CostAdjustmentModal),
    title: 'Cost adjustment',
})

export const showCostAdjustmentModal = (props: ModalPartialProps<CostAdjustmentModalProps>): void =>
    ModalRegistry.get().show<CostAdjustmentModalProps>(Modals.CostAdjustmentModal, props)

export default memo(CostAdjustmentModal)
