import React, { memo, MouseEventHandler, useCallback, useMemo, useState } from 'react'
import NumberFormat, { NumberFormatProps } from 'react-number-format'

import cn from 'classnames'
import './InputText.scss'

export type VerifStatus = 'confirmed' | 'unconfirmed' | 'on-verification'

export interface IInputTextProps extends NumberFormatProps {
    name: string
    classes?: string
    label?: string
    status?: VerifStatus
    icon?: string
    focused?: boolean
    disabled?: boolean
    error?: string
    errorOnFocus?: boolean
    errorHide?: boolean
    required?: boolean
    title?: string
    textError?: boolean
    text?: boolean
    placeholder?: string
    onIconClick?: MouseEventHandler<HTMLDivElement>
}

// eslint-disable-next-line complexity
export const InputText: React.FC<IInputTextProps> = memo(
    ({
        value = '',
        classes,
        type = 'text',
        label,
        title,
        icon,
        required,
        errorHide,
        placeholder,
        disabled,
        text,
        error,
        errorOnFocus,
        status,
        textError,
        onIconClick,
        children,
        mask,
        format,
        onChange,
        ...rest
    }) => {
        const [inputType, setInputType] = useState(type)
        const statusText = useMemo<string | undefined>(() => {
            const map: Record<VerifStatus, string> = {
                confirmed: `${label} has been verified`,
                unconfirmed: `${label} is invalid. Enter the correct ${label}`,
                'on-verification': `${label} is on verification`,
            }
            return status && map[status]
        }, [status, label])
        const [focused, setFocused] = useState(false)
        const onFocus = useCallback(() => {
            setFocused(true)
        }, [])
        const onBlur = useCallback(() => {
            setFocused(false)
        }, [])

        const renderedInput = useMemo(
            () =>
                text ? (
                    <input
                        type={inputType}
                        onFocus={onFocus}
                        onBlur={onBlur}
                        onChange={onChange}
                        placeholder={placeholder || ''}
                        readOnly={disabled}
                        autoCapitalize="off"
                        autoCorrect="off"
                        autoComplete="off"
                        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                        // @ts-ignore
                        value={value}
                        {...rest}
                    />
                ) : (
                    <NumberFormat
                        type={type}
                        mask={mask}
                        format={format}
                        onFocus={onFocus}
                        value={value}
                        onBlur={onBlur}
                        onValueChange={(values) => {
                            onChange?.({ target: { value: values.value, name: rest.name } } as any)
                        }}
                        placeholder={placeholder || ''}
                        readOnly={disabled}
                        autoComplete="off"
                        {...rest}
                    />
                ),
            [text, inputType, onFocus, onBlur, onChange, placeholder, disabled, value, rest, type, mask, format],
        )

        const errorRendered = useMemo(
            () =>
                !errorHide && (
                    <div className="w-error">
                        {!!error && textError && (!focused || errorOnFocus) && <div className="error">{error}</div>}
                    </div>
                ),
            [error, errorHide, textError, focused, errorOnFocus],
        )

        const togglePwMode = useCallback(() => {
            if (type === 'password') {
                setInputType((i) => (i === 'password' ? 'text' : 'password'))
            }
        }, [type])

        return (
            <>
                <div
                    className={cn('input-text', classes, {
                        focused,
                        disabled,
                        'has-error': !!error && (!focused || errorOnFocus),
                        'has-status': !!status,
                        'has-icon': !!icon,
                    })}
                >
                    <div className="input-wrapper">
                        {label && (
                            <div className="label-wrap">
                                {label && <div className="label">{required === true ? `${label}*` : label}</div>}
                            </div>
                        )}
                        <div className="control">
                            {renderedInput}

                            {type === 'password' && (
                                <div
                                    tabIndex={-1}
                                    role="button"
                                    aria-label="icon pw"
                                    onClick={togglePwMode}
                                    className={cn('pw-eye', { hidden: inputType === 'password' })}
                                >
                                    <span className="icon-eye" />
                                </div>
                            )}

                            {statusText && (
                                <div className={cn('status', { [status as string]: true })} title={statusText} />
                            )}

                            {icon && (
                                <div
                                    role="button"
                                    tabIndex={-1}
                                    onClick={onIconClick}
                                    className={cn('icon', `icon-${icon}`, { disabled })}
                                />
                            )}
                        </div>
                        {errorRendered}
                    </div>
                </div>
            </>
        )
    },
)

InputText.displayName = 'InputText'
