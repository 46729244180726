import React, { FC, JSXElementConstructor, ReactElement } from 'react'
import { Route, Navigate, RouteProps } from 'react-router-dom'
import { useUserState } from '@/store/user'

type IPrivateRouteProps = RouteProps & {
    isLoggedIn: boolean
    component: JSXElementConstructor<any>
}

export const PrivateRoute: FC<IPrivateRouteProps> = (props) => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const { isLoggedIn, component: Component } = props

    if (isLoggedIn) {
        if (window.location.pathname === '/' && localStorage.getItem('type')) {
            return <Navigate to={{ pathname: `/${localStorage.getItem('type')}` }} />
        }

        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        return <Component />
    }
    return <Navigate to={{ pathname: '/' }} state={{ from: window.location.pathname }} />
}

export const LoggedInRoute: FC = () => {
    const {
        state: { isLoggedIn },
    } = useUserState()

    if (isLoggedIn) {
        if (window.location.pathname === '/' && localStorage.getItem('type')) {
            return <Navigate to={{ pathname: `/${localStorage.getItem('type')}` }} />
        }

        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        return null
    }
    return <Navigate to={{ pathname: '/' }} state={{ from: window.location.pathname }} />
}
