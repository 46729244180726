import React, { memo, useCallback, useMemo, useState } from 'react'
import Select from '@/components/Select'
import { Card } from '@shared/types/card'
import Button from '@/components/Button'
import { showCardModal } from '@/parts/Modals/CardModal'
import { showConfirmModal } from '@/parts/Modals/ConfirmModal'
import { removeCard, setCard } from '@shared/api/carrier'
import { Carrier } from '@shared/types'
import { CARD_ICON_MAP } from '@shared/common/consts'
import cn from 'classnames'
import './Cards.scss'

interface ICards {
    inputLabel?: string
    cardsList: Card[]
    onCardAdded: (card: Card) => void
    onChange: (id: number) => void
    onDelete?: (id: number) => Promise<void>
    value?: number
    loading?: boolean
    label?: string
}

interface UseCardsOptions {
    mutate: any
    data?: Carrier
}

export const useCards = ({ mutate, data }: UseCardsOptions) => {
    const [cardListPending, setCardListPending] = useState<boolean>(false)
    const handleCardAdded = useCallback(
        (card) => {
            if (data && mutate) {
                mutate({ ...data, cards: [...data.cards, card] }, false)
            }
        },
        [data, mutate],
    )

    const handleCardChange = useCallback(
        async (cardId) => {
            setCardListPending(true)
            await setCard(cardId)
            if (data && mutate) {
                mutate(
                    { ...data, cards: data.cards.map((card) => ({ ...card, is_default: card.id === cardId })) },
                    false,
                )
            }
            setCardListPending(false)
        },
        [data, mutate],
    )

    const handleRemoveCard = useCallback(
        async (cardId) => {
            setCardListPending(true)
            await removeCard(cardId)
            if (data && mutate) {
                mutate({ ...data, cards: data.cards.filter((card) => card.id !== cardId) }, false)
            }
            setCardListPending(false)
        },
        [data, mutate],
    )

    return {
        cardListPending,
        handleCardAdded,
        handleCardChange,
        handleRemoveCard,
    }
}

const Cards: React.FC<ICards> = ({
    label = '+ Add new card',
    cardsList,
    loading,
    onCardAdded,
    inputLabel,
    value,
    onDelete,
    onChange,
}) => {
    const list = useMemo(
        () =>
            cardsList
                .map((card) => ({
                    value: card.id,
                    name: `**** ${card.last4}`,
                    icon: CARD_ICON_MAP[card.brand] || 'unknown',
                }))
                .sort((a, b) => -(a.value - b.value)),
        [cardsList],
    )

    const handleAction = useCallback(
        ({ action, value: v, name }) => {
            if (action === 'select' && onChange) {
                onChange(v)
            } else if (action === 'delete' && onDelete) {
                showConfirmModal({
                    props: {
                        title: `Are you sure you want to remove the card ${name}?`,
                        message: '',
                        onConfirm: async (confirmed) => {
                            if (confirmed) await onDelete(v)
                        },
                    },
                })
            }
        },
        [onChange, onDelete],
    )

    const handleClickAdd = () => {
        showCardModal({
            props: {
                onSuccess: onCardAdded,
            },
        })
    }

    return (
        <>
            <div className="cards">
                <div className="control">
                    <Select
                        name="card"
                        label={inputLabel}
                        loading={loading}
                        items={list}
                        value={value}
                        disabled={loading}
                        editable={!!onDelete}
                        onAction={handleAction}
                    />
                </div>

                <Button
                    label={label}
                    types={['plain', 'bold']}
                    onClick={handleClickAdd}
                    className={cn({ topMargin: !!inputLabel })}
                />
            </div>
        </>
    )
}

export default memo(Cards)
