import React, { FC, memo, useCallback, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import cn from 'classnames'
import FormOrderSearch, { useFilters } from '@/parts/Forms/FormOrderSearch/FormOrderSearch'
import { utils } from '@shared/common/utils'
import { mobile } from '@shared/common/mobile'
import './CarrierOrderList.scss'
import { TableList } from '@/parts/Table/TableList'
import { useCarrierOrders } from '@shared/api/carrier'
import { Helmet } from 'react-helmet'
import { useUserState } from '@/store/user'
import { EntityType } from '@shared/api/types'
import { CARRIER_ORDER_LIST_HEADERS } from '@shared/common/order'
import CarrierOrderListItem from './CarrierOrderListItem/CarrierOrderListItem'

const PER_PAGE = 20
const PAGE_TITLE = 'Olimp - Orders'

interface ICarrierOrderListProps {}

export const CarrierOrderList: FC<ICarrierOrderListProps> = memo(() => {
    const navigate = useNavigate()
    const filters = useFilters()
    const [showForm, setShowForm] = useState<boolean>(!mobile.phone)

    const entityType = localStorage.getItem('type') || EntityType.carrier

    const [page, setPage] = useState(1)
    const [sort, setSort] = useState('-period_start')

    const { state } = useUserState()
    const { data, loading, revalidate } = useCarrierOrders({ ...filters, 'per-page': PER_PAGE, page, sort, entityType })

    const handleFiltersUpdate = useCallback(
        (f) => {
            navigate(`/carrier/orders/${utils.encodeRouteFilters(f)}`)
        },
        [navigate],
    )

    const toggleFormVisibility = useCallback(() => {
        setShowForm(!showForm)
    }, [showForm])

    return (
        <div className="orders-list wrapper">
            <Helmet>
                <title>{PAGE_TITLE}</title>
                <meta name="description" content={PAGE_TITLE} />
            </Helmet>
            <div>
                <div className="page-header-wrapper">
                    <div className="page-header">
                        <div className="page-title">Order List</div>
                        {/* eslint-disable-next-line jsx-a11y/no-static-element-interactions */}
                        <div
                            className={cn('icon-filter', 'media-mobile', { opened: showForm })}
                            onClick={toggleFormVisibility}
                        />
                    </div>
                    {showForm && (
                        <FormOrderSearch onUpdate={handleFiltersUpdate} filters={filters} onRefresh={revalidate} />
                    )}
                </div>

                <div className="list">
                    <TableList
                        headers={CARRIER_ORDER_LIST_HEADERS as any[]}
                        data={data?.items ?? []}
                        sort={sort}
                        pending={loading}
                        page={data?.page}
                        pageCount={data?.pageCount}
                        onUpdate={(p) => {
                            setSort(p.sort)
                            setPage(p.page)
                        }}
                    >
                        {(data?.items ?? []).map((item: any) => (
                            <CarrierOrderListItem key={item.id} {...item} />
                        ))}
                    </TableList>
                </div>
            </div>
        </div>
    )
})

CarrierOrderList.displayName = 'CarrierOrderList'
