import React, { ChangeEventHandler, FC, memo, useCallback, useState } from 'react'
import cn from 'classnames'

import './Textarea.scss'

interface ITextareaProps {
    label?: string
    error?: string
    onChange?: ChangeEventHandler<HTMLTextAreaElement>
    value?: string | number
    name: string
    disabled?: boolean
    placeholder?: string
}

const Textarea: FC<ITextareaProps> = ({ label, name, error, onChange, disabled, placeholder, value }) => {
    const [focused, setFocused] = useState(false)
    const onFocus = useCallback(() => {
        setFocused(true)
    }, [])
    const onBlur = useCallback(() => {
        setFocused(false)
    }, [])
    return (
        <div className={cn('textarea', { focused, disabled, 'has-error': !!error && !focused })}>
            <div className="input-wrapper">
                {label && <div className="label">{label}</div>}

                <textarea
                    value={value}
                    name={name}
                    placeholder={placeholder}
                    readOnly={disabled}
                    onFocus={onFocus}
                    onBlur={onBlur}
                    onChange={onChange}
                    autoComplete="off"
                />
            </div>

            <div className="w-error">{!!error && !focused && <div className="error">{error}</div>}</div>
        </div>
    )
}

export default memo(Textarea)
