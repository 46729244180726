import React, { FC, HTMLAttributes, useCallback, useEffect } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import cn from 'classnames'
import ROUTES from '@/router'
import { DropdownMenu } from '@/components/DropdownMenu'
import { useCompany } from '@shared/api/company'
import { useUser } from '@shared/api/user'
import { updateCompanyState, useCompanyState } from '@/store/company'
import { useMobileWidth } from '@shared/common/hooks'
import { EntityStatus } from '@/components/EntityStatus'

import './AccountMenu.scss'
import { TextCutter } from '@/components/TextCutter'
import { getFullEntityStatus } from '@/utils/status'

const CLASS_NAME = 'account-menu'
interface IMenu extends HTMLAttributes<HTMLDivElement> {
    isOpen: boolean
    isAdmin: boolean
    closeMenu: () => void
}

export const AccountMenu: FC<IMenu> = ({ isOpen, isAdmin, closeMenu, className }) => {
    const { data: userInfo } = useUser()
    const { data: companyInfo } = useCompany()
    const { dispatch } = useCompanyState()
    const navigate = useNavigate()
    const isMobileLayout = useMobileWidth()

    useEffect(() => {
        if (companyInfo) {
            updateCompanyState(companyInfo, dispatch)
        }
    }, [companyInfo, dispatch])

    const logout = useCallback(async () => {
        closeMenu()
        navigate(ROUTES.public.logout)
        window.location.reload()
    }, [closeMenu, navigate])

    if (!userInfo || !companyInfo) return null

    return (
        <DropdownMenu
            label={
                <>
                    <EntityStatus
                        id={companyInfo.id}
                        status={getFullEntityStatus({ status: companyInfo.company_status })}
                    />
                    <TextCutter title={companyInfo.entity}>{companyInfo.entity}</TextCutter>
                </>
            }
            isOpen={isOpen}
            className={cn(CLASS_NAME, className)}
            forceShow={isMobileLayout}
        >
            <DropdownMenu.Item border="bottom" noHover>
                {userInfo.first_name} {userInfo.last_name}
            </DropdownMenu.Item>

            <DropdownMenu.Item>
                <Link to={ROUTES.company.base}>Entities</Link>
            </DropdownMenu.Item>

            {isAdmin && (
                <DropdownMenu.Item>
                    <Link to={'/company' + ROUTES.company.managers}>Managers</Link>
                </DropdownMenu.Item>
            )}

            <DropdownMenu.Item>
                <Link to={'/user' + ROUTES.user.settings}>Account settings</Link>
            </DropdownMenu.Item>

            <DropdownMenu.Item border="top" toBottom>
                <Link to={ROUTES.public.logout} onClick={logout}>
                    <span>Log out</span>
                </Link>
            </DropdownMenu.Item>
        </DropdownMenu>
    )
}
