import React, { FC, memo, useCallback } from 'react'
import cn from 'classnames'
import Checkbox from '@/components/Checkbox'
import { Hint } from '@/components/Hint'
import { consts } from '@shared/common/consts'

import './WarehouseService.scss'

interface IWarehouseServiceProps {
    active: boolean
    type: 'radio' | 'radio-check' | 'checkbox' | null
    disabled?: boolean
    title: string
    onSelect?: (service: number | null, checked?: boolean) => void
    service: number
    priceType: number
    hint?: string
    className?: string
}

export const WarehouseService: FC<IWarehouseServiceProps> = memo(
    ({ active, className, type, priceType, title, hint, disabled, onSelect, service }) => {
        const handleServiceClick = useCallback(() => {
            if (!disabled && (type === 'radio' || type === 'radio-check') && onSelect) {
                onSelect(type === 'radio-check' && active ? null : service)
            }
        }, [type, disabled, onSelect, service, active])

        const handleServiceCheck = useCallback(
            (e) => {
                if (!disabled && onSelect) {
                    onSelect(service, e.target.value)
                }
            },
            [disabled, onSelect, service],
        )

        return (
            <div
                role="button"
                tabIndex={0}
                className={cn('service', className, { active, changeable: type !== null })}
                onClick={handleServiceClick}
            >
                <div className="item">
                    <div className="label">
                        <div>
                            {!disabled && type && type !== 'checkbox' && <div className="control-radio" />}
                            {type === 'checkbox' && (
                                <Checkbox
                                    label=""
                                    html={<span className="title">{title}</span>}
                                    value={active}
                                    onChange={handleServiceCheck}
                                />
                            )}
                            {type !== 'checkbox' && <span className="title">{title}</span>}
                        </div>
                        <div>
                            {hint && <Hint>{hint}</Hint>}
                            <span className="price">{consts.warehouse.prices[String(priceType)]}</span>
                        </div>
                    </div>
                </div>
            </div>
        )
    },
)

WarehouseService.displayName = 'WarehouseService'
