/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { FC, useCallback, useEffect, useMemo } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import cn from 'classnames'
import Checkbox from '@/components/Checkbox'
import { InputText } from '@/components/InputText'
import Button from '@/components/Button'
import Datepicker from '@/components/Datepicker'
import { IWarehouseSearchForm } from '@shared/types'
import InputLocation from '@/components/InputLocation'
import { Slider } from '@/components/Slider'
import { Radio } from '@/components/Radio'
import Select from '@/components/Select'
import { utils } from '@shared/common/utils'
import useWarehouseSearch, { WAREHOUSE_SEARCH_FORM_DEFAULT_VALUES } from '@shared/hooks/useWarehouseSearch'
import { licenseValid } from '@/parts/CarrierStatusInfo/helper'
import { Info } from '@/components/Info'
import { useCarrier } from '@shared/api/carrier'
import './FormWarehouseSearch.scss'
import Tooltip from 'react-tooltip-lite'
import Modal from '@/components/Modal'
import { showOptionsModal } from '@/parts/Modals/OptionsModal'

interface IFormWarehouseSearch {
    className?: string
    searchPage: string
    pending?: boolean
    publicQuery?: boolean
    send?: (search: IWarehouseSearchForm) => void
    initialValues?: IWarehouseSearchForm
    initialFetch?: boolean
}

export const getWarehouseFormFromFilters = (filters?: string): IWarehouseSearchForm => {
    const form = filters ? utils.decodeRouteFilters(filters) : {}
    if (!Object.keys(form).length) return WAREHOUSE_SEARCH_FORM_DEFAULT_VALUES
    const features = Array.isArray(form.features)
        ? form.features.map((i: string) => Number(i))
        : [Number(form.features)]
    const serviceFromParam = Array.isArray(form.services) ? form.services[0] : form.services
    return {
        ...WAREHOUSE_SEARCH_FORM_DEFAULT_VALUES,
        ...form,
        radius: Number(form.radius),
        number_of_units: form.number_of_pallets || form.number_of_units,
        features: form.features ? features : [],
        localDelivery: form.localDelivery === 'true',
        isDistributionLogic: form.isDistributionLogic === 'true',
        isDaysRelatedLogic: form.isDaysRelatedLogic === 'true',
        delivery: Number(form.delivery),
        unit: Number(form.unit),
        service: (form.service ? Number(form.service) : undefined) || Number(serviceFromParam),
        unity: Number(form.unity),
        full_search: form.full_search === 'true',
        distance: Number(form.distance),
    } as IWarehouseSearchForm
}

// eslint-disable-next-line complexity
export const FormWarehouseSearch: FC<IFormWarehouseSearch> = ({
    publicQuery,
    className,
    initialFetch,
    send,
    searchPage,
    pending,
}) => {
    const navigate = useNavigate()
    const { filters } = useParams<{ filters?: string }>()
    const { data } = useCarrier(!publicQuery)

    const initialValues = filters ? getWarehouseFormFromFilters(filters) : undefined

    const handleFormSubmit = useCallback(
        (values) => {
            navigate(`${searchPage}/${utils.encodeRouteFilters(values) || ''}`)
            if (send) {
                send(values)
            }
        },
        [send, navigate, searchPage],
    )

    const {
        validateAllowed,
        settings,
        features,
        unities,
        deliveryItems,
        serviceItems,
        handleStartDateChange,
        handleServiceChange,
        handleDeliveryChange,
        handleLocalDeliveryChange,
        handleFeatureChange,
        formik,
        isLoading,
    } = useWarehouseSearch({ initialFetch, initialValues, onSubmit: handleFormSubmit, send, publicQuery })

    const { values, errors, handleChange, handleSubmit } = formik

    const handleSubmitInternal = useCallback(
        async (e) => {
            e.preventDefault()
            handleSubmit(e)
        },
        [handleSubmit],
    )

    useEffect(() => {
        if (publicQuery) {
            handleSubmit()
        }
    }, [])

    const { isDistributionLogic, isDaysRelatedLogic } = formik.values

    const addOptionsBtnHandler = () => {
        showOptionsModal({
            props: {},
        })
    }

    return (
        <div className={cn('form form-search', className)}>
            {data && !licenseValid(data) && <Info className="warning">Enter a valid USDOT/MC#</Info>}

            <form onSubmit={handleSubmitInternal}>
                <div className="form-wrap">
                    <div className="field address">
                        <InputLocation
                            autoFocus
                            required
                            name="address"
                            value={values.address}
                            onChange={handleChange}
                            label="Address"
                            placeholder="Enter address or zip-code"
                            pin="round"
                            allowLocationSelect={!publicQuery}
                            error={validateAllowed ? errors.address : undefined}
                        />
                    </div>

                    <div className="field amount">
                        <InputText
                            text
                            required
                            value={values.number_of_units}
                            onChange={handleChange}
                            label="Number of Pallets"
                            name="number_of_units"
                            error={validateAllowed ? errors.number_of_units : undefined}
                            textError
                        />

                        {/* {!isDistributionLogic && ( */}
                        {/*    <Tabs */}
                        {/*        name="unit" */}
                        {/*        value={values.unit} */}
                        {/*        onChange={handleUnitChange} */}
                        {/*        list={[ */}
                        {/*            { name: 'Pallets', value: '1' }, */}
                        {/*            { name: 'Sq.Feet', value: '2' }, */}
                        {/*        ]} */}
                        {/*    /> */}
                        {/* )} */}
                    </div>
                    <Slider
                        className="column"
                        value={values.radius}
                        min={settings?.radius?.min ?? 1}
                        max={settings?.radius?.max ?? 300}
                        label="Search radius"
                        onChange={handleChange}
                        name="radius"
                        measure="mls`"
                    />
                </div>
                {/* {getValues('selectedServiceId')} */}
                <div className="form-group">
                    <div className="column services">
                        <Radio
                            loading={isLoading}
                            label="Services"
                            list={unities}
                            type="wrap"
                            name="unity"
                            value={values.unity}
                            onChange={handleChange}
                        />
                        <div className="form-group row">
                            <div className="start_date">
                                {isDistributionLogic && (
                                    <Datepicker
                                        start={values.start_date}
                                        end={values.start_date}
                                        past={false}
                                        label="Start date"
                                        onChange={handleStartDateChange as any}
                                        range={false}
                                        hasReset={false}
                                        errorHide
                                    />
                                )}
                            </div>
                            {isDistributionLogic && (
                                <Checkbox
                                    name="pickup"
                                    onChange={handleChange}
                                    label="Pickup"
                                    value={values.pickup}
                                    className="pickup"
                                />
                            )}
                        </div>
                        <div className="form-group row">
                            <div className="service">
                                <Select
                                    value={values.service}
                                    placeholder="Type value"
                                    label="Type"
                                    loading={isLoading}
                                    name="service"
                                    max={3}
                                    onAction={handleServiceChange}
                                    items={serviceItems}
                                />
                            </div>

                            {isDaysRelatedLogic && (
                                <div className="field days">
                                    <InputText
                                        required
                                        name="number_of_days"
                                        onChange={handleChange}
                                        value={values.number_of_days}
                                        label="Days"
                                        textError
                                        error={validateAllowed ? errors.number_of_days : undefined}
                                    />
                                </div>
                            )}
                        </div>

                        {deliveryItems.length > 0 && (
                            <Checkbox
                                label="Local Delivery"
                                name="localDelivery"
                                value={values.localDelivery}
                                onChange={handleLocalDeliveryChange}
                                // disabled={form.services.delivery.disabled}
                            />
                        )}
                        {deliveryItems.length > 0 && values.localDelivery && (
                            <div className="form-group row delivery">
                                <Select
                                    className="type-delivery"
                                    value={values.delivery}
                                    placeholder="Type value"
                                    name="delivery"
                                    max={3}
                                    onAction={handleDeliveryChange}
                                    items={deliveryItems}
                                />
                            </div>
                        )}

                        {features.length > 0 && (
                            <div className="checkboxes">
                                {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
                                <label>Features</label>
                                {features.map((feature: { name: string; code: number }) => (
                                    <Checkbox
                                        label={feature.name}
                                        value={values.features.indexOf(feature.code) >= 0}
                                        key={feature.code}
                                        name={String(feature.code)}
                                        onChange={handleFeatureChange as any}
                                    />
                                ))}
                            </div>
                        )}
                    </div>
                    <div className="column button">
                        <div className="button-component-wrapper">
                            <Button
                                types={['blue', 'large']}
                                type="submit"
                                disabled={pending || !formik.isValid}
                                loading={pending}
                                label="Search"
                            />
                            <div className="attention">
                                <p className="attention__label">
                                    If you need more options{' '}
                                    <a className="attention__link" onClick={addOptionsBtnHandler}>
                                        click here
                                    </a>
                                    .
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    )
}
