import React, { memo, useCallback, useEffect, useRef, useState } from 'react'
import cn from 'classnames'

import './Dropdown.scss'

export interface IDropdown {
    type?: 'right' | 'left' | 'select'
    disabled?: boolean
    forceHide?: boolean
    forceShow?: boolean
    children: React.ReactNode
    toggleElement?: React.ReactNode
    className?: string
    toggleClass?: string
    open?: boolean
    onToggle?: (state: boolean) => void
}

const Dropdown: React.FC<IDropdown> = ({
    type = 'left',
    toggleClass,
    children,
    onToggle,
    className,
    disabled,
    forceHide,
    forceShow,
    open,
    toggleElement,
}) => {
    const dropdownRef = useRef<HTMLDivElement>(null)
    const [opened, setOpened] = useState(false)
    const toggle = useCallback(() => {
        const newState = !opened
        setOpened(newState)
        if (onToggle) {
            onToggle(newState)
        }
    }, [opened, onToggle])

    const onDocumentClick = useCallback(
        (e) => {
            if (dropdownRef?.current?.contains(e.target)) return
            setOpened(false)
            if (onToggle) {
                onToggle(false)
            }
        },
        [onToggle, dropdownRef],
    )

    useEffect(() => {
        if (forceHide === true) {
            setOpened(false)
        }
    }, [forceHide])

    useEffect(() => {
        if (forceShow) return
        document.addEventListener('mousedown', onDocumentClick)

        // eslint-disable-next-line consistent-return
        return () => {
            document.removeEventListener('mousedown', onDocumentClick)
        }
    }, [forceShow, onDocumentClick])

    useEffect(() => {
        setOpened(Boolean(open))
    }, [open])

    return (
        <div
            ref={dropdownRef}
            className={cn('dropdown', className, type, { opened: forceShow ? true : opened, disabled })}
        >
            <div
                role="button"
                tabIndex={0}
                className={cn('toggle', toggleClass)}
                onClick={forceShow ? undefined : toggle}
            >
                {toggleElement}
            </div>
            <div className={cn('list', { opened })}>{children}</div>
        </div>
    )
}

export default memo(Dropdown)
