import React, { FC, HTMLAttributes, memo } from 'react'
import cn from 'classnames'
import './TablePagination.scss'

interface ITablePagination extends HTMLAttributes<HTMLDivElement> {
    page: number
    pageCount: number
    onPageChange: (page: number) => void
}

const TablePagination: FC<ITablePagination> = ({ page, pageCount, onPageChange }) => {
    if (pageCount < 2) {
        return null
    }

    // eslint-disable-next-line complexity
    const pageStatus = (index: number) => {
        if (page === index) return 'active'
        if (
            (index > page + 2 || index < page - 2) &&
            index !== 1 &&
            index !== pageCount &&
            page > 3 &&
            page < pageCount - 2
        ) {
            return 'hidden'
        }
        if (
            (page <= 4 && index > page + 1 && index > 6 && index !== 1 && index !== pageCount) ||
            (page > pageCount - 4 && index < pageCount - 5 && index < page - 1 && index !== 1 && index !== pageCount)
        ) {
            return 'hidden'
        }

        return ''
    }

    const handleChange = (pageNumber: number) => {
        if (pageNumber >= 1 && pageNumber <= pageCount) {
            onPageChange(pageNumber)
        }
    }

    return (
        <div className="pagination">
            {/* eslint-disable-next-line jsx-a11y/no-static-element-interactions */}
            <div className={cn('arrow', 'left', { disabled: page === 1 })} onClick={() => handleChange(page - 1)}>
                <span className="icon-arrow-right" />
            </div>
            <div className="list">
                {Array.from(Array(pageCount).keys()).map((idx) => (
                    // eslint-disable-next-line jsx-a11y/no-static-element-interactions
                    <div
                        className={cn('page', pageStatus(idx + 1))}
                        key={`key-${idx + 1}`}
                        onClick={() => handleChange(idx + 1)}
                    >
                        {idx + 1}
                    </div>
                ))}
            </div>
            {/* eslint-disable-next-line jsx-a11y/no-static-element-interactions */}
            <div
                className={cn('arrow', 'right', { disabled: page === pageCount })}
                onClick={() => handleChange(page + 1)}
            >
                <span className="icon-arrow-right" />
            </div>
        </div>
    )
}

export default memo(TablePagination)
