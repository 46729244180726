import React, { FC, useEffect, useState } from 'react'
import { SignUpTemplate } from '@/pages/SignUp/components/SignUpTemplate'
import { useFormik } from 'formik'
import { apiVersion, Http } from '@shared/api/Http'
import { Register } from '@shared/api/types'
import { RegisterSchema } from '@/parts/Forms/Register/RegisterForm'
import { InputText } from '@/components/InputText'
import Checkbox from '@/components/Checkbox'
import { Link, useLocation } from 'react-router-dom'
import Button from '@/components/Button'
import { updateLoggedState, useUserState } from '@/store/user'
import { useReferralInfo } from '@shared/api/user'
import { utils } from '@shared/common/utils'
import { useNavigate } from 'react-router'
import { showFeedbackModal } from '@/parts/Modals/FeedbackModal'
import { Loader } from '@/components/Loader'
import { useOneTimeSessionData } from '@/hooks/useOneTimeSessionData'
import { getOLIMPLandingURL } from '@/utils/url'

const stripeAgreementLabel = (
    <>
        {' '}
        and{' '}
        <a href="https://stripe.com/connect-account/legal" target="_blank" rel="noreferrer">
            Stripe Connected Account Agreement
        </a>
    </>
)

interface SignUpRegisterStepProps {}
export const SignUpRegisterStep: FC<SignUpRegisterStepProps> = () => {
    const [validateOnChange, setValidateOnChange] = useState(false)
    const [offerLogin, setOfferLogin] = useState(false)
    const navigate = useNavigate()
    const location = useLocation()
    const [refId, setRefId] = useState<string | undefined>(
        utils.getSearchParamByName(location.search, 'refId') ?? undefined,
    )
    const { data, loading, error } = useReferralInfo(refId)
    const { consume } = useOneTimeSessionData('signupValues')

    const { state: userState, dispatch } = useUserState()
    const formik = useFormik({
        initialValues: {
            email: '',
            password: '',
            confirmPassword: '',
            agreement: true,
            refId: '',
        },
        onSubmit: async ({ email, password }) => {
            try {
                const response = await Http.post<{ email: string; password: string }, Register>(
                    `/app/${apiVersion}/registration/sign-up`,
                    {
                        email,
                        password,
                        refId,
                    },
                )
                formik.setSubmitting(false)

                Http.defaults.headers.common['Auth-Token'] = response.token
                localStorage.setItem('token', response.token)
                localStorage.setItem('type', response.type)
                if (data?.logo) {
                    localStorage.setItem('refLogo', data?.logo)
                } else {
                    localStorage.removeItem('refLogo')
                }

                await updateLoggedState(
                    {
                        ...response.info,
                        registration_step: 1,
                    },
                    dispatch,
                )
                const nativeWebView = (window as any).ReactNativeWebView
                if (nativeWebView) {
                    nativeWebView.postMessage(JSON.stringify({ email, password })) // this line for react native props data
                }
            } catch (e: any) {
                formik.setSubmitting(false)
                const em = e?.email?.[0]
                if (em?.includes('has already been')) {
                    setOfferLogin(true)
                } else {
                    formik.setErrors(e)
                }
            }
        },
        validationSchema: RegisterSchema,
        validateOnMount: false,
        validateOnBlur: false,
        validateOnChange,
        enableReinitialize: true,
    })

    const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        setValidateOnChange(true)
        e.preventDefault()
        formik.handleSubmit(e)
    }

    const signIn = () => {
        navigate(`/login?email=${formik.values.email}`)
    }

    const feedback = () => {
        showFeedbackModal({
            props: {},
        })
    }

    useEffect(() => {
        formik.setFieldValue('email', consume('email'))
    }, [])

    if (loading) {
        return <Loader />
    }

    if (offerLogin) {
        return (
            <SignUpTemplate
                refLogo={data?.logo}
                refDescription={data?.intro}
                title="You already have an account"
                onBack={() => setOfferLogin(false)}
            >
                <Button label="Sign in" onClick={signIn} types={['blue', 'full']} />
                <div className="bottom-footer">
                    <small>
                        {/* eslint-disable-next-line jsx-a11y/anchor-is-valid,jsx-a11y/no-static-element-interactions */}
                        <a onClick={feedback} className="secondary-link media-desktop">
                            Contact support
                        </a>
                    </small>
                </div>
            </SignUpTemplate>
        )
    }

    return (
        <SignUpTemplate
            title="Create account"
            progress={userState?.signUp?.chosenEntityType ? 100 / 5 : 100 / 6}
            refLogo={data?.logo}
            refDescription={data?.intro}
        >
            <form onSubmit={handleSubmit}>
                <InputText
                    autoFocus
                    text
                    label="E-mail"
                    name="email"
                    placeholder="E-mail"
                    className="email"
                    onChange={formik.handleChange}
                    value={formik.values.email}
                    error={formik.errors.email}
                    errorHide={false}
                    textError
                />
                <InputText
                    text
                    label="Password"
                    name="password"
                    type="password"
                    placeholder="Password"
                    onChange={formik.handleChange}
                    value={formik.values.password}
                    error={formik.errors.password}
                    errorHide={false}
                    textError
                />
                <InputText
                    text
                    label="Repeat password"
                    name="confirmPassword"
                    type="password"
                    placeholder="Repeat password"
                    onChange={formik.handleChange}
                    value={formik.values.confirmPassword}
                    error={formik.errors.confirmPassword}
                    errorHide={false}
                    textError
                />
                <Checkbox
                    name="agreement"
                    onChange={formik.handleChange}
                    error={formik.errors.agreement}
                    value={formik.values.agreement}
                    label={
                        <>
                            By creating your account you agree to{' '}
                            <a href={getOLIMPLandingURL('terms-and-conditions')} target="_blank" rel="noreferrer">
                                OLIMP&apos;s Terms of Use
                            </a>
                            {stripeAgreementLabel}
                        </>
                    }
                />
                <br />
                <Button
                    label="Next"
                    type="submit"
                    disabled={!formik.isValid}
                    loading={formik.isSubmitting}
                    types={['blue', 'full']}
                />
            </form>
        </SignUpTemplate>
    )
}
