import React, { FC } from 'react'
import cn from 'classnames'
import './PhotosForm.scss'
import { PhotoHandlers } from './types'
import { AppFile, ServiceDocument } from '@shared/types'
import PhotoFrame from './PhotoFrame'
import EmptyFrame from './EmptyFrame'
import ScrollContainer from 'react-indiana-drag-scroll'
import { useMobileWidth } from '@shared/common/hooks'
import { Loader } from '@/components/Loader'
import { getActiveDocuments, formatExtensions } from '@/utils/documents'

const DEFAULT_MAX_PHOTOS = 3
const DEFAULT_MAX_SIZE = 5
export const DEFAULT_FILE_EXTENSIONS = ['PNG', 'JPEG']

interface IPhotosForm extends PhotoHandlers {
    photos: (ServiceDocument | AppFile)[]
    maxPhotos?: number
    fileExtensions?: string[]
    maxSize?: number
    isLoading?: boolean
    loadingEntityId?: number
    isSecured?: boolean
}

export const PhotosForm: FC<IPhotosForm> = ({
    photos,
    maxPhotos = DEFAULT_MAX_PHOTOS,
    fileExtensions = DEFAULT_FILE_EXTENSIONS,
    maxSize = DEFAULT_MAX_SIZE,
    ...props
}) => {
    const actualPhotos = getActualPhotos(getActiveDocuments(photos as AppFile[]), maxPhotos)
    const otherProps = {
        maxPhotos,
        maxSize,
        fileExtensions,
        ...props,
    }

    const isMobileWidth = useMobileWidth()
    if (isMobileWidth) {
        return <MobilePhotosForm photos={actualPhotos} {...otherProps} />
    }
    const { isLoading, loadingEntityId, ...restProps } = props
    const emptyFramesCount = maxPhotos - actualPhotos.length
    const emptyFramesArr = emptyFramesCount ? [...Array<number>(emptyFramesCount).keys()] : []

    return (
        <div className="photos-form">
            <div className="photos-form__wrapper">
                {renderFrames({ photos: actualPhotos, ...otherProps })}
                {emptyFramesArr.length > 0 &&
                    emptyFramesArr.map((el, i) => (
                        // eslint-disable-next-line react/no-array-index-key
                        <div key={`empty-${i}`} className="photos-form__item">
                            {isLoading && !loadingEntityId && i === 0 ? <Loader /> : <EmptyFrame {...restProps} />}
                        </div>
                    ))}
            </div>
            <p className="photos-form__caption">{`You can add up to ${maxPhotos} photos. We support ${formatExtensions(
                fileExtensions,
            )}. ${maxSize} MB max`}</p>
        </div>
    )
}

const MobilePhotosForm: FC<IPhotosForm> = (props) => {
    const {
        photos,
        maxPhotos = DEFAULT_MAX_PHOTOS,
        fileExtensions = DEFAULT_FILE_EXTENSIONS,
        maxSize = DEFAULT_MAX_SIZE,
        isLoading,
        loadingEntityId,
        ...restProps
    } = props
    return (
        <div className="photos-form">
            <ScrollContainer className="photos-form__wrapper">{renderFrames(props)}</ScrollContainer>

            <button
                className="photos-form__upload-btn"
                type="button"
                disabled={photos.length >= maxPhotos}
                onClick={restProps.onUploadClick}
            >
                {isLoading && !loadingEntityId ? <Loader /> : ' + add'}
            </button>
            <p className="photos-form__caption">{`You can add up to ${maxPhotos} photos. We support ${formatExtensions(
                fileExtensions,
            )}. ${maxSize} MB max`}</p>
        </div>
    )
}

const renderFrames = (props: IPhotosForm) => {
    const { photos, isLoading, loadingEntityId, ...restProps } = props

    return photos.map((el, index) => {
        const photoId = el.entity_id || el.id
        const isPhotoLoading = Boolean(isLoading && photoId === loadingEntityId)
        return (
            <div
                key={`photo-${photoId}-${index}`}
                className={cn('photos-form__item', { 'photos-form__item--loading': isPhotoLoading })}
            >
                <PhotoFrame photo={el} isLoading={isPhotoLoading} {...restProps} />
            </div>
        )
    })
}

const getActualPhotos = (photos: AppFile[], max: number) => {
    let activeDocs = getActiveDocuments(photos as AppFile[])
    if (activeDocs.length > max) activeDocs = activeDocs.slice(0, max)
    return activeDocs
}
