import { flattenObject } from '@shared/utils/flattenObject'

const ROUTES = {
    index: '/',
    test: '/test',
    dashboard: '/dashboard',
    public: {
        search: '/search',
        booking: '/booking/:hash',
        warehousesList: '/search/warehouses/:filters',
        login: '/login',
        logout: '/logout',
        register: '/register',
    },
    user: {
        base: '/user',
        settings: '/settings',
    },
    company: {
        base: '/company',
        index: '/:registerType',
        managers: '/managers',
    },
    carrier: {
        base: '/carrier',
        booking: '/warehouse/:warehouseId/:filters',
        bookingNoFilters: '/warehouse/:warehouseId',
        ordersList: '/orders/:filters',
        ordersListNoFilters: '/orders',
        order: '/order/:orderId',
        orderConfirmation: '/order/:orderId/confirmation/:confirmationId',
        quote: '/warehouse/:warehouseId/quote/:filters',
        quoteNoFilters: '/warehouse/:warehouseId/quote/:filters',
        warehouseSearch: '/warehouses/:filters',
        warehouseSearchNoFilters: '/warehouses',
        selectBeforeOrder: '/:carrierId/order/:orderId',
        selectBeforeBooking: '/:carrierId/booking/:bookingWh/:booking',
        settings: '/settings',
        search: '/search',
    },
    warehouse: {
        base: '/warehouse',
        calendar: '/calendar',
        ordersList: '/orders/:filters',
        ordersListNoFilters: '/orders',
        order: '/order/:orderId',
        selectBeforeOrder: '/:warehouseId/order/:orderId',
        settings: {
            base: '/settings',
            warehouse: '/settings/warehouse',
            prices: '/settings/prices',
            payment: '/settings/payment',
            rating: '/settings/rating',
        },
    },
    service: {
        base: '/service',
        emailConfirm: '/email/:token',
        passwordChange: '/password/:token',
        logout: '/logout',
    },
    signUp: {
        index: '/sign-up',
        managerDetails: '/sign-up/intro',
        companyInfo: '/sign-up/company',
        verification: '/sign-up/verification',
        ready: '/sign-up/ready',
        carrier: {
            index: '/sign-up/carrier',
            ready: '/sign-up/carrier/ready',
        },
        warehouse: {
            index: '/sign-up/warehouse',
            address: '/sign-up/warehouse/address',
            hours: '/sign-up/warehouse/hours',
            delivery: '/sign-up/warehouse/delivery',
            USDOT: '/sign-up/warehouse/usdot',
            tax: '/sign-up/warehouse/tax',
            ready: '/sign-up/warehouse/ready',
        },
    },
    registerCarrier: '/register/carrier',
    registerWarehouse: '/register/warehouse',
    page: {
        base: '/page',
        index: '/page/:alias',
    },
} as const

export const DEFAULT_ROUTES = {
    user: ROUTES.user.settings,
    company: ROUTES.company.base,
} as const

export const DEPRECATED_ROUTES = {
    company: {
        settings: '/company/settings',
    },
} as const

export const WAREHOUSE_ROUTES: string[] = Object.values(flattenObject(ROUTES.warehouse))

export const CARRIER_ROUTES: string[] = Object.values(flattenObject(ROUTES.carrier))

export const GUEST_ROUTES: string[] = [ROUTES.public.login]

export default ROUTES
