import React, { FC, memo } from 'react'
import * as Yup from 'yup'
import { ModalRegistry } from '@shared/common/modals'
import { ModalPartialProps, Modals, Order, Warehouse } from '@shared/types'
import Button from '@/components/Button'
import { useFormik } from 'formik'
import Textarea from '@/components/Textarea'
import { RatingItem } from '@/parts/Rating/Item'
import { sendRating } from '@shared/api/carrier'
import './RatingModal.scss'

interface RatingForm {
    location?: number
    professionalism?: number
    price?: number
    friendliness?: number
    order_id: number
    warehouse_id: number
    comment?: string
}

const RatingFormSchema = Yup.object().shape({
    location: Yup.number().required(),
    professionalism: Yup.number().required(),
    price: Yup.number().required(),
    friendliness: Yup.number().required(),
    order_id: Yup.number().required(),
    warehouse_id: Yup.number().required(),
    comment: Yup.string().max(255),
})

interface RatingModalProps {
    orderId: number
    warehouseId: number
    onAction?: (rating: any) => Promise<void> | void
}

const RatingModal: FC<RatingModalProps> = ({ orderId, warehouseId, onAction }) => {
    const formik = useFormik<RatingForm>({
        initialValues: { order_id: orderId, warehouse_id: warehouseId },
        validateOnMount: true,
        validationSchema: RatingFormSchema,
        enableReinitialize: true,
        onSubmit: async (values) => {
            try {
                formik.setSubmitting(true)
                const rating = await sendRating(values)
                formik.setSubmitting(false)
                ModalRegistry.get().close(Modals.RatingModal)
                await onAction?.(rating)
            } catch (errors) {
                formik.setSubmitting(false)
            }
        },
    })

    return (
        <div className="modal-rating">
            <form onSubmit={formik.handleSubmit} className="form form-rating">
                <div className="field rating">
                    <div className="groups">
                        <RatingItem
                            title="Location"
                            name="location"
                            value={formik.values.location}
                            onSelect={formik.handleChange}
                            control
                        />
                        <RatingItem
                            title="Professionalism"
                            name="professionalism"
                            value={formik.values.professionalism}
                            onSelect={formik.handleChange}
                            control
                        />
                        <RatingItem
                            title="Price"
                            name="price"
                            value={formik.values.price}
                            onSelect={formik.handleChange}
                            control
                        />
                        <RatingItem
                            title="Friendliness"
                            name="friendliness"
                            value={formik.values.friendliness}
                            onSelect={formik.handleChange}
                            control
                        />
                    </div>
                </div>
                <div className="field">
                    <Textarea
                        value={formik.values.comment}
                        onChange={formik.handleChange}
                        error={formik.errors.comment}
                        placeholder="Comment"
                        label="Comment"
                        name="comment"
                    />
                </div>
                <div className="btns">
                    <Button
                        label="Send"
                        loading={formik.isSubmitting}
                        disabled={!formik.isValid}
                        types={['blue', 'full']}
                        type="submit"
                    />
                </div>
            </form>
        </div>
    )
}

ModalRegistry.get().register<RatingModalProps>(Modals.RatingModal, {
    id: 'RatingModalProps',
    className: 'modal-rating',
    size: 'medium',
    Component: memo(RatingModal),
    title: 'Evaluate the warehouse',
})

export const showRatingModal = (props: ModalPartialProps<RatingModalProps>): void =>
    ModalRegistry.get().show<RatingModalProps>(Modals.RatingModal, props)

export default memo(RatingModal)
