import React, { FC, memo, useCallback, useEffect, useState } from 'react'
import { Loader } from '@/components/Loader'
import { Helmet } from 'react-helmet'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { changeOrderStatus, useCarrierOrder, useCarrierOrderConfirmation } from '@shared/api/carrier'
import { ReactComponent as UploadIcon } from '@/assets/images/icon-upload.svg'
import { ReactComponent as IconArrowPrev } from 'images/ic-arrow-back.svg'
import { ReactComponent as WarningIcon } from '@/assets/images/warning.svg'
import Button from '@/components/Button'
import { showUploadModal } from '@/parts/Modals/UploadModal'
import {
    CONFIRMATION_STATUSES,
    removeConfirmationDoc,
    signOrderRates,
    uploadConfirmationDocument,
} from '@shared/api/order-confirm'
import Currency from '@/components/Currency'
import cn from 'classnames'
import { showConfirmModal } from '@/parts/Modals/ConfirmModal'
import { ModalRegistry } from '@shared/common/modals'
import { Modals } from '@shared/types'
import { showError } from '@/parts/Modals/InfoModal'
import { useInfo } from '@shared/api/useDataHooks'
import './CarrierOrderConfirmation.scss'
import { EditableDocument, ViewOnlyDocument } from '@/components/LoadedDocument'
import CarrierOrderServices from '@/pages/Carrier/CarrierOrderItem/CarrierOrderServices'
import LocationLink from '@/components/LocationLink'
import { getArrivalString } from '@OlimpDev/lib/dist/common/order'
import { OrderCostAdjustment } from '@/parts/OrderCostAdjustment'

const PAGE_TITLE = 'Olimp - Rates Confirmation'

// eslint-disable-next-line complexity
const CarrierOrderConfirmation: FC = () => {
    const [actionLoading, setActionLoading] = useState<undefined | 'cancel' | 'sign'>(undefined)
    const [signingUrl, setSigningUrl] = useState<string | undefined>(undefined)
    const { orderId, confirmationId } = useParams<{ orderId?: string; confirmationId?: string }>()
    const { revalidate: orderRevalidate, data: orderData, loading: orderLoading } = useCarrierOrder(orderId)
    const { data, mutate, revalidate, loading } = useCarrierOrderConfirmation(confirmationId)
    const { data: info, isLoading: infoLoading } = useInfo()
    const navigate = useNavigate()

    const isRevocable = ['PENDING', 'DRAFT'].indexOf(orderData?.status?.full_code ?? '') >= 0

    const handleDocRemove = (doc: any) => {
        showConfirmModal({
            props: {
                title: `Confirm removing rate confirmation ${doc.rc_int || doc.rc}`,
                message: '',
                onConfirm: async (result: boolean) => {
                    if (result) {
                        try {
                            await removeConfirmationDoc(confirmationId!, doc.id)
                            await mutate({ ...data!, docs: data!.docs.filter((d) => d.id !== doc.id) })
                        } catch (e: any) {
                            ModalRegistry.get().close(Modals.ConfirmModal)
                            showError(e)
                        }
                    }
                },
            },
        })
    }

    const sign = (url: string) => {
        setSigningUrl(url)
    }

    const revalidateWithDocs = useCallback(async () => {
        setActionLoading('sign')
        await Promise.all([revalidate(), orderRevalidate()])
        setActionLoading(undefined)
    }, [orderRevalidate, revalidate])

    const signRates = async () => {
        setActionLoading('sign')

        try {
            const { sign_url, confirmation } = await signOrderRates(confirmationId!, window.location.origin)
            await mutate({ ...data, docs: data!.docs, confirmation }, false)
            sign(sign_url)
        } catch (err: any) {
            showError(err)
            await revalidate()
        }
        setActionLoading(undefined)
    }

    const RC = `RC-${orderId}-${confirmationId}`
    const notSigned = data?.confirmation?.status === 1
    const signRequested = data?.confirmation?.status === 2

    const signedPDF = data?.docs && data.docs.find((d) => d.type === 10)
    const signedPDFInProgress = !signedPDF && data?.confirmation.status === 3
    const hasBeenSigned = data?.confirmation?.status === 3
    const archived = data?.confirmation?.status === 5
    const activeAdjustments =
        !orderLoading &&
        orderData &&
        orderData.adjustments &&
        orderData.adjustments.filter((adj) => adj.confirmation_id === Number(confirmationId))

    const mount = useCallback(() => {
        if (!loading) {
            if (signRequested) {
                signRates()
            }
        }
    }, [data, signRequested, signRates, loading])

    useEffect(() => {
        if (!loading) {
            mount()
        }
    }, [loading])

    const handleUpload = () =>
        showUploadModal({
            props: {
                description: 'Please upload rate confirmation document and attach number to it',
                fields: [
                    {
                        name: 'rc_int',
                        label: 'Document number',
                    },
                ],
                fileRequirements: 'Only PDF documents (maximum 10MB)',
                onSubmit: async (values) => {
                    const doc = await uploadConfirmationDocument(
                        confirmationId!,
                        values.files[0],
                        (values as any).rc_int,
                    )
                    await mutate({ ...data!, docs: data!.docs.concat([doc]) })
                },
            },
        })

    const CLASS_NAME = 'order-item-confirm'
    return (
        <div className={cn(CLASS_NAME, 'wrapper', 'page')}>
            <div>
                <Helmet>
                    <title>{`${PAGE_TITLE} #RC-${orderId}-${confirmationId}`}</title>
                    <meta name="description" content={`${PAGE_TITLE} ${RC}`} />
                </Helmet>
                {(loading || infoLoading) && <Loader type="order-loader" />}
                {!loading && !infoLoading && data && (
                    <div className={cn(`${CLASS_NAME}__wrapper`, 'wrap')}>
                        <div className="section">
                            <div className="header">
                                <div className="header-left">
                                    {(hasBeenSigned || archived) && (
                                        <Link
                                            className={cn(`${CLASS_NAME}__back-link`, 'back-link')}
                                            to={`/carrier/order/${orderId}`}
                                            onClick={(e) => {
                                                if (archived) {
                                                    e.preventDefault()
                                                    navigate(-1)
                                                }
                                            }}
                                        >
                                            <IconArrowPrev /> {archived ? 'Back to main confirmation' : 'Back to order'}
                                        </Link>
                                    )}
                                    <div className={cn(`${CLASS_NAME}__title`, 'title')}>Rate Confirmation</div>
                                </div>
                                <div className="header-right">
                                    {data.confirmation && (
                                        <div className="order-confirmation_info">
                                            <div className="order-confirmation_info-row">
                                                <span>TOTAL </span>
                                                <b>
                                                    <Currency value={data.confirmation.total} />
                                                </b>
                                            </div>
                                            <div className="order-confirmation_info-row">
                                                <div
                                                    className={cn(
                                                        'order-confirmation_status',
                                                        CONFIRMATION_STATUSES[data.confirmation.status].id,
                                                    )}
                                                >
                                                    {CONFIRMATION_STATUSES[data.confirmation.status].title}
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                        {orderLoading && <Loader />}
                        {!orderLoading && orderData && orderData.company && (
                            <div className="order-data-short confirm-section">
                                <div className="section-header">
                                    Order #<span className="value">{orderData.id}</span>
                                </div>
                                {!orderData.parent_id && orderData.company && (
                                    <div className="item">
                                        <div className="label">Warehouse Name</div>
                                        <div className="value">{orderData.company.entity}</div>
                                    </div>
                                )}

                                {!orderData.parent_id && (
                                    <div className="item">
                                        <div className="label">Company Group Name</div>
                                        <div className="value">{orderData.company.group_name}</div>
                                    </div>
                                )}

                                {orderData.warehouse.comment && (
                                    <div className="group">
                                        <div className="item">
                                            <div className="label">Comment</div>
                                            <div className="value">{orderData.warehouse.comment}</div>
                                        </div>
                                    </div>
                                )}

                                {orderData.arrival_from && (
                                    <div className="item">
                                        <div className="label">Arrival Date</div>
                                        <div className="value">
                                            {getArrivalString(
                                                orderData.services[0],
                                                orderData.arrival_from,
                                                orderData.arrival_to,
                                            )}
                                            <span className="time-offset">{orderData.warehouse.utc_offset_title}</span>
                                        </div>
                                    </div>
                                )}

                                {orderData.container_number && (
                                    <div className="item">
                                        <div className="label">Container #</div>
                                        <div className="value">{orderData.container_number}</div>
                                    </div>
                                )}
                                {orderData.load_number && (
                                    <div className="item">
                                        <div className="label">Load #</div>
                                        <div className="value">{orderData.load_number}</div>
                                    </div>
                                )}

                                {!orderData.parent_id && orderData.warehouse.physicalAddress && (
                                    <div className="item">
                                        <div className="label">Address</div>

                                        <div className="value address">
                                            <LocationLink
                                                id={orderData.id}
                                                lat={orderData.warehouse.physicalAddress.lat}
                                                lng={orderData.warehouse.physicalAddress.long}
                                                address={orderData.warehouse.physicalAddress.address || ''}
                                            />
                                        </div>
                                    </div>
                                )}
                            </div>
                        )}
                        {!orderLoading && orderData && (
                            <div className="confirm-section">
                                <div className="section-header">Services</div>
                                <CarrierOrderServices item={orderData} />
                            </div>
                        )}
                        {activeAdjustments && activeAdjustments.length > 0 && (
                            <div className="confirm-section">
                                <div className="section-header">Adjustments</div>
                                <div className="item">
                                    <div className="value services-info">
                                        <div className="service-list">
                                            {activeAdjustments.map((adjustment) => (
                                                <OrderCostAdjustment
                                                    key={`adj_${adjustment.id}`}
                                                    adjustment={adjustment}
                                                    confirmed={adjustment.status.codeName === 'CONFIRMED'}
                                                    editable
                                                    className={'item'}
                                                />
                                            ))}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )}
                        {data.confirmation && data.confirmation.status === 1 && (
                            <div className="order-confirmation_agreement">
                                <WarningIcon />
                                Review the attached documents and sign rate confirmation to proceed with order
                            </div>
                        )}
                        <div className="order-confirmation_items">
                            {data.docs &&
                                data.docs
                                    .sort((a, b) => (a.rc === RC ? -1 : 1))
                                    .map((doc: any) => {
                                        if (doc.file_name === `${RC}.pdf`) {
                                            return (
                                                <ViewOnlyDocument
                                                    key={`doc-${doc.id}`}
                                                    filename={doc.file_name}
                                                    url={doc.url}
                                                    secured
                                                    disabled={!!actionLoading}
                                                    className={cn('order-confirmation_item')}
                                                />
                                            )
                                        }
                                        return (
                                            <EditableDocument
                                                key={`doc-${doc.id}`}
                                                filename={doc.file_name}
                                                url={doc.url}
                                                secured
                                                documentNumber={doc.rc_int || doc.rc}
                                                disabled={!!actionLoading}
                                                signed={signedPDF === doc}
                                                canRemove={!actionLoading && notSigned}
                                                onRemoveClick={() => {
                                                    handleDocRemove(doc)
                                                }}
                                                className="order-confirmation_item"
                                            />
                                        )
                                    })}
                        </div>

                        {signingUrl && signRequested && (
                            <iframe src={signingUrl} width={'100%'} height={500} className="zoho-frame" />
                        )}

                        {data.confirmation.status === 1 && (
                            <Button className="order-confirmation_upload" types={['full']} onClick={handleUpload}>
                                <UploadIcon />
                                Upload your rate confirmation
                            </Button>
                        )}
                        {(signRequested || signedPDFInProgress) && (
                            <Button
                                types={['plain', 'full']}
                                className="order-confirmation_confirm"
                                onClick={signedPDFInProgress ? revalidateWithDocs : signRates}
                                loading={actionLoading === 'sign'}
                                disabled={!!actionLoading}
                            >
                                Refresh status
                            </Button>
                        )}
                        {notSigned && (
                            <div className="order-confirmation_finish">
                                <Button
                                    types={['blue', 'full']}
                                    className="order-confirmation_confirm"
                                    onClick={signRates}
                                    loading={actionLoading === 'sign'}
                                    disabled={!!actionLoading}
                                >
                                    Sign Documents
                                </Button>

                                {isRevocable && (
                                    <Button
                                        types={['remove']}
                                        className="order-confirmation_cancel"
                                        onClick={async () => {
                                            setActionLoading('cancel')
                                            try {
                                                await changeOrderStatus(Number(orderId), 7)
                                                await Promise.all([revalidate(), orderRevalidate()])
                                                navigate(`/carrier/order/${orderId}`)
                                            } catch (e: any) {
                                                setActionLoading(undefined)
                                                showError(e)
                                            }
                                        }}
                                        loading={actionLoading === 'cancel'}
                                        disabled={!!actionLoading}
                                    >
                                        Cancel Order
                                    </Button>
                                )}
                            </div>
                        )}
                        {data.archived && data.archived.length > 0 && (
                            <div className="archived-confirmations">
                                <hr />
                                <h3>Previous confirmations</h3>
                                <div className="archived-confirmations__item archived-confirmations__headings">
                                    <div className="archived-confirmations__header">#</div>

                                    <div className="archived-confirmations__col">Total</div>

                                    <div className="archived-confirmations__col">Updated at</div>
                                </div>
                                {data.archived.map((archivedConfirmation) => {
                                    return (
                                        <div
                                            key={archivedConfirmation.id + '-confirmation'}
                                            className="archived-confirmations__item"
                                            onClick={() => {
                                                navigate(
                                                    `/carrier/order/${orderId}/confirmation/${archivedConfirmation.id}`,
                                                )
                                            }}
                                        >
                                            <div className="archived-confirmations__header">
                                                {archivedConfirmation.rc}
                                            </div>

                                            <div className="archived-confirmations__col">
                                                <Currency value={archivedConfirmation.total} />
                                            </div>

                                            <div className="archived-confirmations__col">
                                                {archivedConfirmation.updated_at}
                                            </div>
                                        </div>
                                    )
                                })}
                            </div>
                        )}
                    </div>
                )}
            </div>
        </div>
    )
}

export default memo(CarrierOrderConfirmation)
