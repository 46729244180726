import React, { FC, useCallback, useEffect, useState } from 'react'
import { OnBookInfo } from '@/pages/Carrier/CarrierWarehouseSearch/CarrierWarehouseTableLine/CarrierWarehouseTableLine'
import { useNavigate, useParams } from 'react-router-dom'
import { IWarehouseMapSearchResponse, IWarehouseRequestMode, IWarehouseSearchForm } from '@shared/types'
import { PageRequest } from '@shared/types/page'
import { useWarehouseSearch } from '@shared/api/search'
import cn from 'classnames'

import { useToggle } from '@shared/common/hooks'
import { utils } from '@shared/common/utils'
import { useUser } from '@shared/api/user'
import { useCarrier } from '@shared/api/carrier'
import { licenseValid } from '@/parts/CarrierStatusInfo/helper'
import OrderList from '@/components/OrdersList'
import { updateCarrierLicenseInDialog } from '@/utils/carrierLicenseModal'
import { useQuotationRequest } from '@/pages/Carrier/CarrierWarehouseSearch/useQuotationRequest'
import './CarrierWarehouseSearch.scss'

// eslint-disable-next-line complexity
export const CarrierWarehouseSearch: FC = () => {
    const navigate = useNavigate()
    const { data: carrier, mutate, revalidate } = useCarrier()
    const { filters } = useParams<{ filters?: string }>()
    const [circle, setCircle] = useState({
        radius: 0,
        long: 0,
        lat: 0,
    })

    const filtersDecoded = utils.decodeRouteFilters(filters || '')

    const [showForm, toggleForm] = useToggle(false)
    const [queryData, updateQueryData] = useState<IWarehouseSearchForm>()
    const [mode, updateMode] = useState<IWarehouseRequestMode>(filtersDecoded.mode || 'list')
    const [fullSearch, setFullSearch] = useState<boolean>(filtersDecoded.full_search === 'true' || false)
    const [pageData, updatePageData] = useState<PageRequest>({
        'per-page': 10,
        page: 1,
        sort: 'distance',
    })

    const { data } = useUser()

    const handleModeUpdate = (m: IWarehouseRequestMode) => {
        updateMode(m)
        navigate(`/carrier/warehouses/${utils.encodeRouteFilters({ ...filtersDecoded, mode: m }) || ''}`)
    }

    const handleFullSearchUpdate = ({ target: { value } }: { target: any }) => {
        setFullSearch(value)
        navigate(`/carrier/warehouses/${utils.encodeRouteFilters({ ...filtersDecoded, full_search: value }) || ''}`)
    }

    const {
        markers,
        warehousesData,
        data: searchData,
        isLoading,
    } = useWarehouseSearch({
        ...pageData,
        form: queryData,
        mode,
        fullSearch,
    })

    const handleTableUpdate = useCallback(
        ({ sort, page }) => {
            updatePageData({
                ...pageData,
                sort,
                page,
            })
        },
        [pageData],
    )

    const { quotationRequest, quotationRequestLoading } = useQuotationRequest({
        queryData,
        isLoading,
    })

    const handleBook = useCallback(
        (info: OnBookInfo) => {
            const { id, distribution, distance } = info
            const decoded = utils.decodeRouteFilters(filters || '')
            const decodedFilters = {
                ...decoded,
                full_search: fullSearch,
                distance,
            }
            if (fullSearch) {
                navigate(`/carrier/warehouse/${id}/quote/${utils.encodeRouteFilters(decodedFilters)}`)
            } else if (carrier && !licenseValid(carrier)) {
                updateCarrierLicenseInDialog({ carrier, onSuccess: () => revalidate(true) })
            } else {
                navigate(
                    `/carrier/warehouse/${distribution ? 'distribution/' : ''}${id}/${utils.encodeRouteFilters(
                        decodedFilters,
                    )}`,
                )
            }
        },
        [filters, navigate, fullSearch, carrier],
    )

    useEffect(() => {
        if (!isLoading) {
            const mapData = searchData as IWarehouseMapSearchResponse

            if (mapData && mapData.center) {
                setCircle({
                    ...mapData.center,
                    radius: Number(mapData.radius),
                })
            }
        }
    }, [isLoading])

    const totalFound = searchData.provider?.total
    const hasFullSearch = data?.flags ? data?.flags?.indexOf('FULL_SEARCH') >= 0 : false

    const orderSettings = {
        searchPage: '/carrier/warehouses',
        mode,
        isLoading,
        circle,
        publicQuery: false,
        quotationRequestLoading,
        quotationRequest,
        hasFullSearch,
        fullSearch,
    }
    const orderData = {
        pageData,
        filters,
        warehousesData,
        markers,
        searchData,
        totalFound,
    }
    const orderMethods = {
        updatePageData,
        updateQueryData,
        handleModeUpdate,
        handleBook,
        handleTableUpdate,
        handleFullSearchUpdate,
    }

    return (
        <div className="warehouses-list wrapper">
            <div>
                <div className="page-header">
                    <div className="page-title">Warehouse search</div>
                    <div
                        role="button"
                        tabIndex={-1}
                        className={cn('icon-arrow media-mobile', { opened: showForm })}
                        onClick={toggleForm}
                    />
                </div>
                <OrderList settings={orderSettings} data={orderData} methods={orderMethods} />
            </div>
        </div>
    )
}
