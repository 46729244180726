import React, { FC, useEffect, useState } from 'react'
import cn from 'classnames'
import { updateGeoLocation, updateLoggedState, useUserState } from '@/store/user'
import AppRoutes from '@/AppRoutes'
import { Footer } from '@/parts/Partials/Footer/Footer'
import { useInfo } from '@shared/api/useDataHooks'
import { Disclaimer } from '@/parts/Partials/Disclaimer/Disclaimer'
import Header from '@/parts/Partials/Header/Header'
import { Http } from '@shared/api/Http'
import { getUserInfo } from '@shared/api/user'
import { Loader } from '@/components/Loader'
import GeneralModal from '@/parts/GeneralModal'

import 'simplebar/dist/simplebar.min.css'
import 'react-responsive-modal/styles.css'

import './App.scss'
import { utils } from '@shared/common/utils'
import { useLocation } from 'react-router'
import { Elements } from '@stripe/react-stripe-js'
import { loadStripe, Stripe } from '@stripe/stripe-js'

import { Userpilot } from 'userpilot'
import { UserInfo } from '@shared/api/types'

import Zendesk from 'react-zendesk'
import { useZendeskJWT } from '@/hooks/useZendeskJWT'
import { Toast } from '@/components/Toast'
import { ZendeskAPI } from 'react-zendesk'
import { Helmet } from 'react-helmet'
import { useGoogleAnalytics } from '@/hooks/useGoogleAnalytics'

const isDevEnv = process.env.NODE_ENV === 'development'

let stripeResolve: (stripe: Stripe | null) => void
const stripePromise: Promise<Stripe | null> = new Promise((resolve) => {
    stripeResolve = resolve
})

export const App: FC = () => {
    const [userData, setUserData] = useState<UserInfo>()

    const [ZENDESK_KEY, setZENDESK_KEY] = useState<string>()
    const [USER_PILOT_KEY, setUSER_PILOT_KEY] = useState<string>()

    const location = useLocation()
    const [restored, setRestored] = useState<boolean>(false)
    const [refId, setRefId] = useState<string | null>(utils.getSearchParamByName(location.search, 'refId'))
    const showHeader = !refId && location.pathname.indexOf('/sign-up') === -1
    const { dispatch, state } = useUserState()
    const { data: info, isLoading: infoLoading } = useInfo({
        onSuccess: async (data) => {
            stripeResolve(await loadStripe(data.payment_token))
            setZENDESK_KEY(data.zendesk_widget_key)
            setUSER_PILOT_KEY(data.user_pilot_token)
        },
    })

    useGoogleAnalytics({ gaId: info?.ga_id, enabled: info?.ga_enabled })

    const restoreSession = async () => {
        if (Http.defaults.headers.common['Auth-Token']) {
            const userInfo = await getUserInfo()
            await updateLoggedState(userInfo, dispatch)
            await setUserData(userInfo)
        }
        setRestored(true)
    }

    useEffect(() => {
        updateGeoLocation(dispatch)
        restoreSession()
    }, [])

    useEffect(() => {
        setRefId(utils.getSearchParamByName(location.search, 'refId'))
    }, [location.search])

    // Userpilot start

    useEffect(() => {
        if (USER_PILOT_KEY) {
            Userpilot.initialize(`${USER_PILOT_KEY}`)
        }
    }, [USER_PILOT_KEY])

    useEffect(() => {
        if (USER_PILOT_KEY) {
            if (state.isLoggedIn && state.userInfo) {
                const { id, company_id, first_name, last_name, position, type, email, phone } = state.userInfo

                Userpilot.identify(id.toString(), {
                    user_id: id,
                    first_name: first_name,
                    last_name: last_name,
                    position: position,
                    type: type,
                    company_id: company_id,
                    email: email?.email,
                    phone: phone?.number,
                })
            } else {
                Userpilot.anonymous()
            }
        }
    }, [state.isLoggedIn, USER_PILOT_KEY])

    useEffect(() => {
        Userpilot.reload()
    }, [location])
    // Userpilot end

    // Zendesk start

    const { handleJwtToken } = useZendeskJWT()

    useEffect(() => {
        if (state.isLoggedIn) {
            handleJwtToken()
        } else {
            ZendeskAPI('messenger', 'close')
        }
    }, [state.isLoggedIn])

    // Zendesk end

    if (!restored) {
        return <Loader />
    }

    return (
        <Elements stripe={stripePromise}>
            <Helmet>
                <link rel="shortcut icon" type="image/png" href="/static/favicon.png?23333" />
                <link rel="apple-touch-icon" sizes="76x76" href="/static/apple-touch-icon-76x76.png?3333" />
                <link rel="apple-touch-icon" sizes="120x120" href="/static/apple-touch-icon-120x120.png?3333" />
                <link rel="apple-touch-icon" sizes="152x152" href="/static/apple-touch-icon-152x152.png?3333" />
                <link rel="apple-touch-icon" sizes="180x180" href="/static/apple-touch-icon.png?3333" />
                <link id="favicon-32" rel="icon" type="image/png" sizes="32x32" href="/static/favicon-32x32.png?3333" />
                <link id="favicon-16" rel="icon" type="image/png" sizes="16x16" href="/static/favicon-16x16.png?3333" />
            </Helmet>
            {ZENDESK_KEY && (
                <Zendesk defer zendeskKey={`${ZENDESK_KEY}`} onLoaded={() => console.log('Zendesk is loaded')} />
            )}
            {isDevEnv && <div className="env">DEVELOPMENT</div>}
            {/* eslint-disable-next-line react/no-string-refs */}
            <div className={cn('container', { dev: isDevEnv })}>
                {showHeader && <Header />}
                <AppRoutes />
            </div>
            <GeneralModal />
            <Footer info={info} />
            <Disclaimer />
        </Elements>
    )
}
