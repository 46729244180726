import React from 'react'
import LoginModal from '@/parts/Modals/LoginModal'
import { useUserState } from '@/store/user'
import { useNavigate } from 'react-router-dom'
import './LoginPage.scss'
export const LoginPage = () => {
    const navigate = useNavigate()
    const {
        state: { isLoggedIn, userInfo },
    } = useUserState()
    return (
        <div className="login-page">
            <LoginModal
                title="Login"
                onClose={(redirect) => {
                    if (isLoggedIn && userInfo?.registration_step !== null && userInfo?.registration_step !== 100) {
                        navigate('/sign-up')
                        return
                    }
                    if (redirect) {
                        navigate(redirect)
                    }
                }}
            />
        </div>
    )
}
