import React, { FC, JSXElementConstructor } from 'react'
import { Navigate, Route, RouteProps } from 'react-router-dom'

type IAdminRouteProps = RouteProps & {
    isLoggedIn: boolean
    isAdmin: boolean
    component: JSXElementConstructor<any>
}

export const AdminRoute: FC<IAdminRouteProps> = (props) => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const { isLoggedIn, isAdmin, component: Component } = props

    if (isAdmin) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        return <Component />
    }
    return <Navigate to={{ pathname: '/' }} state={{ from: window.location.pathname }} />
}
