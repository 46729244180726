import { ServiceDocument, WarehouseService, AppFile } from '@shared/types'
import { WhServiceConstants } from '@shared/common/consts'

export const getActiveDocuments = (documents: AppFile[]): AppFile[] => documents.filter((el) => !!el.link || !!el.url)

export const getDocumentStatus = (status: AppFile['status']): string => WhServiceConstants.statuses[status || 4]

export const getDocumentUrl = (link: AppFile['link']): string => `/api${link}`

export const getServiceDocument = (
    service: WarehouseService,
    docId: ServiceDocument['entity_id'],
): ServiceDocument | undefined => service.documents.find((el) => el.entity_id === docId)

export const formatExtensions = (extensions: string[]) => extensions.join(', ').toUpperCase()
