import React, { FC, useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { CarrierBookingFormUtils } from '@/pages/Carrier/CarrierBooking/Form/utils'
import { Loader } from '@/components/Loader'
import { CarrierBookingForm } from '@/pages/Carrier/CarrierBooking/Form'
import { CarrierBookingState } from '@shared/types'
import { useWarehouseForBooking } from '@shared/api/carrier'
import { updateSessionData, useSessionData } from '@shared/apiv2/quick-session'
import { Http } from '@shared/api/Http'
import { CarrierOrderItem } from '@/pages/Carrier'

// eslint-disable-next-line complexity
export const PublicBooking: FC = () => {
    const navigate = useNavigate()
    const [pending, setPending] = useState(false)
    const [state, setState] = useState<CarrierBookingState | undefined>()
    const { hash } = useParams<{ hash: string }>()
    const { data: sessionData, error: sessionError, mutate } = useSessionData(hash)

    const handleOrderCreated = async (id: number) => {
        setPending(true)
        await updateSessionData(hash!, id)
        await mutate(() => ({ ...sessionData, orderId: id }), false)
        setPending(false)
    }

    const { data, loading } = useWarehouseForBooking(
        sessionData && !sessionData.orderId ? Number(sessionData.warehouseId) : undefined,
    )

    useEffect(() => {
        if (!loading && data && sessionData && !sessionData.orderId) {
            setState(CarrierBookingFormUtils.getCarrierBookingState(data, sessionData.filters))
        }
    }, [loading])

    useEffect(() => {
        Http.defaults.headers.common['Auth-Token'] = hash!
        localStorage.setItem('type', 'carrier')
    }, [])
    if (sessionError?.statusCode === 404) {
        navigate('/404')
        return null
    }

    if ((!sessionData && !sessionError) || (!sessionData.orderId && !state) || pending) {
        return <Loader />
    }

    if (sessionData?.orderId) {
        return <CarrierOrderItem id={sessionData.orderId} />
    }

    if (!data || !state || loading) return <Loader type="order-loader" />

    return <CarrierBookingForm state={state} warehouseInfo={data} publicMode onOrderCreated={handleOrderCreated} />
}
