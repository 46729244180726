import React, { FC, useState } from 'react'
import { SignUpTemplate } from '@/pages/SignUp/components/SignUpTemplate'
import { updateLoggedState, useUserState } from '@/store/user'
import { apiVersion, Http } from '@shared/api/Http'
import { showInfoModal } from '@/parts/Modals/InfoModal'
import { UserActionType } from '@/store/user/user'
import { InputText } from '@/components/InputText'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import Button from '@/components/Button'
import './VerificationStep.scss'
import { resend } from '@/utils/manager'

export const EmailChangeSchema = Yup.object().shape({
    email: Yup.string().email('Invalid email').required('Required'),
})

export const VerificationStep: FC = () => {
    const [anotherEmailMode, setAnotherEmailMode] = useState(false)
    const { state: userState, dispatch } = useUserState()
    const formik = useFormik({
        initialValues: {
            email: '',
        },
        onSubmit: async ({ email }) => {
            try {
                const response = await Http.post<{ email: string }, any>(
                    `/app/${apiVersion}/manager/change-email-address`,
                    {
                        email,
                    },
                )
                await updateLoggedState(
                    {
                        ...userState.userInfo!,
                        email: response,
                    },
                    dispatch,
                )
                setAnotherEmailMode(false)
            } catch (e: any) {
                formik.setSubmitting(false)
                formik.setErrors(e)
            }
        },
        validationSchema: EmailChangeSchema,
        enableReinitialize: true,
    })
    if (anotherEmailMode) {
        return (
            <SignUpTemplate
                onBack={() => {
                    setAnotherEmailMode(false)
                }}
                progress={80}
                title="Enter another e-mail address"
                extra="We’ll send you a verification link"
            >
                <div className="sign-up_change-email">
                    <form onSubmit={formik.handleSubmit}>
                        <InputText
                            autoFocus
                            text
                            label="E-mail"
                            name="email"
                            placeholder="E-mail"
                            className="email"
                            onChange={formik.handleChange}
                            value={formik.values.email}
                            error={formik.errors.email}
                            errorHide={false}
                            textError
                        />
                        <br />
                        <Button
                            label="Next"
                            type="submit"
                            disabled={!formik.isValid}
                            loading={formik.isSubmitting}
                            types={['blue', 'full']}
                        />
                    </form>
                </div>
            </SignUpTemplate>
        )
    }

    return (
        <SignUpTemplate
            progress={userState?.signUp?.chosenEntityType ? 400 / 5 : 400 / 6}
            description={
                <>
                    <span className="sign-up_info-text">
                        Check {userState.userInfo?.email?.email}
                        <br />
                        for your verification link
                    </span>
                    <small>
                        {/* eslint-disable-next-line react/no-unescaped-entities */}
                        If you can't find it, check your spam folder or{' '}
                        {/* eslint-disable-next-line jsx-a11y/anchor-is-valid,jsx-a11y/no-static-element-interactions */}
                        <a
                            style={{ textDecoration: 'underline' }}
                            onClick={(e) => {
                                e.preventDefault()
                                resend()
                            }}
                        >
                            resend
                        </a>{' '}
                        link
                        <br />
                        or{' '}
                        {/* eslint-disable-next-line jsx-a11y/anchor-is-valid,jsx-a11y/no-static-element-interactions */}
                        <a
                            style={{ textDecoration: 'underline' }}
                            onClick={() => {
                                formik.resetForm()
                                setAnotherEmailMode(true)
                            }}
                        >
                            <small>try another e-mail address</small>
                        </a>
                    </small>
                </>
            }
        >
            <div className="sign-up_verification">
                {/* eslint-disable-next-line react/no-unescaped-entities */}

                <br />
                {/* eslint-disable-next-line jsx-a11y/anchor-is-valid,jsx-a11y/no-static-element-interactions */}
                <a
                    className="link-blue"
                    onClick={() => {
                        dispatch?.({ type: UserActionType.SIGN_UP_VERIFY_LATER })
                    }}
                >
                    <small style={{ fontSize: 14 }}>I will check it later</small>
                </a>
                <br />
            </div>
        </SignUpTemplate>
    )
}
