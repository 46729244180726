import * as React from 'react';
import ReactCarousel, { Settings } from 'react-slick';
import cn from 'classnames';
import { ReactComponent as IconArrowPrev } from '@/assets/images/ic-arrow-prev.svg';
import { ReactComponent as IconArrowNext } from '@/assets/images/ic-arrow-next.svg';
import './Carousel.scss';

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

export type CarouselProps = React.PropsWithChildren<Settings>;

const Carousel = React.forwardRef<ReactCarousel, CarouselProps>(
  ({ children, afterChange, ...props }, ref) => {
    const [currentPhotoIdx, setCurrentPhotoIdx] = React.useState<number>(0);
    const handleChange = React.useCallback((current: number) => {
      setCurrentPhotoIdx(current);
      afterChange?.(current);
    }, [afterChange]);

    return (
      <ReactCarousel
        dotsClass="app-carousel-dots"
        slidesToScroll={1}
        dots
        ref={ref}
        arrows
        afterChange={handleChange}
        adaptiveHeight
        swipe
        appendDots={dots => (
          <ul className="app-carousel-dots__list">{dots}</ul>
        )}
        customPaging={(i) => (
          <div
            className={cn('app-carousel-dots__item', { 'app-carousel-dots__item--active': i === currentPhotoIdx})}
          />
        )}
        nextArrow={<NextArrow />}
        prevArrow={<PrevArrow />}
        {...props}
      >
        {children}
      </ReactCarousel>
    )
  }
);
Carousel.displayName = 'Carousel';

const NextArrow = (props: any) => (
    <button
      {...props}
      type='button'
      className='carousel-arrow carousel-arrow--next'
    >
      <IconArrowNext />
    </button>
  );

const PrevArrow = (props: any) => (
    <button
      {...props}
      type='button'
      className='carousel-arrow carousel-arrow--prev'
    >
      <IconArrowPrev />
    </button>
  )

type CarouselSettings = Settings;
type CarouselRef = ReactCarousel;
export { Carousel };
export type { CarouselSettings, CarouselRef };

