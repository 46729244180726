import React, { FC, memo } from 'react'
import cn from 'classnames'
import { Helmet } from 'react-helmet'

import { useUserState } from '@/store/user'
import { UserType } from '@shared/api/types'
import CompanySettings from '@/pages/User/UserAccountSettings/CompanySettings'
import UserSettings from '@/pages/User/UserAccountSettings/UserSettings'

import './UserAccountSettings.scss'

const PAGE_TITLE = 'Olimp - Account Settings'

const CLASS_NAME = 'user-account-settings'

export const UserAccountSettings: FC = memo(() => {
    const {
        state: { userInfo },
    } = useUserState()
    const isAdmin = userInfo?.type === UserType.admin

    return (
        <div className={cn(CLASS_NAME, 'wrapper', 'page')}>
            <Helmet>
                <title>{PAGE_TITLE}</title>
                <meta name="description" content={PAGE_TITLE} />
            </Helmet>
            <div className={`${CLASS_NAME}__wrapper`}>
                <div className="page-title">Account Settings</div>

                <div className={`${CLASS_NAME}__inner`}>
                    {isAdmin && <CompanySettings />}

                    <UserSettings />
                </div>
            </div>
        </div>
    )
})

export default UserSettings
