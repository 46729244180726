import React from 'react'
import { GeoLocation } from '@shared/types'
import { EntityInfo, EntityType } from '@shared/api/types'

export enum EntityActionType {
    SET_ENTITY_STATE = 'SET_ENTITY_STATE',
    UPDATE_GEO = 'UPDATE_GEO',
}

export type EntityAction =
    | { type: EntityActionType.SET_ENTITY_STATE; entityState: EntityInfo; entityType: EntityType }
    | { type: EntityActionType.UPDATE_GEO; geolocation?: GeoLocation }

export type EntityStatus = 'active' | 'inactive' | 'archived' | 'rejected' | 'banned'

export interface EntityState {
    entityType: EntityType
    entityInfo?: EntityInfo
    geolocation?: GeoLocation
}

export interface EntityStore {
    state: EntityState
    dispatch?: React.Dispatch<EntityAction>
}
