import React, { FC, memo, useCallback } from 'react'
import * as Yup from 'yup'
import { ModalRegistry } from '@shared/common/modals'
import { CarrierCommonInfo, ModalPartialProps, Modals, WarehouseCommonInfo } from '@shared/types'
import Button from '@/components/Button'
import { useFormik } from 'formik'
import './EntityManagerModal.scss'
import { apiVersion, Http } from '@shared/api/Http'
import Select from '@/components/Select'
import { EntityType, UserInfo } from '@shared/api/types'

interface EntityManagerForm {
    manager: number
}

const EntityManagerFormSchema = Yup.object().shape({
    manager: Yup.number().required(),
})

interface EntityManagerModalProps {
    entity: WarehouseCommonInfo | CarrierCommonInfo
    entityType: EntityType
    managers: UserInfo[]
    onClose?: (redirectUrl?: string) => void
}

const ManagerModal: FC<EntityManagerModalProps> = ({ onClose, managers, entity, entityType }) => {
    const managersList = managers.map((element) => ({
        value: element.id,
        name: `${element.first_name} ${element.last_name} (${element.email?.email})`,
    }))

    const formik = useFormik<EntityManagerForm>({
        initialValues: {
            manager: entity.defaultManager.id,
        },
        validationSchema: EntityManagerFormSchema,
        onSubmit: async (values) => {
            try {
                if (values.manager !== entity.defaultManager.id) {
                    const response = await Http.post(`/app/${apiVersion}/manager/main`, {
                        manager_id: values.manager,
                        entity_id: entity.id,
                        entity_type: entityType,
                    })
                }

                formik.setSubmitting(true)

                onClose?.('/company/managers')
            } catch (e: any) {
                formik.setSubmitting(false)
                formik.setErrors(e)
            }
        },
    })

    const handleTypeChange = useCallback(
        ({ value }) => {
            formik.handleChange({ target: { name: 'manager', value } })
        },
        [formik.handleChange],
    )

    return (
        <div className="modal-info">
            <div className="modal-header">Entity Manager for {entity.entity}</div>

            <form onSubmit={formik.handleSubmit}>
                <div className="form">
                    <div className="managers">
                        <div className="section">
                            <div className="form-wrap">
                                <div className="field">
                                    <Select
                                        name="manager"
                                        label="Manager#"
                                        value={formik.values.manager}
                                        error={formik.errors.manager}
                                        items={managersList}
                                        onAction={handleTypeChange}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="btns">
                    <Button
                        label="Update"
                        type="submit"
                        loading={formik.isSubmitting}
                        types={['blue', 'full']}
                        disabled={!formik.isValid || !formik.dirty}
                    />
                </div>
            </form>
        </div>
    )
}

ModalRegistry.get().register<EntityManagerModalProps>(Modals.EntityManagerModal, {
    id: 'EntityManagerModalProps',
    className: 'modal-entity-manager',
    size: 'large',
    Component: memo(ManagerModal),
})

export const showEntityManagerModal = (props: ModalPartialProps<EntityManagerModalProps>): void =>
    ModalRegistry.get().show<EntityManagerModalProps>(Modals.EntityManagerModal, props)

export default memo(ManagerModal)
