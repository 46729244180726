import React, { FC, HTMLAttributes } from 'react'
import cn from 'classnames'
import './DropdownMenu.scss'
import Dropdown from '@/components/Dropdown'
import Button from '@/components/Button'

interface IMenu extends Omit<HTMLAttributes<HTMLDivElement>, 'onClick'> {
    label: React.ReactNode
    isOpen: boolean
    className?: string
    forceShow?: boolean
    onClick?(): void
}

const CLASS_NAME = 'dropdown-menu'

const DropdownMenu: FC<IMenu> & { Item: typeof DropdownMenuItem } = ({
    label,
    isOpen,
    children,
    className,
    forceShow,
    onClick,
    ...rest
}) => (
    <div className={cn(CLASS_NAME, className)} {...rest}>
        <Dropdown
            className={`${CLASS_NAME}__dropdown`}
            type="right"
            toggleElement={
                <Button types={['plain', 'bold']} className={`${CLASS_NAME}__toggle`} onClick={onClick}>
                    <div className={`${CLASS_NAME}__label`}>{label}</div>
                    <div className="icon-arrow" />
                </Button>
            }
            open={isOpen}
            forceShow={forceShow}
        >
            <ul className={`${CLASS_NAME}__list`}>{children}</ul>
        </Dropdown>
    </div>
)

interface IMenuItem {
    border?: 'top' | 'bottom'
    noHover?: boolean
    toBottom?: boolean
}

const DropdownMenuItem: FC<IMenuItem> = ({ border, noHover, toBottom, children }) => (
    <li
        className={cn(`${CLASS_NAME}__item`, {
            [`${CLASS_NAME}__item--border-${border}`]: !!border,
            [`${CLASS_NAME}__item--no-hover`]: noHover,
            [`${CLASS_NAME}__item--to-bottom`]: toBottom,
        })}
    >
        {children}
    </li>
)

DropdownMenu.Item = DropdownMenuItem

export { DropdownMenu }
