import { FormWarehouseSearch } from '@/parts/Forms'
import React, { FC } from 'react'
import './CarrierSearch.scss'

export const CarrierSearch: FC = () => (
    <div className="carrier-search wrapper">
        <div>
            <div className="search-wrap">
                <div className="search-header">
                    <div className="icon-search" />
                    <div className="title">Warehouse search</div>
                    <div className="description">Customize your search to find the warehouse you need.</div>
                </div>

                <FormWarehouseSearch searchPage="/carrier/warehouses" pending={false} />
            </div>
        </div>
    </div>
)
