import { useEffect, useMemo, useState } from 'react'
import { EntityType } from '@shared/api/types'
import { matchPath, useNavigate } from 'react-router-dom'
import ROUTES, { WAREHOUSE_ROUTES, CARRIER_ROUTES } from '@/router'
import { useSelectedEntity } from './useSelectedEntity'
import { getEntityBasePath } from '@/utils/getEntityBasePath'
import { useLocation } from 'react-router'

export const routesByType: Record<EntityType, string[]> = {
    [EntityType.carrier]: CARRIER_ROUTES,
    [EntityType.warehouse]: WAREHOUSE_ROUTES,
}

interface IUseCheckEntityPath {
    isCorrectPath: boolean
    redirectPath: string | null
    loading: boolean
}

export const useCheckEntityPath = (type: EntityType, withNavigate = true): IUseCheckEntityPath => {
    const location = useLocation()
    const navigate = useNavigate()
    const { data: selectedEntity } = useSelectedEntity()
    const [loading, setLoading] = useState(true)
    const [redirectPath, setNavigatePath] = useState<string | null>(null)

    const isCurrentPathSpecific = useMemo(
        () => [...WAREHOUSE_ROUTES, ...CARRIER_ROUTES].some((path) => matchPath(location.pathname, path)),
        [location.pathname],
    )

    const isCurrentPathCorrect = useMemo(() => {
        if (!isCurrentPathSpecific) return true
        if (!selectedEntity) return false
        return type === selectedEntity?.type
    }, [isCurrentPathSpecific, selectedEntity, type])

    useEffect(() => {
        if (selectedEntity) {
            if (!isCurrentPathCorrect && selectedEntity) {
                setNavigatePath(selectedEntity.type ? getEntityBasePath(selectedEntity.type) : ROUTES.company.base)
            }
            setLoading(false)
        }
    }, [isCurrentPathCorrect, selectedEntity])

    useEffect(() => {
        if (!loading && redirectPath && withNavigate) {
            navigate(redirectPath)
        }
    }, [loading, redirectPath, navigate, withNavigate])

    return {
        isCorrectPath: isCurrentPathCorrect,
        redirectPath,
        loading,
    }
}
