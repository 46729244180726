/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { memo } from 'react'
import cn from 'classnames'
import { FormWarehouseSearch } from '@/parts/Forms'
import { Loader } from '@/components/Loader'
import Checkbox from '@/components/Checkbox'
import Select from '@/components/Select'
import { PageRequest } from '@shared/types/page'
import {
    IWarehouseRequestMode,
    IWarehouseData,
    IWarehouseMapSearchResponse,
    IWarehouseSearchResponse,
    IWarehouseSearchForm,
} from '@shared/types'
import { OnBookInfo } from '@/pages/Carrier/CarrierWarehouseSearch/CarrierWarehouseTableLine/CarrierWarehouseTableLine'
import { MarkerItem } from '@shared/api/search'
import { TableList } from '@/parts/Table/TableList'
import { CarrierWarehouseTableLine } from '@/pages/Carrier/CarrierWarehouseSearch/CarrierWarehouseTableLine/CarrierWarehouseTableLine'
import Map from '@/components/Map'
import { WAREHOUSE_SEARCH_TABLE_HEADERS } from '@shared/common/search'

interface ICircle {
    radius: number
    long: number
    lat: number
}

export interface IOrderList {
    settings: {
        searchPage: string
        mode: 'list' | 'map'
        isLoading: boolean
        circle: ICircle
        quotationRequestLoading: boolean
        quotationRequest: boolean
        publicQuery?: boolean
        hasFullSearch?: boolean
        fullSearch?: boolean
    }
    data: {
        pageData: PageRequest
        filters: string | undefined
        warehousesData: IWarehouseData[]
        markers: MarkerItem[]
        searchData: IWarehouseSearchResponse | IWarehouseMapSearchResponse
        totalFound: number
    }
    methods: {
        updatePageData: (p: (d: any) => any) => void
        updateQueryData: React.Dispatch<React.SetStateAction<IWarehouseSearchForm | undefined>>
        handleModeUpdate: (m: IWarehouseRequestMode) => void
        handleBook: (info: OnBookInfo) => void
        handleTableUpdate: ({ sort, page }: any) => void
        handleFullSearchUpdate?: ({ target: { value } }: { target: any }) => void
    }
}

export interface IList {
    settings: {
        mode: 'list' | 'map'
        fullSearch?: boolean
        circle: any
        isLoading: boolean
        quotationRequest: boolean
    }
    data: {
        pageData: PageRequest
        filters: string | undefined
        warehousesData: IWarehouseData[]
        markers: MarkerItem[]
        searchData: IWarehouseSearchResponse | IWarehouseMapSearchResponse
    }
    methods: {
        handleBook: (info: OnBookInfo) => void
        handleTableUpdate: ({ sort, page }: any) => void
    }
}

export interface IWarehousesList {
    [details: number]: object
}

const Orders: React.FC<IList> = ({ settings, data, methods }) => {
    const { mode, fullSearch, circle, isLoading, quotationRequest } = settings
    const { pageData, filters, warehousesData, markers, searchData } = data
    const { handleBook, handleTableUpdate } = methods
    const warehousesListById: IWarehousesList = {}
    warehousesData.forEach((item) => {
        warehousesListById[item.id] = item
    })
    return (
        <>
            {mode === 'list' && !quotationRequest && (
                <div className="list">
                    <TableList
                        headers={WAREHOUSE_SEARCH_TABLE_HEADERS}
                        data={warehousesData}
                        pending={isLoading}
                        pageCount={searchData?.provider?.page_count}
                        page={pageData.page}
                        sort={pageData.sort}
                        onUpdate={handleTableUpdate}
                    >
                        {warehousesData.map((item: any) => (
                            <CarrierWarehouseTableLine
                                fullSearch={fullSearch}
                                key={item.id}
                                {...item}
                                address={item.address}
                                onBook={handleBook}
                            />
                        ))}
                    </TableList>
                </div>
            )}

            {mode === 'map' && !quotationRequest && markers && markers.length > 0 && (
                <Map
                    filters={filters}
                    markers={markers}
                    center={circle}
                    maxZoom={fullSearch ? 17 : 11}
                    data={warehousesListById}
                    handleBook={handleBook}
                />
            )}
        </>
    )
}

const OrderList: React.FC<IOrderList> = ({ settings, data, methods }) => {
    const {
        searchPage,
        mode,
        fullSearch,
        hasFullSearch,
        publicQuery = false,
        isLoading,
        quotationRequestLoading,
        quotationRequest,
    } = settings
    const { pageData, totalFound } = data
    const { updatePageData, updateQueryData, handleModeUpdate, handleFullSearchUpdate } = methods
    return (
        <>
            <FormWarehouseSearch
                publicQuery={publicQuery}
                searchPage={searchPage}
                send={updateQueryData}
                pending={isLoading || quotationRequestLoading}
                initialFetch
            />

            <div className="modes">
                {!quotationRequest && (
                    <div className="count" style={{ opacity: isLoading ? '0' : '1' }}>
                        {totalFound} {totalFound === 1 ? 'warehouse' : 'warehouses'} found
                    </div>
                )}

                {quotationRequestLoading && <Loader />}
                {!quotationRequest && (
                    <div className="list">
                        {hasFullSearch && (
                            <Checkbox
                                name="fullSearch"
                                onChange={handleFullSearchUpdate}
                                value={Boolean(fullSearch)}
                                label="Full Search"
                            />
                        )}
                        <span className="list-title">Items per page</span>
                        <Select
                            items={[
                                { name: '10', value: 10 },
                                { name: '20', value: 20 },
                                { name: '30', value: 30 },
                                { name: '40', value: 40 },
                                { name: '50', value: 50 },
                            ]}
                            name="status"
                            className="list-select"
                            value={pageData['per-page']}
                            disabled={isLoading}
                            onAction={({ value }) =>
                                updatePageData((d: any) => ({ ...d, page: 0, 'per-page': Number(value) }))
                            }
                        />
                        {(['list', 'map'] as IWarehouseRequestMode[]).map((item) => (
                            <button
                                key={item}
                                className={cn('item', `mode-${item}`, { active: item === mode })}
                                onClick={() => handleModeUpdate(item)}
                            >
                                <span
                                    className={cn('icon', {
                                        'icon-pin': item === 'map',
                                        'icon-sandwich': item === 'list',
                                    })}
                                />
                            </button>
                        ))}
                    </div>
                )}
            </div>
            <Orders settings={settings} data={data} methods={methods} />
        </>
    )
}

export default memo(OrderList)
