import React from 'react'
import { SignUpTemplate } from '@/pages/SignUp/components/SignUpTemplate'
import { useFormik } from 'formik'
import { InputText } from '@/components/InputText'
import Button from '@/components/Button'
import { updateLoggedState, useUserState } from '@/store/user'
import * as Yup from 'yup'
import { updateCompany, useCompany } from '@shared/api/company'
import './SignUpWarehouseStep.scss'

interface SignUpWarehouseTaxNumberStepValues {
    tax_id: string
}

export const SignUpWarehouseTaxNumberStep: React.FC = () => {
    const { state: userState, dispatch } = useUserState()
    const company = useCompany()
    const formik = useFormik<SignUpWarehouseTaxNumberStepValues>({
        onSubmit: async (values) => {
            try {
                await updateCompany({
                    tax_id: values.tax_id,
                    entity: company.data?.entity,
                })
                await updateLoggedState(
                    {
                        ...userState.userInfo!,
                        registration_step: 100,
                    },
                    dispatch,
                )
            } catch (errors: any) {
                if (errors) {
                    // eslint-disable-next-line no-restricted-syntax
                    for (const key of Object.keys(errors)) {
                        formik.setFieldError(key, errors[key][0])
                    }
                }
            }
        },
        initialValues: {
            tax_id: '',
        },
        validationSchema: Yup.object().shape({
            tax_id: Yup.string().max(36),
        }),
    })
    return (
        <SignUpTemplate title="What is your tax number?" step={[4, 4]}>
            <div className="sign-up_warehouse_taxnumber">
                <form onSubmit={formik.handleSubmit}>
                    <small
                        style={{
                            fontSize: 14,
                            textAlign: 'center',
                            display: 'block',
                            lineHeight: 1.4,
                            marginBottom: 12,
                        }}
                    >
                        TIN needed to be able payout for your service.
                        <br /> You can fill it out later with bank account info
                    </small>
                    <InputText
                        text
                        name="tax_id"
                        onChange={formik.handleChange}
                        value={formik.values.tax_id}
                        error={formik.errors.tax_id}
                        label="Tax number"
                    />

                    <br />
                    <Button
                        label="Save"
                        type="submit"
                        disabled={!formik.isValid}
                        loading={formik.isSubmitting}
                        types={['blue', 'full']}
                    />
                    {/* eslint-disable-next-line jsx-a11y/anchor-is-valid,jsx-a11y/no-static-element-interactions */}
                    {!formik.isSubmitting && (
                        // eslint-disable-next-line jsx-a11y/no-static-element-interactions, jsx-a11y/anchor-is-valid
                        <a className="link-blue" style={{ marginTop: 12 }} onClick={formik.submitForm}>
                            <small style={{ fontSize: 14 }}>Skip</small>
                        </a>
                    )}
                </form>
            </div>
        </SignUpTemplate>
    )
}
