import React, { FC } from 'react'
import { Navigate, useNavigate } from 'react-router-dom'
import { useUserState } from '@/store/user'
import { useLocation } from 'react-router'
import './Landing.scss'

interface ILandingProps {}

const Landing: FC<ILandingProps> = () => {
    const location = useLocation()
    const navigate = useNavigate()
    const {
        state: { isLoggedIn, userInfo },
    } = useUserState()
    const hasNotFinishedSignUp =
        isLoggedIn && !(userInfo?.registration_step === null || userInfo?.registration_step === 100)

    if (isLoggedIn && !hasNotFinishedSignUp) {
        return <Navigate to={{ pathname: '/company' }} />
    }

    if (hasNotFinishedSignUp) {
        return <Navigate to={{ pathname: '/sign-up', search: location.search }} />
    }

    return <Navigate to="/login" />
}

export default Landing
