import React, { memo, MouseEventHandler } from 'react'
import { Loader } from '@/components/Loader'
import cn from 'classnames'
import './Button.scss'

export type ButtonType =
    | 'plain'
    | 'blue'
    | 'bordered'
    | 'gradient'
    | 'red'
    | 'remove'
    | 'large'
    | 'full'
    | 'bold'
    | 'small'
    | 'inverse'
    | 'loading'

export interface IButton {
    className?: string
    label?: React.ReactNode
    children?: React.ReactNode
    loading?: boolean
    onClick?: MouseEventHandler<HTMLButtonElement>
    types?: ButtonType[]
    type?: 'button' | 'submit'
    form?: string
    disabled?: boolean
}

const Button: React.FC<IButton> = ({
    children,
    className,
    type = 'button',
    label,
    onClick,
    loading,
    types = [],
    disabled,
    form,
}) => (
    <button
        type={type}
        form={form}
        className={cn(...types, className, { loading })}
        disabled={disabled}
        onClick={onClick}
    >
        {types.some((i) => i === 'gradient') && <span className="bg-pressed" />}

        {!loading && <span className="slot">{label}</span>}
        {!loading && children}
        {loading && <Loader type={`button ${type}`} />}
    </button>
)

export default memo(Button)
