import React, { FC, useCallback, useEffect, useState } from 'react'
import './WarehouseSettingsPayment.scss'
import { useFormik } from 'formik'
import { InputText } from '@/components/InputText'
import { IPaymentSettingsForm } from '@shared/types'
import { utils } from '@shared/common/utils'
import { useInfo } from '@shared/api/useDataHooks'
import { showInfoModal } from '@/parts/Modals/InfoModal'
import { PaymentPayload, updatePayment } from '@shared/api/payment'
import { EntityType } from '@shared/api/types'
import Button from '@/components/Button'
import { showFeedbackModal } from '@/parts/Modals/FeedbackModal'
import { useWarehouse } from '@shared/api/warehouse'
import { getAccountNumber, useCompany } from '@shared/api/company'
import { PAYMENT_FORM_INITIAL_VALUES, PaymentFormSchema } from './PaymentForm'
import { CompanySettingsFormData } from '@shared/hooks/useCompanySettings'
import { WarehouseStatusInfo } from '@/parts/WarehouseStatusInfo/WarehouseStatusInfo'
import { usePagePrompt } from '@/components/PagePrompt/PagePrompt'

declare global {
    interface Window {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        // eslint-disable-next-line no-undef
        Stripe: stripe.StripeStatic
    }
}

export const WarehouseSettingsPayment: FC = () => {
    const [revalidating, setRevalidating] = useState(false)
    const { data } = useCompany()
    const {
        // eslint-disable-next-line @typescript-eslint/naming-convention
        data: info,
    } = useInfo()
    const { data: warehouse, formValues, revalidate, loading } = useWarehouse()

    const formik = useFormik<IPaymentSettingsForm>({
        initialValues: PAYMENT_FORM_INITIAL_VALUES,
        validationSchema: PaymentFormSchema,
        isInitialValid: true,
        enableReinitialize: false,
        onSubmit: async (values) => {
            try {
                let reset = true
                if (utils.isNewBankRequisites(values.account_number, values.routing_number) && window.Stripe) {
                    const stripeInstance = window.Stripe(info.payment_token!)
                    const stripe = await stripeInstance.createToken('bank_account', {
                        country: 'US',
                        currency: 'usd',
                        routing_number: values.routing_number,
                        account_number: values.account_number.trim(),
                        account_holder_name: `${values.entity}`,
                        account_holder_type: 'company',
                    })

                    if (stripe?.token) {
                        const payload: Partial<PaymentPayload> = {}
                        payload.phone = info.phone.replaceAll(' ', '')
                        payload.stripe_token = stripe.token.id
                        payload.account_number = values.account_number.trim()
                        payload.routing_number = values.routing_number
                        payload.last_account_id_numbers = values.account_number.trim().substr(-4)
                        await updatePayment(EntityType.warehouse, payload as PaymentPayload)
                        showInfoModal({
                            props: {
                                title: 'Payment information successfully saved',
                                message: '',
                            },
                        })
                    }
                    if (stripe?.error) {
                        const field = stripe.error?.param?.match(/[^[\]]+(?=])/g)?.[0]
                        const msg = stripe.error.message
                        formik.setFieldError(field as string, msg)
                        reset = false
                    }
                }

                if (reset) {
                    formik.resetForm({ values: formik.values })
                }
                formik.setSubmitting(false)
            } catch (e) {
                if (Array.isArray(e)) {
                    // eslint-disable-next-line no-restricted-syntax
                    for (const err of e) {
                        if (err.requirement === 'company.tax_id') {
                            formik.setFieldError(
                                'tax_id',
                                'Tax ID invalid. Please check and update it in company settings',
                            )
                        }
                        if (err.requirement.startsWith('company.address')) {
                            formik.setFieldError('billing_address', err.reason)
                        }
                    }
                }

                formik.setSubmitting(false)
                // eslint-disable-next-line no-console
            }
        },
    })

    usePagePrompt({
        dirty: formik.dirty,
        entity: data
            ? {
                  id: data.id,
                  type: EntityType.warehouse,
              }
            : undefined,
    })

    const feedback = () => {
        showFeedbackModal({
            props: {},
        })
    }

    useEffect(() => {
        if (!loading) {
            formik.resetForm({
                values: {
                    account_number: getAccountNumber(formValues?.payout_destination),
                    routing_number: formValues?.payout_destination?.routing_number || '',
                    billing_address: formValues?.billing_address || '',
                } as IPaymentSettingsForm,
            })
        }
    }, [loading])

    const reset = useCallback(async () => {
        setRevalidating(true)
        await revalidate()
        setRevalidating(false)
        if (formValues) {
            formik.resetForm({
                values: {
                    account_number: getAccountNumber(formValues.payout_destination),
                    routing_number: formValues.payout_destination.routing_number,
                    billing_address: formValues.billing_address,
                } as IPaymentSettingsForm,
            })
            formik.setSubmitting(false)
        }
    }, [formik, formValues, revalidate])

    return (
        <div className="warehouse-settings-company wrap">
            <div className="form-company form">
                <form onSubmit={formik.handleSubmit}>
                    {warehouse && <WarehouseStatusInfo warehouse={warehouse} />}
                    <div className="section">
                        <div className="header">
                            <div className="title">
                                <span>Bank Information</span>
                            </div>
                        </div>

                        <div className="form-wrap">
                            <div className="field billing-address">
                                <InputText
                                    text
                                    value={formik.values.billing_address}
                                    label="Billing address"
                                    disabled
                                    readOnly
                                    textError
                                    errorHide={false}
                                    error={(formik.errors as any).billing_address}
                                    name="billing_address"
                                />
                            </div>

                            <div className="field tax-id">
                                <InputText
                                    text
                                    value={data?.tax_id}
                                    label={CompanySettingsFormData.tax_id.label}
                                    disabled
                                    readOnly
                                    textError
                                    errorHide={false}
                                    error={(formik.errors as any).tax_id}
                                    name="tax_id"
                                />
                            </div>
                        </div>

                        <hr />

                        <div className="form-wrap">
                            <div className="field routing">
                                <InputText
                                    text
                                    value={formik.values.routing_number}
                                    onChange={formik.handleChange}
                                    label="Routing Number"
                                    name="routing_number"
                                    errorHide={false}
                                    textError
                                    errorOnFocus
                                    error={formik.errors.routing_number}
                                    required
                                    disabled={formik.isSubmitting}
                                />
                            </div>

                            <div className="field acccount">
                                <InputText
                                    text
                                    value={formik.values.account_number}
                                    onChange={formik.handleChange}
                                    label="Account Number"
                                    name="account_number"
                                    errorHide={false}
                                    textError
                                    errorOnFocus
                                    error={formik.errors.account_number}
                                    required
                                    disabled={formik.isSubmitting}
                                />
                            </div>
                        </div>
                    </div>

                    <div className="links media-mobile">
                        {/* eslint-disable-next-line jsx-a11y/anchor-is-valid,jsx-a11y/no-static-element-interactions */}
                        <a onClick={feedback} className="secondary-link">
                            Contact support
                        </a>
                    </div>

                    <div className="page-footer">
                        <div className="btns">
                            <Button
                                types={['large', 'blue']}
                                type="submit"
                                disabled={!formik.isValid || !formik.dirty || formik.isSubmitting}
                                loading={formik.isSubmitting}
                                label="Save"
                            />
                            <Button types={['large', 'plain']} onClick={reset} loading={revalidating}>
                                Cancel
                            </Button>
                        </div>

                        {/* eslint-disable-next-line jsx-a11y/anchor-is-valid,jsx-a11y/no-static-element-interactions */}
                        <a onClick={feedback} className="secondary-link media-desktop">
                            Contact support
                        </a>
                    </div>
                </form>
            </div>
        </div>
    )
}
