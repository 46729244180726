import React, { FC, memo } from 'react'
import { showInfoModal } from '@/parts/Modals/InfoModal'
import { InputText, VerifStatus } from '@/components/InputText'
import Checkbox from '@/components/Checkbox'
import Button from '@/components/Button'
import './UserSettings.scss'
import { form } from '@/pages/User/UserAccountSettings/UserSettings/UserSettingsForm'
import { UserStatusInfo } from '@/parts/UserStatusInfo/UserStatusInfo'
import { showFeedbackModal } from '@/parts/Modals/FeedbackModal'
import { showChangePasswordModal } from '@/parts/Modals/ChangePasswordModal'
import { updateLoggedState, useUserState } from '@/store/user'
import { getStatus } from '@/utils/status'
import useUserSettings from '@shared/hooks/useUserSettings'

const UserSettings: FC = memo(() => {
    const { dispatch } = useUserState()
    const { formik, reset, submitFailed, data, mutate, loading, validating } = useUserSettings({
        onSuccess: async (userInfo) => {
            if (userInfo) {
                mutate(userInfo)
                await updateLoggedState(userInfo, dispatch)
            }

            showInfoModal({
                props: {
                    title: 'Personal information successfully changed',
                    message: '',
                },
            })
        },
    })

    const feedback = () => {
        showFeedbackModal({
            props: {},
        })
    }

    const changePassword = () => {
        showChangePasswordModal({
            props: {},
        })
    }

    return (
        <div className="user-settings wrap">
            <div className="form-user form">
                <form onSubmit={formik.handleSubmit}>
                    <UserStatusInfo />

                    <div className="section">
                        <div className="header">
                            <div className="title">
                                <span>Personal</span>
                            </div>
                            {/* eslint-disable-next-line jsx-a11y/anchor-is-valid,jsx-a11y/no-static-element-interactions */}
                            <a onClick={changePassword} className="link media-desktop">
                                Change password
                            </a>
                        </div>

                        <div className="form-wrap">
                            <div className="field first-name">
                                <InputText
                                    required
                                    textError
                                    text
                                    errorOnFocus={submitFailed}
                                    error={formik.errors.first_name}
                                    value={formik.values.first_name}
                                    onChange={formik.handleChange}
                                    placeholder={form.first_name.label}
                                    label={form.first_name.label}
                                    name="first_name"
                                />
                            </div>

                            <div className="field last-name">
                                <InputText
                                    text
                                    textError
                                    required
                                    error={formik.errors.last_name}
                                    value={formik.values.last_name}
                                    onChange={formik.handleChange}
                                    placeholder={form.last_name.label}
                                    label={form.last_name.label}
                                    name="last_name"
                                />
                            </div>

                            <div className="field position">
                                <InputText
                                    text
                                    value={formik.values.position}
                                    onChange={formik.handleChange}
                                    placeholder={form.position.label}
                                    label={form.position.label}
                                    name="position"
                                />
                            </div>
                        </div>

                        <div className="form-wrap">
                            <div className="field">
                                <InputText
                                    textError
                                    errorOnFocus={submitFailed}
                                    format="+1 (###) ### ####"
                                    onValueChange={(v) => {
                                        formik.handleChange({ target: { name: 'phone', value: v.value } })
                                    }}
                                    error={formik.errors.phone}
                                    value={formik.values.phone}
                                    required
                                    name="phone"
                                    label={form.phone.label}
                                />
                                <Checkbox
                                    value={formik.values.receive_sms}
                                    onChange={formik.handleChange}
                                    label={form.receive_sms.label}
                                    name="receive_sms"
                                />
                            </div>

                            <div className="field">
                                <InputText
                                    text
                                    textError
                                    status={getStatus(data?.email.status.codeName) as VerifStatus}
                                    errorOnFocus={submitFailed}
                                    required
                                    error={formik.errors.email}
                                    value={formik.values.email}
                                    onChange={formik.handleChange}
                                    placeholder={form.email.label}
                                    label={form.email.label}
                                    name="email"
                                />
                            </div>
                        </div>
                    </div>

                    <div className="links media-mobile">
                        {/* eslint-disable-next-line jsx-a11y/anchor-is-valid,no-console,jsx-a11y/no-static-element-interactions */}
                        <a className="link" onClick={changePassword}>
                            Change password
                        </a>
                        {/* eslint-disable-next-line jsx-a11y/anchor-is-valid,jsx-a11y/no-static-element-interactions,no-console */}
                        <a onClick={feedback} className="secondary-link">
                            Contact support
                        </a>
                    </div>

                    <div className="page-footer">
                        <div className="btns">
                            <Button
                                label="Save"
                                types={['large', 'blue']}
                                type="submit"
                                disabled={!formik.isValid || !formik.dirty || validating}
                                loading={formik.isSubmitting || loading}
                            />
                            <Button
                                label="Reset"
                                disabled={formik.isSubmitting}
                                loading={validating}
                                types={['large', 'plain']}
                                onClick={reset}
                            />
                        </div>

                        {/* eslint-disable-next-line jsx-a11y/anchor-is-valid,jsx-a11y/no-static-element-interactions,no-console */}
                        <a onClick={feedback} className="secondary-link media-desktop">
                            Contact support
                        </a>
                    </div>
                </form>
            </div>
        </div>
    )
})

export default UserSettings
