import { Warehouse } from '@shared/types'

export function moderated(warehouse: Warehouse): boolean {
    return warehouse.status.codeName === 'ACTIVE'
}

export function banned(warehouse: Warehouse): boolean {
    return warehouse.status.codeName === 'BANNED'
}

export function filled(warehouse: Warehouse): boolean {
    return !!(
        warehouse.entity &&
        warehouse.physical_address &&
        warehouse.physical_address?.address &&
        warehouse.status.code === 5
    )
}

export function active(warehouse: Warehouse): boolean {
    return !banned(warehouse) && filled(warehouse) && moderated(warehouse)
}
