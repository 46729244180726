import React, { ChangeEvent } from 'react'
import cn from 'classnames'
import './Item.scss'

const MAX_RATING = 5

interface IRatingItemProps {
    type?: string
    control?: boolean
    title?: React.ReactNode
    value?: number
    name?: string
    onSelect?: (rating: any) => void
    hint?: React.ReactNode
}

export const RatingItem: React.FC<IRatingItemProps> = ({
    name,
    value = 0,
    type,
    control,
    title,
    onSelect,
    children,
}) => (
    <div className={cn('rating-item', type, { control })}>
        <div className="title">
            <span>{title}</span>
            {children}
        </div>

        <div className="list">
            {Array.from(Array(MAX_RATING + 1).keys())
                .slice(1)
                .map((item) => (
                    // eslint-disable-next-line jsx-a11y/no-static-element-interactions
                    <div
                        key={item}
                        className={cn('item', { active: item <= Math.round(value) })}
                        onClick={() => onSelect?.({ target: { name: name || 'unknown', value: item } })}
                    >
                        <span className="icon-star" />
                    </div>
                ))}
        </div>
    </div>
)
