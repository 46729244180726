import React, { useState } from 'react'
import cn from 'classnames'
import { ReactComponent as IconArrowNext } from '@/assets/images/ic-arrow-next.svg'

import './Choose.scss'

interface ChooseOption {
    key: string
    title: React.ReactNode
}

interface ChooseProps {
    optionOne: ChooseOption
    optionTwo: ChooseOption
    onSelect: (key: string) => void
    selected?: string
}

export const Choose: React.FC<ChooseProps> = ({ optionOne, optionTwo, selected, onSelect }) => {
    const selectOptionOne = () => {
        onSelect(optionOne.key)
    }
    const selectOptionTwo = () => {
        onSelect(optionTwo.key)
    }
    return (
        <div className="sign_up-choose">
            {/* eslint-disable-next-line jsx-a11y/no-static-element-interactions */}
            <div
                className={cn('sign_up-select-entity', { active: selected === optionOne.key })}
                onClick={selectOptionOne}
            >
                {optionOne.title}
                <IconArrowNext />
            </div>
            {/* eslint-disable-next-line jsx-a11y/no-static-element-interactions */}
            <div
                className={cn('sign_up-select-entity', { active: selected === optionTwo.key })}
                onClick={selectOptionTwo}
            >
                {optionTwo.title}
                <IconArrowNext />
            </div>
        </div>
    )
}
