import React, { useEffect } from 'react'
import { SignUpTemplate } from '@/pages/SignUp/components/SignUpTemplate'
import { useFormik } from 'formik'
import { InputText } from '@/components/InputText'
import Button from '@/components/Button'
import { updateLoggedState, useUserState } from '@/store/user'
import * as Yup from 'yup'
import { updateWarehouse } from '@shared/api/warehouse'
import './SignUpWarehouseStep.scss'

interface SignUpWarehouseUSDOTValues {
    license: string
}

export const SignUpWarehouseUSDOTStep: React.FC = () => {
    const { state: userState, dispatch } = useUserState()
    const formik = useFormik<SignUpWarehouseUSDOTValues>({
        onSubmit: async (values) => {
            await updateWarehouse({
                license: values.license,
            })
            await updateLoggedState(
                {
                    ...userState.userInfo!,
                    registration_step: 23,
                },
                dispatch,
            )
        },
        initialValues: {
            license: '',
        },
        validationSchema: Yup.object().shape({
            license: Yup.string().required().min(5),
        }),
    })
    return (
        <SignUpTemplate title="What is your USDOT/MC#?" step={[3, 4]}>
            <div className="sign-up_warehouse_usdot">
                <form onSubmit={formik.handleSubmit}>
                    <InputText
                        text
                        name="license"
                        onChange={formik.handleChange}
                        value={formik.values.license}
                        error={formik.errors.license}
                        placeholder="USDOT or MC/MX#"
                        label="USDOT or MC/MX#"
                    />

                    <br />
                    <Button
                        label="Save"
                        type="submit"
                        disabled={!formik.isValid}
                        loading={formik.isSubmitting}
                        types={['blue', 'full']}
                    />
                </form>
            </div>
        </SignUpTemplate>
    )
}
