import React, { FC, memo, useState } from 'react'
import * as Yup from 'yup'
import { ModalRegistry } from '@shared/common/modals'
import { ModalPartialProps, Modals } from '@shared/types'
import Button from '@/components/Button'
import { InputText } from '@/components/InputText'
import { useFormik } from 'formik'
import { useUserState } from '@/store/user'
import { FeedbackPayload, sendFeedbackPassword } from '@shared/api/user'
import { EntityType } from '@shared/api/types'
import './FeedbackModal.scss'
import Textarea from '@/components/Textarea'

interface FeedbackForm {
    name: string | null
    phone: string | null
    email: string | null
    message: string
}

const FeedbackFormSchema = Yup.object().shape({
    name: Yup.string().required(),
    phone: Yup.string().required(),
    email: Yup.string().email('Invalid email').required('Required'),
    message: Yup.string().required(),
})

interface FeedbackModalProps {
    onFeedback?: (msg: string) => Promise<void> | void
}

const FeedbackModal: FC<FeedbackModalProps> = ({ onFeedback }) => {
    const {
        state: { userInfo },
    } = useUserState()
    const [errorMessage, setErrorMessage] = useState<string | null>(null)
    const formik = useFormik<FeedbackForm>({
        initialValues: {
            name: userInfo?.first_name || null,
            phone: userInfo?.phone?.number ? String(userInfo?.phone?.number) : '',
            email: userInfo?.email?.email || null,
            message: '',
        },
        validationSchema: FeedbackFormSchema,
        enableReinitialize: true,
        onSubmit: async (values) => {
            try {
                formik.setSubmitting(true)
                await sendFeedbackPassword(values as FeedbackPayload)
                formik.setSubmitting(false)
                ModalRegistry.get().close(Modals.FeedbackModal)
                await onFeedback?.('Your message has been sent successfully to the administrator')
            } catch (errors) {
                formik.setSubmitting(false)
                if (Array.isArray(errors)) {
                    setErrorMessage(JSON.stringify(errors))
                }
            }
        },
    })

    return (
        <div>
            {errorMessage && <p>{errorMessage}</p>}

            <form onSubmit={formik.handleSubmit}>
                <InputText
                    text
                    label="Name"
                    name="name"
                    onChange={formik.handleChange}
                    value={formik.values.name}
                    error={formik.errors.name}
                />
                <InputText
                    text
                    label="Phone number"
                    name="phone"
                    onChange={formik.handleChange}
                    value={formik.values.phone}
                    error={formik.errors.phone}
                    mask="### ### ####"
                />
                <InputText
                    text
                    label="E-mail"
                    name="email"
                    onChange={formik.handleChange}
                    value={formik.values.email}
                    error={formik.errors.email}
                />
                <Textarea
                    value={formik.values.message}
                    onChange={formik.handleChange}
                    error={formik.errors.message}
                    placeholder="Message"
                    label="Leave message"
                    name="message"
                />

                <div className="btns">
                    <Button
                        label="Send"
                        disabled={!formik.isValid}
                        loading={formik.isSubmitting}
                        types={['blue', 'large']}
                        type="submit"
                    />
                </div>
            </form>
        </div>
    )
}

ModalRegistry.get().register<FeedbackModalProps>(Modals.FeedbackModal, {
    id: 'FeedbackModalProps',
    className: 'modal-feedback',
    size: 'medium',
    Component: memo(FeedbackModal),
    title: 'Contact support',
})

export const showFeedbackModal = (props: ModalPartialProps<FeedbackModalProps>): void =>
    ModalRegistry.get().show<FeedbackModalProps>(Modals.FeedbackModal, props)

export default memo(FeedbackModal)
