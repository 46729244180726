import { IUserSettingsForm } from '@shared/types'
import * as Yup from 'yup'

export const form = {
    phone: {
        value: '',
        label: 'Phone number',
        validator: { required: true },
        mask: '### ### ####',
    },
    email: {
        value: '',
        label: 'E-mail',
        validator: { required: true, email: true },
    },
    first_name: {
        value: '',
        label: 'First name',
        validator: { required: true },
    },
    last_name: {
        value: '',
        label: 'Last name',
    },
    position: {
        value: '',
        label: 'Position',
    },
    receive_sms: {
        value: '',
        label: 'Receive text notifications',
    },
    pending: false,
}

export const UserSettingsSchema = Yup.object().shape({
    phone: Yup.string().required('Required'),
    email: Yup.string().email('Invalid email').required('Required'),
    first_name: Yup.string().required().min(2, 'Too short! Min. 2 characters'),
    last_name: Yup.string().required().min(2, 'Too short! Min. 2 characters'),
    position: Yup.string(),
    receive_sms: Yup.boolean(),
})

export const USER_SETTINGS_INITIAL_VALUES: IUserSettingsForm = {
    phone: '',
    email: '',
    first_name: '',
    last_name: '',
    position: '',
    receive_sms: false,
}
