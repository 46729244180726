export const getServicesNumber = (serviceGroup: any, groupTypes: number[]): number => {
    let result = 0

    groupTypes.forEach((type) => {
        if (serviceGroup && serviceGroup[type]) {
            result += 1
        }
    })

    return result
}
