import React, {useEffect} from 'react';

const Iframe: React.FC<{
    content: string, style?: any, onClick?: { id: string; callback: () => void; }
}> = ({style, content, onClick}) => {
    // eslint-disable-next-line @typescript-eslint/naming-convention
    let iframe_ref: any = null
    const writeHTML: any = (frame: any) => {
        if (!frame) {
            return
        }
        iframe_ref = frame
        const doc = frame.contentDocument
        doc.open()
        doc.write(content)
        doc.close()
        const docu = frame.contentWindow.document;
        // eslint-disable-next-line no-param-reassign
        frame.style.width = '100%'
        // eslint-disable-next-line no-param-reassign
        frame.style.height = `${docu.body.scrollHeight}px`

        if (onClick) {
            docu.getElementById(onClick.id)?.addEventListener('click', onClick.callback)
        }
    }

    useEffect(() => () => {
        if (iframe_ref && iframe_ref.contentWindow && onClick) {
            const docu = iframe_ref.contentWindow.document;
            docu.getElementById(onClick.id)?.removeEventListener('click', onClick.callback)
        }
    }, [])

    return (
        <iframe style={style} scrolling="no" title="quote" src="about:blank" frameBorder="0"
                ref={(ref) => writeHTML(ref)}/>
    )
}

export default Iframe
