import React, { HTMLProps, memo } from 'react'
import { format, parse } from 'date-fns'
import Icon from '@/components/Icon/Icon'
import Button from '@/components/Button'
import { Order } from '@shared/types'
import Currency from '@/components/Currency'
import { utils } from '@shared/common/utils'

interface TableLineModalOrdersProps extends HTMLProps<HTMLDivElement> {
    order: Order
    onOpenOrder?: (id: number) => void
}

const TableLineModalOrders: React.FC<TableLineModalOrdersProps> = ({ order, onOpenOrder }) => {
    const timeRange = () =>
        order.arrival_from && order.arrival_to
            ? `${format(parse(order.arrival_from, 'kk:mm:ss', new Date()), 'p')} - ${format(
                  parse(order.arrival_to, 'kk:mm:ss', new Date()),
                  'p',
              )}`
            : 'Distribution order'
    return (
        <div className="row table-line-modal-orders">
            <div className="cell cell-id">
                <div className="label">PO №</div>
                <span>{order.id}</span>
            </div>

            <div className="cell cell-time">
                <div className="label">Estimated Arrival Time</div>
                <span>{timeRange()}</span>
            </div>

            <div className="cell cell-services">
                <div className="label">Services</div>

                <div className="icons">
                    {order.services.map((service) => (
                        <div key={service.title}>
                            {(service.type_code === 'DELIVERY' ||
                                service.type_code === 'SERVICE' ||
                                service.type_code === 'PICKUP') && (
                                <div className="icon-service">
                                    <Icon
                                        code={service.icon || utils.getDefaultServiceIcon(service.title)}
                                        type="services"
                                    />

                                    <span className="tooltip">
                                        <span className="arrow" />
                                        {service.title}
                                    </span>
                                </div>
                            )}

                            {order.status.code === 1 && <span className="icon-status pending" />}
                            {order.parent_id && <span className="icon-status sub-order" />}
                        </div>
                    ))}
                </div>
            </div>

            <div className="cell cell-amount">
                <div className="label">Units</div>
                <span>
                    {order.amount} {order.unit.title}
                </span>
            </div>

            <div className="cell cell-contacts">
                <div className="label">Contacts</div>

                <div className="contacts">
                    <span className="contacts-icon">i</span>

                    <div className="tooltip">
                        <div className="arrow" />

                        <div className="contacts-list">
                            {order.carrier.company.entity && (
                                <div className="item">
                                    <span className="icon icon-user" />
                                    <span className="value">
                                        {order.carrier.company.group_name} {order.carrier.company.entity}
                                    </span>
                                </div>
                            )}

                            {order.carrier.license && (
                                <div className="item">
                                    <span className="icon icon-license" />
                                    <span className="value">{order.carrier.license?.number}</span>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>

            <div className="cell cell-total">
                <div className="label">Total Cost</div>
                <span>
                    <Currency value={order.total} />
                </span>
            </div>

            <div className="cell cell-button">
                <Button label="Details" types={['inverse', 'small']} onClick={() => onOpenOrder?.(order.id)} />
            </div>
        </div>
    )
}

export default memo(TableLineModalOrders)
