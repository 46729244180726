import React, { FC, useContext, useReducer } from 'react'
import { Company } from '@shared/types'
import { CompanyAction, CompanyActionType, CompanyState, CompanyStore } from './company'

const companyDefaultState: CompanyState = {}

const CompanyContext = React.createContext<CompanyStore>({ state: companyDefaultState })

export const useCompanyState = (): CompanyStore => useContext<CompanyStore>(CompanyContext)

export const entityReducer = (state: CompanyState, action: CompanyAction): CompanyState => {
    switch (action.type) {
        case CompanyActionType.SET_COMPANY_STATE:
            return { ...state, companyInfo: action.companyState }
        default:
            throw new Error('Unknown company action')
    }
}

export const updateCompanyState = (companyInfo: Company, dispatch?: React.Dispatch<CompanyAction>): void => {
    if (dispatch) {
        dispatch({
            type: CompanyActionType.SET_COMPANY_STATE,
            companyState: companyInfo,
        })
    }
}

export const CompanyProvider: FC = ({ children }) => {
    const [state, dispatch] = useReducer(entityReducer, companyDefaultState)
    return <CompanyContext.Provider value={{ state, dispatch }}>{children}</CompanyContext.Provider>
}
