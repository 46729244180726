import { DragEvent, ChangeEvent, useRef, useState } from 'react'

export type DragAndDropOptions = {
    onChange?: (files: FileList) => void
    dropAreaId: string
}

export const useDragAndDrop = (options: DragAndDropOptions) => {
    const [dragActive, setDragActive] = useState(false)
    const inputRef = useRef<HTMLInputElement>(null)

    const handleDrag = function (e: DragEvent<HTMLElement>) {
        e.preventDefault()
        e.stopPropagation()
        if (e.type === 'dragenter' || e.type === 'dragover') {
            setDragActive(true)
        } else if (e.type === 'dragleave') {
            setDragActive(false)
        }
    }

    const handleDrop = function (e: DragEvent<HTMLElement>) {
        e.preventDefault()
        e.stopPropagation()
        setDragActive(false)
        if (e.dataTransfer.files && e.dataTransfer.files[0] && options.onChange) {
            options.onChange(e.dataTransfer.files)
        }
    }

    const handleChange = function (e: ChangeEvent<HTMLInputElement>) {
        e.preventDefault()
        if (e.target.files && e.target.files[0] && options.onChange) {
            options.onChange(e.target.files)
        }
    }

    const handleBrowseFiles = () => {
        if (inputRef.current) {
            inputRef.current.click()
        }
    }

    return {
        handleBrowseFiles,
        handleChange,
        handleDrop,
        handleDrag,
        dragActive,
        inputRef,
    }
}
